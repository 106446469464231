import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { ClibButtonModule } from '@broadstone/clib-button';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { ModalModule } from '@broadstone/modal';
import { SuspendWorkerComponent } from './suspend-worker.component';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';


@NgModule({
	declarations: [SuspendWorkerComponent],
	exports: [SuspendWorkerComponent],
	imports: [
		CommonModule,
		FormsModule,
		ClibButtonModule,
		BrowserAnimationsModule,
		HttpClientModule,
		ModalModule,
		NzSkeletonModule,
	]
})
export class SuspendWorkerModule { }
