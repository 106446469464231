import { AuthSessionService } from '@broadstone/auth-session-service';
import { ApiService } from '@broadstone/api-service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class LocationsService {


    constructor(
        public http: HttpClient,
        public session: AuthSessionService,
        public api: ApiService,
    ) { }

    // google suggestions ep (takes input(name, postcode, street etc))
    public getSuggestions(input: string): Promise<any> {
        const url = this.api.getFullUrl('locations/place/suggestions?input=:input', { input });
        const headers = this.api.getDefaultHeaders(this.session.getToken());
        return new Promise((resolve, reject) => {
            this.http.get(url, { headers })
                .subscribe(
                    (data) => resolve(data),
                    (error) => reject(this.api.handleErrors(error))
                );
        });
    }

    // google places ep (takes ID and returns Place details)
    public getPlace(id: number): Promise<any> {
        const url = this.api.getFullUrl('locations/place/:id', { id });
        const headers = this.api.getDefaultHeaders(this.session.getToken());
        return this.http.get(url, { headers })
            .pipe(
                map((data: any) => {
                    return data.data;
                })
            )
            .toPromise();
    }

    public getGeolocation(postcode): Promise<any> {
        const url = this.api.getFullUrl('locations/geocode?postcode=:postcode', { postcode });
        const headers = this.api.getDefaultHeaders(this.session.getToken());
        return this.http.get(url, { headers })
            .pipe(
                map((data: any) => {
                    return data.data;
                })
            )
            .toPromise();
    }

}


