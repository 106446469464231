import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';




@Injectable()
export class HotJarService {
  constructor() {

  }

  public appendHotJarTrackingCode() {
	if(environment.hotJar.hotJarPause){
		return;
	}

    try {
      const script = document.createElement('script');
      script.innerHTML = `
      (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:` + environment.hotJar.hotJarKey + `,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
      `;
      document.head.appendChild(script);
    } catch (ex) {
      console.error('Error appending hotjar');
      console.error(ex);
    }
  }

  public emit_hotjar(user_id, object) {
	if(environment.hotJar.hotJarPause){
		return;
	}
    let userId = user_id || null; // Replace your_user_id with your own if available.
    window['hj']('identify', userId, object);
  }
}
