import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';


@Component({
    selector: 'app-input-suggestions',
    templateUrl: './input-suggestions.component.html',
    styleUrls: ['./input-suggestions.component.scss'],
})
export class InputSuggestionsComponent implements OnInit {
    @Input() placeholder: string;
    @Input() myid: string; //specify id used when clicking on the label to select the field
    @Input() element_width: string;
    @Input() clear_input: boolean = false;
    @Input() avatar: boolean = false;
    @Input() required: boolean;
    @Input() value: any;
    @Input() data_array: any = [];
    @Input() loading: boolean;
    @Output() valueChange: EventEmitter<any> = new EventEmitter();
    @Output() valueSelected: EventEmitter<any> = new EventEmitter();
    timer: any = null;
    show_sugestions: boolean;
    constructor() { }

    ngOnInit() { }

    ngOnChanges() {
        if (this.value) {
            if (this.data_array.length > 0) {
                this.show_sugestions = true;
            }
        } else {
            this.hideSuggestions();
        }
    }

    checkApi() { // WHEN START TYPING
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.valueChange.emit(this.value);
        }, 500);
    }

    onBlur(e) {
        setTimeout(() => {
            this.hideSuggestions();
            if (this.clear_input) {
                this.value = '';
            }
        }, 550);
    }

    hideSuggestions() {
        this.data_array.length = 0;
        this.show_sugestions = false;
    }

    setValue(value) {
        this.show_sugestions = false;
        this.valueSelected.emit(value); // EMIT OBJECT
    }

}
