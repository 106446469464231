import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PopNotificationService } from '@broadstone/pop-notification-service';
import * as moment from 'moment';
import { EmployeesService } from 'src/services/public_api';

@Component({
	selector: 'app-edit-worker',
	templateUrl: './edit-worker.component.html',
	styleUrls: ['./edit-worker.component.scss']
})
export class EditWorkerComponent {
	@Input() worker: any;
	@Output() refreshWorkers: EventEmitter<any> = new EventEmitter();
	niDefault: string;
	firstNameDefault: string;
	lastNameDefault: string;
	emailDefault: string;
	phoneDefault: string;
	leftDayDefault: string;
	ninoPattern =
		/^(?!BG)(?!GB)(?!NK)(?!KN)(?!TN)(?!NT)(?!ZZ)(?:[A-CEGHJ-PR-TW-Z][A-CEGHJ-NPR-TW-Z])(?:\s*\d\s*){6}([A-D]|\s)$/i;
	lightStrike: string = 'assets/icons/paleStrike.svg';
	darkStrike: string = 'assets/icons/darkStrike.svg';
	disabledDate = (current: Date): boolean => {
		const today = new Date();
		today.setHours(0, 0, 0, 0);
		return  current < today;
	};
	constructor(
		private employeesService: EmployeesService,
		private popNotif: PopNotificationService
	) {

	}
	ngOnChanges() {
		if (this.worker) {
			this.niDefault = this.worker.profile.ni_number;
			this.firstNameDefault = this.worker.details.name;
			this.lastNameDefault = this.worker.details.surname;
			this.emailDefault = this.worker.details.email;
			this.phoneDefault = this.worker.details.phone;
			this.leftDayDefault = this.worker.details.leftAt;
		}
	}

	save() {

		const promises = [];

		if (this.leftDayDefault != this.worker.details.leftAt) {
			if (this.worker.details.leftAt !== null) {
				promises.push(this.updateLeftAt());
			} else if (this.worker.details.leftAt === null) {
				promises.push(this.rejoin());
			}
		}

		promises.push(this.updateWorker());

		Promise.all(promises)
			.then(() => {
				this.popNotif.createNotification('success', 'Success', 'User updated');
				this.refreshWorkers.emit();
			})
			.catch((err) => {
				if (err) {
					if (err['user_job_profiles.ni_number']) {
						this.popNotif.createNotification('error', 'There has been a problem.', err['user_job_profiles.ni_number']);
					}
					if (err['worker_tax_information.date_of_birth']) {
						this.popNotif.createNotification('error', 'There has been a problem.', err['worker_tax_information.date_of_birth']);
					}
					if (err['users.email']) {
						this.popNotif.createNotification('error', 'There has been a problem.', err['users.email']);
					}
					if (err['users.name']) {
						this.popNotif.createNotification('error', 'There has been a problem.', err['users.name']);
					}
					if (err['users.surname']) {
						this.popNotif.createNotification('error', 'There has been a problem.', err['users.surname']);
					}
					if (err['left_at']) {
						this.popNotif.createNotification('error', 'There has been a problem.', err['left_at']);
					}
				}
			});

	}

	updateWorker() {
		const updateObj = {};
		if (this.worker.taxInformation.title) {
			updateObj['worker_tax_information'] = {
				...updateObj['worker_tax_information'],
				title: this.worker.taxInformation.title
			};
		}
		if (this.worker.taxInformation.gender) {
			updateObj['worker_tax_information'] = {
				...updateObj['worker_tax_information'],
				gender: this.worker.taxInformation.gender
			};
		}
		if (this.worker.taxInformation.maritalStatus) {
			updateObj['worker_tax_information'] = {
				...updateObj['worker_tax_information'],
				marital_status: this.worker.taxInformation.maritalStatus
			};
		}
		if (this.worker.taxInformation.dateOfBirth) {
			updateObj['worker_tax_information'] = {
				...updateObj['worker_tax_information'],
				date_of_birth: moment(this.worker.taxInformation.dateOfBirth).format('YYYY-MM-DD')
			};
		}
		updateObj['users'] = {
			name: this.worker.details.name !== this.firstNameDefault ? this.worker.details.name : '',
			surname: this.worker.details.surname !== this.lastNameDefault ? this.worker.details.surname : '',
			email: this.worker.details.email !== this.emailDefault ? this.worker.details.email : '',
			mobile_number: this.worker.details.phone !== this.phoneDefault ? this.worker.details.phone : ''
		};

		if (this.worker.profile.ni_number !== this.niDefault) {
			updateObj['user_job_profiles'] = {
				ni_number: this.worker.profile.ni_number
			};
		}

		return this.employeesService.updateWorker(updateObj, this.worker.details.userUuid);


	}

	updateLeftAt() {
		const leftObj = {
			left_at: moment(this.worker.details.leftAt).format('YYYY-MM-DD'),
			user_uuid: this.worker.details.userUuid
		};
		return this.employeesService.leftAtWorker(leftObj);
	}

	rejoin() {
		return this.employeesService.reJoinWorker({ user_uuid: this.worker.details.userUuid });
	}
}
