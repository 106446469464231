import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { StaffService, CompaniesService, UsersService, AuthService } from 'src/services/public_api';
import { GlobalHelpers } from '@broadstone/helpers';
import { Router } from '@angular/router';
import { AuthSessionService } from '@broadstone/auth-session-service';
import { PopNotificationService } from '@broadstone/pop-notification-service';

@Component({
	selector: 'app-setup-form',
	templateUrl: './setup-form.component.html',
	styleUrls: ['./setup-form.component.scss']
})
export class SetupFormComponent implements OnInit {
	@Input() editComponent: any;
	@Output() success: EventEmitter<any> = new EventEmitter();
	legal_name: string;
	company_name: string;
	company_number: string;
	control_email: string;
	control_phone: string;
	billing_email: string;
	billing_phone: string;
	company_description: string;
	owner_email: string;
	full_name: string;
	clock_distance_limit: number = 0.1;
	imagesrc: any = null;
	timesheets_emails = [];
	save_loading: boolean = false;
	currentUser: any;
	submitted: boolean = false;
	company_edit: boolean;
	metricUnits: boolean = true;
	max: number = 5;
	noFixedArea: boolean;
	radiusInMetres: number = 0;
	owner: boolean = false;

	constructor(
		private globalHelpers: GlobalHelpers,
		private staff_services: StaffService,
		private popNotif: PopNotificationService,
		private session: AuthSessionService,
		private companies: CompaniesService,
		private router: Router,
		public user: UsersService,
		private auth: AuthService
	) {
		if (this.session.geAdminHeader()) {
			this.company_edit = true;
		}
		else {
			this.user.getCurrentUser().then((res) => {
				this.check_permissions(res.permissions)
			}).catch(() => {
				return;
			});
		}
	}

	ngOnInit() {
		if (this.session.isAuthenticated()) {
			this.currentUser = this.session.getUser();
		}
	}

	ngOnChanges() {
		this.populateInput();
	}

	check_permissions(permissions) {
		if (!permissions) {
			return;
		}
		permissions.forEach(element => {
			if (element.type === 'company.company.edit') {
				this.company_edit = true;
			}
		})
	}

	populateInput() {
		if (this.editComponent) {
			this.legal_name = this.editComponent.legal_name;
			this.company_number = this.editComponent.company_reg;
			this.company_name = this.editComponent.company_name;
			this.billing_email = this.editComponent.billing_email ? this.editComponent.billing_email : '';
			this.billing_phone = this.editComponent.billing_phone;
			this.timesheets_emails = this.editComponent.timesheet_email;
			this.imagesrc = this.editComponent.company_logo;
			this.company_description = this.editComponent.company_description;
			this.control_phone = this.editComponent.control_phone;
			this.control_email = this.editComponent.control_email;
			this.full_name = this.editComponent.owner_name;
			this.owner_email = this.editComponent.owner_email;
			if (this.editComponent.owner_name) {
				this.owner = true;
			}
			this.setSiteRadius(this.editComponent)
		}
	}

	setSiteRadius(res) {
		this.metricUnits = true;
		this.max = 5;
		if (res.clock_distance_limit === 0 || res.clock_distance_limit === null) {
			this.noFixedArea = true;
			this.clock_distance_limit = 0;
		}
		else {
			let radius = res.clock_distance_limit ? (res.clock_distance_limit / 1000) : 0;
			this.clock_distance_limit = Math.round(radius * 10) / 10;
		}
	}

	convertBackToKm() {
		this.metricUnits = true;
		this.max = 5;
		if (this.clock_distance_limit === 0 || this.clock_distance_limit === null) {
			this.noFixedArea = true;
			this.clock_distance_limit = 0;
		}
		else {
			let radius = this.clock_distance_limit / 1000;
			this.clock_distance_limit = Math.round(radius * 10) / 10;
		}
	}

	selectMobileResponse() {
		if (!this.noFixedArea) {
			this.clock_distance_limit = 0;
			this.noFixedArea = true;
		}
		else if (this.noFixedArea) {
			this.clock_distance_limit = 1;
			this.noFixedArea = false;
		}
	}

	onSwitchChange(e) {
		if (this.metricUnits) { //change miles to kms
			this.max = 5;
			let radius = this.clock_distance_limit * 1.6;
			this.clock_distance_limit = Math.round(radius * 10) / 10;
		}
		else if (!this.metricUnits) { //change kms to miles
			this.max = 3;
			let radius = this.clock_distance_limit * 0.621371;
			this.clock_distance_limit = Math.round(radius * 10) / 10;
		}
	}

	getMeters() {
		if (this.metricUnits) {
			this.clock_distance_limit = Math.floor(this.clock_distance_limit * 1000);
		}
		else {
			this.clock_distance_limit = Math.floor(this.clock_distance_limit * 1609.344);
		}
	}

	getImg(event) {
		let fileobj = event.target.files;
		let file = fileobj[0];
		if (file) {
			const reader = new FileReader();
			reader.onload = (e: any) => {
				this.imagesrc = e.target.result;
			}
			reader.readAsDataURL(file);
		}
	}

	save_list(e) {
		this.timesheets_emails = e;
	}

	logout() {
		this.session.logout();
		this.auth.logout().catch(err => {
			console.log(err)
		});
		this.router.navigate(['/login']);
	}

	back() {
		this.router.navigate(['/admin']);
	}

	create(form) {
		this.submitted = true;
		this.getMeters();
		if (!form.valid) {
			const directives = form._directives;
			this.convertBackToKm();
			for (let element of directives) {
				const { name } = element;
				if ((name == 'legal_name' && !element.valid) || (name == 'company_number' && !element.valid) || (name == 'company_name' && !element.valid)) {
					this.globalHelpers.scroll_to_section('company-information');
					break;
				} else if (name == 'billing_email' && !element.valid) {
					this.globalHelpers.scroll_to_section('invoicing-timesheets');
					break;
				} else if (name == 'company_description' && !element.valid) {
					this.globalHelpers.scroll_to_section('aditional-info');
					break;
				} else if ((name == 'control_phone' || name == 'control_email') && !element.valid) {
					this.globalHelpers.scroll_to_section('control-room');
					break;
				} else if ((name == 'full_name' || name == 'owner_email') && !element.valid) {
					this.globalHelpers.scroll_to_section('owner');
					break;
				}

			};

		} else {
			let owner_data = {
				"title": this.company_name,
				"legal_title": this.legal_name,
				"description": this.company_description,
				"clock_distance_limit": this.clock_distance_limit,
				"contact_number": this.control_phone ? this.control_phone.toString() : '',
				"check_email": this.control_email,
				"invoice_email": [this.billing_email],
				"invoice_phone": this.billing_phone ? this.billing_phone.toString() : '',
				"timesheet_email": this.timesheets_emails,
				"company_registration_number": this.company_number ? this.company_number.toString() : '',
				"acs_number": "",
				"vat_number": "",
				"owner": {
					"name": this.full_name,
					"email": this.owner_email
				},
			}
			if (this.imagesrc) {
				owner_data['logo'] = this.imagesrc;
			}
			this.save_loading = true;
			this.staff_services.CreateOwner(owner_data).then((result) => {
				this.convertBackToKm()
				this.success.emit(this.owner_email);
				this.save_loading = false;
			}).catch((err) => {
				this.save_loading = false;
				this.convertBackToKm()
				if (err) {
					if (err.company_registration_number) {
						this.popNotif.createNotification('error', 'There has been a problem.', err.company_registration_number);
					} else if (err.contact_number) {
						this.popNotif.createNotification('error', 'There has been a problem.', err.contact_number);
						this.globalHelpers.scroll_to_section('invoicing-timesheets');
					} else if (err.invoice_phone) {
						this.popNotif.createNotification('error', 'There has been a problem.', err.invoice_phone);
						this.globalHelpers.scroll_to_section('invoicing-timesheets');
					} else if (err.timesheet_email) {
						this.popNotif.createNotification('error', 'There has been a problem.', err.timesheet_email);
						this.globalHelpers.scroll_to_section('invoicing-timesheets');
					} else if (err.clock_distance_limit) {
						this.popNotif.createNotification('error', 'There has been a problem.', err.clock_distance_limit);
						this.globalHelpers.scroll_to_section('clock-distance');
					} else {
						this.popNotif.createNotification('error', 'There has been a problem.', "Please try again later.");
					}
				}
			});
		}
	}

	update(form) {
		this.getMeters();
		this.save_loading = true;
		this.submitted = true;
		this.saveToSession();
		if (!form.valid) {
			const directives = form._directives;
			this.convertBackToKm()
			for (let element of directives) {
				const { name } = element;
				if (name == 'billing_email' && !element.valid) {
					this.globalHelpers.scroll_to_section('invoicing-timesheets');
					break;
				} else if (name == 'company_description' && !element.valid) {
					this.globalHelpers.scroll_to_section('aditional-info');
					break;
				} else if ((name == 'control_phone' || name == 'control_email') && !element.valid) {
					this.globalHelpers.scroll_to_section('control-room');
					break;
				}
			};

		} else {
			let updated_company = {
				"check_email": this.control_email,
				"contact_number": this.control_phone ? this.control_phone.toString() : '',
				"invoice_email": (typeof this.billing_email == 'string') ? [this.billing_email] : this.billing_email,
				"invoice_phone": this.billing_phone ? this.billing_phone.toString() : '',
				"description": this.company_description,
				"clock_distance_limit": this.clock_distance_limit,
			}
			if (this.timesheets_emails && this.timesheets_emails.length > 0) {
				updated_company["timesheet_email"] = this.timesheets_emails;
			}
			if (this.imagesrc !== this.editComponent.company_logo) {
				updated_company['logo'] = this.imagesrc;
			}
			this.companies.updateCompany(updated_company).then((res) => {
				this.popNotif.createNotification('success', 'Success', 'Company profile updated successfully');
				this.convertBackToKm()
				this.save_loading = false;
			}).catch((err) => {
				this.save_loading = false;
				if (err) {
					this.convertBackToKm()
					if (err.contact_number) {
						this.popNotif.createNotification('error', 'There has been a problem.', err.contact_number);
						this.globalHelpers.scroll_to_section('control-room');
					} else if (err.invoice_phone || err.invoice_email) {
						this.popNotif.createNotification('error', 'There has been a problem.', err.invoice_phone);
						this.globalHelpers.scroll_to_section('invoicing-timesheets');
					} else if (err.timesheet_email) {
						this.popNotif.createNotification('error', 'There has been a problem.', err.timesheet_email);
						this.globalHelpers.scroll_to_section('invoicing-timesheets');
					} else if (err.clock_distance_limit) {
						this.popNotif.createNotification('error', 'There has been a problem.', err.clock_distance_limit);
						this.globalHelpers.scroll_to_section('clock-distance');
					} else {
						this.popNotif.createNotification('error', 'There has been a problem.', err);
					}
				}
			});
		}
	}

	saveToSession() {
		this.currentUser.company.logo = this.imagesrc;
		this.session.setUser(this.currentUser, true);
	}

}
