import { AuthSessionService } from "@broadstone/auth-session-service";
import { ApiService } from "@broadstone/api-service";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { UserAdapter } from "./user.model";
import { CachingService } from "../caching-service";

@Injectable({
  providedIn: "root",
})
export class UsersService {
  public runOnce: boolean = false;
  public twoFAPopupShown: boolean = false;

  constructor(
    public http: HttpClient,
    public api: ApiService,
    private session: AuthSessionService,
    private adapter: UserAdapter,
    private cachingService: CachingService
  ) {}

  public getMyNotifications(): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = this.api.getFullUrl("company/staff/notifications");
      const headers = this.api.getDefaultHeaders(this.session.getToken());
      return this.http.get(url, { headers }).subscribe(
        (data) => {
          resolve(data);
        },
        (error) => reject(this.api.handleErrors(error))
      );
    });
  }

  //get staff notification preferences

  public amendMyNotifications(notifications: Array<object>): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = this.api.getFullUrl("company/staff/notifications");
      const headers = this.api.getDefaultHeaders(this.session.getToken());
      return this.http.patch(url, { notifications }, { headers }).subscribe(
        (data) => {
          resolve(data);
        },
        (error) => reject(this.api.handleErrors(error))
      );
    });
  }

  public getCurrentUser(): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = this.api.getFullUrl("company/staff");
      const headers = this.api.getDefaultHeaders(this.session.getToken());
		this.cachingService.addCache(url, 120);
      return this.http.get(url, { headers }).subscribe(
        (data) => {
          this.runOnce = false;
          const adapted_data = this.adapter.adapt(data["data"]);
          resolve(adapted_data);
        },
        (error) => reject(this.api.handleErrors(error))
      );
    });
  }
}
