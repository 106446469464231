<div class="job-card-container" [ngClass]="editJob ?  'edit-job-margin' : ''" StickyPolyFill>
	<p class="light-text"><img src="./assets/icons/light-eye.svg"> This is how your job posting will appear in the app.
	</p>
	<p class="light-text rate">* The rate the worker sees includes pay rate and holiday pay</p>

	<div class="job-card">
		<div>
			<div class="tag-placeholders padding-left-24">
				<div *ngIf="smartStaff" class="left-tag">
					<img class="icon-sm" src="/assets/icons/briefcase-dark.svg" />
					<small class="b-font-demibold">Temp to Perm</small>
				</div>
				<div *ngIf="!smartStaff" class="right-tag">
					<img class="icon-sm" src="/assets/icons/clock.svg" />
					<small class="b-font-demibold">Ad Hoc</small>
				</div>
			</div>
			<h4 class="b-font-demibold padding-left-24">{{(roleType ? roleType : 'Role Type') | upperFirst}}</h4>
			<div class="sector-pay-container padding-left-24">
				<p class="sector">{{serviceTitle ? serviceTitle: ''}}</p>
				<p class="b-font-demibold">
					&pound;
					{{workerHourlyRate && holidayPayRate ? (workerHourlyRate + holidayPayRate).toFixed(2) : 0}}
				</p>
				<p class="demibold pay-tag">
					Paid &pound;
					{{ workerHourlyRate.toFixed(2) }}
				</p>
				<div class="aligned-flex">
					<img class="padding-right-4 icon-sm" src="/assets/icons/holiday.svg">
					<p class="demibold">Holiday Est &pound;{{holidayPayRate.toFixed(2)}}</p>
				</div>
				<p class="sector description" (click)="scrollToResponsibilities()">Read job description</p>
			</div>
		</div>
		<div>
			<div class="job-card-bottom"></div>
			<div class="job-card-logo">
				<p class="b-font-demibold">Posted by </p>
				<img [src]="logo  ? logo : 'assets/images/profile.jpg'" />
			</div>
		</div>
	</div>

</div>