import { Component, Input } from "@angular/core";
import { JobsService } from "src/services/public_api";

@Component({
	selector: "app-job-view",
	templateUrl: "./job-view.component.html",
	styleUrls: ["./job-view.component.scss"],
})
export class JobViewComponent {
	@Input() job: any; // is available only if job uuid was paste in url.
	modalImage: string;
	modalVisible: boolean = false;
	loading: boolean = true;
	constructor(private jobService: JobsService) {
	}
	ngOnChanges() {
		if (this.job) {
			this.getJobDetails();
		}
	}

	getJobDetails() {
		this.jobService.getJob(this.job.uuid, false).then((res) => {
			this.job = res;
			this.uniformCheck(this.job);
			this.loading = false;
		}).catch(() => {
			this.loading = false;
			return;
		});

	}
	openImageViewer(img) {
		this.modalImage = img;
		this.modalVisible = true;
	}
	closeModal() {
		this.modalVisible = false;
	}

	uniformCheck(job) {
		if (
			job.uniform_required &&
			job.uniform_description &&
			!job.uniform_image
		) {
			const str = job.uniform_description.toLowerCase();
			if (
				str.search(
					/Make sure you’re wearing a plain black shirt or polo shirt/i,
				) >= 0 ||
				str.search(/Event Uniform: Plain black shirt/i) >= 0
			) {
				job.uniformType = 'event_uniform'; //EVent uniform
			} else if (
				str.search(
					/Security Uniform: White shirt, black pants, black shoes/i,
				) >= 0 ||
				str.search(/Make sure you’re wearing a plain white shirt/i) >= 0
			) {
				job.uniformType = 'white_shirt'; // White shirt
			} else if (
				str.search(
					/Make sure you’re wearing a black jacket or blazer/i,
				) >= 0
			) {
				job.uniformType = 'black_jacket'; // black shirt
			} else {
				job.uniformType = 'custom';
			}
		}
	}
}
