import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputListBuilderComponent } from './input-list-builder.component'
import { FormsModule } from '@angular/forms';
import { ClibButtonModule } from '@broadstone/clib-button';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';

@NgModule({
    declarations: [InputListBuilderComponent],
    exports: [InputListBuilderComponent],
    imports: [
        FormsModule,
        CommonModule,
        ClibButtonModule,
        NzCarouselModule,
    ]
})
export class InputListBuilderModule { }
