import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { JobsProviders, CompaniesService, LocationsService, StaffService, AppAnimations } from 'src/services/public_api';
import { GlobalHelpers } from '@broadstone/helpers';
import { PopNotificationService } from '@broadstone/pop-notification-service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { InvoiceSettingsService } from 'src/services/api/invoice-settings/invoice-settings.services';
import { Router } from '@angular/router';

@Component({
	selector: 'app-post-site',
	templateUrl: './post-site.component.html',
	styleUrls: ['./post-site.component.scss'],
	animations: [AppAnimations.site_job_animation, AppAnimations.fade_in_out],
})
export class PostSiteComponent implements OnInit {
	@Input() siteUuid: string;
	@Output() onReference: EventEmitter<any> = new EventEmitter();
	@Output() onEdit: EventEmitter<any> = new EventEmitter();
	@Output() onComplete: EventEmitter<any> = new EventEmitter();
	@Output() onBack: EventEmitter<any> = new EventEmitter();
	@Output() onSiteNotFound: EventEmitter<any> = new EventEmitter();
	@Output() onEditPage: EventEmitter<string> = new EventEmitter();
	site_review: boolean = false;
	reference_lnk: boolean = false;
	client_postcode_lnk: boolean = false;
	addressForm = false;
	show_client_name: any = true;
	hide_client_name: any = false;
	show_parking: any = false;
	hide_parking: any = true;
	show_training_required: any = false;
	hide_training_required: any = true;
	suggestions_loading: boolean = false;
	poSuggestionsLoading: boolean = false;
	gPlacesSuggestions: any = [];
	site_loading: boolean = false;
	client_loading: boolean = false;
	staff_loading: boolean = false;
	save_loading: boolean = false;
	staff_select_loading: boolean = false;
	client_touched: boolean = false;
	client_suggestions: any = [];
	staff_suggestions: any = [];
	members: any = [];
	assigned_staff: any = [];
	suggestedClients: any = [];
	submittedSite: boolean = false;
	// regexPostcode = '^(([gG][iI][rR] {0,}0[aA]{2})|(([aA][sS][cC][nN]|[sS][tT][hH][lL]|[tT][dD][cC][uU]|[bB][bB][nN][dD]|[bB][iI][qQ]' +
	//   '[qQ]|[fF][iI][qQ][qQ]|[pP][cC][rR][nN]|[sS][iI][qQ][qQ]|[iT][kK][cC][aA]) {0,}1[zZ]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yxA-HK-XY]' +
	//     '?[0-9][0-9]?)|(([a-pr-uwyzA-PR-UWYZ][0-9][a-hjkstuwA-HJKSTUW])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y][0-9][abehmnprv-yABEHMNPRV-Y]))) ' +
	//     '{0,}[0-9][abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2}))$';    // future ref
	site_object = {
		location: {
			name_number: "",
			address_1: "",
			address_2: "",
			county: "",
			city: "",
			postcode: ""
		},
		clock_distance_limit: 0,
		client: "",
		reference: "",
		purchase_order: "",
		client_title: "",
		car_parking_available: false,
		training_required: false,
		client_visible: true,
		instructions: "",
		control_room_name: "",
		control_room_number: "",
		uuid: "",
		site_images: {
			image_1: "",
			image_2: "",
			image_3: "",
		},
		site_type_id: 0
	};
	siteTypes: any = [];
	editStaff: boolean;
	poSuggestions: any = [];
	postcode: string;
	numberPattern = '^([0|\+[0-9]{1,5})?([1-9][0-9]{9})$';
	noFixedArea: boolean = false;
	measure: string = 'miles';
	max: number = 3;
	metricUnits: boolean = false;
	radiusInMetres: number = 0;
	lat: string;
	lng: string;
	postcodeValid: boolean = true;
	imagesrc: any = [{ src: "", }, { src: "", }, { src: "", }];
	modalImage: string;
	modalVisible: boolean = false;
	showImgIcons: boolean = false;

	constructor(
		private location_service: LocationsService,
		public globalHelpers: GlobalHelpers,
		public jobData: JobsProviders,
		public companies: CompaniesService,
		public staff_services: StaffService,
		private popNotif: PopNotificationService,
		public sanitizer: DomSanitizer,
		private route: ActivatedRoute,
		private _location: Location,
		private invoiceSettingsService: InvoiceSettingsService,
		public router: Router
	) { }

	ngOnInit() {
		this.route.queryParams
			.subscribe(params => {
				this.postcode = params.postcode
				if (params['staff'] === 'edit') {
					this.editStaff = true;
				}
			})
		if (this.postcode) {
			this.getLocation(this.postcode);
		}
		this.getSiteTypes();
		this.getSite();
		this.getCompanyInfo();
		this.checkReference();
		this.checkDataCheckbox();
	}

	async getCompanyInfo() {
		if (!this.siteUuid) {
			await this.companies.getCompanyInfo().then((res) => {
				this.setSiteRadius(res);
			}).catch(err => {
				this.popNotif.createNotification('error', 'There has been a problem.', 'Please try again later.');
				// this.logger.error(err);
			});
		}
		else {
			return;
		}
	}

	async getSiteTypes() {
		await this.companies.getSiteType().then((res: any) => {
			this.siteTypes = res;
			this.siteTypes.map(type => {
				type.selected = false;
				if (!this.siteUuid && type.id === 3) {
					type.selected = true;
					this.site_object.site_type_id = 3;
				}
			})
		}).catch(() => {
			return;
		});
	}

	selectSiteType(id) {
		this.siteTypes.map(type => {
			type.selected = false;
			if (id === type.id) {
				type.selected = true;
				this.site_object.site_type_id = type.id;
			}
		})
	}

	getSite() {
		if (this.siteUuid) {
			this.site_loading = true;
			this.companies.getSiteUuid(this.siteUuid).then(res => {
				if (res && res.assigned_staff) {
					this.assigned_staff = res.assigned_staff.map(element => {
						return { avatar: element.user.avatar, title: this.globalHelpers.full_name(element.user), subtitle: element.role ? element.role.type : '', uuid: element.uuid };
					});
				}
				this.storeSiteData(res);
				this.selectSiteType(res.site_type_id.id);
				this.setSiteRadius(res);
				this.sendSiteName();
				this.searchMapByPostcode();
				this.site_loading = false;
				this.site_review = true;
				if (this.editStaff && this.site_review && !this.site_loading) {
					this.globalHelpers.scroll_to_section('assigned-staff-id');
				}
			}).catch(e => { this.site_loading = false; this.onSiteNotFound.emit(); });
		}
	}

	setSiteRadius(res) {
		this.metricUnits = true;
		this.max = 5;
		if (res.clock_distance_limit === 0 || res.clock_distance_limit === null) {
			this.noFixedArea = true;
			this.site_object.clock_distance_limit = 0;
		}
		else {
			let radius = res.clock_distance_limit ? (res.clock_distance_limit / 1000) : 0;
			this.site_object.clock_distance_limit = Math.round(radius * 10) / 10;
		}
	}

	sendSiteName() {
		const site_name_postcode = this.site_object.client_title ? this.site_object.client_title : this.site_object.location.postcode;
		this.onEditPage.emit(site_name_postcode);
	}

	storeSiteData(res) {
		this.site_object.reference = res.reference;
		this.site_object.location = res.location;
		this.addressFormControl('open');
		this.site_object.client_title = res.client ? res.client.name : '';
		this.site_object.client = res.client ? res.client.uuid : '';
		this.site_object.uuid = res.uuid;
		this.site_object.car_parking_available = res.car_parking_available;
		this.site_object.training_required = res.training_required;
		this.populateCarparkCheckbox();
		this.site_object.training_required = res.training_required;
		this.site_object.training_required = res.training_required;
		this.populateTrainingRequiredCheckbox();
		this.site_object.client_visible = res.client_visible;
		this.populateClientCheckbox();
		this.site_object.purchase_order = res.purchase_order;
		this.site_object.instructions = res.instructions;
		this.site_object.control_room_name = res.control_room_name;
		this.site_object.control_room_number = res.control_room_number ? res.control_room_number : '';
		this.site_object.site_images.image_1 = res.image_1;
		this.site_object.site_images.image_2 = res.image_2;
		this.site_object.site_images.image_3 = res.image_3;
		this.imagesrc[0].src = res.image_1;
		this.imagesrc[1].src = res.image_2;
		this.imagesrc[2].src = res.image_3;
		this.site_object.site_type_id = res.site_type_id.id;
	}

	populateClientCheckbox() {
		if (this.site_object.client_visible) {
			this.globalHelpers.change_check_box('', 'yes', 'show_client_name', 'hide_client_name', this);
		} else {
			this.globalHelpers.change_check_box('', 'no', 'show_client_name', 'hide_client_name', this);
		}
	}

	populateCarparkCheckbox() {
		if (this.site_object.car_parking_available) {
			this.globalHelpers.change_check_box('', 'yes', 'show_parking', 'hide_parking', this);
		} else {
			this.globalHelpers.change_check_box('', 'no', 'show_parking', 'hide_parking', this);
		}
	}

	populateTrainingRequiredCheckbox() {
		if (this.site_object.training_required) {
			this.globalHelpers.change_check_box('', 'yes', 'show_training_required', 'hide_training_required', this);
		} else {
			this.globalHelpers.change_check_box('', 'no', 'show_training_required', 'hide_training_required', this);
		}
	}

	getLocation(value) {
		if (value != this.postcode) {
			this.postcode = value;
		}
		if (value && value.length < 3) {
			this.resetAddressForm();
		}
		this.suggestions_loading = true;
		this.getAddressSuggestions(value);
	}

	getAddressSuggestions(value) {
		if (value && value.length < 3) {
			this.resetAddressForm();  // resetting form if input is cleared (or 2 characters or less - make way for new suggestion)
			this.suggestions_loading = false;
			return;
		}
		this.checkPostcodeClient();// running postcode-client name check
		this.location_service.getSuggestions(value) // using location service to get suggestion from google EP
			.then(results => {
				this.suggestions_loading = false;
				if (!results) {  // break if there's no results
					return false;
				}
				this.gPlacesSuggestions = results.data.map(element => {   // if there are results, map through and save their name/ID in gPlacesSuggestions
					return { title: element.description, id: element.id };
				});
			}).catch(e => {
				this.suggestions_loading = false;
				// this.setLocation(value);
			});
	}

	resetAddressForm() {
		this.site_object.location.name_number = "";
		this.site_object.location.address_1 = "";
		this.site_object.location.address_2 = "";
		this.site_object.location.city = "";
		this.site_object.location.county = "";
		this.site_object.location.postcode = "";
	}

	addressFormControl(type: string) {
		switch (type) {
			case 'toggle':
				this.toggleAddressForm();
				break;
			case 'open':
				this.openAddressForm();
				break;
			case 'close':
				this.closeAddressForm();
				break;
		}
	}

	toggleAddressForm() {
		if (this.addressForm) {
			this.addressForm = false;
		} else if (!this.addressForm) {
			this.addressForm = true;
		}
	}

	openAddressForm() {
		if (!this.addressForm) {
			this.addressForm = true;
			this.searchMapByPostcode();
		}
	}

	closeAddressForm() {
		if (this.addressForm) {
			this.addressForm = false;
		}
	}

	getPoNumbers(e) {
		this.poSuggestionsLoading = true;
		this.invoiceSettingsService.getPurchaseOrders(e, 1)
			.then(res => {
				if (res['data'].length > 0) {
					this.poSuggestions = res['data'].map(element => {
						this.poSuggestionsLoading = false;
						return {
							title: element.purchase_order,
						};
					});
				} else {
					this.poSuggestions = [];
					this.poSuggestionsLoading = false;
				}
			})
			.catch(e => {
				this.poSuggestionsLoading = false;
				// this.logger.error(e);
			})
	}

	poValueSelected(value: any) {
		this.site_object.purchase_order = value.title;
	}

	valueSelected(value: any) {
		if (!value && !value.id) {
			return;
		}
		this.location_service.getPlace(value.id)
			.then(result => {
				this.resetAddressForm(); // resetting form before injecting results
				this.setLocation(result); // injection          
				this.addressFormControl('open'); // open address form
			}).catch(() => {
				return;
			});
	}

	getClients(value) {
		this.client_loading = true;
		this.setClientName(value);
		if (!value) {
			this.client_suggestions.length = 0;
			this.client_loading = false;
			return;
		}
		this.companies.getClient(value).then(resp => {   //Get clients from api, set suggestions for template and emit the first suggestion if non selected
			if (resp.length > 0) {
				this.client_suggestions = resp.map(element => {
					this.client_loading = false;
					const res = { title: element.name, uuid: element.uuid };
					this.suggestedClients.push(res); // pushing to another array for client name validation
					return res;     // returning res to apply map to client_loading array
				});
			} else {
				this.client_loading = false;
			}
			this.checkPostcodeClient();
		}).catch(e => {
			this.client_loading = false;
			// this.logger.error(e);
		})
	}

	checkPostcodeClient() {
		this.client_postcode_lnk = false;
		if (this.site_object.location.postcode && this.site_object.client_title) {
			this.companies.getPostcodeClient(this.site_object.location.postcode, this.site_object.client_title).then(res => {
				if (res.length > 0) {
					this.client_postcode_lnk = true;
					this.site_object.uuid = res[0].uuid;
				}
			}).catch(e => {
				// this.logger.error(e); 
				this.client_postcode_lnk = false;
			});
		}
	}

	checkReference() {
		this.reference_lnk = false;
		if (!this.site_object.reference) {
			return;
		}
		this.companies.getSiteReference(this.site_object.reference).then(res => {
			if (res.length > 0) {
				let results = res.filter(element => {
					if (element.reference === this.site_object.reference && element.location.postcode === this.site_object.location.postcode) {
						return element.reference == this.site_object.reference;
					}
				});
				if (results && results.length > 0) {
					this.reference_lnk = true;
					this.site_object.uuid = res[0].uuid;
				}
			}
		}).catch(e => {
			// this.logger.error(e); 
			this.reference_lnk = false;
		});
	}

	openReference() {
		this.onReference.emit(this.site_object);
	}

	setLocation(value) {
		if (value && typeof value == 'object') {
			this.site_object.location.name_number = value.building_identifier;
			this.site_object.location.address_1 = value.address;
			this.site_object.location.city = value.city;
			this.site_object.location.county = value.county;
			this.site_object.location.postcode = value.postcode;
		} else {
			this.site_object.location = value;
		}
	}

	setClientName(value) {
		this.site_object.client = (value && value.uuid) ? value.uuid : ''; // add from the dropdown list or from the input string
		this.site_object.client_title = (value && value.title) ? value.title : value;
		this.checkPostcodeClient();
	}

	checkDataCheckbox() {
		if (this.site_object.hasOwnProperty('client_visible')
			&& this.site_object.hasOwnProperty('car_parking_available')
			&& this.site_object.hasOwnProperty('training_required')) {
			let { client_visible, car_parking_available, training_required } = this.site_object;
			this.show_client_name = client_visible;
			this.hide_client_name = !client_visible;
			this.show_parking = car_parking_available;
			this.hide_parking = !car_parking_available;
			this.show_training_required = training_required;
			this.hide_training_required = !training_required;
		}
	}

	savingClient() {
		this.getMeters();
		this.submittedSite = true;
		if (this.assigned_staff.length == 0) {
			return;
		}
		const { client_title, client } = this.site_object || null;
		if (!client) {
			if (client_title) {
				this.companies.createClient({ name: client_title }).then(resp => {
					this.site_object.client_title = resp.name;
					this.site_object.client = resp.uuid;
					if (this.siteUuid) {
						this.patchSite();
					} else {
						this.createSite();
					}
				}).catch(e => {
					// this.logger.error(e);
				});
			}
		} else {
			if (this.siteUuid) {
				this.patchSite();
			} else {
				this.createSite();
			}
		}
	}

	prepareImages() {
		for (let index = 1; index < 4; index++) {
			if (this.site_object.site_images['image_'+index] && (this.site_object.site_images['image_'+index].indexOf("https://") === 0)) {
				delete this.site_object.site_images['image_'+index];
			}
		}
	}

	patchSite() {
		this.save_loading = true;
		this.prepareImages();
		this.companies.editSite(this.site_object, this.siteUuid).then(res => {
			this.save_loading = false;
			res.edit = true;
			this.onComplete.emit(res)
		}).catch(e => {
			this.save_loading = false;
			if (e && e.client) {
				this.popNotif.createNotification('error', 'There has been a problem.', e.client);
			} else if (e && e.reference) {
				this.popNotif.createNotification('error', 'There has been a problem.', e.reference)
			} else if (e && e.control_room_number) {
				this.popNotif.createNotification('error', 'There has been a problem.', e.control_room_number);
			} else if (e && e.clock_distance_limit) {
				this.popNotif.createNotification('error', 'There has been a problem.', e.clock_distance_limit);
			} else {
				this.popNotif.createNotification('error', 'There has been a problem.', 'We cannot create this site.');
			}
			// this.logger.error(e);
		});
	}

	createSite() {
		this.save_loading = true;
		this.companies.createSite(this.site_object).then(res => {
			this.siteUuid = res.uuid;
			this.save_loading = false;
			this.setStaffOnCreate();
			this.onComplete.emit(res);
		}).catch(e => {
			this.save_loading = false;
			if (e && e.client) {
				this.popNotif.createNotification('error', 'There has been a problem.', e.client);
			} else if (e && e.reference) {
				this.popNotif.createNotification('error', 'There has been a problem.', e.reference)
			} else if (e && e.control_room_number) {
				this.popNotif.createNotification('error', 'There has been a problem.', e.control_room_number);
			} else if (e && e.clock_distance_limit) {
				this.popNotif.createNotification('error', 'There has been a problem.', e.clock_distance_limit);
			} else {
				this.popNotif.createNotification('error', 'There has been a problem.', 'We cannot create this site.');
			}
			// this.logger.error(e);
		});
	}

	switchToReview() {
		if (this.site_object.location.name_number) {
			this.site_object.location.address_1 = `${this.site_object.location.name_number} ${this.site_object.location.address_1}`;
		}
		this.searchMapByPostcode();
		this.checkClientName(this.site_object.client_title);
		if (this.checkFormErrors()) this.site_review = true;
	}

	async searchMapByPostcode() {
		if (this.site_object.location.postcode) {
			await this.location_service.getGeolocation(this.site_object.location.postcode).then((result) => {
				this.lat = result.lat;
				this.lng = result.lng;
				this.postcodeValid = true;
			}).catch((err) => {
				if (err.error.errors.postcode) {
					this.popNotif.createNotification('error', 'There has been a problem', err.error.errors.postcode)
					this.postcodeValid = false;
				}
			});
		}
	}

	selectMobileResponse() {
		if (!this.noFixedArea) {
			this.site_object.clock_distance_limit = 0;
			this.noFixedArea = true;
		}
		else if (this.noFixedArea) {
			this.site_object.clock_distance_limit = 0.1;
			this.noFixedArea = false;
		}
	}

	getMeters() {
		if (this.radiusInMetres === 0) {
			this.radiusInMetres++;
			if (this.metricUnits) {
				this.site_object.clock_distance_limit = Math.floor(this.site_object.clock_distance_limit * 1000);
			}
			else {
				this.site_object.clock_distance_limit = Math.floor(this.site_object.clock_distance_limit * 1609.344);
			}
		}
		else {
			return;
		}
	}

	onSwitchChange() {
		if (this.metricUnits) { //change miles to kms
			this.max = 5;
			let radius = this.site_object.clock_distance_limit * 1.6;
			this.site_object.clock_distance_limit = Math.round(radius * 10) / 10;
		}
		else if (!this.metricUnits) { //change kms to miles
			this.max = 3;
			let radius = this.site_object.clock_distance_limit * 0.621371;
			this.site_object.clock_distance_limit = Math.round(radius * 10) / 10;
		}
	}

	checkFormErrors() {
		this.client_touched = false;
		if (this.reference_lnk) {
			this.globalHelpers.scroll_to_section('reference-id');
			return false;
		} else if (!this.site_object.location.address_1) {
			this.addressFormControl('open');
			this.globalHelpers.scroll_to_section('address-id');
			return false;
		} else if (!this.site_object.location.county) {
			this.addressFormControl('open');
			this.globalHelpers.scroll_to_section('county-id');
			return false;
		} else if (!this.site_object.location.city) {
			this.addressFormControl('open');
			this.globalHelpers.scroll_to_section('city-id');
			return false;
		} else if (!this.site_object.location.postcode) {
			this.addressFormControl('open');
			this.globalHelpers.scroll_to_section('postcode-id');
			return false;
		} else if (!this.site_object.client_title || this.client_postcode_lnk) {
			this.client_touched = true;
			this.globalHelpers.scroll_to_section('client-id');
			return false;
		}
		return true;
	}

	edit() {
		this.site_review = false;
		if (this.radiusInMetres > 0) {
			this.setSiteRadius(this.site_object);
			this.radiusInMetres = 0;
		}
	}

	goBack() {
		if (this.site_review) { // if we are on the review page site
			if (this.siteUuid) { // if this is from edit site (has uuid)
				this.onComplete.emit({ uuid: this.siteUuid, edit: true }); // redirect back to job site
			} else {
				this.edit();
			}
		} else {
			this._location.back();
		}
	}

	setExactMatch(arr: any = [], match: string): any {
		let exactMatch = arr.filter(element => {
			return element.title === match;
		});
		if (exactMatch) {
			exactMatch = exactMatch[0];
			return exactMatch;
		} else {
			return false;
		}
	}

	checkClientName(givenClientName: string): any {
		if (!this.client_postcode_lnk && this.suggestedClients.length > 0) { // if there's suggestions and a conflict hasn't already been flagged
			const ucGivenName = givenClientName.toUpperCase(); // uppercasing param name (Client Name input from form)
			const ucSuggestedNames = this.suggestedClients.map(suggestion => { // uppercasing all suggested client names (populated in get_clients() )
				return (
					{
						title: suggestion.title.toUpperCase(),
						uuid: suggestion.uuid
					}
				);
			});
			const exactMatch = this.setExactMatch(ucSuggestedNames, ucGivenName); // storing exact match
			(exactMatch) ? this.setClientName(exactMatch) : 0;   // replacing inputted client name with suggested
		}
	}

	getStaff(value) {
		this.staff_loading = true;
		if (!value) {
			this.staff_suggestions.length = 0;
			this.staff_loading = false;
			return;
		}
		const page = 1;
		const limit = 100;
		this.staff_services.searchStaff({ search: encodeURIComponent(value) }, page, limit)
			.then(resp => {
				if (resp['data'].length > 0) {
					this.staff_suggestions = resp['data'].map(element => {
						this.staff_loading = false;
						return {
							avatar: element.user.avatar,
							title: this.globalHelpers.full_name(element.user),
							subtitle: element.role,
							uuid: element.uuid
						};
					});
				} else {
					this.staff_suggestions = [];
					this.staff_loading = false;
				}
			})
			.catch(e => {
				this.staff_loading = false;
				// this.logger.error(e);
			})
	}

	setStaffOnEdit(value) {
		const member_exists = this.assigned_staff.filter(element => {
			return element.title == value.title;
		}).length > 0;
		if (!member_exists) {
			this.assigned_staff.push(value);
			this.saveStaff(value);
		}
	}

	saveStaff(value) {
		if (this.siteUuid) {
			this.staff_select_loading = true;
			this.companies.assignStaff(this.siteUuid, value.uuid).then(res => { this.staff_select_loading = false; }).catch(e => {
				// this.logger.error(e); 
				this.staff_select_loading = false;
			})
		}
	}

	setStaffOnCreate() {
		this.assigned_staff.forEach(staff => {
			this.saveStaff(staff);
		});
	}

	deleteStaff(staff) {
		if (!this.siteUuid) {
			return;
		}
		this.staff_select_loading = true;
		this.companies.removeStaff(this.siteUuid, staff.uuid).then(res => { this.staff_select_loading = false; }).catch(e => {
			// this.logger.error(e);
			this.staff_select_loading = false;
		})
	}

	editCompany() {
		this.router.navigate(['/app-company-edit'])
	}

	openImageViewer(img) {
		this.modalImage = img;
		this.modalVisible = true;
	}

	closeModal() {
		this.modalVisible = false;
	}

	removeImage(index) {
		this.imagesrc[index].src = "";
		this.saveImgToSite(index, null);
	}

	getImg(event, i) {
		let fileobj = event.target.files;
		let file = fileobj[0];
		if (file) {
			const reader = new FileReader();
			reader.onload = (e: any) => {
				this.imagesrc[i].src = e.target.result;
				this.saveImgToSite(i, e.target.result)
			}
			reader.readAsDataURL(file);
		}
	}

	saveImgToSite(i, img) {
		switch (i) {
			case 0:
				this.site_object.site_images.image_1 = img;
				break;
			case 1:
				this.site_object.site_images.image_2 = img;
				break;
			case 2:
				this.site_object.site_images.image_3 = img;
				break;
		}
	}

}
