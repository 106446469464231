import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GlobalHelpers } from '@broadstone/helpers';

export interface InputWorker {
    site_trained: boolean;
    name: string;
    surname: string;
    avatar: string;
    mobile_number: string;
    ranking_score: number;
    time_since_applied?: string;
}

@Component({
    selector: 'app-worker-row',
    templateUrl: './worker-row.component.html',
    styleUrls: ['./worker-row.component.scss']
})
export class WorkerRowComponent implements OnInit {
    @Input() worker: InputWorker;
    @Input() secondary_button: string; // Button label
    @Input() primary_button: string;
	@Input() blockedWorkers: string
    @Output() primaryButton: EventEmitter<any> = new EventEmitter();
    @Output() secondaryButton: EventEmitter<any> = new EventEmitter();
    @Output() openProfile: EventEmitter<any> = new EventEmitter();
    loading = { loading_primary: false, loading_secondary: false };

    constructor(public helper: GlobalHelpers) { }

    ngOnInit() { }

    secondaryButtonAction() {
        this.secondaryButton.emit(this.loading);
    }

    primaryButtonAction() {
        this.primaryButton.emit(this.loading);
    }

    openProfileAction(worker) {
        this.openProfile.emit(worker);
    }

}
