import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoDataComponent } from './no-data.component';
import { DirectivesModule } from 'src/directives/directives.module';
import { NzGridModule } from 'ng-zorro-antd/grid';


@NgModule({
    declarations: [NoDataComponent],
    exports: [NoDataComponent],
    imports: [
        CommonModule,
        NzGridModule,
        DirectivesModule
    ]
})
export class NoDataModule { }
