import { Component, OnInit } from '@angular/core';
import { InvoiceSettingsService } from 'src/services/api/invoice-settings/invoice-settings.services'
import { AlertService } from '@broadstone/alert';
import { PopNotificationService } from '@broadstone/pop-notification-service';

@Component({
    selector: 'app-invoice-settings-view',
    templateUrl: './invoice-settings-view.component.html',
    styleUrls: ['./invoice-settings-view.component.scss']
})
export class InvoiceSettingsViewComponent implements OnInit {
    searchNumber: any;
    poNumberInput: string;
    desciptionInput: string;
    editClicked: boolean;
    allPos: any;
    buttonLabel: string = 'Add';
    page: number = 1;
    totalPages: number;
    item: any;
    constructor(
        private invoiceSettingsService: InvoiceSettingsService,
        private alert: AlertService,
        private popNotif: PopNotificationService
    ) { }

    async ngOnInit() {
        const data = await this.invoiceSettingsService.getPurchaseOrders(this.searchNumber, this.page)
            .catch(err => {
                this.popNotif.createNotification('error', 'There has been a problem.', err);

            })
        this.allPos = data.data;
        this.totalPages = data.metaData.last_page
    }

    async changePage(event) {
        window.scrollTo(0, 0);
        this.page = event;
        const data = await this.invoiceSettingsService.getPurchaseOrders(this.searchNumber, this.page)
            .catch(err => {
                this.popNotif.createNotification('error', 'There has been a problem.', err);
            })
        this.allPos = data.data;
    }

    async search() {
        this.page = 1;
        const data = await this.invoiceSettingsService.getPurchaseOrders(this.searchNumber, this.page)
            .catch(err => {
                this.popNotif.createNotification('error', 'There has been a problem.', err);
            })
        this.allPos = data.data;
        this.totalPages = data.metaData.last_page
    }

    onClickedOutside(event) {
        this.buttonLabel = 'Add';
        this.desciptionInput = '';
        this.poNumberInput = '';
        if (this.allPos) {
            this.allPos.map(po => {
                po.selected = false;
                return po;
            })
        }
    }

    async addPoNumber() {
        if (this.buttonLabel === 'Add' && this.poNumberInput) {
            const res: any = await this.invoiceSettingsService.createPurchaseOrder(this.poNumberInput, this.desciptionInput)
                .catch(err => {
                    this.popNotif.createNotification('error', 'There has been a problem.', err);
                })
            if (res) {
                this.popNotif.createNotification('success', 'Success', 'The purchase order number has been added');
                this.getPos('add');
            }
            this.desciptionInput = '';
            this.poNumberInput = '';
        }
        else if (this.buttonLabel === 'Update') {
            let payload = {};
            if (this.item.description !== this.desciptionInput) {
                payload['description'] = this.desciptionInput;
            }
            // if (this.item.purchase_order !== this.poNumberInput) {
            payload['purchase_order'] = this.poNumberInput;
            // }      
            const res: any = await this.invoiceSettingsService.editPurchaseOrder(this.item.uuid, payload)
                .catch(err => {
                    this.popNotif.createNotification('error', 'There has been a problem.', err);
                })
            if (res) {
                this.popNotif.createNotification('success', 'Success', 'The Purchase order number has been updated.');
                this.allPos.map(po => {
                    if (res.data.uuid === po.uuid) {
                        po.purchase_order = res.data.purchase_order;
                        po.description = res.data.description;
                    }
                    po.selected = false;
                    return po;
                })
                this.buttonLabel = 'Add';
                this.desciptionInput = '';
                this.poNumberInput = '';
            }
        }
    }

    handleEdit(item) {
        this.allPos.map(po => {
            if (item.uuid === po.uuid) {
                po.selected = true;
                this.poNumberInput = po.purchase_order;
                this.desciptionInput = po.description;
            } else {
                po.selected = false;
            }
            return po;
        })
        this.buttonLabel = 'Update';
        this.item = item;
    }

    async handleDelete(item) {
        this.alert.showConfirm("Are you sure?", "This PO number will be deleted and cannot be recovered.")
            .then(async () => {
                const res = await this.invoiceSettingsService.deletePurchaseOrder(item.uuid)
                    .catch(err => {
                        this.popNotif.createNotification('error', 'There has been a problem.', err);
                    });
                if (res) {
                    await this.getPos('delete');
                }
            })
            .catch(err => console.log(err))
    }

    async getPos(action) {
        this.page =
            (action === 'delete' && this.allPos.length === 1) &&
                this.page !== 1
                ?
                this.page = this.page - 1
                :
                this.page;
        const data = await this.invoiceSettingsService.getPurchaseOrders(this.searchNumber, this.page)
            .catch(err => {
                this.popNotif.createNotification('error', 'There has been a problem.', err);
            })
        this.allPos = data.data;
        this.totalPages = data.metaData.last_page
    }

}


