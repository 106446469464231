<div>
    <h1>Cancellation Policy</h1>
    <h5>
        Cancellations disrupt the plans of workers and impact the confidence of the

        Orka Works community.
        Because of this we apply the below cancellation policy:
        <br>
        <br>
        Companies may cancel a shift at any time on the Orka Works web platform.
        <br>
        <br>
        There is no fee to cancel shifts once you have approved a worker (for one or more

        shifts) with MORE than 72 hours notice.
        <br><br>

        Cancelling an approved shift within 72 hours of its start time will incur a 12.5% fee.

          <br><br>
        Cancelling an approved shift within 24 hours of its start time will incur a further 50%

        of the shift cost, payable to the approved worker.
        <br><br>

        If you indicate that the worker is unsatisfactory once on location, the fee may be

        removed at the discretion of an Orka Works manager.
    </h5>


</div>