import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '@broadstone/api-service';
import { AuthAdapter, Auth, Credentials } from './auth.model';
@Injectable({
    providedIn: 'root'
})
export class AuthService {



    constructor(
        private http: HttpClient,
        private api: ApiService,
        private adapter: AuthAdapter
    ) { }


    public authenticate(credentials: Credentials): Promise<Auth> {
        return new Promise((resolve, reject) => {
            const url = this.api.getAuthUrl('company/auth/authenticate');
            const headers = this.api.getDefaultHeaders(null);
            return this.http.post(url, credentials, { headers, withCredentials: true })
                .subscribe(
                    data => {
                        const adapted_data = this.adapter.adapt_auth(data);
                        resolve(adapted_data);
                    },
                    (error) => {
                        if (error && error.error) {
                            reject(error.error);
                        }

                    }
                );
        });
    };

    public authenticateTFA(cred): Promise<Auth> {
        return new Promise((resolve, reject) => {
            const url = this.api.getAuthUrl('company/auth/authenticate/2fa');
            const headers = this.api.getDefaultHeaders(null);
            return this.http.post(url, cred, { headers, withCredentials: true })
                .subscribe(
                    data => {
                        const adapted_data = this.adapter.adapt_auth(data);
                        resolve(adapted_data);
                    },
                    (error) => {
                        if (error && error.error) {
                            reject(error.error);
                        }

                    }
                );
        });
    };


    public sendResetEmail(email): Promise<any> {
        return new Promise((resolve, reject) => {
            const url = this.api.getAuthUrl('company/auth/password/reset');
            const headers = this.api.getDefaultHeaders(null);
            return this.http.post(url, email, { headers })
                .subscribe(
                    data => resolve((data['data'])),
                    error => reject(this.api.handleErrors(error))
                );
        });

    }

    public updatePassword(resetData): Promise<any> {
        return new Promise((resolve, reject) => {
            const url = this.api.getAuthUrl('company/auth/password');
            const headers = this.api.getDefaultHeaders(null);
            return this.http.post(url, resetData, { headers })
                .subscribe(
                    data => resolve((data)),
                    error => reject(this.api.handleErrors(error))
                );
        });

    }


    public logout(): Promise<any> {
        return new Promise((resolve, reject) => {
            const url = this.api.getAuthUrl('company/auth/logout');
            const headers = this.api.getDefaultHeaders(null);
            return this.http.post(url, null, { headers, withCredentials: true })
                .subscribe(
                    data => resolve((data)),
                    error => reject(this.api.handleErrors(error))
                );
        });

    }

    public getUser(): Promise<any> {
        return new Promise((resolve, reject) => {
            const url = this.api.getAuthUrl('company/user');
            const headers = this.api.getDefaultHeaders(null);
            return this.http.get(url, { headers, withCredentials: true })
                .subscribe(
                    data => resolve((data)),
                    error => reject(this.api.handleErrors(error))
                );
        });

    }

    public updateAuthPref(tfa): Promise<any> {
        return new Promise((resolve, reject) => {
            const url = this.api.getAuthUrl('company/auth/preferences');
            const headers = this.api.getDefaultHeaders(null);
            return this.http.patch(url, tfa, { headers, withCredentials: true })
                .subscribe(
                    data => resolve((data)),
                    error => reject(this.api.handleErrors(error))
                );
        });

    }

}
