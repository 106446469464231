import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';


@Component({
	selector: 'app-admin-workers',
	templateUrl: './admin-workers.component.html',
	styleUrls: ['./admin-workers.component.scss']
})
export class AdminWorkersComponent {
	mobile: string;

	constructor(private route: ActivatedRoute) { }

	ngOnInit(): void {
		this.route.paramMap.subscribe(params => {
			this.mobile = params.get('mobile');
		});
	}

}
