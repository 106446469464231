import { Injectable } from '@angular/core';

export interface Industry {
  uuid: string,
  title: string,
  description: string,
  avatar_src: string,
}

export interface Service {
  uuid: string,
  title: string,
  description: string,
  avatar_src: string,
  sector: Sector
}

export interface ServiceRole {
  uuid: string,
  title: string,
  description: string,
  avatar_src: string,
  vacancy_service: Service
}

export interface Sector {
  uuid: string,
  title: string,
  description: string,
  avatar_src: string,
  services: Array<Service>
}




@Injectable({
  providedIn: 'root'
})
export class SectorsServices {

  adapt_industries(data): Industry {
    return {
      uuid: data.uuid,
      title: data.title,
      description: data.description,
      avatar_src: data.avatar_src
    }
  }

  adapt_sectors(data): Sector {
    return {
      uuid: data.uuid,
      title: data.title,
      description: data.description,
      avatar_src: data.avatar_src,
      services: data.services
    }
  }



}


