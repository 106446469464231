import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JobCardComponent } from './job-card.component';
import { ApplicationGlobals } from '@broadstone/helpers';




@NgModule({
    declarations: [JobCardComponent],
    exports: [JobCardComponent],
    imports: [
        CommonModule,
		ApplicationGlobals
    ],
    providers: []

})
export class JobCardModule { }