import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-staff',
  templateUrl: './staff.component.html',
  styleUrls: ['./staff.component.scss']
})
export class StaffComponent implements OnInit {
  pageSetup = {
    breadcrumbs: [
      { title: 'account', link: 'account' },
      { title: 'manage staff' }
    ],
    pageTitle: 'manage staff',
    extraInfo: ''
  };
  constructor() { }

  ngOnInit() {
  }

}
