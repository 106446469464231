import { Injectable } from '@angular/core';
import { Adapter } from '../adapter';

export interface Employee {
	avatar: string | null;
	name: string;
	surname: string;
	userUuid: string;
	email: string;
	phone: string;
	suspendedAt: string | null;
	suspendedMessage: string | null;
	bannedAt: string | null;
	bannedMessage: string | null;
	leftAt: string | null;
	lastActive: string | null;
	userCreatedAt: string;
	registeredSectors: Array<string>;
	statuses: WorkerStatuses;
}

interface WorkerStatuses {
	flow: string;
	complianceStatus: string;
	checkIdStatus: string;
	checkRtwStatus: string;
	flowDescription: string;
	complianceEffectiveFrom?: string;
	daysToTopUp: string;
	canWork: Array<string>;
	rtwEffectiveDate: string;
	idEffectiveDate: string;
	isSoftApplyGateOpen: boolean;
	rtwStatusType: string | null;
	rtwExpiresDate: string | null;
	idStatusType: string | null;
	idStatusExpiresDate: string | null;
	madePartiallyCompliantAt: string | null;
	daysLeftLimitedScreening: string | null;
	workerStatusAudit: Array<string>;
	lastTopedUpDate: string;
	complianceDocumentsUrl?: string;
	vettingPartner?: string;
}

interface WorkerTaxInformation {
	employeeNumber: number;
	title: string;
	maritalStatus: string;
	gender: string;
	dateOfBirth: string;
	payrollNo: null | string; // Assuming payroll_no can be a string or null
}

interface EmployeeWorkStatus {
	details: Employee;
	statuses: WorkerStatuses;
	taxInformation: WorkerTaxInformation;
	profile: any;
}

@Injectable({
	providedIn: 'root',
})
export class EmployeeAdapter implements Adapter<Employee> {
	adapt(item: any): Employee {
		return {
			avatar: item.avatar ?? null,
			name: item.name ?? null,
			surname: item.surname ?? null,
			userUuid: item.user_uuid ?? null,
			email: item.email ?? null,
			phone: item.phone ?? null,
			suspendedAt: item.suspended_at ?? null,
			suspendedMessage: item.suspended_message ?? null,
			lastActive: item.last_active_at ?? null,
			bannedAt: item.banned_at ?? null,
			bannedMessage: item.banned_message ?? null,
			leftAt: item.left_at ?? null,
			userCreatedAt: item.user_created_at ?? null,
			registeredSectors: item.registered_sectors ?? [],
			statuses: this.adaptStatuses(item),
		};
	}

	adaptEmployeeWorkStatus(item: any): EmployeeWorkStatus {
		return {
			details: this.adapt(item),
			profile: item.user_job_profiles ?? null,
			statuses: this.adaptStatuses(item),
			taxInformation: {
				employeeNumber: item.worker_tax_information?.employee_number ?? null,
				title: item.worker_tax_information?.title ?? null,
				maritalStatus: item.worker_tax_information?.marital_status ?? null,
				gender: item.worker_tax_information?.gender ?? null,
				dateOfBirth: item.worker_tax_information?.date_of_birth ?? null,
				payrollNo: this.formatPayrollNumber(item.worker_tax_information?.payroll_no) ?? null,
			},
		};
	}
	adaptStatuses(item): WorkerStatuses {
		return {
			flow: item.worker_statuses?.compliant_statuses?.flow ?? null,
			flowDescription: item.worker_statuses?.compliant_statuses?.flow_description ?? null,
			daysToTopUp: item.worker_statuses?.compliant_statuses?.days_to_top_up ?? null,
			lastTopedUpDate: item.worker_statuses?.compliant_statuses?.compliance_last_topped_up_at ?? null,
			canWork: item.worker_statuses?.compliant_statuses?.registered_sectors_can_work ?? [],

			checkRtwStatus: item.worker_statuses?.compliant_statuses?.rtw_status ?? null,
			rtwEffectiveDate: item.worker_statuses?.compliant_statuses?.rtw_status_effective_from_at ?? null,
			rtwStatusType: item.worker_statuses?.compliant_statuses?.rtw_status_type ?? null,
			rtwExpiresDate: item.worker_statuses?.compliant_statuses?.right_to_work_expires_date ?? null,

			checkIdStatus: item.worker_statuses?.compliant_statuses?.id_status ?? null,
			idEffectiveDate: item.worker_statuses?.compliant_statuses?.id_status_effective_from_at ?? null,
			idStatusType: item.worker_statuses?.compliant_statuses?.id_status_type ?? null,
			idStatusExpiresDate: item.worker_statuses?.compliant_statuses?.id_status_expires_date ?? null,

			complianceStatus: item.worker_statuses?.compliant_statuses?.compliance_status ?? null,
			complianceEffectiveFrom: item.worker_statuses?.compliant_statuses?.compliance_effective_from_at ?? null,
			madePartiallyCompliantAt: item.worker_statuses?.compliant_statuses?.made_partially_compliant_at ?? null,
			daysLeftLimitedScreening: item.worker_statuses?.compliant_statuses?.days_left_of_limited_screening ?? null,

			isSoftApplyGateOpen: item.worker_statuses?.is_soft_apply_gate_open ?? null,
			workerStatusAudit: item.worker_statuses?.worker_status_audit_logs ?? [],
			complianceDocumentsUrl: item.worker_statuses?.compliant_statuses?.compliance_documents_url ?? null,
			vettingPartner: item.worker_statuses?.compliant_statuses?.vetting_partner ?? null,
		};
	}

	formatPayrollNumber(payrollNo: string | number | null): string | null {
		if (payrollNo == null) {
			return null;
		}
		const payrollString = payrollNo.toString();

		return 'AW' + payrollString.padStart(7, '0');
	}
}
