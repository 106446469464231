import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FloatingFooterComponent } from './floating-footer.component';
import { LoadingSpinnerModule } from '@broadstone/loading-spinner';

@NgModule({
    declarations: [FloatingFooterComponent],
    exports: [FloatingFooterComponent],
    imports: [
        CommonModule,
        LoadingSpinnerModule
    ]
})
export class FloatingFooterModule { }
