<section class="job-section">
	<div class="search-bar-area">
		<app-job-search-area [dropdown_reset]="true" (onSelectedSearch)="selectedSearchDropdown($event)"
			[search_box_data]="search_box_input" [job_type_filter]="job_type_filter" [buttonConfig]="subNavButton">
		</app-job-search-area>



	</div>

	<div *ngIf="all_sites.length > 0" class="job-row-heading job-row">
		<div class="row briefcase">

		</div>
		<div (click)="locationAscending = !locationAscending; sortJobs('city' , locationAscending, 'locationAscending')"
			class="row">
			<p class="title">location</p>
			<img [src]="locationAscending ? arrow_up : arrow_down">
		</div>
		<div class="row site">
			<p class="title">site</p>
		</div>
		<div (click)="clientAscending = !clientAscending; sortJobs('client' , clientAscending, 'clientAscending')"
			class="row">
			<p class="title">client</p>
			<img [src]="clientAscending ? arrow_up : arrow_down">
		</div>
		<div (click)="refAscending = !refAscending; sortJobs('reference' , refAscending, 'refAscending')" class="row">
			<p class="title">reference</p>
			<img [src]="refAscending ? arrow_up : arrow_down">
		</div>
		<div (click)="jobAscending = !jobAscending; sortJobs('job' , jobAscending, 'jobAscending')" class="row job">
			<p class="title">job</p>
			<img [src]="jobAscending ? arrow_up : arrow_down">
		</div>
		<div class="row shifts-filled">
			<p class="title">shifts filled</p>
		</div>
		<div class="row assigned-staff">
			<p class="title">assigned staff</p>
		</div>
		<div class="row job-integration">
		</div>
	</div>
	<nz-skeleton *ngIf="loading" class="nz-timesheets-loading" [nzParagraph]="{ rows: 7 }" [nzTitle]="false"
		[nzActive]="true">
	</nz-skeleton>
	<div class="job-row-area" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="80"
		(scrolled)="onScroll()" [scrollWindow]="true">
		<div class="job-row" *ngFor="let oneJob of all_sites">

			<div (click)="openJob(oneJob?.uuid)" class=" job-row-wrap">
				<div class="row briefcase">
					<img *ngIf="oneJob?.smart_staff" src="./assets/icons/smartstaff.svg">
				</div>
				<div class="location row">
					<p>{{oneJob?.site?.location?.city}}</p>

				</div>
				<div class="site row">
					<p class="address1" *ngIf="oneJob.site.reference">{{oneJob?.site?.reference}}</p>
					<p class="address1" [ngClass]="oneJob.site.reference ? 'not-bold': ''">
						{{oneJob?.site?.location?.address_1}}</p>
					<p class="not-bold postcode">{{oneJob?.site?.location?.postcode}}</p>
				</div>

				<div class="client row">

					<p class="client-name"
						*ngIf="oneJob && oneJob.site && oneJob.site.client && oneJob.site.client.name">
						{{oneJob?.site?.client?.name}}</p>

					<p class="client-name" *ngIf="!oneJob.site.client">{{oneJob.site.location.postcode}}
					</p>
				</div>
				<div class="ref row">
					<p *ngIf="oneJob && oneJob.reference" class="not-bold reference">
						{{oneJob?.reference}}</p>
					<p *ngIf="!oneJob.reference" class="not-bold reference">...</p>
				</div>
				<div class="ref job row">
					<p *ngIf="oneJob && oneJob.vacancy_service_role && oneJob.vacancy_service_role.title" class="reference">
						{{oneJob?.vacancy_service_role?.title}}</p>

					<p class="not-bold" *ngIf="oneJob && oneJob.title">{{oneJob?.title}}</p>
				</div>
				<div class="shifts-filled row">
					<div class="progress-wrapper-jobs">
						<nz-progress [nzShowInfo]="false" class="b-progress-thin"
							[nzPercent]="global_helper.get_percentage(oneJob.shifts_filled_count, oneJob.shifts_count)">
						</nz-progress>
					</div>
					<p class='progress-text'>
						{{oneJob.shifts_filled_count}}/{{oneJob.shifts_count ? oneJob.shifts_count : 0}}</p>
				</div>
				<div class="assigned-staff row" *ngIf="oneJob && oneJob.site && oneJob.site.assigned_staff.length > 0"
					(click)="editAssignedStaff($event, oneJob)">
					<div>
						<span *ngIf="oneJob.site.assigned_staff.length == 1" nz-tooltip
							[nzTooltipTitle]="oneJob.site.assigned_staff[0].user.name + ' ' + oneJob.site.assigned_staff[0].user.surname">
							<nz-avatar
								[nzText]="oneJob && oneJob.site && oneJob.site.assigned_staff ? oneJob.site.assigned_staff[0].user.name[0] : '' "
								[nzSrc]="oneJob && oneJob.site && oneJob.site.assigned_staff ? oneJob.site.assigned_staff[0].user.avatar : '' "
								nzSize="32">
							</nz-avatar>
						</span>
						<nz-avatar *ngIf="oneJob.site.assigned_staff.length > 1"
							[nzText]="oneJob && oneJob.site && oneJob.site.assigned_staff ? oneJob.site.assigned_staff[0].user.name[0] : '' "
							[nzSrc]="oneJob && oneJob.site && oneJob.site.assigned_staff ? oneJob.site.assigned_staff[0].user.avatar : '' "
							nzSize="32">
						</nz-avatar>
						<nz-avatar *ngIf="oneJob && oneJob.site && oneJob.site.assigned_staff.length > 1"
							[nzText]=showAssignedStaffTotal(oneJob) nzSize="32" nz-tooltip
							[nzTooltipTitle]="oneJob.full_names">
						</nz-avatar>
					</div>
				</div>
				<div class="assigned-staff row" *ngIf="oneJob && oneJob.site && oneJob.site.assigned_staff.length == 0">
					<nz-avatar nz-tooltip nzTooltipTitle="No staff member assigned" nzText="?" nzSize="32">
					</nz-avatar>
				</div>

				<div class="row job-integration">
					<img *ngIf="!oneJob.site.source" src="assets/icons/orka-icon.svg">
					<img *ngIf="oneJob.site.source ==='timegate'" src="assets/icons/timegate-icon.svg">
				</div>
			</div>
		</div>
		<div class="text-center">
			<nz-skeleton *ngIf="loading_more" class="nz-timesheets-loading" [nzParagraph]="{ rows: 1 }"
				[nzTitle]="false" [nzActive]="true">
			</nz-skeleton>

		</div>
	</div>






	<lib-no-search-data *ngIf="all_sites.length == 0 && !loading && !filters_on && edit_jobs"
		img_src="assets/icons/desert-island.svg" title='It’s empty in here…'
		body='To get started tap the button below to add your first job.' button_name='Post Jobs +'
		(onButtonClick)="openManageSite()">
	</lib-no-search-data>


	<lib-no-search-data
		*ngIf="all_sites.length == 0 && !loading && !filters_on && !edit_jobs || all_sites.length == 0 && !loading && filters_on"
		img_src='assets/icons/magnifying-glass-tilted-left.svg' title='No Results Found' body='Could not find any matches. Check your spelling and
try again or click the button below to clear the filters.' button_name='Clear Filters' button_class='outline'
		(onButtonClick)="clearFilters()">
	</lib-no-search-data>
	<div class="jobs-wrapper">
	</div>
</section>