<div *ngIf="loading" class="margin-top-32">
	<div class="margin-bottom-32">
		<nz-skeleton>
		</nz-skeleton>
	</div>
	<nz-skeleton class="nz-timesheets-loading" [nzParagraph]="{ rows: 7 }" [nzTitle]="false" [nzActive]="true">
	</nz-skeleton>
</div>

<div *ngIf="job && !loading">
	<!-- TAGS -->
	<div class="tag-placeholders">
		<div *ngIf="job.smart_staff" class="temp-tag">
			<img class="icon-sm" src="/assets/icons/briefcase-dark.svg" />
			<small class="b-font-demibold">Temp to Perm</small>
		</div>
		<div *ngIf="!job.smart_staff" class="add-hoc-tag">
			<img class="icon-sm" src="/assets/icons/clock.svg" />
			<small class="b-font-demibold">Ad Hoc</small>
		</div>
		<div *ngIf="job?.site?.car_parking_available" class="parking-tag">
			<small class="b-font-demibold">Parking</small>
		</div>
	</div>

	<!-- JOB SECTOR -->
	<div class="job-temporary-header item-divider">
		<div class="job-temporary-header-left margin-top-16 margin-bottom-16">
			<div *ngIf="job?.vacancy_service_role?.title !=='Other'">
				<h4 class="b-font-demibold">{{job?.vacancy_service_role?.title }}</h4>
				<p>{{job.title | upperFirst}}</p>
			</div>
			<div *ngIf="job?.vacancy_service_role?.title ==='Other'">
				<h4 class="b-font-demibold">{{job.title | upperFirst}}</h4>
				<p>{{job?.vacancy_service_role?.title }}</p>
			</div>

			<p class="job-code" *ngIf="job?.job_id">Job Code: {{job.job_id | uppercase}}</p>
		</div>
		<div *ngIf="job?.vacancy_service_role?.vacancy_service" class="job-temporary-header-right">
			<div class="industry">
				<img [src]="job?.vacancy_service_role?.vacancy_service?.avatar_src">
				<p class="margin-top-8">{{job?.vacancy_service_role?.vacancy_service.title }}</p>
			</div>
		</div>
	</div>

	<!-- CONTACT DETAILS -->
	<div *ngIf="job.site && job.site.control_room_name && job.site.control_room_number"
		class="aligned-flex-icon margin-bottom-32">
		<div>
			<p class="b-font-demibold margin-bottom-8">Contact Details</p>
			<p>{{job.site.control_room_name}}</p>
			<p>{{job.site.control_room_number}}</p>
		</div>
		<img src="/assets/icons/phone-dark.svg">
	</div>

	<!-- AUTO FILL -->
	<div class="margin-bottom-32 aligned-flex-icon">
		<div>
			<p class="b-font-demibold margin-bottom-8">Auto Fill</p>
			<div>
				<p *ngIf="job.auto_fill_enabled"> Yes</p>
				<p *ngIf="!job.auto_fill_enabled"> No</p>
			</div>
		</div>
		<img src="/assets/icons/user-check.svg">
	</div>

	<!-- TRAINING -->
	<div *ngIf="job.site" class="margin-bottom-32 aligned-flex-icon">
		<div>
			<p class="b-font-demibold margin-bottom-8">Training Required</p>
			<div>
				<p *ngIf="job.site?.training_required"> Yes</p>
				<p *ngIf="!job.site?.training_required"> No</p>
			</div>
		</div>
		<img src="/assets/icons/site-train-dark.svg">
	</div>

	<!-- JOB ENVIRONMENT -->
	<div *ngIf="job.location_type" class="aligned-flex-icon margin-bottom-32">
		<div>
			<p class="b-font-demibold margin-bottom-8">Job Environment</p>
			<div *ngIf="job.location_type !=='Both'">
				<p>{{job.location_type}}</p>
			</div>
			<div *ngIf="job.location_type ==='Both'">
				<p>Indoors</p>
				<p>Outdoors</p>
			</div>
		</div>
		<img src="/assets/icons/home.svg">
	</div>

	<!-- CLIENT VISIBLE -->

	<div *ngIf="job.site" class="margin-bottom-32 aligned-flex-icon">
		<div>
			<p class="b-font-demibold margin-bottom-8">Client name visible</p>
			<p *ngIf="job.site.client && job.site.client.name">
				{{job?.site?.client?.name | titlecase}}
			</p>

		</div>
		<div>
			<img *ngIf="job.site?.client_visible" class="margin-bottom-8" src="/assets/icons/eye-dark.svg">
			<img *ngIf="!job.site?.client_visible" class="margin-bottom-8" src="/assets/icons/eye-off.svg">
			<p *ngIf="job.site?.client_visible"> Yes</p>
			<p *ngIf="!job.site?.client_visible"> No</p>
		</div>
	</div>

	<!-- JOB LOCATION -->
	<div *ngIf="job.site">
		<div class="aligned-flex-icon  margin-bottom-16">
			<div>
				<p class="b-font-demibold margin-bottom-8">Job Location</p>
				<div *ngIf="job.site.location">
					<p *ngIf="job.site.location.address_1">{{job.site?.location?.address_1 | titlecase}}</p>
					<p *ngIf="job.site.location.address_2">{{job.site?.location?.address_2 | titlecase}}</p>
					<p *ngIf="job.site.location.city">{{job.site?.location?.city | titlecase}}</p>
					<p *ngIf="job.site.location.postcode">{{job.site?.location?.postcode | uppercase}}</p>
				</div>
			</div>
			<img src="/assets/icons/map-pin.svg">
		</div>
		<div *ngIf="job.site.location.latitude && job.site.location.longitude" class="aligned-flex-icon">
			<div>
				<p>Latitude</p>
				<p>Longitude</p>
			</div>
			<div class="text-right">
				<p>{{job.site?.location?.latitude}}</p>
				<p>{{job.site?.location?.longitude}}</p>
			</div>
		</div>
	</div>



	<!-- REQUIRE WORKER WITHIN -->
	<div *ngIf="job.require_worker_within > 0" class="aligned-flex-icon">
		<p>Require worker within</p>
		<p>{{job?.require_worker_within}} miles</p>
	</div>

	<!-- CLOCK IN / OUT LIMIT -->
	<div *ngIf="job.site" class="margin-bottom-8 aligned-flex-icon">
		<p>Clock in/out area limit</p>
		<p *ngIf="job.site.clock_distance_limit > 0">{{job?.site?.clock_distance_limit}} meters</p>
		<p *ngIf="!job.site.clock_distance_limit">unlimited</p>
	</div>

	<!-- MAP -->
	<div *ngIf="job.site.location.latitude && job.site.location.longitude" class="margin-bottom-32">
		<app-geofencing-map [lat]="job.site.location.latitude" [lng]="job.site.location.longitude" [radius]="null"
			nzTooltipTitle="clock in/out area" [metricUnits]="false">
		</app-geofencing-map>
	</div>

	<!-- LICENSE REQUIREMENTS -->
	<div class="margin-bottom-32" *ngIf="job.requirements && job.requirements.length > 0">
		<p class="b-font-demibold margin-bottom-8">License Requirements</p>
		<div class="aligned-flex margin-bottom-16" *ngFor="let require of job.requirements">
			<img class="margin-right-16" src="/assets/icons/check-circle.svg">
			<div>
				<p>{{require.title}}</p>
				<p>
					<small *ngIf="require.id === 1">(Includes Close Protection)</small>
					<small *ngIf="require.id === 6">(Includes Door Supervision & Close Protection)</small>
				</p>
			</div>
		</div>
	</div>

	<!-- ADDITIONAL REQUIREMENTS -->
	<div class="margin-bottom-32">
		<div *ngIf="job.client_requirements && job.client_requirements.length > 0">
			<p class="b-font-demibold margin-bottom-8">Additional Requirements</p>
			<div class="aligned-flex margin-bottom-16" *ngFor="let clientJobRequire of job.client_requirements">
				<img class="margin-right-16" src="/assets/icons/check-circle.svg">
				<p>{{clientJobRequire?.worker_title}}</p>
			</div>
		</div>
	</div>

	<!-- JOB DESCRIPTION -->
	<div *ngIf="job.description" class="margin-bottom-32">
		<p class="b-font-demibold margin-bottom-8">Job Description</p>
		<p>{{job.description}}</p>
	</div>

	<!-- ADDITIONAL INFORMATION -->
	<div *ngIf="job.site && job.site.instructions" class="margin-bottom-32">
		<p class="b-font-demibold margin-bottom-8">Additional information</p>
		<p *ngIf="job.site.instructions">{{job.site.instructions}}</p>
	</div>
	<div *ngIf="job.site && job.site.image_1" class="site-images margin-bottom-32">
		<img *ngIf="job.site.image_1" class="cursor-pointer" (click)="openImageViewer(job.site.image_1)"
			[src]="job.site.image_1" />
		<img *ngIf="job.site.image_2" class="cursor-pointer" (click)="openImageViewer(job.site.image_2)"
			[src]="job.site.image_2" />
		<img *ngIf="job.site.image_3" class="cursor-pointer" (click)="openImageViewer(job.site.image_3)"
			[src]="job.site.image_3" />
	</div>
	<lib-modal [show_modal]="modalVisible" [close_icon]='true' (closeModal)="closeModal()" [width]="1000">
		<div class="img-display-container">
			<img [src]="modalImage">
		</div>
	</lib-modal>

	<!-- UNIFORM -->
	<div *ngIf="job.uniform_required" class="uniform margin-bottom-32">
		<p class="b-font-demibold margin-bottom-8">Uniform</p>

		<div class="aligned-flex-icon" *ngIf="job.uniformType === 'event_uniform'">
			<img src="assets/icons/uniform/black-shirt-avatar.svg">
			<p>Make sure you’re wearing a
				<strong>
					plain black shirt or polo shirt, black trousers, black boots or shoes.
				</strong>
			</p>
		</div>

		<div class="aligned-flex-icon" *ngIf="job.uniformType === 'white_shirt'">
			<img src="assets/icons/uniform/white-shirt-avatar.svg">
			<p>Make sure you’re wearing a
				<strong>plain white shirt, black tie, black trousers, and smart black shoes.</strong>
			</p>
		</div>

		<div class="aligned-flex-icon" *ngIf="job.uniformType === 'black_jacket'">
			<img src="assets/icons/uniform/suit-avatar.svg">
			<p>Make sure you’re wearing a
				<strong>
					black jacket or blazer, plain white shirt, black trousers, and smart black shoes.
				</strong>
			</p>
		</div>

		<div *ngIf="job.uniformType === 'custom'">
			<p>{{job.uniform_description}}</p>
		</div>
	</div>
</div>