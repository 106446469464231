import { Injectable } from '@angular/core';
import { Adapter } from '../adapter';

export interface User {
	uuid: string;
	firstName: string;
	lastName: string;
	fullName: string;
	company: {
		uuid: string;
		logo: string;
		name: string;
	}
	permissions: Array<string>;
	email: string;
	userAvatar: string;
	mobileNumber: number;
}




@Injectable({
	providedIn: 'root'
})
export class UserAdapter implements Adapter<User> {

	adapt(item: any): User {
		return {
			'uuid': item.user.uuid,
			'firstName': item.user.name,
			'lastName': item.user.surname,
			'fullName': item.user.name + ' ' + item.user.surname,
			'company': item.company ? { uuid: item.company.uuid, logo: item.company.logo, name: item.company.name } : { uuid: '', logo: '', name: '' },
			'permissions': item.permissions ? item.permissions : [],
			'email': item.user.email,
			'userAvatar': item.user.avatar ? item.user.avatar : '',
			'mobileNumber': item.user.mobile_number
		}
	}


}
