import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditStaffComponent } from './edit-staff.component';
import { FormsModule } from '@angular/forms';
import { ClibButtonModule } from '@broadstone/clib-button';
import { ModalModule } from '@broadstone/modal';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';

@NgModule({
  declarations: [EditStaffComponent],
  imports: [
    CommonModule,
    FormsModule,
    NzAvatarModule,
    ClibButtonModule,
    ModalModule


  ],
  exports: [EditStaffComponent]
})
export class EditStaffModule { }
