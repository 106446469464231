import { NgModule } from "@angular/core";
import { NzAvatarModule } from "ng-zorro-antd/avatar";
import { NzCarouselModule } from "ng-zorro-antd/carousel";
import { NzDatePickerModule } from "ng-zorro-antd/date-picker";
import { NzDrawerModule } from "ng-zorro-antd/drawer";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzModalModule } from "ng-zorro-antd/modal";
import { NzPaginationModule } from "ng-zorro-antd/pagination";
import { NzPopoverModule } from "ng-zorro-antd/popover";
import { NzSkeletonModule } from "ng-zorro-antd/skeleton";
import { NzSwitchModule } from "ng-zorro-antd/switch";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { NzProgressModule } from 'ng-zorro-antd/progress';


@NgModule({
  exports: [
    NzDatePickerModule,
    NzAvatarModule,
    NzToolTipModule,
    NzPopoverModule,
    NzSkeletonModule,
    NzPaginationModule,
    NzCarouselModule,
    NzGridModule,
    NzDrawerModule,
    NzSwitchModule,
	NzProgressModule,
    NzModalModule
  ],
})
export class NzComponentsModule {}
