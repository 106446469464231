<lib-modal [width]="755" [show_modal]="statusModalVisible" (closeModal)="closeStatusModal()" btn_label_primary="Save"
	btn_color_primary="primary" btn_label_secondary="Cancel" (clickSecondary)="closeStatusModal()"
	(clickPrimary)="confirm()" [close_icon]='false' [body]="modalContent">
	<div class="input-wrapper-modal">
		<div class="top-header">
			<div class="aligned-flex space-between">
				<div>
					<h4 class="b-font-demibold title-margin">Work Status</h4>
					<div class="b-light b-Eh4">{{worker.statuses?.flowDescription}}</div>
					<div (click)="openAuditDrawer()" class="b-font-demibold cursor-pointer">Status Audit <img
							width="7px" src="assets/icons/arrow-right.svg" /></div>
				</div>
				<div class="text-right">

					<div *ngIf="worker.statuses?.daysToTopUp !==null">
						<div *ngIf="worker.statuses?.daysToTopUp > 0" class="b-Eh5 b-light">Top Up needed in</div>
						<div *ngIf="worker.statuses?.daysToTopUp <= 0" class="b-Eh5 danger">Top Up is required</div>
						<div class="b-Eh4"><span [ngClass]="topUpColors(worker.statuses?.daysToTopUp)"
								class="b-font-demibold">{{worker.statuses?.daysToTopUp}}</span> Days</div>
						<div *ngIf="worker.statuses?.lastTopedUpDate" class="b-Eh5 b-light">Last Topped Up
							-
							{{worker.statuses.lastTopedUpDate | dateFormat:'DD/MM/YY' : 'Europe/London'}}</div>
					</div>


				</div>
			</div>

			<div *ngIf="worker.profile?.last_worked_shift?.length > 0 && worker.profile?.last_worked_shift[0]?.shift?.end_at"
				class="b-Eh4">Last Worked Shift: <span
					class="b-font-demibold">{{worker.profile?.last_worked_shift[0]?.shift?.end_at |
					dateFormat:'DD/MM/YY' : 'Europe/London'}}</span></div>
		</div>

		<nz-skeleton *ngIf="!worker" class="nz-cites-loading" [nzParagraph]="{ rows: 2 }" [nzTitle]="false"
			[nzActive]="true">
		</nz-skeleton>
		<div *ngIf="worker">
			<div class="worker-details">
				<nz-avatar [nzText]='worker.details?.name[0] | uppercase' [nzSrc]="worker.details?.avatar"
					nzSize="small">
				</nz-avatar>
				<div class="details">
					<h5 class="b-font-demibold">{{worker.details?.name}} {{worker.details?.surname}}</h5>
					<p>{{worker.details?.phone}}</p>
					<p class="b-Eh4 b-light">{{worker.details?.email}}</p>
				</div>
				<div class="tax-info">
					<div class="tax-info-item">
						<p>NI Nr: <span class="b-light">{{worker.profile?.ni_number ?? 'N/A' | uppercase}}</span></p>

					</div>
					<div class="tax-info-item">
						<p>Payroll Nr: <span class="b-light">{{worker.taxInformation?.payrollNo ?? 'N/A'}}</span></p>

					</div>
					<div class="tax-info-item">
						<p nz-tooltip [nzTooltipTrigger]="!worker.taxInformation?.employeeNumber ? 'hover': null"
							nzTooltipTitle='Unable to work without an Employee Number.'
							[ngClass]="!worker.taxInformation?.employeeNumber ? 'b-danger':''">Employee Nr: <span
								class="b-light">{{worker.taxInformation?.employeeNumber ?? 'N/A'}}</span>
						</p>
					</div>
					<div class="tax-info-item">
						<p>Has Bank: <span class="b-light">{{worker.profile?.has_bank ? 'Yes' : 'N/A'}}</span></p>
					</div>

				</div>
			</div>
			<div *ngIf="renderDone" class="clearance-wrapper">
				<div class="min-height-div">
					<div *ngFor="let workerStatus of workStatusesData" class="worker-clearance-item">
						<div>
							<div class="aligned-flex space-between">
								<h4>{{workerStatus.title}}</h4>
								<div>

									<div *ngIf="workerStatus.effectiveDate" class="updated-date b-Eh5 b-light">Updated -
										{{
										workerStatus.effectiveDate | dateFormat:'DD/MM/YY' : 'Europe/London'}}</div>
								</div>
							</div>
							<div class="aligned-flex space-between align-base-line">
								<div *ngIf="workerStatus.statusTypes">
									<div class="margin-bottom-16 b-Eh4 b-light">Type</div>
									<app-list-drop-down *ngIf="workerStatus.statusTypes"
										(selectedItem)="selectedItem('statusTypes',workerStatus, $event)"
										element_width="190px" [status_param]="workerStatus.statusTypesSelectedIndex"
										[removedDropDownItem]="removedDropDownItem" [data]="workerStatus.statusTypes">
									</app-list-drop-down>
								</div>

								<div>
									<div class="b-Eh4 margin-bottom-16" *ngIf="workerStatus?.partiallyCompliantAt">Made
										Limited Screened on : {{workerStatus.partiallyCompliantAt |
										dateFormat:'DD/MM/YY' : 'Europe/London'}}</div>
									<div class="margin-bottom-16 b-Eh4 b-light">Status</div>
									<app-list-drop-down (selectedItem)="selectedItem('statuses',workerStatus, $event)"
										element_width="190px" [removedDropDownItem]="removedDropDownItem"
										[status_param]="workerStatus.statusSelectedIndex"
										[data]="workerStatus.statuses">
									</app-list-drop-down>

								</div>

								<div>
									<div *ngIf="workerStatus.expiresDate !=='not applicable'">
										<div class="margin-bottom-16 b-Eh4 b-light">Expires on</div>
										<div class="expires-date-picker">
											<nz-date-picker [nzDisabledDate]="disabledDate"
												[(ngModel)]="workerStatus.expiresDate"
												class="nz-override-date-range nz-override-date-b-input nz-date-no-icon"
												nzDropdownClassName='nz-override-date-range-drop' nzFormat='yyyy/MM/dd'>
											</nz-date-picker>
										</div>
									</div>
									<div *ngIf="workerStatus.valueName ==='complianceStatus' && workerStatus.daysLeftLimitedScreening !==null"
										class="text-right margin-right-8">
										<div *ngIf="workerStatus.daysLeftLimitedScreening > 0" class="b-Eh4"><span
												[ngClass]="topUpColors(workerStatus.daysLeftLimitedScreening)"
												class="b-font-demibold">{{workerStatus.daysLeftLimitedScreening}}</span>
											Days Left
										</div>
										<div *ngIf="workerStatus.daysLeftLimitedScreening <= 0" class="b-Eh4"><span
												class="b-font-demibold b-danger">Expired</span>
										</div>

									</div>
								</div>


							</div>


						</div>

					</div>
				</div>
				<div class="note">
					<div class="b-Eh4">Soft Apply Gate is currently
						<span *ngIf="worker.statuses.isSoftApplyGateOpen" class="b-font-demibold b-primary">On</span>
						<span *ngIf="!worker.statuses.isSoftApplyGateOpen" class="b-font-demibold">Off</span>
					</div>
					<div class="b-light b-Eh5">The worker must have either Full or Limited Screening and all other
						statuses must
						be Approved to apply for shifts if apply gate is closed.</div>

				</div>
			</div>
		</div>
	</div>

	<nz-drawer *ngIf="renderDone" [(nzVisible)]="viewAudit" nzWidth="450px" [nzClosable]="false"
		(nzOnClose)="closeAuditDrawer()">
		<ng-container *nzDrawerContent>
			<div class="close-btn text-right">
				<a class="cursor-pointer" (click)="closeAuditDrawer()">
					<img src="assets/icons/crossIcon.svg" />
				</a>
			</div>
			<h4 class="b-font-demibold title-margin">Work Status Audit</h4>
			<p *ngFor="let audit of workerAudit" [innerHTML]="audit"></p>
		</ng-container>
	</nz-drawer>
</lib-modal>