<div class="post-head-wrapper">
	<h2>Create Staff Account</h2>
</div>
<hr>
<form [formGroup]="form">
	<div class="setup-form">
		<!-- Your Information -->
		<div id="invoicing-timesheets" nz-row nzType="flex" nzJustify="space-between">
			<div class="title-sticky" StickyPolyFill nz-col nzSpan="8">
				<div class="row-spacing">
					<h3>Your Information</h3>
					<h5 class="b-light">Please fill out the required fields to complete account registration.
					</h5>
				</div>
			</div>
			<div nz-col nzSpan="14">
				<div id="name_fill" class="row-spacing">
					<label for="name-id">First Name</label>
					<input class="b-input b-input-white custom-width" formControlName="name" type="text" name="name"
						required id='name-id' placeholder='Enter First Name'>
					<div *ngIf="name.hasError('required') && (name.touched || submitted)">
						<p class="error-box custom-width">Please fill in this field.</p>
					</div>
				</div>
				<div id="surname_fill" class="row-spacing">
					<label for="surname-id">Surname</label>
					<input class="b-input b-input-white custom-width" formControlName="surname" type="text"
						name="surname" required id='surname-id' placeholder='Enter Surname'>
					<div *ngIf="surname.hasError('required') && (surname.touched || submitted)">
						<p class="error-box custom-width">Please fill in this field.</p>
					</div>
				</div>
				<div id="email_address_fill" class="row-spacing">
					<label for="email-address">Email Address</label>
					<input class="b-input b-input-white custom-width" formControlName="email_address" email="true"
						type="email" name="email_address" required id='email-address' placeholder='Enter Email Address'>
					<div *ngIf="email_address.hasError('required') && email_address.touched">
						<p class="error-box custom-width">Please fill in this field.</p>
					</div>
					<div
						*ngIf="!email_address.hasError('required') && email_address.touched && email_address.hasError('email')">
						<p class="error-box custom-width">Email is invalid.</p>
					</div>
				</div>
				<!-- Phone Number -->
				<div id="phone_number_fill" class="row-spacing">
					<label for="phone-number">Mobile Number</label>
					<div class="mobile-number">
						<p class="country-code">+44</p>
						<input class="b-input b-input-white custom-width" formControlName="phone_number" type="text"
							name="phone_number" id='phone-number' placeholder="Enter Mobile Number">
					</div>
					<div *ngIf="phone_number.hasError('required') && (phone_number.touched || submitted)">
						<p class="error-box custom-width">Please fill in this field.</p>
					</div>
					<div *ngIf="phone_number.hasError('invalidPhoneNumber') && (phone_number.touched || submitted) ">
						<p class="error-box custom-width">Mobile number is invalid.</p>
					</div>
					<div *ngIf="phone_number.hasError('pattern') && (phone_number.touched) ">
						<p class="error-box custom-width">Only numerical values allowed.</p>
					</div>
				</div>
				<div id="password_fill" class="row-spacing">
					<label for="password">Password</label>
					<input class="b-input b-input-white custom-width" formControlName="password" type="password"
						name="password" id='password' placeholder="Enter Password" required [pattern]="passPattern">
					<div *ngIf="password.hasError('required') && (password.touched || submitted)">
						<p class="error-box custom-width">Please fill in this field.</p>
					</div>
					<div *ngIf="password.hasError('minlength') && (password.touched || submitted)">
						<p class="error-box custom-width">Minimum 6 characters.</p>
					</div>
					<div *ngIf="password.hasError('pattern') && (password.touched || submitted)">
						<p class="error-box custom-width">Please enter a secure password.</p>
					</div>
				</div>
				<div id="confirm_password_fill" class="row-spacing">
					<label for="confirm-password">Confirm Password</label>
					<input class="b-input b-input-white custom-width" formControlName="confirm_password" type="password"
						name="confirm_password" id='confirm-password' placeholder="Re-enter Password"
						[pattern]="passPattern" required>
					<div *ngIf="confirm_password.hasError('required') && (confirm_password.touched || submitted)">
						<p class="error-box custom-width">Please fill in this field.</p>
					</div>
					<div
						*ngIf="confirm_password.hasError('MatchPassword') && (confirm_password.touched || confirm_password.dirty || submitted)">
						<p class="error-box custom-width">Passwords do not match.</p>
					</div>
					<p class="b-light b-Eh5 custom-width pass">Passwords must have at least 6 characters and contain at
						least 1 numbern and 1 uppercase letter.
					</p>
				</div>
				<div class="row-spacing">
					<label class="b-checkbox">
						<input type="checkbox" formControlName="terms" name="terms" id="terms" required />
						<span class="checkmark"></span><span class='terms custom-width'>I Accept the <span
								(click)="linkToTerms()" class='underline'>Service Terms & Conditions</span></span>
					</label>
					<div *ngIf="terms.hasError('required') && submitted">
						<p class="error-box custom-width">Please accept to continue.</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</form>
<hr>
<div class="group-buttons">
	<app-clib-button [loading]="save_loading" label="Create" (pressAction)="createStaffMember()">
	</app-clib-button>
</div>