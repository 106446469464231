import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "../environments/environment";
import { AuthSessionService } from "@broadstone/auth-session-service";
import { ApiService } from "@broadstone/api-service";
import {
	EventsTracker,
	AuthService,
	UsersService,
} from "src/services/public_api";
import { Location } from "@angular/common";

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.scss"],
})
export class AppComponent {
	constructor(
		protected apiService: ApiService,
		protected session: AuthSessionService,
		public router: Router,
		private event_tracker: EventsTracker,
		public location: Location,
		private auth: AuthService,
		public user: UsersService
	) {
		this.event_tracker.setEnv(environment, this.session.getUser());
		this.event_tracker.trackTiming("app_loading");
		this.apiService.setPlatform("company");
		this.apiService.setBaseUrl(environment.apiUrl);
		if (environment.gatewayUrl) {
			this.apiService.setAuthUrl(environment.gatewayUrl);
		} else {
			this.apiService.setAuthUrl(environment.apiUrl);
		}
		this.redirectIfWrongUrl();

		this.session.loggedIn.subscribe(() => {
			//When logged in with email and pass not from session.
			this.redirect();
		});

		this.session.loggedOut.subscribe(() => {
			this.logout();
		});
		this.redirect404();
	}

	logout() {
		this.session.setAdminHeader(null);
		this.auth.logout().catch((err) => {
			console.log(err);
		});
		this.router.navigate(["/login"]);
		this.session.logout(false);
	}

	redirect() {
		if (this.session.isAuthenticated() && !this.session.getIsAdmin()) {
			this.router.navigate(["/"]);
		} else {
			this.router.navigate(["/admin"]);
		}
	}

	redirectIfWrongUrl() {
		const currentUrl = window.location.href;
		const urlSplit = currentUrl.split("/");
		if (urlSplit[2] === "broadstone-company.appspot.com") {
			window.location.href = "https://company.orka.works";
		}
	}

	redirect404() {
		this.router.errorHandler = (error: any) => {
			let routerError = error.toString();
			if (routerError.indexOf("Cannot match any routes") >= 0) {
				this.router.navigate(["/404"]);
			} else {
				throw error;
			}
		};
	}
}
