import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JobsProviders, CompaniesService, JobsService, EventsTracker } from 'src/services/public_api';


@Component({
	selector: 'app-manage-shifts',
	templateUrl: './manage-shifts.component.html',
	styleUrls: ['./manage-shifts.component.scss']
})
export class ManageShiftsComponent implements OnInit {
	pageSetup = {
		breadcrumbs: [],
		pageTitle: '',
		extraInfo: ''
	};
	private sub: any;
	jobUuid: any;
	job_name: any;
	job_details: any;
	siteUuid: any;
	site_name: any;

	constructor(
		private event_tracker: EventsTracker,
		private route: ActivatedRoute,
		private router: Router,
		public jobData: JobsProviders,
		public companies: CompaniesService,
		public jobs: JobsService
	) { }

	ngOnInit(): void {
		this.sub = this.route.params.subscribe(params => {
			this.jobUuid = params['job_uuid'];
		});
	}

	ngOnDestroy() {
		this.sub.unsubscribe();
	}


	getJobDetails($event) {
		this.job_details = $event;
		if (this.job_details && this.job_details.title) {
			this.job_name = this.job_details.title
		} else if (this.job_details && this.job_details.service && this.job_details.service.title) {
			this.job_name = this.job_details.service.title
		} else {
			this.job_name = '';
		}
		this.siteUuid = this.job_details.site.uuid;
		if (this.job_details && this.job_details.site && this.job_details.site.client && this.job_details.site.client.name) {
			this.site_name = this.job_details.site.client.name;
		} else if (this.job_details && this.job_details.site && this.job_details.site.location && this.job_details.site.location.postcode) {
			this.site_name = this.job_details.site.location.postcode;
		} else {
			this.site_name = '';
		}
	}

	postedCompleted(e) {
		this.jobs.shiftsPosted = true;
		this.event_tracker.trackEvent('post_shifts', { shifts: e });
		if (this.jobUuid) {
			this.router.navigateByUrl("jobs/role/" + this.jobUuid);
		}
		else {
			this.router.navigate(['/jobs']);
		}
	}

	backBtn(jobUuid) {
		if (this.jobData.site_to_shifts) { // check if we click add shifts from site page and when click back go back to site
			this.router.navigateByUrl("jobs/role/" + jobUuid);
			this.jobData.site_to_shifts = false;
		} else {
			this.jobData.job_to_shifts = true;
			this.router.navigateByUrl("jobs/role/" + jobUuid + "/edit");
		}
	}



}
