import { Component, OnInit, Output, EventEmitter, Input, OnChanges } from '@angular/core';
import { ApplicationsService, CompaniesService } from 'src/services/public_api';
import { DatesHelper } from '@broadstone/helpers';
import { PopNotificationService } from '@broadstone/pop-notification-service';

@Component({
	selector: 'app-pools-create-shift',
	templateUrl: './pools-create-shift.component.html',
	styleUrls: ['./pools-create-shift.component.scss']
})
export class PoolsCreateShiftComponent implements OnChanges {
	@Input() siteUuid: string;
	@Input() resetPools: boolean;
	@Input() autoApprove: number;
	@Input() selectedWorkers: any = [];
	@Input() poolSelected: 'All workers' | 'Previously worked' | 'Specific workers';
	@Input() nextButtonAction: boolean;
	@Input() shifts: any;
	@Input() locationTimezone: string;
	@Output() nextButtonActionChange: EventEmitter<any> = new EventEmitter();
	openModalToggle: boolean = false;

	@Output() poolSelectedChange: EventEmitter<any> = new EventEmitter();


	showDropdownList: boolean = false;
	searchWorkerName: string;
	numberOfWorkers: number;
	numberOfPreviousWorkers: number;
	allWorkers: any = [];
	hideNotifyRows: boolean = false;
	overSelectNr: number = 0;
	profileVisible: boolean = false;
	profile: any;
	reverseApplyAutoAccept: number;
	isSearching: boolean = false;
	constructor(
		private companies: CompaniesService,
		private application: ApplicationsService,
		public dateHelper: DatesHelper,
		private popNotif: PopNotificationService,
	) { }

	getCompanyInfo() {
		this.companies.getCompanyInfo().then((result) => {
			this.reverseApplyAutoAccept = result['reverse_apply_flow_auto_accept_enabled'];
		}).catch(err => {
			// this.logger.error(err);
		});
	}

	ngOnChanges(changes) {
		if (this.siteUuid && this.shifts && this.shifts.length > 0) {
			this.getCompanyInfo();
			this.setOverSelectNr();
			this.getPreviouslyWorked();
			this.workersWorkedCount();
			this.allWorkersCount();
		}


		if (changes['resetPools'] && changes['resetPools'].previousValue !== undefined && changes['resetPools'].currentValue !== changes['resetPools'].previousValue) {
			this.markCheckbox('All workers');
		}

		if (this.nextButtonAction) {
			const checkModal = this.getStorage(this.poolSelected) ? true : false;
			if (!checkModal && ((this.autoApprove && (this.poolSelected === 'All workers' || this.poolSelected === 'Previously worked')) || (this.reverseApplyAutoAccept && this.poolSelected === 'Specific workers'))) {
				this.openModal();
			} else {
				this.nextButtonActionChange.emit();
			}
		}
	}

	onCloseModal(e) {
		this.openModalToggle = e.toggle;
		this.nextButtonActionChange.emit(e.action);
	}



	async searchWorkerAction() {
		this.hideNotifyRows = true;
		this.isSearching = true;
		await this.getPreviouslyWorked();
		this.isSearching = false;
		if (this.allWorkers.length === 0 || this.searchWorkerName === '') {
			this.hideNotifyRows = false;
		}
		this.markCheckbox('Specific workers');
	}


	async markSelectedWorkers(worker, poolType) {


		if (!this.checkSelectedWorker(worker)) {
			const cantWork = await this.checkWorkerCantWork(worker);
			if (cantWork) {
				return;
			}
			if (worker && worker.uuid) {
				this.selectedWorkers.push(worker);
				this.checkSelectedAllWorkers(worker, true);
			}
		} else {
			const index = this.selectedWorkers.findIndex(key => key.uuid === worker.uuid);
			this.selectedWorkers.splice(index, 1);
			this.checkSelectedAllWorkers(worker, false);
		}


		this.markCheckbox(poolType);
		this.setOverSelectNr();
	}

	checkSelectedAllWorkers(worker, select) {
		const found = this.allWorkers.filter(element => element.uuid === worker.uuid);
		if (found && found.length > 0) {
			found[0].selected = select;
		}
	}

	setOverSelectNr() {
		if (this.selectedWorkers.length > 5) {
			this.overSelectNr = this.selectedWorkers.length - 5;
		}
	}

	checkSelectedWorker(worker) {
		return this.selectedWorkers.filter(element => element.uuid === worker.uuid).length > 0;
	}

	selectWorkersOnLoad() {
		if (this.selectedWorkers.length > 0) {
			this.selectedWorkers.forEach(element => {
				this.checkSelectedAllWorkers(element, true);
			});
		} else {
			this.allWorkers.forEach(element => {
				element.selected = false;
			});
		}

	}

	allWorkersCount() {
		this.application.getAllWorkersCount(this.siteUuid).then((result) => {
			this.numberOfWorkers = result['count'];
		}).catch((err) => {
			this.hideNotifyRows = false;
		});
	}
	workersWorkedCount() {
		this.application.getAllWorkersWorkedCount(this.siteUuid).then((result) => {
			this.numberOfPreviousWorkers = result['count'];
		}).catch((err) => {
			this.hideNotifyRows = false;
		});
	}


	markCheckbox(poolType) {
		if (poolType !== 'Specific workers') {
			this.selectedWorkers.length = 0;
			this.selectWorkersOnLoad();
		}
		this.poolSelected = poolType;
		this.poolSelectedChange.emit(poolType);
	}

	openModal() {
		this.openModalToggle = true;
	}

	getPreviouslyWorked() {
		if (!this.siteUuid) {
			return;
		}
		return this.application.workersWorked(this.searchWorkerName, 1, 999).then((result) => {
			this.numberOfPreviousWorkers = result['metaData']['total'];
			this.allWorkers = result['data'];
			this.selectWorkersOnLoad();
		}).catch(() => {
			return;
		});
	}

	onPopOverClose(e) {

		if (this.selectedWorkers.length === 0 && !e && this.poolSelected === 'Specific workers') {
			this.markCheckbox('All workers');
			this.searchWorkerName = '';
			this.hideNotifyRows = false;
		}
		if (!e) {
			this.getPreviouslyWorked();
		}

	}

	closeProfile(): void {
		this.profileVisible = false;
		this.profile = {};
	}

	openProfile(worker): void {
		this.closeProfile();
		this.getProfile(worker.uuid)
	}

	getProfile(uuid) {
		this.application.getApplicantProfile(uuid).then((result) => {
			this.profile = { ...result };
			this.profileVisible = true;
		}).catch((err) => {
			// this.logger.error(err);
		});
	}

	getStorage(key) {
		if (localStorage.getItem(key) !== 'undefined') {
			return JSON.parse(localStorage.getItem(key));
		}
	}

	checkWorkerCantWork(worker) {
		return new Promise((resolve) => {
			worker.loadingCheck = true;


			if (this.shifts && this.shifts.length === 0) {
				setTimeout(() => {
					this.popNotif.createNotification('error', 'Shifts not added', 'Make sure all the shifts are added before selecting a specific worker.');
					worker.loadingCheck = false;
					resolve(true);
				}, 200);

			} else {

				const startAtList = this.shifts.map(element => this.dateHelper.reformat_moment(this.locationTimezone, element.start_at)).join();
				const endAtList = this.shifts.map(element => this.dateHelper.reformat_moment(this.locationTimezone, element.end_at)).join();
				const obj = {
					"startAtList": startAtList,
					"endAtList": endAtList,
					"workerUuids": worker.uuid, // profile uuid
					"siteUuid": this.siteUuid
				};


				this.application.canWorkCheck(obj).then((resp: any) => {
					const foundCanWorkShifts = resp.data.filter(element => element.available_workers && element.available_workers.length === 0); // return shifts that worker cannot work
					if (foundCanWorkShifts && foundCanWorkShifts.length > 0) {
						worker.cantWorkShifts = foundCanWorkShifts;
						resolve(true);
					} else {
						worker.cantWorkShifts = null;
						resolve(false);
					}

					worker.loadingCheck = false;
				}).catch(e => { worker.loadingCheck = false; });
			}





		})




	}

	conflictingShiftsTip(worker) {
		if (worker && worker.cantWorkShifts && worker.cantWorkShifts.length > 0) {
			const startAt = worker.cantWorkShifts.map(element => this.dateHelper.format_date(element.start_at)).join(' ');
			return `Conflicting Shift. ${startAt}`;
		}

	}



}
