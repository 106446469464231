import { ElementRef, Injectable } from "@angular/core";



@Injectable()

export class CalendarRange {
	extendField: any = {};
	constructor(
	) {
	}


	init(elRef: ElementRef) {
		this.extendField.separator = elRef.nativeElement.querySelector('.ant-picker-range-separator');
		this.extendField.picker = elRef.nativeElement.querySelectorAll('.ant-picker-input')[1];
		this.calendarSeparator();
	}

	calendarSeparator(show?) {
		if (show) {
			this.extendField.separator.style.display = 'inline-block';
			this.extendField.picker.style.opacity = '1';
			this.extendField.picker.style.width = '100%';
		} else {
			this.extendField.separator.style.display = 'none';
			this.extendField.picker.style.opacity = '0.01';
			this.extendField.picker.style.width = '60px';
		}
	}

	onClose(refresh = true, rangeDates, onRefresh?) {
		if (rangeDates && rangeDates.length > 0) {
			if (new Date(rangeDates[0]).toString() !== new Date(rangeDates[1]).toString()) {
				this.calendarSeparator(true);
			} else {
				this.calendarSeparator();
			}
		} else {
			this.calendarSeparator();
		}
		if (refresh && onRefresh) {
			onRefresh();
		}
	}


}