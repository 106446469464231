
<section>

    <div class="reports-row-heading report-row" *ngIf="incidentReports && incidentReports.length > 0">
        <div class="row">
            <p class="title">Site</p>
        </div>
        <div class="row">
            <p class="title">Job</p>
        </div>
        <div class="row incident">
            <p class="title">Report</p>
        </div>
        <div class="row">
            <p class="title">Worker</p>
        </div>
        <div class="row date">
            <p class="title">Date</p>
        </div>
    </div>
    <nz-skeleton [nzLoading]="loading" class="nz-timesheets-loading" [nzParagraph]="{ rows: 7 }" [nzTitle]="false"
        [nzActive]="true">
    </nz-skeleton>

    <div class="reports-row-area" *ngIf="incidentReports && incidentReports.length > 0 && !loading">
        <div class="report-row" *ngFor="let report of incidentReports">
            <div class="report-row-wrap">
                <div class="row">
                    <p class="postcode1" *ngIf="report.clientName" nz-tooltip [nzTooltipTitle]="report?.clientName">
                        {{report?.clientName}}</p>
                    <p class="not-bold" nz-tooltip [nzTooltipTitle]="report?.address1">
                        {{report?.address1}}</p>
                    <p class="not-bold postcode">{{report?.postcode}}</p>
                </div>
                <div class="row">
                    <p *ngIf="report.jobTitle">{{report?.jobTitle}}</p>
                    <p class="not-bold" *ngIf="report.jobRef">{{report?.jobRef}}</p>
                </div>
                <div class="row incident">
                    <p class="not-bold report" *ngIf="report?.incidentReport">{{report?.incidentReport}}</p>
                </div>
                <div class="row worker">
                    <nz-avatar [nzText]="report && report.workerName ? report.workerName[0] : '' "
                        [nzSrc]="report.avatar ? report.avatar : '' " nzSize="32">
                    </nz-avatar>
                    <p *ngIf="report?.workerName" nz-tooltip [nzTooltipTitle]="report?.workerName">
                        {{report?.workerName}}</p>
                </div>
                <div class="row date">
                    <p class="not-bold" *ngIf="report?.shiftStart">{{report?.shiftStart | dateFormat:'DD/MM/YY':
                        'Europe/London'}}</p>
                </div>
            </div>

        </div>
    </div>

    <lib-no-search-data *ngIf="incidentReports && incidentReports.length === 0 && !loading"
        img_src="assets/icons/magnifying-glass-tilted-left.svg" title="No Incidents Reported"
        body="Looks like everything is running smoothly on your sites. We will let you know if any incidents are reported.">
    </lib-no-search-data>
    <div class="pagination" *ngIf="incidentReports && incidentReports.length > 0 && !loading">
        <nz-pagination nzSimple nzHideOnSinglePage="false" class="b-nz-pagination" [nzSize]="'medium'"
            [nzPageIndex]="page" [nzPageSize]="1" [nzTotal]="totalPages" (nzPageIndexChange)="changePage($event)">
        </nz-pagination>
    </div>

</section>