import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SiteJobsComponent } from './site-jobs.component';
import { WorkerProfileModule } from '@broadstone/worker-profile';
import { FloatingFooterModule } from '../floating-footer/floating-footer.module';
import { NoSearchDataModule } from '@broadstone/no-search-data';
import { EditShiftsModule } from '../edit-shifts/edit-shifts.module';
import { ApplicationGlobals } from '@broadstone/helpers';
import { ClibButtonModule } from '@broadstone/clib-button';
import { WorkerRowModule } from '../worker-row/worker-row.module';
import { SearchInputModule } from '@broadstone/search-input';
import { FormsModule } from '@angular/forms';
import { ModalModule } from '@broadstone/modal';
import { LoadingSpinnerModule } from '@broadstone/loading-spinner';
import { StaticBannerModule } from '../static-banner/static-banner.module';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { JobViewModule } from '../job-view/job-view.module';



@NgModule({
	declarations: [SiteJobsComponent],
	exports: [SiteJobsComponent],
	imports: [
		CommonModule,
		FloatingFooterModule,
		WorkerProfileModule,
		EditShiftsModule,
		NoSearchDataModule,
		NzToolTipModule,
		NzPopoverModule,
		NzAvatarModule,
		NzPaginationModule,
		NzDrawerModule,
		NzSkeletonModule,
		ApplicationGlobals,
		ClibButtonModule,
		WorkerRowModule,
		JobViewModule,
		SearchInputModule,
		FormsModule,
		ModalModule,
		StaticBannerModule,
		LoadingSpinnerModule
	]
})
export class SiteJobsModule { }
