import { AuthSessionService } from '@broadstone/auth-session-service';
import { ApiService } from '@broadstone/api-service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { InvoiceSettingsAdapater } from './invoice-settings.model';
import { map } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})

export class InvoiceSettingsService {

    constructor(
        public http: HttpClient,
        public api: ApiService,
        private session: AuthSessionService,
        private invoiceSettingsAdapater: InvoiceSettingsAdapater
    ) {


    }


    public getPurchaseOrders(search?, page = 1): Promise<any> {
        const queryParams = search ? `&search=${search}` : ''

        const headers = this.api.getDefaultHeaders(this.session.getToken());
        const url = this.api.getFullUrl(`company/purchase-order?page=${page}${queryParams}`);

        return new Promise((resolve, reject) => {
            this.http.get(url, { headers })
                .subscribe(
                    (data) => {
                        const adapted_purchase_order = data['data'].map(item => {
                            return this.invoiceSettingsAdapater.adapt_purchase_order(item);
                        });

                        const metaData = {...data['meta']};

                        const response = {
                            data: adapted_purchase_order,
                            metaData
                        }
                        resolve(response);
                    },
                    (error) => reject(this.api.handleErrors(error))
                )
        });
    }


    public createPurchaseOrder(purchase_order: string, description?: string): Promise<any> {
        const url = this.api.getFullUrl('company/purchase-order');
        const payload = {
            purchase_order,
            description
        }
        const headers = this.api.getDefaultHeaders(this.session.getToken());
        return new Promise((resolve, reject) => {
            return this.http.post(url, payload, { headers })
                .subscribe(
                    (data) => {
                        resolve(data);
                    },
                    (error) => reject(this.api.handleErrors(error))
                )
        })

    }

    public editPurchaseOrder(uuid, purchaseOrder) {

        return new Promise((resolve, reject) => {

            const url = this.api.getFullUrl('company/purchase-order/:uuid', { uuid });
            const headers = this.api.getDefaultHeaders(this.session.getToken());
            return this.http.patch(url, purchaseOrder, { headers })
                .subscribe(
                    data => resolve(data),
                    error => reject(this.api.handleErrors(error))
                );
        });

    }
    public deletePurchaseOrder(uuid): Promise<any> {
        const url = this.api.getFullUrl('company/purchase-order/:uuid', { uuid });
        const headers = this.api.getDefaultHeaders(this.session.getToken());
        return this.http.delete(url, { headers })
            .pipe(
                map((data: any) => {
                    return data;
                })
            )
            .toPromise();
    }

}

