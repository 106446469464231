import { Component, ElementRef, Input } from '@angular/core';
import { AlertService } from '@broadstone/alert';
import { DatesHelper } from '@broadstone/helpers';
import { PopNotificationService } from '@broadstone/pop-notification-service';
import { CalendarRange, EmployeesService } from 'src/services/public_api';
export interface filtersModel {
	title: string,
	active: boolean,
}
interface apiFilter {
	email: string,
	mobile: string,
	name: string,
	sector: string,
	suspended: string | null,
	banned: string | null,
	canWork: string | null,
	left: string | null,
	createdStart: string,
	createdEnd: string
}
@Component({
	selector: 'app-workers',
	templateUrl: './workers.component.html',
	styleUrls: ['./workers.component.scss'],
})
export class WorkersComponent {
	@Input() workerMobile: any;
	searchBoxInput = [{ title: 'Email', value: 'email' }, { title: 'Mobile', value: 'mobile' }, { title: 'Full Name', value: 'name' }];
	selectedSearchValue: any = this.searchBoxInput[0];
	searchValue: string = '';
	rangeDates: any = [];
	suspendModalVisible: boolean = false;
	statusModalVisible: boolean = false;

	apiFilter: apiFilter = {
		email: '',
		mobile: '',
		name: '',
		sector: '',
		suspended: null,
		banned: null,
		canWork: null,
		left: null,
		createdStart: '',
		createdEnd: ''
	};

	loading: boolean = true;
	allWorkers: any = [];
	page: number = 1;
	totalPages: number;
	showFilters: boolean = false;
	registerSectors: Array<filtersModel> = [
		{ title: 'Security', active: false },
		{ title: 'Staffing', active: false },
	];
	canWorkSectors: Array<filtersModel> = [
		{ title: 'Security', active: false },
		{ title: 'Staffing', active: false },
	];
	filterWorkers = [
		{ title: 'Suspended', value: 'suspended', active: false, reset: false, dropDown: [{ title: 'All', value: null }, { title: 'Suspended', value: 1 }, { title: 'Not Suspended', value: 0 }] },
		{ title: 'Banned', value: 'banned', active: false, reset: false, dropDown: [{ title: 'All', value: null }, { title: 'Banned', value: 1 }, { title: 'Not Banned', value: 0 }] },
		{ title: 'Left', value: 'left', active: false, reset: false, dropDown: [{ title: 'All', value: null }, { title: 'Left', value: 1 }, { title: 'Not Left', value: 0 }] },
	];
	disabledDate = (current: Date): boolean => current > new Date(); // put your logic here
	workerInfo: any = null;
	viewWorkerDrawer: boolean = false;
	constructor(
		private calendarRange: CalendarRange,
		private elRef: ElementRef,
		private alert: AlertService,
		private employeesService: EmployeesService,
		private dateHelper: DatesHelper,
		private popNotif: PopNotificationService

	) {
		this.resetApiFilters();
	}

	expandFilters() {
		this.showFilters = !this.showFilters;
	}
	selectSector(filter, type) {
		filter.active = !filter.active;
		const selectedSector = this[type].filter((item) => { return item.active }).map((item) => { return item.title }).join(',').toLowerCase();

		if (type === 'registerSectors') {
			this.apiFilter.sector = selectedSector;
		}
		if (type === 'canWorkSectors') {
			this.apiFilter.canWork = selectedSector;
		}
		//Load workers
		this.page = 1;
		this.allWorkers.length = 0;
		this.getWorkers();

	}
	selectFilterDropDown(filterWorker, filter) {
		// Prevent first render
		if (filterWorker.reset) {
			filterWorker.reset = false;
		}
		if (!filterWorker.active) {
			filterWorker.active = true;
			return;
		}
		this.apiFilter[filterWorker.value] = filter.value;
		this.page = 1;
		this.allWorkers.length = 0;
		this.getWorkers();
	}
	checkActiveFilters() {
		const isDropdownSelected = this.filterWorkers.filter(item => {
			return this.apiFilter[item.value] !== null;
		}).length;
		const isSectorSelected = this.registerSectors.filter((item) => { return item.active }).length;
		const isCanWorkSelected = this.canWorkSectors.filter((item) => { return item.active }).length;
		return isSectorSelected || isCanWorkSelected || isDropdownSelected;
	}
	ngOnInit(): void {
		if (this.workerMobile) {
			this.getSpecificWorker();
		} else {
			this.getWorkers();
		}
	}
	getSpecificWorker() {
		this.searchBoxInput.sort((a, b) => {
			if (a.value === 'mobile') return -1;
			if (b.value === 'mobile') return 1;
			return 0;
		});
		this.selectedSearchItem(this.searchBoxInput[0]);
		this.searchValue = this.workerMobile;
		this.searchAction();
	}

	getWorkers() {
		this.loading = true;
		this.employeesService.getAllWorkers(this.page, 10, this.apiFilter).then((result: any) => {
			this.allWorkers = result.data;
			this.mapData();
			this.totalPages = result.metaData.last_page;
			this.page = result.metaData.current_page;
			this.loading = false;
		}).catch(() => {
			this.loading = false;
		});
	}
	getWorker(userUuid) {
		this.workerInfo = null;
		return this.employeesService.getSpecificWorker(userUuid).then((result: any) => {
			this.workerInfo = result;
		});
	}
	mapData() {
		this.allWorkers.map((worker) => {
			worker.workSecurity = worker.statuses.canWork.includes('security');
			worker.workStaffing = worker.statuses.canWork.includes('staffing');
			worker.registerSecurity = worker.registeredSectors.includes('Security');
			worker.registerStaffing = worker.registeredSectors.includes('Staffing');
			worker.lastActive = this.lastSeen(worker);
		});

	}
	lastSeen(worker) {
		if (!worker.lastActive) return;
		return this.dateHelper.time_ago(worker.lastActive);

	}
	selectedSearchItem(event) {
		this.selectedSearchValue = event;
		this.resetApiFilters();
	}

	searchAction() {
		// set filter object
		this.apiFilter[this.selectedSearchValue.value] = this.searchValue;
		//Load workers
		this.page = 1;
		this.allWorkers.length = 0;
		this.getWorkers();
	}

	ngAfterViewInit() {
		this.calendarRange.init(this.elRef);
	}


	onCloseRange() {
		this.calendarRange.onClose(true, this.rangeDates, () => {

			if (this.rangeDates && this.rangeDates.length > 0) {
				if (new Date(this.rangeDates[0]).toString() !== new Date(this.rangeDates[1]).toString()) {
					this.apiFilter['createdStart'] = this.rangeDates[0];
					this.apiFilter['createdEnd'] = this.rangeDates[1];
				} else {
					this.apiFilter['createdStart'] = this.rangeDates[0];
					this.apiFilter['createdEnd'] = this.rangeDates[0];
				}
			} else {
				this.apiFilter['createdStart'] = '';
				this.apiFilter['createdEnd'] = '';
				this.rangeDates = null;
			}
			//Load workers
			this.page = 1;
			this.allWorkers.length = 0;
			this.getWorkers();

		});

	};


	async suspendAction(worker) {
		if (worker.suspendedAt) {
			this.unsuspendAction(worker);
		} else {
			try {
				await this.getWorker(worker.userUuid);
				this.suspendModalVisible = true;
			} catch (error) {
				this.popNotif.createNotification('error', 'There has been a problem.', error);
			}
		}
	}
	async showStatus(worker) {
		try {
			await this.getWorker(worker.userUuid);
			this.statusModalVisible = true;
		} catch (error) {
			this.popNotif.createNotification('error', 'There has been a problem.', error);
		}


	}


	unsuspendAction(worker) {
		this.alert.showConfirm("Are you sure?", `${worker.name} ${worker.surname} will be unsuspended. Are you sure you want to proceed?`, "Yes").then(() => {
			this.employeesService.UnSuspendWorker({ user_uuid: worker.userUuid }).then(() => {
				this.popNotif.createNotification('success', 'Success', 'Worker has been unsuspended.');
				this.refreshWorkers();
			}).catch(() => {
				this.popNotif.createNotification('error', 'There has been a problem.', 'Worker has not been unsuspended.');
			});
		});
	}


	pageChange(e) {
		this.page = e;
		this.allWorkers.length = 0;
		this.getWorkers();
		window.scroll(0, 0);
	}

	clearFilters() {
		this.filterWorkers.forEach((item) => {
			item.active = false;
			item.reset = true;
		});
		this.registerSectors.forEach((item) => {
			item.active = false;
		});
		this.canWorkSectors.forEach((item) => {
			item.active = false;
		});
		this.rangeDates = null;
		this.searchValue = '';
		this.resetApiFilters();
		this.page = 1;
		this.allWorkers.length = 0;
		this.getWorkers();
	}

	resetApiFilters() {
		this.apiFilter = {
			email: '',
			mobile: '',
			name: '',
			sector: '',
			suspended: null,
			banned: null,
			canWork: null,
			left: null,
			createdStart: '',
			createdEnd: ''
		};
	}
	refreshWorkers() {
		this.allWorkers.length = 0;
		this.getWorkers();
	}
	closeWorkerDrawer() {
		this.viewWorkerDrawer = false;
	}
	async openWorkerDrawer(worker) {

		try {
			await this.getWorker(worker.userUuid);
			this.viewWorkerDrawer = true;
		} catch (error) {
			this.popNotif.createNotification('error', 'There has been a problem.', error);
		}


	}
}
