import { Component, OnInit, ElementRef, ViewChild, Input } from '@angular/core';
import { GlobalHelpers } from '@broadstone/helpers';
declare var google;

@Component({
    selector: 'app-geofencing-map',
    templateUrl: './geofencing-map.component.html',
    styleUrls: ['./geofencing-map.component.scss']
})
export class GeofencingMapComponent implements OnInit {
    @ViewChild('mapArea', { static: true }) mapElement: ElementRef;
    @Input() lat: string;
    @Input() lng: string;
    @Input() radius: number = 0;
    @Input() metricUnits: boolean;
    google: any;
    map: any;
    time: any;
    markers: any = [];
    siteRadius: any;
    circle: any;
    jobLocation: any;
    map_style: any =
        [
            {
                "featureType": "water",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#e9e9e9"
                    },
                    {
                        "lightness": 17
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#f5f5f5"
                    },
                    {
                        "lightness": 20
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "lightness": 17
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "lightness": 29
                    },
                    {
                        "weight": 0.2
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "lightness": 18
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "lightness": 16
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#f5f5f5"
                    },
                    {
                        "lightness": 21
                    }
                ]
            },
            {
                "featureType": "poi.park",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#dedede"
                    },
                    {
                        "lightness": 21
                    }
                ]
            },
            {
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "visibility": "on"
                    },
                    {
                        "color": "#ffffff"
                    },
                    {
                        "lightness": 16
                    }
                ]
            },
            {
                "elementType": "labels.text.fill",
                "stylers": [
                    // {
                    //     "saturation": 36
                    // },
                    {
                        "color": "#333333"
                    },
                    {
                        "lightness": 40
                    }
                ]
            },
            {
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "transit",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#f2f2f2"
                    },
                    {
                        "lightness": 19
                    }
                ]
            },
            {
                "featureType": "administrative",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#fefefe"
                    },
                    {
                        "lightness": 20
                    }
                ]
            },
            {
                "featureType": "administrative",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "color": "#fefefe"
                    },
                    {
                        "lightness": 17
                    },
                    {
                        "weight": 1.2
                    }
                ]
            }
        ];
    constructor(
        private helper: GlobalHelpers
    ) { }

    ngOnInit(): void {
    }

    ngOnChanges() {
        this.getMeters();
        this.loadMap();
    }

    getMeters() {
        if (this.metricUnits === true) {
            this.radius = Math.floor(this.radius * 1000);
        }
        else if (this.metricUnits === false) {
            this.radius = Math.floor(this.radius * 1609.344);
        }
        else return;
    }

    loadMap() {
        clearTimeout(this.time);
        let latLng = new google.maps.LatLng(55.378051, -3.435973);
        let styledMapType = new google.maps.StyledMapType(this.map_style, { name: 'Styled Map' });
        let mapOptions = { //set map settings
            center: latLng,
            zoom: 12,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            mapTypeControl: false,
            fullscreenControl: false,
            disableDefaultUI: true,
            draggable: false
        }
        this.time = setTimeout(() => {
            this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);
            this.map.mapTypes.set('styled_map', styledMapType);
            this.map.setMapTypeId('styled_map');
            if (this.lat && this.lng) {
                this.jobLocation = { lat: parseFloat(this.lat), lng: parseFloat(this.lng) };
                this.addMarker(this.jobLocation, "./assets/icons/pin.png");
                this.map.setCenter(new google.maps.LatLng(this.jobLocation));
            }
            if (this.radius) {
                this.addCircle(this.jobLocation)
            }
            if (this.circle && this.jobLocation && this.map) {
                this.setZoomFromRadius();
            }
        }, 200);
    }

    addCircle(position) {
        this.circle = new google.maps.Circle({
            strokeColor: "#4a4a4a",
            strokeOpacity: 0.2,
            strokeWeight: 0.5,
            fillColor: "#4a4a4a",
            fillOpacity: 0.3,
            map: this.map,
            center: position,
            radius: this.radius,
        });
        return this.circle;
    }

    addMarker(position, icon) {
        let marker = new google.maps.Marker({
            zIndex: 999,
            animation: google.maps.Animation.DROP,
            map: this.map,// your google.maps.Map object
            position: position,
            icon: icon,
        });
        this.markers.push(marker);
        return marker;
    }

    setZoomFromRadius() {
        this.map.fitBounds(this.circle.getBounds()); //remove one zoom level to ensure no marker is on the edge.
    }



}
