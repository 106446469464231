
	<div *ngIf='!hideBanner' class='banner-explainer'>
		<div class="banner-explainer-flex">
			<p class="b-font-demibold b-primary">{{title}}</p>
			<div class="text-right">
				<img (click)="remove()" class="close-card cursor-pointer" src="assets/icons/close_modal.svg" />
			</div>
		</div>
		<div class="b-Eh4 b-light">{{body}}</div>			
	</div>
