import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-input-list-builder',
    templateUrl: './input-list-builder.component.html',
    styleUrls: ['./input-list-builder.component.scss']
})
export class InputListBuilderComponent implements OnInit {
    data: any = [];
    value: any;
    @Input() inputted_data: any;
    @Input() placeholder: string;
    @Input() myid: string; //specify id used when clicking on the label to select the field
    @Input() input_text_limit: string;
    @Input() save_limit: string;
    @Input() email: any;
    @Input() width: string; //px
    @Input() save_loading: boolean;
    @Output() ListStored: EventEmitter<any> = new EventEmitter();

    constructor() { }

    ngOnInit() {
    }

    ngOnChanges() {
        if (this.inputted_data) {
            this.data = this.inputted_data;
        }
    }

    save() {
        if (this.save_limit && this.save_limit <= this.data.length) {
            return;
        }
        if (!this.value) {
            return;
        }
        if (this.data.indexOf(this.value) == -1) {
            this.data.push(this.value);
        }
        this.ListStored.emit(this.data);
        this.value = '';
    }

    delete(index) {
        if (this.data && this.data.length > 0) {
            this.data.splice(index, 1);
            this.ListStored.emit(this.data);
        }
    }
}
