import { Injectable } from '@angular/core';
import { Job } from '../jobs/job.model';
import { GlobalAdapter } from '../adapter';


export interface Shifts {
	start_at: string,
	end_at: string,
	slots: number,
	accepted_applications: number,
	hourly_rate: number,
	uuid?: string,
	job?: Job,
	lone_worker_shift: number,
	bank_holiday_rate: number,
	is_bank_holiday: boolean,
	reverse_apply_flow_expiry_at?: string,
}

export interface AcceptedShifts {
	accepted_applications: number
}



@Injectable({
	providedIn: 'root'
})
export class ShiftsAdapter {

	constructor(
		public adapter: GlobalAdapter
	) {

	}

	shiftsModel = [
		"start_at",
		"end_at",
		"slots",
		"accepted_applications",
		"hourly_rate",
		"uuid",
		"job",
		"lone_worker_shift",
		"bank_holiday_rate",
		"is_bank_holiday",
		"reverse_apply_flow_expiry_at",
	]

	shiftsModelCreation = [
		"start_at",
		"end_at",
		"slots",
		"hourly_rate",
		"uuid",
		"lone_worker_shift",
		"bank_holiday_rate",
		"is_bank_holiday"
	]

	adaptShifts(shift, logger): Shifts {
		logger.logMissingObjProps('Shift', shift, this.shiftsModel);
		return this.adapter.globalAdapting(this.shiftsModel, shift) as Shifts;
	}

	adaptNewShifts(shift, logger): Shifts {
		logger.logMissingObjProps('Shift', shift, this.shiftsModelCreation);
		return this.adapter.globalAdapting(this.shiftsModelCreation, shift) as Shifts;
	}



	adaptAccepted(data): AcceptedShifts {
		return {
			"accepted_applications": data.accepted_applications
		}
	}
}