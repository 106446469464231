import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';


@Component({
    selector: 'app-auth-twofa',
    templateUrl: './auth-twofa.component.html',
    styleUrls: ['./auth-twofa.component.scss']
})

export class AuthTwofaComponent implements OnInit {
    @Input() contactMethod: any;
    @Input() selectMethod: boolean;
    @Input() codeFailed: boolean = false;
    @Output() submitCode: EventEmitter<any> = new EventEmitter();
    @Output() selectOption: EventEmitter<any> = new EventEmitter();
    authCode = [{ id: 1, numValue: '' }, { id: 2, numValue: '' }, { id: 3, numValue: '' }, { id: 4, numValue: '' }, { id: 5, numValue: '' }, { id: 6, numValue: '' }, { id: 7, numValue: '' }]
    pastedCode: boolean = false;
    options = [{ title: 'SMS', text: 'Send a verification code to my mobile', option: 'sms' }, { title: 'Email', text: 'Send a verification code to my email', option: 'email' }]

    constructor(
    ) { }

    ngOnInit(): void {

    }
    ngOnChanges() {
        if (this.codeFailed) {
            this.resetAuthCode();
            this.pastedCode = false;
            this.codeFailed = false;
        }
    }

    resetAuthCode() {
        this.authCode.forEach((digit, index) => {
            digit.numValue = '';
            if (index === 0) {
                this.autoTab(digit);
            }
        });

    }

    submitAuthCode(digit) {
        if (this.pastedCode) {
            return;
        }
        this.autoTab(digit);
        let userCode = '';
        let count = 0;
        this.authCode.forEach(digit => {
            if (digit.numValue != '') {
                count++;
                userCode += digit.numValue;
                if (count === 7) {
                    this.submitCode.emit({ "code": userCode, "token": this.contactMethod.token });
                }
                else return;
            }
            else {
                return;
            }
        });
    }

    autoTab(digit) {
        if (digit.id === 7 || this.pastedCode) {
            return;
        }
        const maxLength = 1;
        if (digit.numValue.length === maxLength) {
            document.getElementById(digit.id + 1).focus();
        }
        if (digit.id === 1 && digit.numValue.length < maxLength) {
            document.getElementById(digit.id).focus();
        }
        else if (digit.numValue.length < maxLength) {
            digit.id--;
            this.pastedCode = false;
        }
    }

    onPaste(event: ClipboardEvent) {
        this.pastedCode = true;
        const clipboardData = event.clipboardData;
        const pastedText = clipboardData.getData('text');
        const codeArr: Array<any> = pastedText.split("");
        codeArr.forEach((ele, index) => {
            this.authCode[index].numValue = ele;
        });
        if (codeArr.length === this.authCode.length) {
            this.submitCode.emit({ "code": pastedText, "token": this.contactMethod.token });
        }


    }

    selectTfaOption(option) {
        this.selectOption.emit(option);
    }





}
