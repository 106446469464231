import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-auto-approve-modal',
	templateUrl: './auto-approve-modal.component.html',
	styleUrls: ['./auto-approve-modal.component.scss']
})
export class AutoApproveModalComponent implements OnInit {
	@Input() openModal: boolean;
	@Input() autoApprove: number;
	@Input() reverseApplyAutoAccept: number;
	@Input() modalCopyType: 'All workers' | 'Previously worked' | 'Specific workers';

	@Output() openModalChange: EventEmitter<any> = new EventEmitter();
	@Input() notShowModalCheckbox: boolean;
	constructor() { }

	ngOnInit(): void {
	}

	ngOnChanges() {
		if(this.openModal){
			this.notShowModalCheckbox= this.getStorage(this.modalCopyType) ? true : false;
		}
	}


	handleCancel() {
		 this.openModalChange.emit({toggle: !this.openModal, action:'cancel'});
	}

	handleOk() {
		this.rememberDismiss(this.modalCopyType);
		this.openModalChange.emit({toggle: !this.openModal, action:'ok'});

	}

	markCheckbox() {
		this.notShowModalCheckbox = !this.notShowModalCheckbox;
	}

	rememberDismiss(key) {
		localStorage.setItem(key, JSON.stringify(this.notShowModalCheckbox));
	}

	getStorage(key) {
		if (localStorage.getItem(key) !== 'undefined') {
			return JSON.parse(localStorage.getItem(key));
		}
	}
}
