<lib-modal [show_modal]="openShiftPatternModal" (closeModal)='handleCancel()' [width]="400">
    <div tabindex="0"></div>
    <h4 *ngIf="new_pattern === postShiftPattern.new">New Shift Pattern</h4>
    <h4 *ngIf="new_pattern === postShiftPattern.fourOnFourOff">4 on 4 off</h4>
    <h4 *ngIf="new_pattern === postShiftPattern.sixOnThreeOff">6 on 3 off</h4>
    <div *ngIf="new_pattern === postShiftPattern.new" class='days-container'>
        <div>
            <label>Day</label>
        </div>
        <div class='days'>
            <div *ngFor="let day of days">
                <app-clib-button [color]="day.selected ? 'primary' : 'dark'" [label]="day.day"
					(click)="selectDay(day.day)" [type]="day.selected ? 'filled' : 'outline'"
                    [icon]="day.selected ? './assets/icons/ic-check-mark.svg' : ''">
                </app-clib-button>
            </div>
        </div>
    </div>
    <form [formGroup]="form">
        <div *ngIf="form.controls.shifts_added" formArrayName='shifts_added'>
            <div *ngFor="let date of form.controls.shifts_added.controls; let index = index;">
                <div formGroupName="{{index}}">
                    <div class="input-container">
                        <div>
                            <label>Date Range</label>
                        </div>
                        <div class="date-input-selection modal-right-side">
                            <nz-range-picker nzInputReadOnly [ngModelOptions]="{standalone: true}" [(ngModel)]='range_dates'
                                (ngModelChange)="handleGetFirstDay()" #calendar_range
                                [nzPlaceHolder]="['Please select']"
                                class="nz-shift-pattern-date-range nz-date-no-icon nz-date-no-separator nz-no-cross"
                                nzDropdownClassName='nz-override-date-range-drop' nzFormat='dd/MM/yyyy'>
                            </nz-range-picker>
                        </div>

                    </div>
                    <div class="first-shift" *ngIf="firstDay">
                        <p>Your first shift starts <span class="bold">{{ firstDay }}</span></p>
                    </div>
                    <div class='view-schedule-container'>
                        <div class='view-schedule'>
                            <p nz-popover [nzPopoverContent]="viewSchedulePop" nzPopoverTrigger="click" class="date-input-text">View Schedule</p>
                            <img nz-popover [nzPopoverContent]="viewSchedulePop" nzPopoverTrigger="click" src="assets/icons/view-schedule.svg">
                        </div>
						<ng-template #viewSchedulePop>
							<app-view-schedule-component [validDays]="validDays"></app-view-schedule-component>
						</ng-template>
	
                    </div>

                    <div class="input-container">
                        <div>
                            <label>Shift Time</label>
                        </div>
                        <div class="times modal-right-side">
                            <textarea (keyup)="amendTime(index, 'start_at')" formControlName='start_at'
                                placeholder="07:00" required class="start-time" type="text" rows="1">
              </textarea>
                            <p>-</p>
                            <textarea (keyup)="amendTime(index, 'end_at')" formControlName='end_at' placeholder="16:00"
                                required class="end-time" type="text" rows="1">
              </textarea>
                        </div>
                    </div>
                    <div class="error" *ngIf="(date.controls.start_at?.errors || date.controls.end_at?.errors)">
                        <p class="error-box"
                            *ngIf="date.controls.start_at?.errors?.pattern || date.controls.end_at?.errors?.pattern">
                            Time must be
                            entered in HH:MM format</p>
                        <p class="error-box"
                            *ngIf="(date.controls.start_at?.errors?.required && date.controls.start_at.touched) || ( date.controls.end_at?.errors?.required && date.controls.end_at.touched)">
                            Shift time is
                            required</p>
                        <p class="error-box"
                            *ngIf="(date.controls.start_at?.errors?.maxHours || date.controls.end_at?.errors?.maxHours) ">
                            The shift
                            must be
                            less than {{ max_hours }} hours
                        </p>
                    </div>
                    <div class="input-container">
                        <div>
                            <label>Pay</label>
                        </div>
                        <div class="modal-right-side">
                            <div class="pay">
                                <textarea (keyup)="set()" formControlName='hourly_rate' min="min_pay_rate"
                                    [placeholder]="min_pay_rate" required class="b-textarea b-textarea-white"
                                    type="number" rows="1">
                </textarea>
                                <!-- <input (keyup)="set()" formControlName='hourly_rate' min="9" placeholder="9.00"
      class="b-input b-input-white" type="number"> -->
                            </div>
                        </div>
                    </div>
                    <div class="error-rate"
                        *ngIf="date.controls.hourly_rate?.errors && date.controls.hourly_rate?.touched">
                        <p class="error-box" *ngIf="date.controls.hourly_rate?.errors?.min">Min &pound;
                            {{min_pay_rate}}</p>
                        <p class="error-box"
                            *ngIf="!date.controls.hourly_rate?.errors?.min || date.controls.hourly_rate?.errors?.numberinvalid">
                            Hourly rate is required.</p>
                    </div>

                    <div class="input-container">
                        <div>
                            <label>Positions</label>
                        </div>
                        <div class="modal-right-side">
                            <div class="staff">
                                <input (keyup)="set()" (change)="set()" formControlName='slots' min="1" placeholder="1"
                                    class="b-input b-input-white" type="number">
                            </div>
                        </div>
                    </div>
                    <div class="error error-staff" *ngIf="date.controls.slots?.errors && date.controls.slots?.touched">
                        <p class="error-box">Staff required.</p>
                    </div>
                </div>



            </div>
        </div>
    </form>

    <div class="group-buttons">
        <app-clib-button type="outline" color="dark" label="Cancel" (pressAction)="handleCancel()">
        </app-clib-button>
        <app-clib-button [disabled]="shifts_data.length == 0" [loading]="save_loading" label="Add" (pressAction)="publishShifts()">
        </app-clib-button>

    </div>











</lib-modal>