import { Injectable } from '@angular/core';
import { GlobalAdapter } from '../adapter';
import { Qualifications } from '../jobs/requirements.model';
import { LoggingService } from '../logging/logging.service';

export interface WorkerExperience {
	description: string,
	end_at: string,
	position: string,
	start_at: string,
	string: string
}
export interface WorkerQualification {
	uuid: string,
	data: any,
	qualification_types?: Array<Qualifications>
}
export interface Worker {
	uuid: string;
	name: string;
	surname: string;
	avatar: string;
	location: any;
	mobile_number?: string;
}
export interface Skill {
	uuid: string,
	title: string
}
export interface WorkerSkills {
	uuid: string,
	type_name: string,
	requirements: Array<Skill>
}

export interface Breakdown {
	dateIssued: string,
	points: any,
	type: any,
	uuid: string
}

export interface Strikes {
	breakdown: Array<Breakdown>,
	expires_in: number,
	expiry_date: string,
	total: number

}

export interface EmployeeStatus {
	has_bank: boolean,
	has_nok: boolean,
	has_tax: boolean,
}

export interface CompliantStatuses {
	compliance_status: string | null,
	id_status: string | null,
	rtw_status: string | null,
	work_job_compliant: boolean | null
}

export interface WorkStatus {
	allowed_to_apply: boolean | null,
	compliant_statuses: CompliantStatuses,
	employee_statuses: EmployeeStatus
}

export interface Applicant {
	uuid: string,
	review_score: number,
	has_site_training: number,
	strikes: Strikes,
	// best_matched: boolean,
	skills_qualifications: Array<WorkerSkills>
	user: Worker,
	worker_status: WorkStatus,
	ranking_score: number
}

export interface WorkerProfile {
	uuid: string,
	description: string,
	shifts_worked: number,
	hours_worked: number,
	price_to_hire: number,
	ranking_score: number,
	review_score: number,
	next_of_kin_name: string,
	next_of_kin_phone: string,
	next_of_kin_relationship: string,
	has_driving_license: string,
	has_vehicle: boolean,
	user: Worker,
	qualifications: Array<WorkerQualification>,
	experience: Array<WorkerExperience>
}

export interface Applications {
	uuid: string,
	shift_uuid: string,
	profile_uuid: string,
	clocked_in_at: string,
	clocked_out_at: string,
	accepted_at: string,
	automatically_accepted: number,
	cancelled_at: string,
	declined_at: string,
	created_at: string,
	expires_at?: string,
	status: string,
	no_show: string,
	held_at: string

}
export interface ApplicantApplication {
	applicant: Applicant,
	application: Applications
}

export interface DashboardApplication {
	accepted_at: string,
	clocked_in_at: string,
	clocked_out_at: string,
	clocked_in_latitude: string,
	clocked_in_longitude: string,
	clocked_out_latitude: string,
	clocked_out_longitude: string,
	profile_uuid: string,
	shift_uuid: string,
	status: string,
	timesheet_status: string,
	created_at: string,
	user: Worker,
	uuid: string,
	no_show: number,
}

export interface WorkerWorked {
	avatar: string;
	mobile_number: string;
	name: string;
	surname: string;
	uuid: string;
	applicant_uuid: string;
	ranking_score: number,
	site_trained: boolean

}

export interface IncidentReport {
	workerName: string,
	avatar: string,
	incidentReport: string,
	shiftStart: string,
	shiftEnd: string,
	jobTitle: string,
	jobRef: string,
	clientName: string,
	address1: string,
	address2: string,
	city: string,
	postcode: string
}

export interface blackListedWorker {
	name: string,
	surname: string,
	jobProfileUuid: string,
	avatar: string,
	blacklistedDate: string,
	blacklistedReason: string
}


@Injectable({
	providedIn: 'root'
})
export class ApplicationsAdapter {

	constructor(
		public adapter: GlobalAdapter,
		public logger: LoggingService
	) {

	}

	applicantModel = [
		'uuid',
		'review_score',
		'has_site_training',
		'strikes',
		'skills_qualifications',
		'user',
		'worker_status',
		'ranking_score'
	]

	applicationsModel = [
		'uuid',
		'shift_uuid',
		'profile_uuid',
		'clocked_in_at',
		'clocked_out_at',
		'accepted_at',
		'automatically_accepted',
		'cancelled_at',
		'declined_at',
		'created_at',
		'expires_at',
		'status',
		'no_show',
		'held_at'
	]

	workerProfileModel = [
		'uuid',
		'description',
		'shifts_worked',
		'hours_worked',
		'price_to_hire',
		'ranking_score',
		'review_score',
		'next_of_kin_name',
		'next_of_kin_phone',
		'next_of_kin_relationship',
		'has_driving_license',
		'has_vehicle',
		'user',
		'qualifications',
		'experience'
	]

	dashboardApplicationModel = [
		'accepted_at',
		'clocked_in_at',
		'clocked_out_at',
		'clocked_in_latitude',
		'clocked_in_longitude',
		'clocked_out_latitude',
		'clocked_out_longitude',
		'profile_uuid',
		'shift_uuid',
		'status',
		'timesheet_status',
		'created_at',
		'user',
		'uuid',
		'no_show',
	]

	adaptApplicant(applicant, logger): Applicant {
		logger.logMissingObjProps('Applicant', applicant, this.applicantModel);
		return this.adapter.globalAdapting(this.applicantModel, applicant) as Applicant;
	}

	adaptApplications(application, logger): Applications {
		logger.logMissingObjProps('Application', application, this.applicationsModel);
		return this.adapter.globalAdapting(this.applicationsModel, application) as Applications;
	}

	adaptApplicantProfile(workerProfile, logger): WorkerProfile {
		logger.logMissingObjProps('Worker Profile', workerProfile, this.workerProfileModel);
		return this.adapter.globalAdapting(this.workerProfileModel, workerProfile) as WorkerProfile;
	}

	adaptApplicantApplication(data): ApplicantApplication {
		return {
			applicant: this.adaptApplicant(data.applicant, this.logger),
			application: this.adaptApplications(data.application, this.logger)
		}
	}

	adaptDashboardApplication(application, logger): DashboardApplication {
		logger.logMissingObjProps('Dashboard Application', application, this.dashboardApplicationModel);
		return this.adapter.globalAdapting(this.dashboardApplicationModel, application) as DashboardApplication;
	}


	adaptWorkersWorked(data): WorkerWorked {
		return {
			avatar: data.avatar,
			mobile_number: data.mobile_number,
			name: data.name,
			surname: data.surname,
			uuid: data.jobProfile && data.jobProfile.uuid ? data.jobProfile.uuid : null,
			applicant_uuid: data.uuid,
			ranking_score: data.jobProfile.ranking_score,
			site_trained: data.jobProfile.site_trained
		}
	}

	adaptIncidentReport(data): IncidentReport {
		return {
			workerName: data.first_name + ' ' + data.surname,
			avatar: data.avatar,
			incidentReport: data.incident_report,
			shiftStart: data.shift_start,
			shiftEnd: data.shift_end,
			jobTitle: data.job_title,
			jobRef: data.job_reference,
			clientName: data.client_name,
			address1: data.address_1,
			address2: data.address_2,
			city: data.city,
			postcode: data.postcode
		}

	}

	adaptBlackListedWorker(data): blackListedWorker {
		return {
			name: data.forename,
			surname: data.surname,
			jobProfileUuid: data.jobProfileUuid,
			avatar: data.avatar,
			blacklistedDate: data.blacklistedDate,
			blacklistedReason: data.blacklistedReason
		}
	}

	adaptRejectApplications(item) {
		return {
			'uuid': item
		}
	}

	adaptErrorApplications(item) {
		return {
			'noSlots': item.noSlots,
			'overlap': item.overlap,
			'notSiteTrained': item.notSiteTrained,
			'hoursLimit': item.hoursLimit,
			'failedWelfareChecks': item.failedWelfareChecks,
		}
	}

}
