import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputSuggestionsComponent } from './input-suggestions.component';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { LoadingSpinnerModule } from '@broadstone/loading-spinner';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzPopoverModule } from 'ng-zorro-antd/popover';


@NgModule({
    declarations: [InputSuggestionsComponent],
    exports: [InputSuggestionsComponent],
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        BrowserAnimationsModule,
        HttpClientModule,
        LoadingSpinnerModule,
		NzAvatarModule,
		NzPopoverModule,
    ]
})
export class InputSuggestionsModule { }
