
<div *ngIf="!dashboard_data && is_loaded" class="content-wrapper">
	<app-no-data [no_data]="no_data"></app-no-data>
	<hr>
	<div class="group-buttons">
		<app-clib-button *ngIf="edit_jobs" routerLink='/jobs/manage-site' label="Post Job +">
		</app-clib-button>
	</div>
</div>
<div class="dashboard-content" *ngIf="dashboard_data && is_loaded">

	<lib-dashboard (selectFilters)="selectFilter($event)" [filters]="filters" [edit_applications]="edit_applications" (onOpenSchedule)="openSchedule($event)"
		(intervalAction)="checkInterval($event)" [dashboard_shifts]="dashboard_shifts" [title]="title"
		[profile]="profile" [pendingApps]="pendingApps" [has_n_p]="has_n_p" (noShowRequest)="noShow($event)"
		(apiRequest)="resetData($event)" (onScrollEvent)="onScroll($event)" (pendingAppRequest)="getPendingApps($event)"
		(profileRequest)="getProfile($event)" (acceptApplication)="acceptWorker($event)"
		(closeDrawer)="closeDrawer($event)">
	</lib-dashboard>
</div>

<lib-modal title="Increase Account Security?"
	body="By enabling two-factor login you will increase the security on your account. This will require you to enter a unique code that will be sent to your email address each time you login."
	[show_modal]="isVisible" (closeModal)="modalClose()" btn_color_primary="primary" btn_label_primary="Yes"
	(clickPrimary)="turnOnTFA()" btn_label_secondary="No" (clickSecondary)="modalClose()">
</lib-modal>

<lib-modal title="We would like to hear your thoughts" [close_icon]='true'
	body="We're looking to introduce auto approval for any worker who has worked on a site before. We would love to hear your feedback on this feature."
	[show_modal]="modalIsVisible" (closeModal)="closeSurveyQuestion()" btn_color_primary="primary"
	btn_label_primary="Give Feedback" (clickPrimary)="openSurvey()" btn_label_secondary="No Thanks"
	(clickSecondary)="closeSurveyQuestion()">
</lib-modal>