import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, UsersService } from 'src/services/public_api';
import { AuthSessionService } from '@broadstone/auth-session-service';


@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
    logo_src = 'assets/logo/orka-word.svg';
    token: string;
    private sub: any;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private session: AuthSessionService,
        private auth: AuthService,
        private user: UsersService
    ) { }

    ngOnInit() {
        if (this.session.isAuthenticated()) {
            this.redirect('worker-map');
        }

        this.sub = this.route.queryParams.subscribe(params => {
            this.token = params['token'];
        });
    }




    redirect(e) {
        const redirect = '/' + e;
        this.router.navigate([redirect]);
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

}
