
<section>
    <div class="content-wrapper">
        <div>

            <div class='settings'>
                <h3 class="underline">Profile</h3>
                <ul>
                    <li (click)="openEditProfile()"><span>
                            <p class="b-link-grey">Edit Profile</p>
                        </span></li>
                    <li (click)="openEditNotifications()"><span>
                            <p class="b-link-grey">Notification Preferences</p>
                        </span></li>
                </ul>


                <h3 class="underline">Settings</h3>

                <ul>
                    <li class="b-link-grey" (click)="openEditCompany()"><span>
                            <p class="b-link-grey">Edit Company </p>
                        </span></li>
                    <li class="b-link-grey" (click)="openStaffPermissions()"><span>
                            <p class="b-link-grey">Manage Staff</p>
                        </span></li>
                    <li class="b-link-grey" (click)="openInvoiceSettings()"><span>
                            <p class="b-link-grey">Invoice Settings</p>
                        </span></li>
                </ul>
            </div>
        </div>

    </div>
</section>