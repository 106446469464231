import { Component, OnInit, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { StaffService, AuthService } from 'src/services/public_api';
import { AuthSessionService } from '@broadstone/auth-session-service';
import { PopNotificationService } from '@broadstone/pop-notification-service';
import { ActivatedRoute } from '@angular/router';
import { GlobalHelpers } from '@broadstone/helpers';

@Component({
	selector: 'app-edit-staff-profile',
	templateUrl: './edit-staff-profile.component.html',
	styleUrls: ['./edit-staff-profile.component.scss']
})
export class EditStaffProfileComponent implements OnInit {
	form: any;
	name: any;
	last_name: any;
	email_address: any;
	phone_number: any;
	currentUser: any;
	imagesrc: any = null;
	update_loading: boolean = false;
	submitted: boolean = false;
	tfa: any = { email: false, sms: false };
	isVisible: boolean = false;
	password: string;


	// @Output() success: EventEmitter<any> = new EventEmitter();

	constructor(
		private formB: UntypedFormBuilder,
		public session: AuthSessionService,
		private popNotif: PopNotificationService,
		private staff_services: StaffService,
		private auth: AuthService,
		private route: ActivatedRoute,
		public globalHelpers: GlobalHelpers
	) {
		this.form = this.formB.group({
			name: ['', Validators.required],
			last_name: ['', Validators.required],
			email_address: [{ value: '', disabled: true }, Validators.required],
			phone_number: [{ value: '', disabled: true }, Validators.required]
		})

		this.name = this.form.get('name');
		this.last_name = this.form.get('last_name');
		this.email_address = this.form.get('email_address');
		this.phone_number = this.form.get('phone_number');


	}

	ngOnInit() {
		this.route.queryParams
			.subscribe(params => {
				if (params['auth'] === 'update') {
					this.globalHelpers.scroll_to_section('auth-update')
				}
			})
		if (this.session.isAuthenticated()) {
			this.currentUser = this.session.getUser();
			this.populateInput();
			this.getUser();
		}
	}

	populateInput() {
		if (this.currentUser) {
			this.form.controls['name'].patchValue(this.currentUser.firstName);
			this.form.controls['last_name'].patchValue(this.currentUser.lastName);
			this.form.controls['email_address'].patchValue(this.currentUser.email);
			// this.form.controls['phone_number'].patchValue(this.currentUser.mobileNumber);
		}
	}

	getUser() {
		this.auth.getUser().then((result) => {
			this.tfa.email = result.tfa.email;
			this.tfa.sms = result.tfa.sms;
			this.form.controls['phone_number'].patchValue(result.mobileNumber);
		}).catch((err) => {
			// this.logger.error(err);
		});
	}

	getImg(event) {
		let fileobj = event.target.files;
		let file = fileobj[0];
		if (file) {   //SHOWING SELECTED IMG
			const reader = new FileReader();
			reader.onload = (e: any) => {
				this.imagesrc = e.target.result;
			}
			reader.readAsDataURL(file);
		}
	}

	updateProfile() {
		this.update_loading = true;
		let profile = {
			"name": this.name.value,
			"surname": this.last_name.value,
			// "mobile_number": this.phone_number.value,
		};
		if (this.imagesrc) {
			profile['avatar'] = this.imagesrc;
		}
		this.staff_services.updateMe(profile).then((result) => {
			this.saveToSession(profile);
			this.popNotif.createNotification('success', 'Success', 'Profile updated successfully');
			this.update_loading = false;
		}).catch((err) => {
			this.popNotif.createNotification('error', 'There has been a problem.', err);
			// this.logger.error(err);
			this.update_loading = false;
		});
	}

	saveToSession(profile) {
		this.currentUser.firstName = profile.name;
		this.currentUser.lastName = profile.surname;
		this.currentUser.fullName = profile.name + ' ' + profile.surname;
		this.currentUser.mobileNumber = profile.mobile_number;
		if (profile && profile.avatar) {
			this.currentUser.userAvatar = profile.avatar;
		}
		this.session.setUser(this.currentUser, true);
	}

	onSwitchChange(e, type) {
		let authPref;
		if (type === 'email') {
			authPref = {
				tfa: {
					email: e
				}
			}
		}
		else if (type === 'sms') {
			authPref = {
				tfa: {
					sms: e
				}
			}
		}
		this.updateAuthPref(authPref)
	}

	updateAuthPref(pref?) {
		let authPref;
		if (!pref) {
			authPref = {
				tfa: {
					email: this.tfa.email,
					sms: this.tfa.sms,
					password: this.password
				},
			}
		}
		else {
			authPref = pref;
		}
		this.auth.updateAuthPref(authPref).then((result) => {
			this.popNotif.createNotification('success', 'Success', 'Your login preferences have been updated');
			this.modalClose();
		}).catch((err) => {
			if (err === 'Authentication required to perform this action.') {
				this.isVisible = true;
			}
			else {
				this.popNotif.createNotification('error', 'There has been a problem.', err);
				// this.logger.error(err);
			}
		});
	}

	modalClose() {
		this.isVisible = false;
		this.password = '';
		this.getUser();
	}

}
