import { EventEmitter, Injectable } from "@angular/core";

export interface DeleteReason {
	reason: string,
	selected: boolean
}

export interface PageAction {
	link: string,
	tooltip: string
}

export interface PageSetup {
	actionOne: PageAction,
	actionTwo: string,
	actionFour: string,
	actionThree: PageAction,
	pageTitle: string,
	extraInfo: string
}

@Injectable()
export class JobsProviders {
	job_to_shifts = false;
	site_to_shifts = false;
	schedule_discard_page: boolean = false;
	active_site = {
		location: {},
		client_title: '',
		reference: '',
		uuid: '',
	}
	pageSetup: PageSetup = {
		actionOne: { link: '', tooltip: '' },
		actionTwo: '',
		actionFour: '',
		actionThree: { link: '', tooltip: '' },
		pageTitle: '',
		extraInfo: ''
	};
	deleteReasons: Array<DeleteReason> = [
		{ reason: 'Smart-Staffed Worker', selected: false },
		{ reason: 'Site no longer required', selected: false },
		{ reason: 'End Client No Longer Requires', selected: false },
		{ reason: 'Insufficient Applicants', selected: false },
		{ reason: 'Filled by another agency', selected: false },
		{ reason: 'Filled Internally', selected: false },
		{ reason: 'Other', selected: false }
	]
	editJobs: boolean = false;

	public viewInfoJobEmitter: EventEmitter<any> = new EventEmitter();
	public shareJobEmitter: EventEmitter<any> = new EventEmitter();

	constructor() {
	}

	clearPageSetup() {
		this.pageSetup.pageTitle = '';
		this.pageSetup.actionOne.link = ''
		this.pageSetup.actionOne.tooltip = '';
		this.pageSetup.actionTwo = '';
		this.pageSetup.actionFour = '';
		this.pageSetup.actionThree.link = '';
		this.pageSetup.actionThree.tooltip = '';
		this.pageSetup.extraInfo = '';
	}

	storeSiteData(res, where) {
		this[where].location = res.location;
		this[where].client_title = res.client ? res.client.name : res.location.address_1;
		this[where].reference = res.reference;
		this[where].uuid = res.uuid;
		this[where].car_parking_available = res.car_parking_available;
		this[where].on_site_training = res.on_site_training;
		this[where].client_visible = res.client_visible;
	}


	emitViewInfoJobAction(action: any) {
		this.viewInfoJobEmitter.emit(action);
	}
	emitShareJobAction(action: any) {
		this.shareJobEmitter.emit(action);
	}
}
