import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { ClibButtonModule } from '@broadstone/clib-button';
import { CompaniesComponent } from './companies.component';
import { SearchInputModule } from '@broadstone/search-input';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';


@NgModule({
	declarations: [CompaniesComponent],
	exports: [CompaniesComponent],
	imports: [
		CommonModule,
		FormsModule,
		SearchInputModule,
		ClibButtonModule,
		NzPaginationModule,
		NzAvatarModule,
		NzSkeletonModule,

	]
})
export class CompaniesModule { }
