import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService, AuthService, CompaniesService, ApplicationsService, EventsTracker } from 'src/services/public_api';
import { AuthSessionService } from '@broadstone/auth-session-service';
import { CookieService } from 'ngx-cookie-service';
import { DatesHelper, GlobalHelpers } from '@broadstone/helpers';
import { PopNotificationService } from '@broadstone/pop-notification-service';

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit {
	no_data: any = [
		{
			title: 'Jobs',
			body_1: 'Here you will find an overview of all your open positions so you can easily see what needs prioritising at a glance.',
			body_2: '',
			body_3: '',
			img_src_1: 'assets/images/jobOverview.png',
			img_src_2: '',
			img_position: 'right'
		},
		{
			title: 'Job Pages',
			body_1: 'Tapping into a job you can see a list of all shifts that you have created for that job. From here you can add more shifts or edit existing ones.',
			body_2: 'When you receive enough applications we will show you a recommended roster that will let you fill as many shifts as possible with the highest quality workers at the click of a button. Simple right?',
			body_3: '',
			img_src_1: 'assets/images/schedular.png',
			img_src_2: '',
			img_position: 'left'
		},
		{
			title: 'Posting a Job',
			body_1: 'Posting a new job is a breeze! Simply create a job site by providing us with a location and then set up your roles. Once a role has been created once you don’t need to create it again – just top it up with new shifts!',
			body_2: '',
			body_3: '',
			img_src_1: 'assets/images/postJob.png',
			img_src_2: '',
			img_position: 'right'
		},
	];
	edit_jobs: boolean;
	edit_applications: boolean;
	interval_shifts: any = null;
	interval_time = 20000;
	dashboard_data: boolean;
	is_loaded: boolean;
	pauseInterval: boolean = false;
	loading: boolean = false;
	number = 1;
	assigned_sites: boolean;
	applicant: any;
	search_name: any = '';
	dashboard_shifts: any = [];
	has_n_p: boolean = false;
	indexed_data: any = {};
	limit: number = 999;
	date: any;
	title: string;
	dayDiff: number = 0;
	application_loading: boolean = false;
	applications_indexed_data: any = {};
	applications: any = [];
	titleDateFormat: string = 'dddd DD MMMM';
	profile: any;
	pendingApps: any;
	page: number = 1;
	has_data: boolean = false;
	isVisible: boolean = false;
	modalIsVisible: boolean = false;

	filters: any = [
		{ title: 'Total Workers', count: 0, active: true },
		{ title: 'On Time', count: 0, active: false },
		{ title: 'Offsite', count: 0, active: false },
		{ title: 'Late', count: 0, active: false },
		{ title: 'No-Show', count: 0, active: false },
		{ title: 'Unknown', count: 0, active: false }

	];

	constructor(
		private router: Router,
		public user: UsersService,
		private session: AuthSessionService,
		private cookie: CookieService,
		public companies: CompaniesService,
		public applications_service: ApplicationsService,
		private date_helper: DatesHelper,
		public helper: GlobalHelpers,
		private popNotif: PopNotificationService,
		private auth: AuthService,
		private eventTracker: EventsTracker,
	) {
		if (this.session.geAdminHeader()) {
			this.edit_applications = true;
			this.edit_jobs = true;
		}
		else {
			this.user.getCurrentUser().then((res) => {
				this.checkPermissions(res.permissions)
			}).catch(e => {
				console.log(e);
			});
		}
		this.checkLoginCount();
	}

	selectFilter(filter: string) {
		this.filters.forEach(element => {
			element.active = false;
		});
		const found = this.filters.filter(item => item.title === filter);
		if (found.length > 0) {
			found[0].active = true;
		}
		this.eventTracker.trackEvent('dashboard_filters', filter);
		this.getTodaysShifts({ page: this.page });

	}

	async ngOnInit() {
		if (this.interval_shifts) {
			clearInterval(this.interval_shifts);
		}
		this.loading = true;
		this.interval_shifts = setInterval(() => {
			if (!this.pauseInterval) {
				this.getTodaysShifts({ page: this.page })
			}
		}, this.interval_time);
		await this.checkStorage();
		this.getTodaysShifts({ page: this.page }).then((data: any) => {
			this.has_data = data.length > 0 ? true : false;
			this.hasData({ is_loaded: !this.loading, has_data: this.has_data });
			this.setDayTitle();
		});
	}

	ngOnDestroy() {
		if (this.interval_shifts) {
			clearInterval(this.interval_shifts);
			this.interval_shifts = null;
		}
	}

	ngOnChanges() { }

	checkLoginCount() {
		this.auth.getUser().then((result) => {
			if (result.loginCount === 1 && !this.user.twoFAPopupShown) {
				this.isVisible = true;
				this.user.twoFAPopupShown = true;
			} else {
				this.showFeedbackModal();
			}
		}).catch((err) => {
			console.log(err);
		});
	}

	checkInterval(e) {
		this.pauseInterval = e;
	}

	checkPermissions(permissions) {
		if (!permissions) {
			return;
		}
		permissions.forEach(element => {
			if (element.type === 'company.jobs.edit') {
				this.edit_jobs = true;
			}
			else if (element.type === 'company.applications.edit') {
				this.edit_applications = true;
			}
		})
	}

	openSchedule(job_uuid) {
		this.router.navigateByUrl("jobs/role/" + job_uuid);
	}

	hasData(e) {
		if (e) {
			this.dashboard_data = e.has_data;
			this.is_loaded = e.is_loaded;
		}
	}

	getPendingApps(e) {
		this.applications_service.dashboardApplications(e, 1, 5, this.search_name, 'pending').then((res) => {
			this.pendingApps = res['data'];
			this.pendingApps.forEach(application => {
				const time_ago = this.date_helper.time_ago(application.created_at);
				application.time_since_applied = this.date_helper.short_time(time_ago);
			})
		})
	}

	checkStorage() {
		if (this.session.geAdminHeader()) {
			this.assigned_sites = false;
			return;
		}
		else {
			if (!this.getStorage('assigned_sites')) {
				this.assigned_sites = false;
			}
			else {
				this.assigned_sites = true;
			}
		}
	}

	resetData(e) {
		this.indexed_data = {};
		this.pendingApps = null;
		this.page = 1;
		this.dashboard_shifts.length = 0;
		if (!e.searchName) {
			this.search_name = '';
		}
		this.getTodaysShifts(e);
	}

	onScroll(e) {
		this.getTodaysShifts(e);
	}

	getTodaysShifts(e) {
		return new Promise(resolve => {
			let range;
			if (!e.range) {
				let date = this.date ? this.date : new Date();
				this.date = date;
				range = { start: date, end: date };
			}
			else {
				range = e.range;
				this.date = e.range.start;
			}
			if (e.searchName) {
				this.search_name = e.searchName
			}
			let searchName = e.searchName ? e.searchName : this.search_name;
			this.companies.getDashboardShifts(searchName, range, this.assigned_sites, e.page, this.limit).then((result) => {
				if (this.date !== range.start) { // Is making sure when change the date the prv request for prev date is cancelled
					return;
				}

				this.has_n_p = (result['links'].next ? true : false);
				let data = result['data'];
				this.dayDiff = this.date_helper.dateDiffInDays(this.date);
				let isPastDefault = null; // Today
				if (this.dayDiff < 0) { // Past
					isPastDefault = true;
				} else if (this.dayDiff > 0) { // Future
					isPastDefault = false;
				}
				for (let index in data) {
					let shift = data[index];
					if (this.indexed_data[shift.uuid] && this.indexed_data[shift.uuid].no_show_for_shift) {
						shift.no_show_for_shift = true;
					}
					this.indexed_data[shift.uuid] = shift;
					shift.applications = this.applications_indexed_data[shift.uuid];
					shift.empty_slots = shift.slots - shift.accepted_applications;
					shift.isPast = isPastDefault === null ? this.date_helper.isInPast(shift.end_at) : isPastDefault;
				}

				this.loading = false;
				this.getApplications();
				this.setDayTitle();
				resolve(data);
			}).catch((err) => {
				this.loading = false;
				// this.logger.error(err);

			});
		});
	}

	filtersCondition(shiftsNotGrouped, callback) {
		shiftsNotGrouped.forEach(element => {
			element.applications = (element && element.applications) ? element.applications.filter(item => callback(item)) : [];
		});
	}

	activateFilter(shiftsNotGrouped) {
		this.countOnFilters(shiftsNotGrouped);
		const selectedFilter = this.filters.filter(element => element.active)[0];
		let filteredShifts = null;

		switch (selectedFilter.title) {

			case 'On Time':
				this.filtersCondition(shiftsNotGrouped, (item) => !item.timing_in && !item.no_show_action && item.clocked_in_at);
				break;
			case 'Offsite':
				this.filtersCondition(shiftsNotGrouped, (item) => item.miles_away_in > 1 && item.clocked_in_at);
				break;
			case 'Late':
				this.filtersCondition(shiftsNotGrouped, (item) => item.warning_in && item.clocked_in_at);
				break;
			case 'No-Show':
				this.filtersCondition(shiftsNotGrouped, (item) => item.no_show && !item.clocked_in_at);
				break;
			case 'Unknown':
				this.filtersCondition(shiftsNotGrouped, (item) => (!item.no_show && item.no_show_action) || (!item.clocked_in_at && !item.no_show_action));
				break;

			default:
				break;
		}

		if (selectedFilter.title === 'No-Show') {
			filteredShifts = shiftsNotGrouped.filter(element => {
				return element.applications.filter(item => item.no_show && !item.clocked_in_at).length > 0;
			});
		} else if (selectedFilter.title !== 'Total Workers') {
			filteredShifts = shiftsNotGrouped.filter(element => element.accepted_applications && (element.applications && element.applications.length > 0));
		}


		// CONVERT THE OBJECT TO ARRAYS

		this.dashboard_shifts = filteredShifts ? filteredShifts : shiftsNotGrouped;
		this.addGroupStatus();

	}

	countOnFilters(shiftsNotGrouped) {
		let allApplications = [];
		shiftsNotGrouped.forEach(element => {
			if (element.applications) {
				allApplications = allApplications.concat(element.applications);
			}

		});
		this.filters.forEach(filter => {
			switch (filter.title) {
				case 'Total Workers':
					filter.count = allApplications.length;
					break;
				case 'On Time':
					filter.count = allApplications.filter(item => !item.timing_in && !item.no_show_action && item.clocked_in_at).length;
					break;
				case 'Offsite':
					filter.count = allApplications.filter(item => item.miles_away_in > 1 && item.clocked_in_at).length;
					break;
				case 'Late':
					filter.count = allApplications.filter(item => item.warning_in && item.clocked_in_at).length;
					break;
				case 'No-Show':
					filter.count = allApplications.filter(item => item.no_show && !item.clocked_in_at).length;
					break;
				case 'Unknown':
					filter.count = allApplications.filter(item => (!item.no_show && item.no_show_action) || (!item.clocked_in_at && !item.no_show_action)).length;
					break;

				default:
					break;
			}

		});
	}

	addGroupStatus() {
		this.dashboard_shifts.forEach(element => {
			if (element.status == 1) {
				element.time_status = 'completed';
			}
			if (element.status == 2) {
				element.time_status = 'in progress';
			}
			if (element.status == 3) {
				element.time_status = 'upcoming';
			}
		});
		this.dashboard_shifts = this.groupByDate(this.dashboard_shifts);
	}

	setDayTitle() {
		this.dayDiff = this.date_helper.dateDiffInDays(this.date);
		switch (this.dayDiff) {
			case 0:
				this.title = "Today";
				break;
			case +1:
				this.title = "Tomorrow";
				break;
			case -1:
				this.title = "Yesterday";
				break;
			default:
				this.title = this.date_helper.format_date(this.date, this.titleDateFormat);
		}
	}

	getApplications() {
		const shifts = Object.keys(this.indexed_data).map((key) => {
			return key;
		});
		this.applications.length = 0;
		this.application_loading = true;
		this.applications_service.dashboardApplications(shifts, 1, this.limit, this.search_name, 'accepted_and_no-show').then((result) => {
			this.applications = result['data'];
			const shiftsWithApplicants = this.mapApplicationsWithShift();
			this.activateFilter(shiftsWithApplicants);
			this.application_loading = false;
		}).catch((err) => {
			this.application_loading = false;
			// this.logger.error(err);
		});
	}

	groupByDate(data) {
		let groupedObj = {};
		for (let index in data) {
			let item = data[index];
			let groupKey = item['time_status'];
			if (!(groupKey in groupedObj)) {
				groupedObj[groupKey] = [];
			}
			groupedObj[groupKey].push(item);
		}
		const map_data = Object.keys(groupedObj).map(key => {
			return { key, value: groupedObj[key] };
		});
		return map_data;
	}

	mapApplicationsWithShift() {
		let shifts = Object.keys(this.indexed_data).map((key) => {
			return this.indexed_data[key];
		});

		shifts.forEach(shift => {
			const applicants_shift = this.applications.filter(element => {
				return element.shift_uuid == shift.uuid;
			});
			if (applicants_shift && applicants_shift.length > 0) {
				applicants_shift.forEach(applicant => {
					this.mapApplication(shift, applicant);
				});
				this.applications_indexed_data[shift.uuid] = applicants_shift;
			}
			shift.applications = this.applications_indexed_data[shift.uuid];
		});
		return shifts;
	}

	mapApplication(shift, element) {
		element.miles_away_in = element.clocked_in_at && element.clocked_in_latitude && element.clocked_in_longitude ? this.checkClockDistance(shift, element.clocked_in_latitude, element.clocked_in_longitude) : 0;
		element.miles_away_out = element.clocked_out_at && element.clocked_out_latitude && element.clocked_out_longitude ? this.checkClockDistance(shift, element.clocked_out_latitude, element.clocked_out_longitude) : 0;
		element.timing_in = element.clocked_in_at ? this.timingReading(shift.start_at, element.clocked_in_at, 'in') : '';
		element.timing_out = element.clocked_out_at ? this.timingReading(shift.end_at, element.clocked_out_at, 'out') : '';
		element.warning_in = this.checkWarning(element, 'in');
		element.warning_out = this.checkWarning(element, 'out');
		element.map_in = this.checkMap(element, 'in');
		element.map_out = this.checkMap(element, 'out');
		element.no_show_action = !element.clocked_in_at ? this.noShowCheck(shift.start_at) : false;
		shift.no_show_for_shift = (element.no_show > 0 || element.no_show_action) ? true : false;
	}

	checkClockDistance(shift, lat, long) {
		const { latitude, longitude } = shift.site ? shift.site.location || '' : '';
		if (!latitude && !longitude) {
			return;
		}
		const dist = this.helper.miles_away(latitude, longitude, lat, long);
		return dist;

	}

	timingReading(shift_date, clock_date, direction: 'in' | 'out') {
		let stats = '';
		const lateInMilliseconds = 60000 * 4; // allow workers 4 minutes grace
		const difference = this.date_helper.date_difference(shift_date, clock_date, 'minutes');
		if (direction === 'out' && difference > lateInMilliseconds) {
			stats = `${this.date_helper.humanize(difference)} Early`;
		} else if (direction === 'in' && difference < -lateInMilliseconds) {
			stats = `${this.date_helper.humanize(difference)} Late`;
		}
		return stats;
	}

	checkWarning(element, param) {
		if (param == 'in') {
			if (element.timing_in.search("Late") !== -1) {
				return true;
			}
			return false;
		} else {
			if (element.timing_out.search("Early") !== -1) {
				return true;
			}
			return false;
		}
	}

	checkMap(element, param) {
		if (param == 'in') {
			if (element.clocked_in_latitude && element.clocked_in_longitude) {
				return true;
			}
			return false;
		} else {
			if (element.clocked_out_latitude && element.clocked_out_longitude) {
				return true;
			}
			return false;
		}
	}

	noShowCheck(shift_date) {
		const now = new Date();
		const lateInMilliseconds = 60000 * 15; // show no show button if is more then 15 
		const difference = this.date_helper.date_difference(shift_date, now, 'minutes');
		if (difference < -lateInMilliseconds) {
			return true;
		}
		return false;
	}


	getProfile(e) {
		this.applications_service.getApplicantProfile(e).then((result) => {
			this.profile = { ...result };
		}).catch((err) => {
			// this.logger.error(err);
		});
	}

	closeDrawer(e) {
		if (e === 'close profile') {
			this.profile = null;
		}
		if (e === 'close drawer') {
			this.pendingApps = null;
		}
	}

	acceptWorker(e) {
		this.applications_service.approveApplications(e).then((result: any) => {
			if (result.errors && Object.values(result.errors).length > 0) {
				let reason = '';
				if (result.errors.noSlots && result.errors.noSlots.length > 0) {
					reason = 'No Slots: All the slots are already filled for the shift - This job has already been filled. If you would like to book an additional officer for this shift please edit the shift and increase applicants required.<br><br>';
				}
				if (result.errors.notSiteTrained && result.errors.notSiteTrained.length > 0) {
					reason += 'Staff member is not site trained - One of the staff members you are trying to book is not site-trained. Please update their training status on their profile.<br><br>';
				}
				if (result.errors.overlap && result.errors.overlap.length > 0) {
					reason += 'Application Overlaps - It looks like the selected shifts overlap. Please make sure none of the shifts overlap and try again.<br><br>';
				}
				if (result.errors.hoursLimit && result.errors.hoursLimit.length > 0) {
					reason += 'Hours Exceeded - One of the workers you are trying to book has already been booked for the maximum amount of hours today.<br><br>';
				}
				this.popNotif.createNotification('error', 'Sorry!', reason);
			} else {
				this.popNotif.createNotification('success', 'Success', 'All selected applications have been approved.');
			}
			this.getTodaysShifts({ page: this.page });
		})
			.catch((err) => {
				// this.logger.error(err);
				this.popNotif.createNotification('error', 'There has been a problem.', err);
			});

		this.pauseInterval = false;
		this.pendingApps = null;
	}

	noShow(e) {
		if (e.action === 'apply') {
			this.applications_service.setNoShow(e.uuid).then((res) => {
				this.getTodaysShifts({ page: this.page }).then(() => {
				});
			}).catch(err => {
				// this.logger.error(err);
				this.popNotif.createNotification('error', 'There has been a problem.', err);
			});
		}
		else if (e.action === 'undo') {
			this.applications_service.revertNoShow(e.uuid).then((res) => {
				this.getTodaysShifts({ page: this.page }).then(() => {
				});
			}).catch(err => {
				// this.logger.error(err);
				this.popNotif.createNotification('error', 'There has been a problem.', err);
			});
		}
	}

	modalClose() {
		this.isVisible = false;
	}

	showFeedbackModal() {
		const dismissFeedbackPopup = this.getStorage('dismiss_feedback_popup') ? true : false;
		if (!dismissFeedbackPopup) {
			this.modalIsVisible = true;
		}
	}

	getStorage(key) {
		if (localStorage.getItem(key) !== 'undefined') {
			return JSON.parse(localStorage.getItem(key));
		}
	}

	closeSurveyQuestion() {
		this.modalIsVisible = false;
		localStorage.setItem('dismiss_feedback_popup', JSON.stringify('true'));
	}

	openSurvey() {
		this.helper.open_new_tab('https://docs.google.com/forms/d/e/1FAIpQLSc5Dm0ouXKlkKz7C4MGwG6si7LptHssqx55Quxni4vaAKgtFw/viewform?usp=sf_link');
		this.closeSurveyQuestion();
	}

	turnOnTFA() {
		this.router.navigate(['account/staff-profile-edit'], { queryParams: { auth: 'update' }, queryParamsHandling: 'merge' });
	}

}
