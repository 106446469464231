import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-account',
    templateUrl: './account.component.html',
    styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
    constructor(
        public router: Router
    ) { }

    ngOnInit() { }

    openStaffPermissions() {
        this.navigate('account/staff');
    }

    openEditProfile() {
        this.navigate('account/staff-profile-edit');
    }

    openEditNotifications() {
        this.navigate('account/notification-preferences');
    }

    openEditCompany() {
        this.navigate('account/app-company-edit');
    }

    openInvoiceSettings() {
        this.navigate('account/invoice-settings');
    }

    navigate(where) {
        this.router.navigate([where]);
    }

}
