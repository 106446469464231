<div class="e-row">
    <div class="expandable-row" (mouseup)="toggleRow()">
        <div class="timesheet-wrap cursor-pointer">

            <!-- company name  -->
            <div class="company-name-area">
                <div class="company-name">
                    <span *ngIf="timesheet.client"> {{ timesheet?.client?.name }}</span>
                    <span *ngIf="!timesheet.client && timesheet.site.location">
                        {{ timesheet?.site?.location?.postcode }}</span>
                </div>
            </div>

            <!-- worker avatar and name  -->
            <div class="avatar-name-area">
                <div class="avatar-name">
                    <!-- avatar  -->
                    <nz-avatar [nzText]='timesheet?.user?.name[0]' nzPlacement="top" class="worker-avatar"
                        [nzSrc]="timesheet?.user?.avatar" nzSize="small">
                    </nz-avatar>

                    <!-- worker name  -->
                    <div class="worker-name">
                        <p>{{ helper.full_name(timesheet?.user) }}</p>
                        <a *ngIf="!timesheet.user.deleted_at" class="b-link-grey"
                            (mousedown)="openApplicantProfile(timesheet?.job_profile?.uuid)">View Profile</a>
                    </div>
                </div>
            </div>

            <!-- address of site worked at  -->
            <div class="site-address-area">
                <div class="site-address">
                    {{ timesheet?.site?.location?.city }} {{ timesheet?.site?.location?.postcode }}
                </div>
            </div>

            <!-- date worked -->
            <div class="shift-date-area">
                <div class="shift-date ">
                    {{ timesheet.shift.start_at | dateFormat:'DD/MM/YY': timesheet?.site.location?.timezoneId }}
                </div>
            </div>

            <div class="clock-times-area">
                <!-- clock in/out times w dynamic colours  -->
                <div class="clock-in-out-dots">
                    <!-- if there's an unmatched flag  -->
                    <div>
                        <span *ngIf="timesheet.clock_in_late && !timesheet.timesheet.amended_clocked_in_at"
                            class="red-dot"></span>
                        <span *ngIf="!timesheet.clock_in_late || timesheet.timesheet.amended_clocked_in_at"
                            class="green-dot"></span>
                    </div>
                    <!-- if there's no unmatched flag  -->
                    <div>
                        <span *ngIf="timesheet.clock_out_early && !timesheet.timesheet.amended_clocked_out_at"
                            class="red-dot"></span>
                        <span *ngIf="!timesheet.clock_out_early || timesheet.timesheet.amended_clocked_out_at"
                            class="green-dot"></span>
                    </div>

                </div>
                <div class="clock-in-out-times">
                    <p *ngIf="!timesheet.timesheet.amended_clocked_in_at">
                        {{ timesheet?.timesheet?.clocked_in_at | dateFormat:'HH:mm':
                        timesheet?.site.location?.timezoneId }}<span
                            *ngIf="!timesheet?.timesheet?.clocked_in_at">--:--</span>
                    </p>
                    <p *ngIf="timesheet.timesheet.amended_clocked_in_at">
                        {{ timesheet?.timesheet?.amended_clocked_in_at | dateFormat:'HH:mm':
                        timesheet?.site.location?.timezoneId }}
                    </p>

                    <p *ngIf="!timesheet.timesheet.amended_clocked_out_at">
                        {{ timesheet?.timesheet?.clocked_out_at | dateFormat:'HH:mm':
                        timesheet?.site.location?.timezoneId }}<span
                            *ngIf="!timesheet?.timesheet?.clocked_out_at">--:--</span>
                    </p>
                    <p *ngIf="timesheet.timesheet.amended_clocked_out_at">
                        {{ timesheet?.timesheet?.amended_clocked_out_at | dateFormat:'HH:mm':
                        timesheet?.site.location?.timezoneId }}
                    </p>
                </div>
                <!-- amended by text  -->
                <div *ngIf="timesheet.timesheet.amended_clocked_in_at || timesheet.timesheet.amended_clocked_out_at"
                    class="amended-by">
                    <span class="b-light">
                        Amended
                    </span>
                </div>

            </div>

            <!-- Worker Pay  -->
            <div class="amount-due-area">
                <div class="amount-due normal-cost">
                    <div class="custom-text">Worker Pay</div>
                    <span class="b-bold-value" *ngIf="timesheet?.cost?.normal_cost > 0">
                        £{{ timesheet?.cost?.normal_cost.toFixed(2)}}
                    </span>
                    <span class="b-bold-value"
                        *ngIf="!timesheet?.cost?.normal_cost && !timesheet?.cost?.bank_holiday_cost">
                        £0
                    </span>

                </div>
                <div *ngIf="timesheet && timesheet.cost && timesheet.cost.bank_holiday_cost" class="amount-due">
                    <span class="b-bold-value b-primary" nz-popover [nzPopoverContent]="bankHolidayPopover"
                        nzPopoverTrigger="hover">
                        <span *ngIf="timesheet?.cost?.normal_cost > 0">+</span>
                        &pound;{{ timesheet?.cost?.bank_holiday_cost?.toFixed(2) }} <img class="calendar-icon"
                            src="./assets/icons/bankHolidayPay.svg">

                    </span>
                    <ng-template #bankHolidayPopover>
                        <div class="bankHolidayPopover">
                            <h3 class="b-primary b-font-demibold">Bank Holiday Pay</h3>
                            <p class="b-primary b-font-demibold">x{{timesheet?.shift?.bank_holiday_rate}} Standard Pay
                            </p>
                            <p class="explainer">This shift falls on a bank holiday so we have automatically boosted
                                the pay based on your job settings. Head to edit job if you want to
                                change the rate.

                            </p>
                            <p class="explainer b-font-demibold">
                                *The bank holiday pay rate is only applied to shift hours which fall on the bank
                                holiday
                            </p>
                        </div>
                    </ng-template>
                </div>
            </div>

            <div class="check-expand-area">
                <!-- group checkbox  -->
                <div *ngIf="timesheet?.timesheet?.timesheet_status == 'pending' || (timesheet?.timesheet?.timesheet_status == 'denied' && status_selected =='denied')"
                    class="select-multiple checkbox-wrapper flex-item">
                    <label *ngIf="edit_timesheets" class="b-checkbox" (mouseup)="timesheetSelected($event); true">
                        <input type="checkbox" [(ngModel)]="timesheet.checked">
                        <span class="checkmark"></span>
                    </label>
                </div>
                <div *ngIf="timesheet?.timesheet?.timesheet_status == 'approved'">
                    <img src="/assets/icons/check-icon.svg" />
                </div>
                <div *ngIf="timesheet?.timesheet?.timesheet_status == 'denied' && status_selected !=='denied'">
                    <img src="/assets/icons/cross.svg" />
                </div>
                <div *ngIf="timesheet?.timesheet?.timesheet_status == 'no-show'">
                    <img class='no-show-icon' src="/assets/icons/no-show-icon.svg" />
                </div>
                <!-- arrow  -->
                <div class="toggle-arrow flex-item">
                    <div *ngIf="!rowStatus" class="arrow-button"><img src="../../assets/icons/arrow-down.svg" /></div>
                    <div *ngIf="rowStatus" class="arrow-button"><img src="../../assets/icons/arrow-up.svg" /></div>
                </div>
            </div>


        </div>
        <p *ngIf="timesheet.error" class="error-box">
            {{timesheet?.error}}
        </p>
    </div>

    <!-- EXPANDED ROW  -->
    <div [@expand_y] [@fadeInOut] *ngIf="rowStatus" class="expanded-row">

        <hr>

        <div class="row">
            <!-- MAP  -->
            <div class="expand-map">
                <div class="map">
                    <!--
                *** PERSONAL NOTE ***
                NEED TO EDIT THIS COMPONENT TO TAKE A CLOCK OUT & COMPARE WITH SITE - CONDITIONAL COLOURS
              -->
                    <lib-worker-map [job_latitude]="timesheet?.site?.location?.latitude"
                        [job_longitude]="timesheet?.site?.location?.longitude"
                        [worker_clock_in_lat]="timesheet?.timesheet?.clocked_in_at ? timesheet?.timesheet?.clocked_in_latitude: ''"
                        [worker_clock_in_lng]="timesheet?.timesheet?.clocked_in_at ? timesheet?.timesheet?.clocked_in_longitude: ''"
                        [worker_clock_out_lat]="timesheet?.timesheet?.clocked_out_at ? timesheet?.timesheet?.clocked_out_latitude : ''"
                        [worker_clock_out_lng]="timesheet?.timesheet?.clocked_out_at ? timesheet?.timesheet?.clocked_out_longitude : ''"
                        [mapHeight]="'228px'">
                    </lib-worker-map>
                </div>
            </div>

            <!-- MID SECTION (w/form etc) -->
            <div class="expand-form">

                <div class="col-1">
                    <!-- Worker Pay  -->
                    <div class="amount-due">
                        <div class="custom-text">Worker Pay</div>
                        <span class="b-bold-value">
                            £{{ total_pay }}
                        </span>

                    </div>
                    <!-- clocked times  -->
                    <div class="clocked-times">
                        <div class="custom-text">Clocked Times</div>
                        <span class="b-bold-value">
                            {{ timesheet?.timesheet?.clocked_in_at | dateFormat:'HH:mm':
                            timesheet?.site.location?.timezoneId }}
                            -
                            {{ timesheet?.timesheet?.clocked_out_at | dateFormat:'HH:mm':
                            timesheet?.site.location?.timezoneId }}
                        </span>
                    </div>
                </div>

                <div class="col-2">
                    <!-- hours worked (work out from clock in/out times) -->
                    <div class="amount-due">
                        <div class="custom-text">Hours Worked</div>
                        <span class="b-bold-value">
                            {{ timesheet?.timesheet?.hours_worked }}
                        </span>
                    </div>
                    <!-- clocked times (by worker) -->
                    <div class="clocked-times">
                        <div class="custom-text">Shift Hours Listed</div>
                        <span class="b-bold-value-grey">
                            {{ timesheet?.shift?.start_at | dateFormat:'HH:mm': timesheet?.site.location?.timezoneId }}
                            -
                            {{ timesheet?.shift?.end_at | dateFormat:'HH:mm': timesheet?.site.location?.timezoneId }}
                        </span>
                    </div>
                </div>

                <div class="col-3">
                    <!-- hourly rate  -->
                    <div class="amount-due">
                        <div class="custom-text">Hourly Rate</div>
                        <span class="b-bold-value">
                            £{{ timesheet?.shift?.hourly_rate }}
                        </span>
                    </div>
                    <!-- amend times  -->
                    <div *ngIf="timesheet?.timesheet?.timesheet_status == 'approved'" class="clocked-times">
                        <div class="custom-text">Amended Clock In/Out</div>
                        <span class="b-bold-value-grey">
                            {{ timesheet?.timesheet?.amended_clocked_in_at | dateFormat:'HH:mm':
                            timesheet?.site.location?.timezoneId }}
                            -
                            {{ timesheet?.timesheet?.amended_clocked_out_at | dateFormat:'HH:mm':
                            timesheet?.site.location?.timezoneId }}
                        </span>
                    </div>
                    <div *ngIf="timesheet?.timesheet?.timesheet_status !== 'approved'" class="clocked-times">
                        <div class="custom-text">Amend Clock In/Out</div>
                        <div class="input-underlined">
                            <input [(ngModel)]='start_at' [pattern]="time_pattern" #start_time="ngModel"
                                (keyup)="amendTime('start_at', $event)" placeholder="HH:MM" required
                                class="b-input b-input-white start-time" maxlength="5" #start_at_element type="text" />

                            <p>-</p>
                            <input [(ngModel)]='end_at' [pattern]="time_pattern" #end_time="ngModel"
                                (keyup)="amendTime('end_at', $event)" placeholder="HH:MM" required
                                class="b-input b-input-white end-time" maxlength="5" #end_at_element type="text" />

                        </div>
                        <div class="list_update-errors"
                            *ngIf="(start_time.dirty || start_time.touched) || (end_time.dirty || end_time.touched)">
                            <p *ngIf="(start_time.invalid && start_time.errors.pattern) || (end_time.invalid && end_time.errors.pattern)"
                                class="error-box">Time must be
                                entered in<br> HH:MM format</p>
                            <p class="error-box"
                                *ngIf="(start_time.dirty || start_time.touched) && start_time.invalid && start_time.errors.required">
                                Start
                                time is
                                required.
                            </p>
                            <p class="error-box"
                                *ngIf="(end_time.dirty || end_time.touched) && end_time.invalid && end_time.errors.required">
                                End time is
                                required.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="update-button">
                    <app-clib-button
                        *ngIf="edit_timesheets && (timesheet?.timesheet?.timesheet_status === 'pending' || timesheet?.timesheet?.timesheet_status === 'denied') || timesheet?.timesheet?.timesheet_status === 'no-show'"
                        label="Update" type="outline" color="dark"
                        (pressAction)="amendTimesheet(timesheet.uuid, timesheet?.site.location?.timezoneId)"
                        label="Approve" [loading]="update_loading">
                    </app-clib-button>
                </div>
            </div>


        </div>
    </div>
</div>