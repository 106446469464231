import { Component, OnInit } from '@angular/core';
import { JobsProviders, JobsService, CompaniesService, EventsTracker, UsersService } from 'src/services/public_api';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from '@broadstone/alert';
import { Location } from '@angular/common';
import { AuthSessionService } from '@broadstone/auth-session-service';

@Component({
	selector: 'app-setup-role',
	templateUrl: './setup-role.component.html',
	styleUrls: ['./setup-role.component.scss']
})
export class SetupRoleComponent implements OnInit {
	siteUuid: string;
	site_name: string;
	sitePostcode: string;
	site_name_edit: string;
	jobUuid: string;
	job: any;
	edit: boolean = false;
	private sub: any;
	sector_view: boolean = true;
	service_selected: any;
	currentUser: any;
	can_edit_shifts: boolean;

	constructor(
		private jobservice: JobsService,
		private companies: CompaniesService,
		private router: Router,
		private route: ActivatedRoute,
		public jobData: JobsProviders,
		private alert: AlertService,
		private event_tracker: EventsTracker,
		public user: UsersService,
		private _location: Location,
		private session: AuthSessionService
	) {
		if (this.session.geAdminHeader()) {
			this.can_edit_shifts = true;
		}
		else {
			this.user.getCurrentUser().then((res) => {
				this.checkPermissions(res.permissions)
			}).catch(e => {
				console.log(e);
			});
		}
	}

	ngOnInit(): void {
		this.sub = this.route.params.subscribe(params => {
			this.siteUuid = params['site_uuid']; // (+) converts string 'id' to a number
			this.jobUuid = params['job_uuid'];
			if (this.siteUuid) {
				this.getSite();
			} else {
				if (this.jobUuid) { // If we have a jobUuid in url we mark the component as edited componet
					this.edit = true;
					this.getJob();
				}
			}
		});
	}

	ngOnDestroy() {
		this.sub.unsubscribe();
	}

	checkPermissions(permissions) {
		if (!permissions) {
			return;
		}
		permissions.forEach(element => {
			if (element.type === 'company.shifts.edit') {
				this.can_edit_shifts = true;
			}
		})
	}

	getJob() {
		if (!this.jobUuid) {
			return;
		}
		this.jobservice.getJob(this.jobUuid).then((result:any) => {
			this.job = result;
			this.siteUuid = result.site.uuid;
			this.jobData.storeSiteData(result.site, 'active_site');
			const { name } = this.job.site.client || '';
			const { postcode } = this.job.site.location || '';
			this.site_name_edit = name ? name : postcode ? postcode : 'No Client';
			if(result && result.site && result.site.source && result.site.source){
				this.router.navigate(['jobs']);
			}
		}).catch((err) => {
			this.router.navigate(['jobs']);
		});
	}

	getSite() {
		this.companies.getSiteUuid(this.siteUuid).then(res => {
			this.jobData.storeSiteData(res, 'active_site');
			this.siteUuid = res.uuid;
			const { name } = res.client || '';
			const { postcode } = res.location || '';
			if(res.client_visible){
				this.sitePostcode = postcode;
			}else {
				const postcodeArr = postcode.split(' ');
				this.sitePostcode = postcodeArr[0];
			}
			
			this.site_name = name ? name : postcode ? postcode : 'No Client';
		}).catch(e => {
			this.router.navigate(['jobs']);
		});
	}

	onDeleted(res) {
		this.event_tracker.trackEvent('delete_role', res);
		this.router.navigate(['jobs/']);
	}

	onSaved(res) {
		if (this.can_edit_shifts) {
			this.router.navigate(['jobs/manage-shifts', res.uuid]);
		}
		else {
			this.router.navigate(['jobs/role', res.uuid]);
		}
		this.event_tracker.trackEvent('post_role', res);
	}

	onEdited(res) {
		this.event_tracker.trackEvent('edit_role', res);
		//If user edits a job from shifts manage page and click update will be redirected to back to shifts
		if (this.jobData.job_to_shifts) {
			this.router.navigate(['jobs/manage-shifts', this.jobUuid]);
			this.jobData.job_to_shifts = false;
		} else {
			this.router.navigate(['jobs/role', res.uuid]);
		}
	}

	goBack() {
		this.alert.showConfirm("Unsaved changes",
			"Are you sure you want to leave this page? The changes won't be applied until you save the role.",
			'Continue', 'Cancel').then(() => {
				this.router.navigate(['jobs/manage-site', this.siteUuid]);
			}).catch(() => {
				//cancel alert
			});
	}


}
