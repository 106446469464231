import { Component, TemplateRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthSessionService } from "@broadstone/auth-session-service";
import { GlobalHelpers } from "@broadstone/helpers";
import { PopNotificationService } from "@broadstone/pop-notification-service";
import {
	AuthService,
	EventsTracker,
	FiltersServices,
	JobsProviders,
	UsersService,
} from "src/services/public_api";

@Component({
	selector: "app-client",
	templateUrl: "./client.component.html",
	styleUrls: ["./client.component.scss"],
})
export class ClientComponent {
	firstName: string;
	currentUser: any;
	superAdmin: boolean = false;
	links: Array<{
		name: string;
		navLink: string;
		template: TemplateRef<any>;
	}> = [];
	@ViewChild("contentHelpTemplate") contentHelpTemplate: TemplateRef<any>;
	adminCompany: any = {};
	statusModalVisible: boolean = false;
	modalContent: any; // @todo added for build to pass - update type and default value
	dropMenu: boolean = false;
	dropProfile: boolean = false;
	constructor(
		protected session: AuthSessionService,
		public router: Router,
		private global_helper: GlobalHelpers,
		public activeRoute: ActivatedRoute,
		private event_tracker: EventsTracker,
		private auth: AuthService,
		public jobData: JobsProviders,
		public user: UsersService,
		private popNotif: PopNotificationService,
		public filters: FiltersServices
	) {
		this.currentUser = this.session.getUser();
		this.firstName = this.currentUser ? this.currentUser.firstName : "";
		if (this.session.geAdminHeader()) {
			this.superAdmin = true;
			this.adminCompany = this.session.geAdminHeader();
		}
	}

	ngAfterViewInit() {
		this.links = [
			{ name: "Worker Map", navLink: "worker-map", template: null },
			{ name: "Dashboard", navLink: "dashboard", template: null },
			{ name: "Jobs", navLink: "jobs", template: null },
			{ name: "Incidents", navLink: "incidents", template: null },
			{ name: "Timesheets", navLink: "timesheets", template: null },
			{ name: "Shop", navLink: "shop", template: null },
			{ name: "Help", navLink: "", template: this.contentHelpTemplate },
		];
	}

	getUserFullName(obj): string {
		if (obj && obj.fullName) {
			return obj.fullName;
		} else {
			return "";
		}
	}

	getUserEmail(obj): string {
		if (obj && obj.email) {
			return obj.email;
		} else {
			return "";
		}
	}

	getUserID(obj): string {
		if (obj && obj.uuid) {
			return obj.uuid;
		} else {
			return "";
		}
	}

	getCompanyLogo(obj): string {
		if (obj && obj.company && obj.company.logo) {
			return obj.company.logo;
		} else {
			return "";
		}
	}

	getUserCompanyProfile(obj): any {
		if (obj && obj.company && obj.company.uuid && obj.company.title) {
			const company = {
				id: obj.company.uuid,
				name: obj.company.title,
			};
			return company;
		} else {
			return "";
		}
	}

	logout() {
		if (this.currentUser) {
			this.event_tracker.trackEvent("logout", this.currentUser);
		}
		this.superAdmin = false;
		this.session.setAdminHeader(null);
		this.auth.logout().catch((err) => {
			console.log(err);
		});
		this.router.navigate(["/login"]);
		this.session.logout(false);
	}

	staffPermissions() {
		this.navigate("account/staff");
	}

	invoiceSettings() {
		this.navigate("account/invoice-settings");
	}

	editProfile() {
		this.navigate("account/staff-profile-edit");
	}

	editNotifications() {
		this.navigate("account/notification-preferences");
	}

	editCompany() {
		this.navigate("account/app-company-edit");
	}

	navigate(where) {
		this.router.navigate([where]);
		this.dropProfile = false;
	}

	admin() {
		this.navigate("admin");
	}

	openHelp() {
		this.dropMenu = false;
		this.global_helper.open_new_tab(
			"https://intercom.help/orkatech/en/collections/755690-companies"
		);
	}
	openShop() {
		this.dropMenu = false;
		this.global_helper.open_new_tab("https://shop.orka.works");
	}

	openTimesheets() {
		this.global_helper.open_new_tab(
			"http://www.company.orka.works/#/timesheets-new"
		);
	}

	changeRoute(link) {
		if (!link) {
			return;
		}
		if (link === "shop") {
			this.openShop();
			return;
		}

		this.router.navigate([link]);
	}

	clickAction(e) {
		if (this.filters.active_toggle) {
			this.jobData.emitShareJobAction(e);
		} else {
			this.popNotif.createNotification(
				"error",
				"There has been a problem.",
				"You cannot share a job in the past."
			);
		}
	}
	clickInfo(e) {
		this.jobData.emitViewInfoJobAction(e);
	}
	closeStatusModal() {
		this.statusModalVisible = false;
	}
	openModal() {
		this.dropMenu = false;
		this.statusModalVisible = true;
	}
}
