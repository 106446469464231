<!-- <app-nav *ngIf="show_nav"></app-nav> -->
<app-nav [activeRoute]="activeRoute" logo='assets/logo/orka-logo.svg' [user_name]="firstName"
	[user_avatar]="currentUser?.userAvatar" [links]="links" (onTap)="changeRoute($event)"
	[contentNavTemplate]='contentNavTemplate' id='nav' (clickAction)="clickAction($event)" [(dropMenu)]="dropMenu"
	[(dropProfile)]="dropProfile" (clickInfo)="clickInfo($event)" [config]="jobData.pageSetup"
	[editJobs]="jobData.editJobs">

	<!-- dropdown -->

	<ng-template #contentHelpTemplate>
		<div class="drop-nav-menu help-tab">
			<ul>

				<li class="b-Eh4" (click)="openModal()"><span>
						<p>Get in touch!</p>
					</span></li>
				<li class="b-Eh4" (click)="openHelp()"><span>
						<p>Articles</p>
					</span></li>
			</ul>
		</div>
	</ng-template>

	<ng-template #contentNavTemplate>
		<div class="drop-nav-menu">
			<ul>
				<div class="profile-placeholder user-link-place">
					<nz-avatar *ngIf="currentUser" [nzText]='currentUser?.firstName[0]'
						[nzSrc]="currentUser?.userAvatar" nzSize="small" [ngStyle]="{'background-color': '#1dc7b2'}"
						style="vertical-align: left;">
					</nz-avatar>
					<p class="b-Eh5 b-font-demibold">{{currentUser.fullName}}</p>
				</div>
				<li class="b-Eh4" (click)="editProfile()"><span>
						<p>Edit Profile</p>
					</span></li>
				<li *ngIf="!superAdmin" class="b-Eh4" (click)="editNotifications()"><span>
						<p>Notification Preferences</p>
					</span></li>

				<div class="profile-placeholder" *ngIf="!superAdmin && currentUser">

					<nz-avatar [nzText]='currentUser?.company.name[0]' [nzSrc]="currentUser?.company?.logo"
						nzSize="small" [ngStyle]="{'background-color': '#1dc7b2'}" style="vertical-align: left;">
					</nz-avatar>
					<p class="b-Eh5 b-font-demibold">{{currentUser.company.name}}</p>
				</div>
				<div class="profile-placeholder" *ngIf="superAdmin && adminCompany">

					<nz-avatar [nzText]='adminCompany?.name[0]' [nzSrc]="adminCompany?.avatar" nzSize="small"
						[ngStyle]="{'background-color': '#1dc7b2'}" style="vertical-align: left;">
					</nz-avatar>
					<p class="b-Eh5 b-font-demibold">{{adminCompany.name}}</p>
				</div>
				<li class="b-Eh4" (click)="editCompany()"><span>
						<p>Edit Company </p>
					</span></li>
				<li class="b-Eh4" (click)="invoiceSettings()"><span>
						<p>Invoice Settings</p>
					</span></li>
				<li class="b-Eh4" (click)="staffPermissions()"><span>
						<p>Manage Staff</p>
					</span></li>
				<li *ngIf="superAdmin" class="b-Eh4" (click)="admin()"><span>
						<p>Admin</p>
					</span></li>
				<li class="b-Eh4" (click)="logout()"><span>
						<p>Log out</p>
					</span></li>
			</ul>
		</div>
	</ng-template>

</app-nav>
<lib-modal [show_modal]="statusModalVisible" (closeModal)="closeStatusModal()" [close_icon]='true'
	[body]="modalContent">
	<div class="input-wrapper-modal">
		<div class="top-header">
			<h4 class="b-font-demibold title-margin">Contact Us</h4>

			<p class="b-Eh5">24/7 Operational Control Centre</p>
			<p class="b-Eh5 b-font-demibold"> <img width="18" class="margin-right-8" src="/assets/icons/phone-dark.svg">0161 533 0465</p>
			<iframe src="https://orka.works/contact-form-company-portal/" class="full-screen-iframe" frameborder="0"
				marginheight="0" marginwidth="0" allowfullscreen referrerpolicy="no-referrer"></iframe>


		</div>


	</div>
</lib-modal>
<section>
	<router-outlet></router-outlet>
</section>