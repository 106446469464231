// START
import { environment } from "../environments/environment";
import { NgModule, ErrorHandler } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule } from "@angular/forms";
import { AppRoutingModule } from "./app-routing.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

// Providers
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { GlobalErrorHandler } from "../services/api/error-handler";
import { PopNotificationService } from "@broadstone/pop-notification-service";
import { ApiService } from "@broadstone/api-service";
import { AuthSessionService } from "@broadstone/auth-session-service";
import { AlertService } from "@broadstone/alert";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { CookieService } from "ngx-cookie-service";
import { NZ_I18N, en_US } from "ng-zorro-antd/i18n";
import { registerLocaleData } from "@angular/common";
import en from "@angular/common/locales/en";
registerLocaleData(en);
import {
	Costs,
	CustomValidator,
	DatesHelper,
	GlobalHelpers,
} from "@broadstone/helpers";
import {
	HttpErrorsInterceptor,
	JobsProviders,
	CompaniesService,
	ShiftsService,
	ApplicationsService,
	AuthGuardService,
	StaffService,
	TimesheetService,
	FiltersServices,
	EventsTracker,
	GoogleAnalyticsService,
	SmartStaffService,
	HotJarService,
	PermissionsGuardService,
	PermissionsService,
	UrlHelpers,
	AdminInterceptor,
	LoggingService,
	GlobalAdapter,
	CachingService,
	MaintenanceService,
	MaintenanceGuardService,
	HttpRequestInterceptor,
	CalendarRange,
	UsersService,
	EmployeesService,
	LocalHelpers,
} from "../services/public_api";

// Directives
import { DirectivesModule } from "../directives/public_api";

// Declarations
import { AppComponent } from "./app.component";
import { StaffAccountEditComponent } from "./client/staff/staff-account-edit/staff-account-edit.component";
import { NotificationsComponent } from "./client/notifications/notifications.component";
import { MaintenanceModeComponent } from "./maintenance-mode/maintenance-mode.component";
import { IncidentReportsComponent } from "./client/incident-reports/incident-reports.component";
import { AdminComponent } from "./admin/admin.component";
import { SelectSiteComponent } from "./client/jobs/select-site/select-site.component";
import { InvoiceSettingsComponent } from "./client/invoice-settings/invoice-settings.component";
import { AccountComponent } from "./client/account/account.component";
import { CompanyEditComponent } from "./client/company-edit/company-edit.component";
import { ResetPasswordComponent } from "./login/reset-password/reset-password.component";
import { StaffAccountComponent } from "./client/staff/staff-account/staff-account.component";
import { CompanySetupComponent } from "./admin/company-setup/company-setup.component";
import { CodeErrorComponent } from "./code-error/code-error.component";
import { StaffComponent } from "./client/staff/staff.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { SiteComponent } from "./client/jobs/site/site.component";
import { ManageSiteComponent } from "./client/jobs/post-job/manage-site/manage-site.component";
import { ManageShiftsComponent } from "./client/jobs/post-job/manage-shifts/manage-shifts.component";
import { SetupRoleComponent } from "./client/jobs/post-job/setup-role/setup-role.component";
import { TimesheetsComponent } from "./client/timesheets/timesheets.component";
import { JobsComponent } from "./client/jobs/jobs.component";
import { LoginComponent } from "./login/login.component";
import { DashboardComponent } from "./client/dashboard/dashboard.component";

// Components
import { IntercomModule } from "ng-intercom-x";
import { AppCustomComponentsModule } from "src/components/components.module";

// External Components
import { AppExternalComponentsModule } from "./external-components.module";
import { NzComponentsModule } from "./nz-components.module";
import { WorkersOnMapComponent } from "./client/workers-on-map/workers-on-map.component";
import { AdminCompaniesComponent } from "./admin/companies/admin-companies.component";
import { AdminWorkersComponent } from "./admin/workers/admin-workers.component";
import { ClientComponent } from "./client/client.component";
import { AuthGuardAdminService } from "src/services/api/auth/auth-guard-admin.service";

@NgModule({
	declarations: [
		AppComponent,
		LoginComponent,
		DashboardComponent,
		JobsComponent,
		TimesheetsComponent,
		SetupRoleComponent,
		ManageShiftsComponent,
		ManageSiteComponent,
		SiteComponent,
		PageNotFoundComponent,
		StaffComponent,
		CodeErrorComponent,
		CompanySetupComponent,
		AdminCompaniesComponent,
		AdminWorkersComponent,
		StaffAccountComponent,
		ResetPasswordComponent,
		CompanyEditComponent,
		AccountComponent,
		InvoiceSettingsComponent,
		SelectSiteComponent,
		AdminComponent,
		IncidentReportsComponent,
		MaintenanceModeComponent,
		NotificationsComponent,
		StaffAccountEditComponent,
		WorkersOnMapComponent,
		ClientComponent,
	],
	imports: [
		IntercomModule.forRoot({
			appId: environment.intercom,
			updateOnRouterChange: true,
		}),

		HttpClientModule,
		BrowserModule,
		FormsModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		DirectivesModule,
		AppCustomComponentsModule,
		AppExternalComponentsModule,
		NzComponentsModule,
	],
	providers: [
		JobsProviders,
		CompaniesService,
		ShiftsService,
		ApplicationsService,
		Costs,
		CustomValidator,
		GlobalHelpers,
		AuthGuardService,
		StaffService,
		TimesheetService,
		DatesHelper,
		FiltersServices,
		AuthSessionService,
		ApiService,
		EventsTracker,
		HotJarService,
		GoogleAnalyticsService,
		SmartStaffService,
		CookieService,
		PermissionsGuardService,
		PermissionsService,
		UrlHelpers,
		LoggingService,
		MaintenanceService,
		MaintenanceGuardService,
		GlobalAdapter,
		CachingService,
		ApiService,
		PopNotificationService,
		UsersService,
		NzNotificationService,
		AlertService,
		EmployeesService,
		CalendarRange,
		LocalHelpers,
		AuthGuardAdminService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpErrorsInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AdminInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpRequestInterceptor,
			multi: true,
		},

		{ provide: ErrorHandler, useClass: GlobalErrorHandler },
		{ provide: NZ_I18N, useValue: en_US },
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
