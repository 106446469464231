import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { ClibButtonModule } from '@broadstone/clib-button';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { ModalModule } from '@broadstone/modal';
import { WorkerStatusesComponent } from './worker-statuses.component';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { ApplicationGlobals } from '@broadstone/helpers';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { ListDropDownModule } from 'src/components/public_api';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';

@NgModule({
	declarations: [WorkerStatusesComponent],
	exports: [WorkerStatusesComponent],
	imports: [
		CommonModule,
		FormsModule,
		ClibButtonModule,
		BrowserAnimationsModule,
		HttpClientModule,
		ModalModule,
		NzAvatarModule,
		ApplicationGlobals,
		ListDropDownModule,
		NzSkeletonModule,
		NzToolTipModule,
		NzIconModule,
		NzDatePickerModule,
		NzDrawerModule,
	],
})
export class WorkerStatusesModule {}
