import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StaffPermissionsComponent } from './staff-permissions.component';
import { ListStaffModule } from '../list-staff/list-staff.module';
import { EditStaffModule } from '../edit-staff/edit-staff.module';
import { ClibButtonModule } from '@broadstone/clib-button';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';




@NgModule({
    declarations: [StaffPermissionsComponent],
    exports: [StaffPermissionsComponent],
    imports: [
        CommonModule,
		NzSkeletonModule,
		NzAvatarModule,
        ListStaffModule,
        EditStaffModule,
        ClibButtonModule,
        InfiniteScrollModule,
    ]
})
export class StaffPermissionsModule { }
