import * as moment from 'moment';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormArray, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { JobsService } from 'src/services/public_api';
import { CustomValidator, DatesHelper } from '@broadstone/helpers';

export enum PostShiftPattern {
	new,
	fourOnFourOff,
	sixOnThreeOff
}

@Component({
	selector: 'app-post-shift-patterns-modal',
	templateUrl: './post-shift-patterns-modal.component.html',
	styleUrls: ['./post-shift-patterns-modal.component.scss']
})
export class PostShiftPatternsModalComponent implements OnInit {
	@Input() new_pattern: PostShiftPattern;
	@Input() jobUuid: string;
	@Input() openShiftPatternModal: boolean;
	@Output() emitJob: EventEmitter<any> = new EventEmitter();
	@Output() sendShiftPatternData: EventEmitter<any> = new EventEmitter();
	@Output() closeShiftPatternModal: EventEmitter<any> = new EventEmitter();
	postShiftPattern = PostShiftPattern;
	job: any;
	picker_date: any;
	datePipe: any;
	form: UntypedFormGroup;
	first_paste: boolean = true;
	last_row_date: any; // @todo added for build to pass - update type and default value
	worker_hourly_rate: string;
	cost_view: boolean = false;
	save_loading: boolean = false;
	shifts_data = [];
	payment_method: string;
	min_pay_rate: number = 0;
	max_hours: number = 13;
	location_timezone: string;
	range_dates: any = [];
	extend_field: any = {};
	firstDay: any;
	validDays: any;
	viewScheduleIcon: string = './assets/icons/view-schedule.svg'
	selected_days: any;
	days: Array<{ day: string, selected: boolean }> = [
		{ day: 'Monday', selected: false },
		{ day: 'Tuesday', selected: false },
		{ day: 'Wednesday', selected: false },
		{ day: 'Thursday', selected: false },
		{ day: 'Friday', selected: false },
		{ day: 'Saturday', selected: false },
		{ day: 'Sunday', selected: false },
	];
	constructor(
		private jobservice: JobsService,
		private formb: UntypedFormBuilder,
		public customvalid: CustomValidator,
		public date_helper: DatesHelper,
	) {
		this.createForm();
	}

	ngOnInit() {
		this.getJob();
	}

	getJob() {
		this.jobservice.getJob(this.jobUuid).then((result: any) => {
			this.job = result;
			this.location_timezone = result.site.location.timezoneId;
			this.worker_hourly_rate = result.worker_hourly_rate;
			this.payment_method = result.payment_method;
			this.getPayRate();
			this.emitJob.emit(this.job);
		}).catch((err) => {
			// this.logger.error(err);
		});
	}

	getPayRate() {
		this.jobservice.getPayRates().then((result) => {
			if (this.payment_method == 'paye') {
				this.min_pay_rate = result.paye.minimum_worker_hourly_rate;
			} else {
				this.min_pay_rate = result.contractor.minimum_worker_hourly_rate;
			}
			this.createForm();
		}).catch((err) => {
			// this.logger.error(err);
		});
	}

	createForm() {
		let formData = {
			'shifts_added': new UntypedFormArray([this.shiftRow()])
		}
		this.form = this.formb.group(formData, { validator: this.customvalid.validate_shift_hours.bind(this) }); // shift must be max 16 hours
	}

	shiftRow(slots?, start_at?, start_date?, date?, picker_date?, end_at?, uuid?, hourly_rate?) {
		const pattern = '^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$';
		return new UntypedFormGroup({
			'start_date': new UntypedFormControl(start_date ? start_date : moment(new Date()).format("DD/MM/YYYY"), Validators.compose([this.customvalid.date_check, Validators.required])),
			'date': new UntypedFormControl(date ? date : moment(new Date()).format("DD/MM/YYYY"), Validators.compose([this.customvalid.date_check, Validators.required])),

			'start_at': new UntypedFormControl(start_at ? start_at : '', Validators.compose([Validators.pattern(pattern), Validators.required])),
			'end_at': new UntypedFormControl(end_at ? end_at : '', Validators.compose([Validators.pattern(pattern), Validators.required])),
			'slots': new UntypedFormControl(slots ? slots : 1, Validators.required),
			'uuid': new UntypedFormControl(uuid ? uuid : ''),
			'hourly_rate': new UntypedFormControl(hourly_rate ? hourly_rate : this.worker_hourly_rate, Validators.compose([Validators.min(this.min_pay_rate), this.customvalid.check_number_decimal, Validators.required])),
			'picker_date': new UntypedFormControl(''),
			'lone_worker_shift': new UntypedFormControl(false),
		});
	}

	prepareShifts() {
		let shifts = this.form.controls.shifts_added.value.map(element => {
			let shift = this.calcEndShift(element.start_date, element.start_at, element.end_at, element.slots, element.hourly_rate, element.lone_worker_shift, element.uuid);
			return shift;
		});
		return shifts;
	}

	calcEndShift(date_format, start_at, end_at, slots, hourly_rate, lone_worker_shift, uuid?) {
		const { start_date, end_date } = this.date_helper.calculate_end_date(date_format, start_at, end_at);
		return {
			start_at: start_date,
			end_at: end_date,
			slots: slots,
			hourly_rate: hourly_rate,
			uuid: uuid,
			lone_worker_shift: lone_worker_shift ? 1 : 0
		}
	}

	set() {
		if (this.form.valid) {
			let shifts = this.prepareShifts();
			this.shifts_data = shifts.sort((a: any, b: any) => {
				return new Date(a.start_at).getTime() - new Date(b.start_at).getTime();
			});
		} else {
			this.shifts_data.length = 0;
		}
	}

	amendTime(index, controler) {
		const current_element = this.form.controls.shifts_added['controls'][index].controls;
		const input_val = current_element[controler].value;
		if (input_val.indexOf(':') == -1 && input_val) {
			const final_val = input_val.match(/.{1,2}/g).join(':');
			current_element[controler].patchValue(final_val);
		}
		this.set();
	}

	costConfirmation() {
		this.cost_view = true;
	}

	constructListOfIntervals(start, end, interval) {
		var intervals = [];
		var newIntervals = [];
		var dayCount: number = 0;
		var patternOn: boolean = true;
		while (moment(end).diff(start, interval, true) >= 0) {
			intervals.push(moment(start).endOf(interval));
			start = moment(moment(start).add(1, interval));
		}
		intervals.forEach(element => {
			if (this.new_pattern === PostShiftPattern.new) {
				return;
			} else if (this.new_pattern === PostShiftPattern.fourOnFourOff) {
				dayCount++;
				if (dayCount <= 4) {
					if (patternOn) {
						element.selected = true;
					} else {
						element.selected = false;
					}
					if (dayCount === 4) {// Reverse pattern on day 4
						dayCount = 0;
						patternOn = !patternOn;
					}
				} else {
					return;
				}
			} else if (this.new_pattern === PostShiftPattern.sixOnThreeOff) {
				dayCount++;
				if (dayCount <= 6) {
					if (patternOn) {
						element.selected = true;
					}
					else {
						element.selected = false;
					}

					// If pattern is off, turn pattern on again on day 3
					if (!patternOn && dayCount === 3) {
						dayCount = 0;
						patternOn = true;
					}
					// If pattern is on, turn pattern off on day 6
					else if (patternOn && dayCount === 6) {
						dayCount = 0;
						patternOn = false;
					}
				}
				else {
					return;
				}
			}
		})
		intervals.forEach(element => {
			if (element.selected === true) {
				newIntervals.push(element);
			}
		});
		if (newIntervals.length > 0) {
			return newIntervals;
		}
		else {
			return intervals;
		}
	}

	handleGetFirstDay() {
		if (this.new_pattern !== PostShiftPattern.new) {
			this.days.forEach(element => {
				element.selected = true;
			});
		}
		var allDays = this.constructListOfIntervals(this.range_dates[0], this.range_dates[1], 'day');
		const selectedDays = this.days.reduce((acc, curr: any) => {
			if (curr.selected === true) {
				curr = curr.day.substring(0, 3);
				acc = [...acc, curr];
			}
			return acc;
		}, []);
		if (selectedDays.length > 0) {
			const validDays = allDays.reduce((acc, curr) => {

				selectedDays.forEach(day => {
					if (curr._d.toString().includes(day)) {
						let date: any = new Date(curr._d);
						acc = [...acc, date];
					}

				});
				return acc;
			}, []);
			this.validDays = validDays.map(day => {
				return moment(day).format('DD-MM-YYYY');
			});
			this.firstDay = moment(validDays[0]).format('ddd D MMM YYYY');
		} else {
			this.firstDay = '';
		}
		this.set();
	}

	publishShifts() {
		this.save_loading = true;
		const allDays = this.constructListOfIntervals(this.range_dates[0], this.range_dates[1], 'day')
		const selectedDays = this.days.reduce((acc, curr: any) => {
			if (curr.selected === true) {
				curr = curr.day.substring(0, 3)
				acc = [...acc, curr]
			}
			return acc;
		}, [])
		const validDays = allDays.reduce((acc, curr) => {
			selectedDays.forEach(day => {
				if (curr._d.toString().includes(day)) {
					let date: any = new Date(curr._d);
					date = moment(date).format('DD/MM/YYYY')
					acc = [...acc, date]
				}
			})
			return acc;
		}, [])
		const shiftObject = validDays.map(day => {
			return {
				date: day,
				start_at: this.shifts_data[0].start_at.substring(11),
				end_at: this.shifts_data[0].end_at.substring(11),
				hourly_rate: this.shifts_data[0].hourly_rate,
				lone_worker_shift: this.shifts_data[0].lone_worker_shift,
				slots: this.shifts_data[0].slots
			}
		})
		setTimeout(() => {
			this.save_loading = false;
			this.sendShiftPatternData.emit(shiftObject);
			this.handleCancel();
		}, 200);


	}

	openPostShiftPatternModal(event) {
		this.openShiftPatternModal = true;
	}

	selectDay(selectedDay) {
		this.days.map(day => {
			if (day.day === selectedDay) day.selected = !day.selected;
		})
	}

	handleCancel() {
		this.closeShiftPatternModal.emit(this.openShiftPatternModal);
		this.days = [
			{ day: 'Monday', selected: false },
			{ day: 'Tuesday', selected: false },
			{ day: 'Wednesday', selected: false },
			{ day: 'Thursday', selected: false },
			{ day: 'Friday', selected: false },
			{ day: 'Saturday', selected: false },
			{ day: 'Sunday', selected: false },
		];
		this.openShiftPatternModal = false;
		this.shifts_data = [];
		this.range_dates = [];
		this.firstDay = '';
	}

	getValidDays() {
		const allDays = this.constructListOfIntervals(this.range_dates[0], this.range_dates[1], 'day')
		const selectedDays = this.days.reduce((acc, curr: any) => {
			if (curr.selected === true) {
				curr = curr.day.substring(0, 3);
				acc = [...acc, curr];
			}
			return acc;
		}, []);
		if (selectedDays.length > 0) {
			const validDays = allDays.reduce((acc, curr) => {
				selectedDays.forEach(day => {
					if (curr._d.toString().includes(day)) {
						let date: any = new Date(curr._d);
						acc = [...acc, date];
					}
				});
				return acc;
			}, []);
			return validDays;
		}
		return [];
	}
}

