import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { PasswordValidation } from '@broadstone/helpers';
import { AuthService } from 'src/services/public_api';
import { PopNotificationService } from '@broadstone/pop-notification-service';

@Component({
	selector: 'app-form-reset-password',
	templateUrl: './form-reset-password.component.html',
	styleUrls: ['./form-reset-password.component.scss']
})
export class FormResetPasswordComponent implements OnInit {
	@Input() logo: string;
	@Input() uuid: string;
	form: any;
	form2: any;
	email_address: any;
	retrieve_email: string;
	password: any;
	confirm_password: any;
	reset: boolean = false;
	passPattern: string = '^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{6,}$';
	@Output() redirect: EventEmitter<any> = new EventEmitter();
	@Output() success: EventEmitter<any> = new EventEmitter();

	constructor(
		private _formb: UntypedFormBuilder,
		private popNotif: PopNotificationService,
		private auth: AuthService,
	) {
		this.form = this._formb.group({
			email_address: ['', Validators.required],

		});
		this.form2 = this._formb.group({
			password: ['', Validators.compose([Validators.pattern(this.passPattern), Validators.required])],
			confirm_password: ['', Validators.compose([Validators.pattern(this.passPattern), Validators.required])]
		},
			{
				validator: PasswordValidation.MatchPassword
			}
		);
		this.email_address = this.form.get('email_address');
		this.password = this.form2.get('password');
		this.confirm_password = this.form2.get('confirm_password');
	}

	ngOnInit() {
	}

	ngOnChanges() {
		if (this.uuid) {
			this.reset = true;
		}
	}

	emailReset() {
		if (!this.email_address.value) {
			return;
		}
		const email = { email: this.email_address.value };
		this.auth.sendResetEmail(email).then(res => {
			this.popNotif.createNotification('success', 'Success', `An email has been sent to ${this.email_address.value}.`);
			this.form.reset();
		}).catch((err) => {
			if (err) {
				// this.logger.error(err);
				this.popNotif.createNotification('error', 'There has been a problem.', err);
			}
		});
	}

	signIn() {
		this.redirect.emit('login');
	}

	resetPassword() {
		if (!this.password.value && !this.uuid) {
			return;
		}
		const resetData = { token: this.uuid, password: this.password.value };
		this.auth.updatePassword(resetData).then((result) => {
			this.retrieve_email = result.email;
			this.popNotif.createNotification('success', 'Success', 'Your password has been updated.');
			this.redirect.emit('login');
		}).catch((err) => {
			if (err) {
				if (err === 'Error with the token provided.'){
					this.popNotif.createNotification('error', 'There has been a problem.', 'It looks like your reset password token is invalid, please try resetting your password again.');
				} else {
					this.popNotif.createNotification('error', 'There has been a problem.', err);
				}		
			}
		});
	}


}
