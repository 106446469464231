import * as moment from 'moment';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormArray, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { JobsService, ShiftsService, FiltersServices, EventsTracker } from 'src/services/public_api';
import { PopNotificationService } from '@broadstone/pop-notification-service';
import { PostShiftPattern } from './post-shift-patterns-modal/post-shift-patterns-modal.component';
import { CustomValidator, DatesHelper, Costs, GlobalHelpers } from '@broadstone/helpers';


@Component({
	selector: 'app-post-shifts',
	templateUrl: './post-shifts.component.html',
	styleUrls: ['./post-shifts.component.scss'],
})
export class PostShiftsComponent implements OnInit {
	@Input() jobUuid: string;
	@Output() onPosted: EventEmitter<any> = new EventEmitter();
	@Output() onPostedTimezone: EventEmitter<any> = new EventEmitter();
	@Output() onBack: EventEmitter<any> = new EventEmitter();
	@Output() emitJob: EventEmitter<any> = new EventEmitter();
	postShiftPatterns = PostShiftPattern;
	job: any;
	my_date: any;
	picker_date: any;
	datePipe: any;
	form: UntypedFormGroup;
	first_paste: boolean = true;
	last_row_date: any; // @todo added for build to pass - update type and default value
	worker_hourly_rate: string;
	costView: boolean = false;
	save_loading: boolean = false;
	shifts_data = [];
	payment_method: string;
	min_pay_rate: number = 0;
	upcomingMinPayRate: any = {
		date: '',
		rate: null
	}
	max_hours: number = 13;
	location_timezone: string;
	openShiftPatternModal: boolean = false;
	new_pattern: PostShiftPattern;
	popover_open: boolean = false;
	jobBankHolidayRate: number;
	bank_holiday_pay_rate: number;
	worker_costs = {
		amountObject: {},
		total: 0
	}
	loading: boolean = false;
	siteUuid: string;
	job_location: any;
	poolsOptions: 'All workers' | 'Previously worked' | 'Specific workers' = "All workers";
	selectedWorkers: any = [];
	selectedWorkersUuids: any = [];
	nextButtonAction: boolean = false;
	resetPools: boolean = false;
	constructor(
		private jobservice: JobsService,
		private shifts_service: ShiftsService,
		private formb: UntypedFormBuilder,
		public customvalid: CustomValidator,
		private popNotif: PopNotificationService,
		public date_helper: DatesHelper,
		private filters: FiltersServices,
		public costHelper: Costs,
		public helper: GlobalHelpers,
		private event_tracker: EventsTracker,
	) {
		this.createForm();
	}

	ngOnInit() {
		this.loadData();

	}
	async loadData() {
		await this.getJob();

	}

	async getJob() {
		return await this.jobservice.getJob(this.jobUuid).then((result: any) => {
			this.job = result;
			this.siteUuid = result.site.uuid;
			this.job_location = result.site.location;
			this.location_timezone = result.site.location.timezoneId;
			this.worker_hourly_rate = result.worker_hourly_rate;
			this.bank_holiday_pay_rate = this.costHelper.to_fixed(result.worker_hourly_rate * result.bank_holiday_rate);
			this.jobBankHolidayRate = result.bank_holiday_rate;
			this.payment_method = result.payment_method;
			this.getPayRate();
			this.emitJob.emit(this.job);
		}).catch((err) => {
			// this.logger.error(err);
		});
	}




	formatBankHolidayShiftDate(date, returnFormat = "YYYY-MM-DD") {
		return moment(date, "DD/MM/YYYY").format(returnFormat);
	}

	getPayRate() {
		this.jobservice.getPayRates().then((result) => {
			if (this.payment_method == 'paye') {
				// Set upcomingMinPayRate
				this.min_pay_rate = result.paye.minimum_worker_hourly_rate;
				this.upcomingMinPayRate.date = result.paye.minimum_worker_worker_hourly_rate_effective_date;
				this.upcomingMinPayRate.rate = result.paye.post_effective_date_min_worker_hourly_rate;
			} else {
				this.min_pay_rate = result.contractor.minimum_worker_hourly_rate;
			}
			this.createForm();
		}).catch((err) => {
			// this.logger.error(err);
		});
	}

	createForm() {
		let formData = {
			'shifts_added': new UntypedFormArray([this.shiftRow()])
		}
		this.form = this.formb.group(formData, { validator: this.customvalid.validate_shift_hours.bind(this) }); // shift must be max 16 hours
	}

	shiftRow(slots?, start_at?, start_date?, picker_date?, end_at?, uuid?, hourly_rate?, lone_worker_shift?) {
		const pattern = '^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$';
		return new UntypedFormGroup({
			'start_date': new UntypedFormControl(start_date ? start_date : moment(new Date()).format("DD/MM/YYYY"), Validators.compose([this.customvalid.date_check, Validators.required])),
			'start_at': new UntypedFormControl(start_at ? start_at : '', Validators.compose([Validators.pattern(pattern), Validators.required])),
			'end_at': new UntypedFormControl(end_at ? end_at : '', Validators.compose([Validators.pattern(pattern), Validators.required])),
			'slots': new UntypedFormControl(slots ? slots : 1, Validators.required),
			'uuid': new UntypedFormControl(uuid ? uuid : ''),
			'hourly_rate': new UntypedFormControl(hourly_rate ? hourly_rate : this.worker_hourly_rate, Validators.compose([Validators.min(this.min_pay_rate), this.customvalid.check_number_decimal, Validators.required])),

			'bank_holiday_rate': new UntypedFormControl(this.jobBankHolidayRate),
			'display_holiday_hourly_rate': new UntypedFormControl(hourly_rate ? this.costHelper.to_fixed(hourly_rate * this.jobBankHolidayRate) : this.bank_holiday_pay_rate),

			'regular_hours': new UntypedFormControl(0),
			'bank_holiday_hours': new UntypedFormControl(0),
			'picker_date': new UntypedFormControl(''),
			'lone_worker_shift': new UntypedFormControl(lone_worker_shift ? lone_worker_shift : false),
			'is_bank_holiday': new UntypedFormControl(false),
			'below_min': new UntypedFormControl(false)
		});
	}

	shiftsAdd(e) {
		let control = <UntypedFormArray>this.form.controls['shifts_added'];
		let last_shift = this.form.controls['shifts_added'].value.slice(-1)[0]; // get the data from last shift
		const start_date = moment(last_shift.start_date, "DD/MM/YYYY").add(1, 'day').format("DD/MM/YYYY");
		control.push(this.shiftRow(last_shift.slots, last_shift.start_at, start_date, '', last_shift.end_at, '', '', last_shift.lone_worker_shift));
		const index = this.form.controls.shifts_added['controls'].length;
		const current_element = this.form.controls.shifts_added['controls'][index - 1].controls;
		this.checkForBankHolidays(start_date, current_element);
		this.checkPayRate(index - 1, current_element);
		this.set();
	}

	checkBankHolidayRates(row) {
		if (!row.start_at.value || !row.end_at.value) {
			return;
		}

		let shift = this.calculateEndShift(row.start_date.value, row.start_at.value, row.end_at.value, row.slots.value, row.hourly_rate.value, row.lone_worker_shift.value, row.is_bank_holiday.value, '', row.bank_holiday_rate.value);
		this.shifts_service.getShiftPay({
			"shifts": [{
				"start_at": shift.start_at,
				"end_at": shift.end_at,
				"slots": shift.slots,
				"hourly_rate": shift.hourly_rate,
				"bank_holiday_rate": shift.bank_holiday_rate
			}],
			"job": this.jobUuid
		}
		).then((result) => {
			result.forEach(element => {
				if (element.bank_holiday_pay.hours) {
					row.is_bank_holiday.patchValue(true);
					row.regular_hours.patchValue(element.shift_pay.hours);
					row.bank_holiday_hours.patchValue(element.bank_holiday_pay.hours);
				}
				else {
					row.is_bank_holiday.patchValue(false);
				}
			});
			if (row.below_min.value && row.is_bank_holiday.value && row.regular_hours.value === 0) {
				row.hourly_rate.patchValue(this.costHelper.to_fixed(this.upcomingMinPayRate.rate));
				row.display_holiday_hourly_rate.patchValue(this.costHelper.to_fixed(this.upcomingMinPayRate.rate * row.bank_holiday_rate.value));
			}
		}).catch(() => {
			return;
		});
	}

	checkForBankHolidays(date, row) {
		const transform_date = moment(date, "DD/MM/YYYY");
		if (transform_date.isValid()) {
			const reformatedDate = this.formatBankHolidayShiftDate(date);
			this.shifts_service.getBankHolidays({
				"dates": [reformatedDate],
				"postcode": this.job.site.location.postcode,
			}).then((result) => {
				result.forEach(element => {
					if (element.is_bank_holiday) {
						row.is_bank_holiday.patchValue(true);
					}
					else {
						row.is_bank_holiday.patchValue(false);
					}

				});
			}).catch(() => {
				return;
			});
		}
	}


	checkPayRate(index, date) {
		const selectedDate = moment(date.start_date.value, 'DD/MM/YYYY').format("YYYY-MM-DD");
		if (moment(selectedDate).isSameOrAfter(this.upcomingMinPayRate.date)) {
			const hourlyRate = this.form.controls.shifts_added['controls'][index].controls.hourly_rate.value;
			if (hourlyRate < this.upcomingMinPayRate.rate) {
				this.form.controls.shifts_added['controls'][index].controls.below_min.patchValue(true);
			}
			else {
				this.form.controls.shifts_added['controls'][index].controls.below_min.patchValue(false);
			}
		}
		else {
			this.form.controls.shifts_added['controls'][index].controls.below_min.patchValue(false);
		}
	}

	shiftPatternAdd(shifts) {
		let control = <UntypedFormArray>this.form.controls['shifts_added'];
		shifts.forEach((shift) => {
			control.push(this.shiftRow(shift.slots, shift.start_at, shift.date, '', shift.end_at, '', shift.hourly_rate));
		});
		const index = control.value.findIndex(obj => obj.start_at === "");
		if (index >= 0) {
			this.shiftsRemove(index);
		}
		this.set();
	}

	shiftsRemove(i) {
		let control = <UntypedFormArray>this.form.controls['shifts_added'];
		if (control.length === 1) {
			this.shifts_data.length = 0;
			this.createForm();
			return;
		}
		control.removeAt(i);
		this.set();
	}

	openDatePicker(picker) {
		if (!picker.nzOpen) {
			this.onInputDate();
		}
		picker.nzOpen = !picker.nzOpen;
		if (!picker.cdr['destroyed']) {
			picker.cdr.detectChanges();
		}
	}

	onInputDate() { //getting the date from input and add in calendar
		const shifts_list = this.form.controls.shifts_added['controls'];
		for (let i = 0; i < shifts_list.length; i++) {
			const current_element = shifts_list[i].controls;
			const transform_date = moment(current_element.start_date.value, "DD/MM/YYYY");
			if (transform_date.isValid()) {
				current_element.picker_date.patchValue(moment(transform_date, "DD/MM/YYYY").format('llll'));
			}
		}
	}

	onClose(picker, event, index) {// Getting the date from calendar and adding in input
		const transform_date = new Date(moment(picker.inputValue, "DD/MM/YYYY").format('llll'));
		const current_element = this.form.controls.shifts_added['controls'][index].controls;
		if (picker?.inputValue) {
			current_element.start_date.patchValue(moment(transform_date).format("DD/MM/YYYY"));
			this.checkPayRate(index, current_element);
			this.checkForBankHolidays(picker.inputValue, current_element);
			this.set();
			picker.nzOpen = false;
			if (!picker.cdr['destroyed']) {
				picker.cdr.detectChanges();
			}
		}
	}

	prepareShifts() {
		let shifts = this.form.controls.shifts_added.value.map(element => {
			let shift = this.calculateEndShift(element.start_date, element.start_at, element.end_at, element.slots, element.hourly_rate, element.lone_worker_shift, element.is_bank_holiday, element.uuid, element.bank_holiday_rate);
			return shift;
		});
		return shifts;
	}

	calculateEndShift(date_format, start_at, end_at, slots, hourly_rate, lone_worker_shift, is_bank_holiday, uuid?, bank_holiday_rate?) {
		const { start_date, end_date } = this.date_helper.calculate_end_date(date_format, start_at, end_at);
		return {
			start_at: start_date,
			end_at: end_date,
			slots: slots,
			hourly_rate: hourly_rate,
			uuid: uuid,
			lone_worker_shift: lone_worker_shift ? 1 : 0,
			is_bank_holiday: is_bank_holiday,
			bank_holiday_rate: bank_holiday_rate
		}
	}

	typed(index) {
		const current_element = this.form.controls.shifts_added['controls'][index].controls;
		const date = current_element.start_date.value;
		this.checkForBankHolidays(date, current_element);
		this.checkPayRate(index, current_element);
		this.checkBankHolidayRates(current_element);
	}

	set() {
		this.resetPools = !this.resetPools;
		if (this.form.valid) {
			let shifts = this.prepareShifts();
			this.shifts_data = shifts.sort((a: any, b: any) => {
				return new Date(a.start_at).getTime() - new Date(b.start_at).getTime();
			});
			this.loading = true;
			this.reFormatShiftData();
		} else {
			this.shifts_data.length = 0;
		}
	}

	updateBankHolidayRate(index) {
		const current_element = this.form.controls.shifts_added['controls'][index].controls;
		this.checkPayRate(index, current_element);
		const hourly_rate = current_element.below_min.value ? this.upcomingMinPayRate.rate : current_element.hourly_rate.value;
		current_element.display_holiday_hourly_rate.patchValue(this.costHelper.to_fixed(hourly_rate * current_element.bank_holiday_rate.value));
	}

	amendTime(index, controler) {
		const current_element = this.form.controls.shifts_added['controls'][index].controls;
		const input_val = current_element[controler].value;
		if (input_val.indexOf(':') == -1 && input_val) {
			const final_val = input_val.match(/.{1,2}/g).join(':');
			current_element[controler].patchValue(final_val);
		}

		if (controler === 'end_at' && input_val.length > 4) {
			this.checkPayRate(index, current_element);
		}
		this.set();
	}

	pasteData(index, controler) {
		setTimeout(() => {                                                          // give time for the form to load data
			const shifts_list = this.form.controls.shifts_added['controls'];
			const current_element = shifts_list[index].controls;                      // get first shift
			let text_data = current_element[controler].value.split('\n');             //get data from first shift and split by line brake
			let control = <UntypedFormArray>this.form.controls['shifts_added'];              // init form controls to push back data
			if (this.first_paste) {                                                   // CREATING // When paste for the first time
				this.pasteAppendReplace(text_data, current_element, controler, control);
			}
			if (!this.first_paste) {                                                    //UPDATING //When pasting data second time we update each rows with data
				if (index == (shifts_list.length - 1)) {                                  //When you paste at the end in date input.. adds more rows
					this.pasteAppendReplace(text_data, current_element, controler, control);
				} else {
					if (text_data.length > shifts_list.length) {
						shifts_list.length = 0;
						this.combineTableRowPush(text_data, control, controler);
					} else {
						for (let i = 0; i < shifts_list.length; i++) {
							const curent_element_controler = shifts_list[i]['controls'][controler];
							if (text_data[i]) {
								this.pasteRowPatch(curent_element_controler, i, text_data);
								if (controler == 'start_date') {                                    // Check if you click on the first box date_calendar
									let current_cell = shifts_list[i]['controls'];
									this.pasteTablePatch(text_data, i, current_cell);
								}
							}
						}
					}
				}
			}
			this.last_row_date = shifts_list[shifts_list.length - 1].value.start_date;   //Saving last row data
			this.first_paste = false;
			this.set();
			shifts_list.forEach((element, index) => {
				Object.keys(element.controls).forEach(key => {
					element.controls[key].markAsTouched();
				});
				const date = element.controls.start_date.value;
				this.checkForBankHolidays(date, element.controls);
				this.checkBankHolidayRates(element.controls);
				this.checkPayRate(index, element.controls);
			});
		}, 30);
	}

	pasteAppendReplace(text_data, current_element, controler, control) {
		const curent_element_controler = current_element[controler];
		this.pasteRowPatch(curent_element_controler, 0, text_data);
		if (controler == 'start_date') {                                                  // Check if you click on the first box date_calendar
			this.pasteTablePatch(text_data, 0, current_element);
		}
		text_data.shift();                                                                //Remove first row of text
		this.combineTableRowPush(text_data, control, controler);
	}

	pasteTablePatch(text_data, i, current_element) {
		let first_text = text_data[i];
		const split_right = first_text.split("\t");
		if (split_right.length > 0) {                                                      // Check if the text you paste is a block of text containing all the fields
			current_element['start_date'].patchValue(split_right[0]);                        //Populate the first row that exists
			current_element['start_at'].patchValue(split_right[1]);
			current_element['end_at'].patchValue(split_right[2]);
			current_element['hourly_rate'].patchValue(split_right[3]);
		}
	}

	pasteTablePush(control, split_right, controler) {
		if (controler == 'start_date') {                                                   //If text was pasted in date field
			control.push(this.shiftRow(1, split_right[1], split_right[0], '', split_right[2], '', split_right[3]));
		}
	}

	pasteRowPatch(curent_element_controler, i, text_data) {
		curent_element_controler.patchValue(text_data[i]);                                 // populate first row that exists with the first data text
	}

	pasteRowPush(controler, control, element) {
		if (controler == 'start_date') {                                                  //If text was pasted in date field
			control.push(this.shiftRow(1, '', element, '', ''));                            //Pushing data in the form
		} else if (controler == 'start_at') {
			control.push(this.shiftRow(1, element, '', '', ''));
		} else if (controler == 'end_at') {
			control.push(this.shiftRow(1, '', '', '', element));
		} else if (controler == 'hourly_rate') {
			control.push(this.shiftRow(1, '', '', '', '', '', element));
		}
	}

	combineTableRowPush(text_data, control, controler) {
		text_data.forEach(element => {                                                      //Loop text rows
			const split_right = element.split("\t");                                          //Split text in cells
			if (split_right.length > 1) {                                                     //IF TEMPLATE PASTED
				this.pasteTablePush(control, split_right, controler);
			} else {
				this.pasteRowPush(controler, control, element);
			}
		});
	}

	costConfirmation() {
		this.nextButtonAction = true;
	}

	nextButtonActionCallback(e) {
		if (this.checkShifts()) {
			return;
		}



		this.nextButtonAction = false;

		if (e === 'cancel') {
			return;
		}

		setTimeout(() => {
			this.costView = true;
			window.scroll(0, 0);
			this.selectedWorkersUuids = this.selectedWorkers.map(element => element.applicant_uuid);
		}, 100);

	}

	checkShifts() {
		const foundBelowMinRate = this.form.controls.shifts_added['controls'].filter(element => {
			return element.controls.below_min.value && !element.controls.is_bank_holiday.value ||
				element.controls.below_min.value && (element.controls.is_bank_holiday.value && element.controls.regular_hours.value > 0);

		}).length > 0;
		if (this.shifts_data.length > 0 && !this.loading && !foundBelowMinRate) {
			return false;
		} else {
			return true;
		}
	}

	checkShiftsDates() {
		let feature_shifts = [];
		let past_shifts = [];
		this.shifts_data.forEach(element => {
			if (this.date_helper.check_time(element.start_at, element.end_at, 'passed')) {
				past_shifts.push(true);
			} else {
				feature_shifts.push(true);
			}
		})
		if (feature_shifts.length > past_shifts.length) {
			this.filters.clear_site_filters(); // Clear filters to make sure will expand the role when redirecting
		} else {
			this.filters.set_inactive_toggle();
		}
	}

	getShiftsData() {
		this.shifts_data.map(shift => {

			shift.start_at = this.date_helper.reformat_moment(this.location_timezone, shift.start_at);
			shift.end_at = this.date_helper.reformat_moment(this.location_timezone, shift.end_at);
			shift.bank_holiday_rate = shift.bank_holiday_rate;
		});
	}

	publishShifts() {
		this.set();
		this.save_loading = true;
		this.checkShiftsDates(); // set filters before redirecting to job role

		this.getShiftsData();



		if (this.poolsOptions) {
			this.event_tracker.trackEvent(this.poolsOptions);
		}

		let selectedPool: 'previously_worked' | 'all' | 'specific' = 'all';
		if (this.poolsOptions === 'Previously worked') {
			selectedPool = 'previously_worked';
		}
		if (this.poolsOptions === 'Specific workers') {
			selectedPool = 'specific';
		}


		this.shifts_service.createShifts(this.shifts_data, this.selectedWorkersUuids, this.jobUuid, selectedPool).then((res) => {
			this.shifts_data.length = 0;
			this.save_loading = false;
			this.poolsOptions = 'All workers';
			this.onPosted.emit(res);
			this.onPostedTimezone.emit(this.location_timezone);
			this.popNotif.createNotification('success', 'Success', 'Posted successfully.');
		}).catch(e => {
			this.save_loading = false;
			if (e.error.status === 403) {
				this.popNotif.createNotification('error', 'There has been a problem', e.error.message);
			}
			else {
				this.popNotif.createNotification('error', 'Please check your shifts and try again.', 'If this problem persists please contact community@orka.works');
			}
		})
	}

	cancel() {
		window.scroll(0, 0);
		if (this.costView) {
			this.costView = false;

		} else {
			this.onBack.emit(this.jobUuid);
		}
	}

	reFormatShiftData() {
		let shifts: any = [];
		this.shifts_data.forEach((shift, index) => { //format shift data to send to pay endpoint to get breakdown of hours paid at what rates

			shifts.push({
				"start_at": shift.start_at,
				"end_at": shift.end_at,
				"slots": shift.slots,
				"hourly_rate": shift.hourly_rate,
				"bank_holiday_rate": shift.bank_holiday_rate
			})
		});
		this.generatePayBreakdown(shifts)
	}

	generatePayBreakdown(shifts) {
		let number_of_hours: number = 0;
		let number_of_bank_holiday_hours: number = 0;
		let pay_rate: number = 0;
		let bank_holiday_pay_rate: number = 0;
		let total = 0;
		let shifts_obj = {
			"shifts": shifts,
			"job": this.jobUuid
		}

		this.shifts_service.getShiftPay(shifts_obj).then((result) => { // use reduce functions to collate all hours at same rates and make objects out of them
			const normalHrsArr = this.reduceFunction('shift_pay', result)
			const bankHolArr = this.reduceFunction('bank_holiday_pay', result);
			this.worker_costs.amountObject = { //put two arrays created into worker costs object
				normalHrsArr,
				bankHolArr
			}
			result.forEach(element => { //then generate total cost of all shifts
				number_of_hours = element.shift_pay.hours ? element.shift_pay.hours : 0;
				number_of_bank_holiday_hours = element.bank_holiday_pay.hours ? element.bank_holiday_pay.hours : 0;
				pay_rate = element.shift_pay.hourly_rate;
				bank_holiday_pay_rate = element.bank_holiday_pay.hourly_rate ? element.bank_holiday_pay.hourly_rate : 0;
				total = total + ((number_of_hours * pay_rate) + (number_of_bank_holiday_hours * bank_holiday_pay_rate));
				this.worker_costs.total = this.costHelper.to_fixed(total)
			});
			this.loading = false;
		}).catch((err) => {
			this.loading = false;
			// this.logger.error(err);
		});
	}

	reduceFunction(property, result) {
		return result.reduce((acc, curr) => {
			let key = curr[property].hourly_rate;
			if (acc.hasOwnProperty(key)) {
				acc[key] += curr[property].hours;
			} else {
				acc[key] = curr[property].hours;
			}
			return acc

		}, {});
	}

	postShiftPattern(type) {
		this.popover_open = false;
		this.new_pattern = type;
		this.openShiftPatternModal = true;
	}

	openPopover() {
		this.popover_open = true;
	}

	handleShiftPatternData(data) {
		this.shiftPatternAdd(data);
		const shifts_list = this.form.controls.shifts_added['controls'];
		for (let i = 0; i < this.shifts_data.length; i++) {
			const start_date = this.shifts_data[i].start_at.split(' ')[0];
			const formatted_date = moment(new Date(start_date), "DD/MM/YYYY").format("DD/MM/YYYY");
			const start_time = this.shifts_data[i].start_at.split(' ')[1];
			const end_time = this.shifts_data[i].end_at.split(' ')[1];
			const current_element = shifts_list[i].controls;
			current_element.start_date.patchValue(formatted_date);
			current_element.start_at.patchValue(start_time);
			current_element.end_at.patchValue(end_time);
			current_element.hourly_rate.patchValue(this.shifts_data[i].hourly_rate);
			current_element.slots.patchValue(this.shifts_data[i].slots);
			current_element.lone_worker_shift.patchValue(this.shifts_data[i].lone_worker_shift);
			current_element.is_bank_holiday.patchValue(this.shifts_data[i].is_bank_holiday);
			this.checkForBankHolidays(formatted_date, current_element);
			this.checkBankHolidayRates(current_element);
			this.checkPayRate(i, current_element);
		}
	}

	handleCloseShiftPatternModal() {
		this.openShiftPatternModal = false;
	}




}
