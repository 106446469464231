import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { PostSetupRoleComponent } from './post-setup-role.component';
import { InputListBuilderModule } from '../../input-list-builder/input-list-builder.module';
import { InputSuggestionsModule } from '../../input-suggestions/input-suggestions.module';
import { ListStaffModule } from '../../list-staff/list-staff.module';
import { ApplicationGlobals } from '@broadstone/helpers';
import { LoadingSpinnerModule } from '@broadstone/loading-spinner';
import { ClibButtonModule } from '@broadstone/clib-button';
import { ModalModule } from '@broadstone/modal';
import { DirectivesModule } from 'src/directives/directives.module';
import { JobCardModule } from '../../job-card/job-card.module';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';


@NgModule({
    declarations: [PostSetupRoleComponent],
    exports: [PostSetupRoleComponent],
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        BrowserAnimationsModule,
        HttpClientModule,
        InputSuggestionsModule,
        InputListBuilderModule,
        ListStaffModule,
        ClibButtonModule,
        ApplicationGlobals,
        DirectivesModule,
        LoadingSpinnerModule,
        ModalModule,
		JobCardModule,
		NzPopoverModule,
		NzSkeletonModule,
		NzToolTipModule,
    ]
})
export class PostSetupRoleModule { }
