import { NgModule } from "@angular/core";

import { ClibButtonModule } from "@broadstone/clib-button";
import { DashboardModule } from "@broadstone/dashboard";
import { ApplicationGlobals } from "@broadstone/helpers";
import { LoadingSpinnerModule } from "@broadstone/loading-spinner";
import { FormLoginModule } from "@broadstone/login";
import { ModalModule } from "@broadstone/modal";
import { NavModule } from "@broadstone/navbar";
import { NoSearchDataModule } from "@broadstone/no-search-data";
import { SearchInputModule } from "@broadstone/search-input";
import { WorkerMapModule } from "@broadstone/worker-map";
import { WorkerProfileModule } from "@broadstone/worker-profile";
import { InfiniteScrollModule } from "ngx-infinite-scroll";



@NgModule({
  exports: [
    ApplicationGlobals,
    WorkerProfileModule,
    NoSearchDataModule,
    DashboardModule,
    SearchInputModule,
    WorkerMapModule,
    ClibButtonModule,
    LoadingSpinnerModule,
    FormLoginModule,
    ModalModule,
    NavModule,
    InfiniteScrollModule,
  ],
})
export class AppExternalComponentsModule {}
