<div class="login-wrapper">
    <div class="login-slider-area">
        <nz-carousel class="slider-element" nzAutoPlay>
            <div nz-carousel-content>
                <img src="assets/icons/emoji/workforce-emoji.svg" />
                <h4 class="title">On-demand Workforce</h4>
                <h4>Thousands of workers eager and ready to work for you!</h4>
            </div>
            <div nz-carousel-content>
                <img src="assets/icons/emoji/man-juggling.svg" />
                <h4 class="title">Scheduling</h4>
                <h4>Let us do the hard work for you!</h4>
            </div>
            <div nz-carousel-content>
                <img class="memo-img" src="assets/icons/emoji/memo.svg" />
                <h4 class="title">Timesheets</h4>
                <h4>Manage timesheets with ease!</h4>
            </div>
        </nz-carousel>
    </div>

    <div class="login-form-area">
        <div class="logo">
            <img [src]="logo" alt="Orka Works Logo" />
        </div>
        <br />

        <!-- reset password email-->
        <form [formGroup]="form" *ngIf="!reset">
            <div class="input-spacing">
                <h5 class="b-light">Reset Password</h5>
                <p class="b-light">Please enter your email address below and we will send you a link to reset your
                    password.</p>
            </div>


            <div class="input-spacing">

                <input class="b-input b-input-login" type="email" formControlName="email_address" name="email"
                    id='email' placeholder="Email Address" email="true">
                <img class="input-email-icon" src="assets/icons/msg-shape.svg" />
                <div *ngIf="email_address.hasError('required') && email_address.touched">
                    <p class="error-box custom-width">Please fill in this field.</p>
                </div>
                <div
                    *ngIf="!email_address.hasError('required') && email_address.touched && email_address.hasError('email')">
                    <p class="error-box custom-width">Email is invalid.</p>
                </div>
            </div>



            <div class="button-wrapper">

                <app-clib-button [disabled]="form && !form.valid" label="Send" (pressAction)="emailReset()">
                </app-clib-button>
            </div>
            <div class="sign-up-wrapper">
                <p>Remember account? <a class="b-font-demibold" (click)="signIn()">Sign in</a>
                </p>
            </div>
        </form>

        <!-- set new password -->
        <form [formGroup]="form2" *ngIf="reset">

            <div class="input-spacing">
                <h5 class="b-light heading">Set your new password</h5>
                <input class="b-input b-input-login" type="password" formControlName="password" name="password"
                    id='password' placeholder="New Password" [pattern]="passPattern" required>

                <div *ngIf="password.hasError('required') && password.touched">
                    <p class="error-box custom-width">Please fill in this field.</p>
                </div>

                <div *ngIf="password.hasError('minlength') && (password.touched || submitted)">
                    <p class="error-box custom-width">Minimum 6 characters</p>
                </div>
                <div *ngIf="password.hasError('pattern') && (password.touched || submitted)">
                    <p class="error-box custom-width">Please enter a secure password.</p>
                </div>
            </div>
            <div class="input-spacing">
                <input class="b-input b-input-login" type="password" formControlName="confirm_password"
                    name="confirm_password" id='confirm_password' placeholder="Confirm Password" [pattern]="passPattern"
                    required>
                <p class="b-light b-Eh5">Passwords must have at least 6 characters and contain at least 1 number and 1
                    uppercase letter.</p>

                <div *ngIf="confirm_password.hasError('required') && confirm_password.touched">
                    <p class="error-box custom-width">Please fill in this field.</p>
                </div>
                <div
                    *ngIf="confirm_password.hasError('MatchPassword') && (confirm_password.touched || confirm_password.dirty)">
                    <p class="error-box custom-width">Passwords do not match.</p>
                </div>
            </div>


            <div class="button-wrapper">
                <app-clib-button [disabled]="form2 && !form2.valid" label="Reset Password"
                    (pressAction)="resetPassword()">
                </app-clib-button>
            </div>
        </form>
    </div>
</div>