import { Component, OnInit, Input } from '@angular/core';
import { StaffService, UsersService } from 'src/services/public_api';
import { Staff } from 'src/services/api/staff/staff.model';
import { AuthSessionService } from '@broadstone/auth-session-service';
@Component({
	selector: 'app-staff-permissions',
	templateUrl: './staff-permissions.component.html',
	styleUrls: ['./staff-permissions.component.scss']
})
export class StaffPermissionsComponent implements OnInit {
	public save: string = '';
	public cancel: string = '';
	assigned_staff: any = [];
	owner: Staff;
	staff_loading: boolean = false
	value = "Select";
	editStaffUuid: string;
	currentUser: any;
	manage_staff: boolean;
	page: number = 1;
	has_n_p: boolean = false;
	companyUuid: string = '';
	limit: number = 50;

	constructor(
		private staff_services: StaffService,
		private session: AuthSessionService,
		public user: UsersService
	) {
		if (this.session.geAdminHeader()) {
			this.manage_staff = true;
		}
		else {
			this.user.getCurrentUser().then((res) => {
				this.checkPermissions(res.permissions)
			}).catch(e => {
				console.log(e);
			});
		}
	}

	ngOnInit() {
		this.currentUser = this.session.getUser();
		if (this.session.getIsAdmin()) {
			this.companyUuid = this.session.geAdminHeader();
			this.companyUuid = this.companyUuid['x-broadstone-company'];
		}
		else {
			this.companyUuid = this.currentUser.company.uuid;
		}
		this.staff_loading = true;
		this.getStaff(1);
	}

	checkPermissions(permissions) {
		if (!permissions) {
			return;
		}
		permissions.forEach(element => {
			if (element.type === 'company.staff.manage') {
				this.manage_staff = true;
			}
		})
	}

	getStaff(page) {
		this.staff_services.searchStaff({ search: '' }, page, this.limit)
			.then(resp => {
				this.has_n_p = resp['links'].next ? true : false;
				let data = resp['data'];
				data.forEach(element => {
					if (element.user_uuid === this.currentUser.uuid) {
						this.owner = element;
					} else {
						if (this.assigned_staff.indexOf(element) == -1) {
							this.assigned_staff.push(element);
						}
					}
				});
				this.staff_loading = false;
			})
			.catch(e => {
				this.staff_loading = false;
				// this.logger.error(e);
			});
	}

	onScroll() {
		if (this.has_n_p) {
			this.page += 1;
			this.getStaff(this.page);
		}
	}

	openEditStaff(staff?) {
		if (staff && staff.uuid) {
			this.editStaffUuid = staff.uuid;
		} else {
			this.editStaffUuid = 'none';
		}
	}

	editStaffClose($event: any) {
		this.editStaffUuid = null;
		if ($event.refresh) {
			this.staff_loading = true;
			setTimeout(() => {
				this.assigned_staff.length = 0;
				this.getStaff(1);
			}, 500);
		} else {
			this.staff_loading = false;
		}
	}

}
