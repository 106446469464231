import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-static-banner',
	templateUrl: './static-banner.component.html',
	styleUrls: ['./static-banner.component.scss']
})
export class StaticBannerComponent implements OnInit {
	@Input() title: string;
	@Input() body: string;
	@Input() key: string;
	hideBanner: boolean = false;
	constructor() { }

	ngOnInit(): void {
		const item = this.getStorage('static_banner') ? this.getStorage('static_banner') : null;
		if (item && item[this.key]) {
			this.hideBanner = true;
		}
	}

	remove() {
		const key = { [this.key]: true };
		const obj = {...this.getStorage('static_banner'),...key};
		this.setStorage('static_banner', obj);
		this.hideBanner = true;
	}

	setStorage(key, value) {
		localStorage.setItem(key, JSON.stringify(value));
	}

	getStorage(key) {
		if (localStorage.getItem(key) !== 'undefined') {
			return JSON.parse(localStorage.getItem(key));
		}
	}
}
