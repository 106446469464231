import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchInputModule } from '@broadstone/search-input';

import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { LoadingSpinnerModule } from '@broadstone/loading-spinner';
import { InvoiceSettingsViewComponent } from './invoice-settings-view.component';
import { ClibButtonModule } from '@broadstone/clib-button';
import { AlertService } from '@broadstone/alert';
import { PopNotificationService } from '@broadstone/pop-notification-service';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NgClickOutsideDirective } from 'ng-click-outside2';

@NgModule({
    declarations: [InvoiceSettingsViewComponent],
    exports: [InvoiceSettingsViewComponent],
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        BrowserAnimationsModule,
        HttpClientModule,
        LoadingSpinnerModule,
        SearchInputModule,
        NgClickOutsideDirective,
        ClibButtonModule,
		NzPaginationModule
    ],
    providers: [AlertService, PopNotificationService]

})
export class InvoiceSettingsViewModule { }