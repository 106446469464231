<form #form="ngForm">
	<lib-modal [show_modal]="isVisible" (closeModal)='handleCancel()' btn_label_primary="Save"
		btn_color_primary="primary" btn_label_secondary="Cancel" [btn_disabled_primary]="!form.valid || upcomingMinRate"
		(clickPrimary)="saveChanges($event, form)" (clickSecondary)="handleCancel()" [width]='668'>
		<div>

			<div class="group-buttons">
			</div>
			<div class="modal-edit-shifts">
				<div class="shift-block">
					<div class="shift-wrapper" *ngIf="!multipleShifts">
						<h3 class="b-light">Date</h3>
						<div class="shift-input-field">
							<nz-date-picker nzInputReadOnly name='calendar' #calendar_date="ngModel"
								(ngModelChange)="checkForBankHolidays($event)" [(ngModel)]='date' #calendar_range
								required class="nz-override-date-range nz-override-date-b-input nz-date-no-icon"
								nzDropdownClassName='nz-override-date-range-drop' nzFormat='dd/MM/yyyy'>
							</nz-date-picker>
							<img *ngIf="shift?.is_bank_holiday && date" src="./assets/icons/bankHolidayPay.svg">
							<p class="error-box"
								*ngIf="calendar_date.invalid && calendar_date.errors.required && (calendar_date.dirty || calendar_date.touched)">
								Date is
								required.</p>
						</div>


					</div>
					<div class="shift-wrapper">
						<h3 class="b-light">Time</h3>
						<div>
							<div class="shift-input-field shift-times">
								<textarea name="start_time" #start_at_time="ngModel"
									(keyup)="amendTime('start_at', $event)" maxlength="5" required
									[pattern]="time_pattern" #start_at_element [(ngModel)]='start_at'
									placeholder="07:00" required class="b-textarea b-textarea-white start-time"
									type="text" rows="1"></textarea>
								<p class="shift-dash">-</p>
								<textarea name="end_time" #end_at_time="ngModel" (keyup)="amendTime('end_at', $event)"
									maxlength="5" required [pattern]="time_pattern" #end_at_element [(ngModel)]='end_at'
									placeholder="16:00" required class="b-textarea b-textarea-white end-time"
									type="text" rows="1"></textarea>
							</div>
							<div
								*ngIf="(start_at_time.dirty || start_at_time.touched) || (end_at_time.dirty || end_at_time.touched)">
								<p *ngIf="(start_at_time.invalid && start_at_time.errors.pattern) || (end_at_time.invalid && end_at_time.errors.pattern)"
									class="error-box">Time must be
									entered in<br> HH:MM format</p>
								<p class="error-box" *ngIf="start_at_time.invalid && start_at_time.errors.required">
									Start time is
									required.
								</p>
								<p class="error-box" *ngIf="end_at_time.invalid && end_at_time.errors.required">End time
									is required.
								</p>
								<p class="error-box"
									*ngIf="(start_at_time?.errors?.maxHours || end_at_time?.errors?.maxHours) ">The
									shift must be<br>
									less than {{ max_hours }} hours
								</p>
							</div>
						</div>
					</div>

					<div class="shift-wrapper">
						<h3 class="b-light">Pay</h3>
						<div class="shift-input-field">
							<p class='input-pound'>&pound;</p>
							<input class="b-input b-font-demibold input-simbol" #pay_field="ngModel" required name="pay"
								[customMin]="paye_min_rate" [(ngModel)]="pay"
								(ngModelChange)="updateBankHolidayRate(pay)" type="number">
							<div *ngIf="pay_field.dirty || pay_field.touched">
								<p class="error-box" *ngIf="pay_field.invalid && pay_field.errors.required">Pay is
									required.</p>
								<p class="error-box"
									*ngIf="pay_field.invalid && pay_field.errors.customMin && !pay_field.errors.required && !upcomingMinRate">
									Minimum &pound;
									{{paye_min_rate}}.
								</p>
							</div>
							<p class="error-box" *ngIf="upcomingMinRate">
								Minimum &pound;
								{{upcomingMinPayRate.rate.toFixed(2) }}.
							</p>
						</div>

					</div>
					<div *ngIf="shift && shift.is_bank_holiday && !multipleShifts" class="bank-holiday-pay-wrapper">
						<p class="b-font-demibold b-primary" nz-popover [nzPopoverContent]="bankHolidayPopover"
							nzPopoverTrigger="hover"> <img *ngIf="shift?.is_bank_holiday && date"
								src="./assets/icons/bankHolidayPay.svg">&pound; {{bank_holiday_pay_rate}} ph</p>
					</div>
					<ng-template #bankHolidayPopover>
						<div class="bankHolidayPopover">
							<h3 class="b-primary b-font-demibold">Bank Holiday Pay</h3>
							<p class="b-primary b-font-demibold">x{{bank_holiday_rate}} Standard Pay</p>
							<p class="explainer">This shift falls on a bank holiday so we have automatically boosted
								the pay based on your job settings. Head to edit job if you want to
								change the rate.

							</p>
							<p class="explainer b-font-demibold">
								*The bank holiday pay rate is only applied to shift hours which fall on the bank
								holiday
							</p>
						</div>
					</ng-template>
					<div class="shift-wrapper">
						<h3 class="b-light">Positions</h3>
						<div class="shift-input-field">
							<input class="b-input b-font-demibold" required #slots='ngModel' name="positions"
								[customMin]="minimum_slots" [(ngModel)]="positions" type="number">
							<div *ngIf="slots.dirty || slots.touched">
								<p class="error-box" *ngIf="slots.invalid && slots.errors.required">Positions is
									required.</p>
								<p class="error-box"
									*ngIf="slots.invalid && slots.errors.customMin && !slots.errors.required">Please
									check
									approved applicants</p>
							</div>
						</div>
					</div>
					<div class="shift-wrapper">
						<h3 class="b-light">Lone Worker</h3>
						<div class="shift-input-field lone-worker">
							<label class="b-checkbox">
								<input name="lone_worker_shift" [(ngModel)]="lone_worker_shift" type="checkbox">
								<span class="checkmark" nz-tooltip
									nzTooltipTitle="Check box if this is a lone worker shift"></span>
							</label>
						</div>
					</div>
				</div>

			</div>

			<div *ngIf="shift && shift.accepted_applications > 0 && loading_applicants && !hideDeleteButton"
				class="applicants-block">
				<nz-skeleton class="nz-applicant-loading" [nzAvatar]="true" [nzTitle]="false" [nzActive]="true"
					[nzParagraph]="{ rows: 1 }"></nz-skeleton>
			</div>
			<div *ngIf="applicants.length > 0 && !loading_applicants && !hideDeleteButton"
				[ngClass]="applicants.length > 2 ? 'overflow' : ''" class="applicants-block">
				<div *ngFor="let data of applicants">
					<div class="applicant">
						<nz-avatar class="applicant-avatar" [nzSize]="68" [nzText]='data?.applicant?.user?.name[0]'
							[nzSrc]="data?.applicant?.user?.avatar" nzSize="large"></nz-avatar>
						<h4 class="name" class="b-light">{{data?.applicant?.user?.name}}
							{{data?.applicant?.user?.surname}}
						</h4>
						<a (click)="decline(data)" class="remove-btn">
							<img *ngIf=" !decline_loading" src="assets/icons/remove-gray.svg" />
							<app-loading-spinner *ngIf="decline_loading" color="color_grey_4" size="small"
								class="link-loading">
							</app-loading-spinner>
						</a>
					</div>
					<hr>
				</div>
			</div>
		</div>
		<!-- <div class="policy" *ngIf="delete_shift">
            <app-policy [fee]="shift?.job?.payment_method == 'contractor' ? contractor_fee: paye_fee"
                [edit_shift]="true">
            </app-policy>
        </div> -->

	</lib-modal>
</form>