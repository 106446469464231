import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PermissionsService } from './permissions.service';
import { UsersService } from '../users/users.service';
import { AuthSessionService } from '@broadstone/auth-session-service';


@Injectable({
    providedIn: 'root'
})
export class PermissionsGuardService  {

    constructor(
        private router: Router,
        public permissions: PermissionsService,
        public user: UsersService,
        private session: AuthSessionService
    ) {

    }

    async canActivate() {
        let returnValue = false;
        if (this.session.geAdminHeader()) {
            returnValue = true;
            this.redirect(returnValue);
            return returnValue;
        }
        else {
            let currentUserPermissions = [];
            const promisesCompleted = [];
            promisesCompleted.push(this.permissions.getStaffPermissions().catch(e => { console.log(e); }));
            promisesCompleted.push(this.user.getCurrentUser().then(res => { currentUserPermissions = res.permissions; }).catch(e => { console.log(e) }));
            await Promise.all(promisesCompleted);

            const all_permissions = this.permissions.all_permissions ? this.permissions.all_permissions : [];



            if (currentUserPermissions.length >= all_permissions) {
                returnValue = true;
            } else {
                currentUserPermissions.forEach(element => {
                    if (element.type === 'company.jobs.edit') {
                        returnValue = true;
                    }
                });
            }

            this.redirect(returnValue);
            return returnValue;
        }
    }

    redirect(returnValue) {
        if (returnValue === false) {

            this.router.navigate(['/jobs']);
        }
        else {
            return;
        }

    }
}
