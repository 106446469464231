import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectDropDownComponent } from './select-drop-down.component';
import { ClibButtonModule } from '@broadstone/clib-button';
@NgModule({
    declarations: [SelectDropDownComponent],
    exports: [SelectDropDownComponent],
    imports: [
        CommonModule,
        ClibButtonModule
    ]
})
export class SelectDropDownModule { }
