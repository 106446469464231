import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExpandableTimesheetComponent } from './expandable-timesheet.component';
import { WorkerMapModule } from '@broadstone/worker-map';

import { FormsModule } from '@angular/forms';
import { ClibButtonModule } from '@broadstone/clib-button';
import { ApplicationGlobals } from '@broadstone/helpers';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzPopoverModule } from 'ng-zorro-antd/popover';



@NgModule({
    declarations: [ExpandableTimesheetComponent],
    exports: [ExpandableTimesheetComponent],
    imports: [
        ClibButtonModule,
        FormsModule,
        WorkerMapModule,
        NzAvatarModule,
		NzPopoverModule,
        CommonModule,
        ApplicationGlobals
    ]
})
export class ExpandableTimesheetModule { }
