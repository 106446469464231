import { Injectable } from '@angular/core';

export interface Fields {
    number: number
}
export interface Qualifications {
    id: number,
    title: string,
    fields: Fields
}
export interface Requirements {

    id: number,
    title: string,
    qualification_types?: Array<Qualifications>

}

export interface cRequirements {
    id: number,
    title: string
}

export interface clientRequirements {
    uuid: string,
    type_name: string
    requirements: Array<cRequirements>
}


@Injectable({
    providedIn: 'root'
})
export class RequirementsAdapter {

    adapt_requrements(data): Requirements {
        return {
            "id": data.id,
            "title": data.title,
            "qualification_types": data.qualification_types
        }
    }

    adapt_client_requirements(data): clientRequirements {
        return {
            "uuid": data.uuid,
            "type_name": data.type_name,
            "requirements": data.requirements
        }
    }

}