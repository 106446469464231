<section>
    <div class="staff-perm-wrapper">
        <!-- header -->



        <div *ngIf="staff_loading">
            <nz-skeleton class="nz-staff-loading" [nzLoading]="staff_loading" [nzParagraph]="{ rows: 2 }"
                [nzTitle]="false" [nzAvatar]="true" [nzActive]="true"></nz-skeleton>
            <hr>
            <nz-skeleton class="nz-staff-loading" [nzLoading]="staff_loading" [nzParagraph]="{ rows: 2 }"
                [nzTitle]="false" [nzAvatar]="true" [nzActive]="true"></nz-skeleton>
        </div>

        <div *ngIf="!staff_loading">
            <!-- list of  staff -->
            <div class="staff-wrapper">
                <div class="staff-sub-wrapper owner-wrapper">
                    <div class="staff-name-column" *ngIf="owner">
                        <div>
                            <nz-avatar [nzText]='owner?.full_name[0] | uppercase' [nzSrc]="owner?.avatar" nzSize="large"
                                [ngStyle]="{'background-color': '#1dc7b2'}" style="vertical-align: left;"></nz-avatar>

                        </div>
                        <div class="staff-details">
                            <p class="b-font-demibold uppercase owner">{{ owner?.role }}</p>
                            <h5 class="b-font-demibold">{{ owner?.full_name }}</h5>
                            <h5 class="b-light">{{ owner?.email }}</h5>
                        </div>
                    </div>
                    <div class="staff-name-column" *ngIf="!owner">
                        <div>
                            <nz-avatar [nzText]='currentUser?.fullName[0] | uppercase' [nzSrc]="currentUser?.userAvatar"
                                nzSize="large" [ngStyle]="{'background-color': '#1dc7b2'}"
                                style="vertical-align: left;"></nz-avatar>

                        </div>
                        <div class="staff-details">
                            <h5 class="b-font-demibold">{{ currentUser?.fullName }}</h5>
                            <h5 class="b-light">{{ currentUser?.email }}</h5>
                        </div>
                    </div>
                    <div class="staff-flex-item">

                    </div>
                    <div class="staff-flex-item" *ngIf="manage_staff">
                        <app-clib-button (pressAction)="openEditStaff(staff)" label="Add Staff">
                        </app-clib-button>
                    </div>
                </div>
                <div infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="80" (scrolled)="onScroll()"
                    [scrollWindow]="true">
                    <div class="staff-sub-wrapper" *ngFor="let staff of assigned_staff">
                        <div class="staff-name-column">
                            <div>
                                <nz-avatar [nzText]='staff.full_name[0] | uppercase' [nzSrc]="staff.avatar"
                                    nzSize="large">
                                </nz-avatar>
                            </div>
                            <div class="staff-details">
                                <p class="uppercase b-font-demibold">{{staff.full_name}}</p>
                                <p *ngIf="staff.role && staff.role !== 'hr'" class="b-light">{{staff.role | titlecase}}
                                </p>
                                <p *ngIf="staff.role && staff.role == 'hr'" class="b-light">{{staff.role | uppercase}}
                                </p>
                            </div>
                        </div>
                        <div class="staff-flex-item staff-email">
                            <p class="b-light">{{staff.email}}</p>
                        </div>
                        <div class="staff-flex-item" *ngIf="manage_staff">
                            <app-clib-button color="tertiary" (pressAction)="openEditStaff(staff)" label="Edit">
                            </app-clib-button>
                        </div>
                    </div>
                </div>


            </div>
        </div>

        <app-edit-staff [staffuuid]='editStaffUuid' [show_modal]="editStaffUuid" [companyUuid]="companyUuid"
            (closeModal)='editStaffClose($event)'>
        </app-edit-staff>



    </div>
</section>