import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { AppAnimations, TimesheetService, ApplicationsService } from 'src/services/public_api';
import { DatesHelper, GlobalHelpers, Costs } from '@broadstone/helpers';
import { PopNotificationService } from '@broadstone/pop-notification-service';


@Component({
	selector: 'app-expandable-timesheet',
	templateUrl: './expandable-timesheet.component.html',
	styleUrls: ['./expandable-timesheet.component.scss'],
	animations: [AppAnimations.expand_animation, AppAnimations.fade_in_out]
})
export class ExpandableTimesheetComponent implements OnInit {
	@ViewChild("end_at_element") end_at_element: ElementRef;
	@ViewChild("start_at_element") start_at_element: ElementRef;
	@Input() edit_timesheets: boolean;
	@Input() timesheet: any;
	@Input() status_selected: string;
	@Output() onChecked: EventEmitter<any> = new EventEmitter();
	@Output() openProfile: EventEmitter<any> = new EventEmitter();
	@Output() onUpdate: EventEmitter<any> = new EventEmitter();
	worker_in_lat: number;
	worker_in_lng: number;
	worker_out_lat: number;
	worker_out_lng: number;
	site_lat: number;
	site_lng: number;
	rowStatus = false;
	profile_visible = false;
	start_at: any;
	end_at: any;
	time_pattern = '^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$';
	update_loading: boolean = false;
	total_pay: number;

	constructor(
		private timesheets_service: TimesheetService,
		public helper: GlobalHelpers,
		private date_helper: DatesHelper,
		private popNotif: PopNotificationService,
		private applications_service: ApplicationsService,
		public costHelper: Costs
	) { }

	ngOnInit() {
		this.firstLoad();
	}

	firstLoad() {
		this.total_pay = this.costHelper.to_fixed(this.timesheet.cost.normal_cost + this.timesheet.cost.bank_holiday_cost);
		this.matchedDots();
	}

	amendTime(module = null, event) {
		if (!module) {
			return;
		}
		if (this[module] && this[module].indexOf(':') == -1) {
			const final_val = this[module].match(/.{1,2}/g).join(':');
			this[module] = final_val;
		}
		//when first input is fill focus next input
		if (this[module].length > 4) {
			if (module == 'start_at') {
				this.end_at_element.nativeElement.focus();
			}
		}
		//when backspace focus first input
		if (module == 'end_at' && event.keyCode == 8 && this[module] == '') {
			this.start_at_element.nativeElement.focus();
		}
	}

	timesheetSelected(e) {
		e.stopPropagation(); // stopping event from bubbling
		this.onChecked.emit();// emitting event
	}

	toggleRow() {
		if (this.profile_visible) { // when click on view profile and profile is open don`t expand row
			return;
		}
		this.rowStatus = !this.rowStatus;
	}

	matchedDots() {
		const lateInMilliseconds = 60000 * 4; // allow workers 4 minutes grace
		const { start_at, end_at } = this.timesheet.shift || '';
		const { clocked_in_at, clocked_out_at } = this.timesheet.timesheet || '';
		if (!clocked_in_at) {
			this.timesheet.clock_in_late = true;
		} else {
			const difference_in = this.date_helper.date_difference(start_at, clocked_in_at, 'minutes');
			if (difference_in < -lateInMilliseconds) {
				this.timesheet.clock_in_late = true;
			} else {
				this.timesheet.clock_in_late = false;
			}
		}
		if (!clocked_out_at) {
			this.timesheet.clock_out_early = true;
		} else {
			const difference_out = this.date_helper.date_difference(end_at, clocked_out_at, 'minutes');
			if (difference_out > lateInMilliseconds) {
				this.timesheet.clock_out_early = true;
			} else {
				this.timesheet.clock_out_early = false;
			}
		}
	}

	openApplicantProfile(applicant_uuid) {
		if (!applicant_uuid) {
			return;
		}
		this.openProfile.emit(applicant_uuid);
	}

	async amendTimesheet(uuid, timezone) {
		if (this.timesheet && this.timesheet.error) {  //clear all errors before approving 
			this.timesheet.error = null;
		}
		if (this.timesheet.timesheet.timesheet_status == 'no-show') {
			await this.undoNoShow(this.timesheet); // if timesheet is now show we revert now show and amend and approve
		}
		if (!uuid || this.timesheet.timesheet.timesheet_status == 'approved') {
			return;
		}
		if (this.start_at && this.end_at) { // if has amend data in input fields do update and approve ELSE DO APPROVE
			const shift_date = this.date_helper.format_date(this.timesheet.shift.start_at);
			const { start_date, end_date } = this.date_helper.calculate_end_date(shift_date, this.start_at, this.end_at);
			const amended_mapped = {
				"amended_clocked_in_at": this.start_at ? this.date_helper.reformat_moment(timezone, start_date) : '',
				"amended_clocked_out_at": this.end_at ? this.date_helper.reformat_moment(timezone, end_date) : '',
			}
			this.update_loading = true;
			this.timesheets_service.update_timesheet(uuid, amended_mapped).then((result) => {
				this.update_loading = false;
				this.timesheet.timesheet.amended_clocked_in_at = result['data'].timesheet.amended_clocked_in_at;
				this.timesheet.timesheet.amended_clocked_out_at = result['data'].timesheet.amended_clocked_out_at;
				this.timesheet.timesheet.hours_worked = result['data'].timesheet.hours_worked;
				this.timesheet.cost = result['data'].cost;
				this.firstLoad();
				this.onUpdate.emit(this.timesheet);
			}).catch((err) => {
				this.update_loading = false;
				// this.logger.error(err);
				this.popNotif.createNotification('error', 'There has been a problem.', 'We cannot update this timesheet. Please try again later.');
			});
		} else {
			if (this.timesheet.timesheet.clocked_in_at && this.timesheet.timesheet.clocked_out_at) { // approve if has clockin and out times
				this.onUpdate.emit(this.timesheet);
			}
		}
	}

	async undoNoShow(application) {
		return await this.applications_service.revertNoShow(application.uuid).catch(() => {
			return;
		});
	}

}
