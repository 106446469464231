import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { JobViewComponent } from "./job-view.component";
import { GeofencingMapModule } from "../public_api";
import { NzCarouselModule } from "ng-zorro-antd/carousel";
import { ModalModule } from "@broadstone/modal";
import { NzSkeletonModule } from "ng-zorro-antd/skeleton";
import { ApplicationGlobals } from "@broadstone/helpers";

@NgModule({
	declarations: [JobViewComponent],
	exports: [JobViewComponent],
	imports: [BrowserModule,
		CommonModule,
		GeofencingMapModule,
		NzCarouselModule,
		NzSkeletonModule,
		ApplicationGlobals,
		ModalModule],
})
export class JobViewModule { }
