import { HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

abstract class HttpCache {
	abstract get(req: HttpRequest<any>): HttpResponse<any> | null;
	abstract put(req: HttpRequest<any>, res: HttpResponse<any>): void;
}

type cacheData = {
	url: string;
	stripUrl: string;
	cacheSecondsTime: number;
};

@Injectable({
	providedIn: "root",
})
export class CachingService implements HttpCache {
	cacheLists: Array<cacheData> = [];
	baseUrl: string = environment.apiUrl;
	constructor() { }

	stripUrl(url) {
		const index = url.indexOf("?");
		if (index !== -1) {
			const queryString = url.substring(0, index + 1);
			return this.removeBaseUrl(queryString);
		}else{
			const stringDash = url.substring(0, url.indexOf('-'));
			const stringSlash = stringDash.substring(0,stringDash.lastIndexOf('/') + 1);
			return this.removeBaseUrl(stringSlash);
		}
	}
	removeBaseUrl(queryString){
		const strippedUrl = queryString.replace(this.baseUrl, "");
		return strippedUrl;
	}

	addCache(url: string, cacheSecondsTime: number, strip: boolean = false) {
		const found =
			this.cacheLists.filter((element) => element.url === url).length > 0;
		const stripUrl = strip ? this.stripUrl(url) : "";
		if (!found) {
			this.cacheLists.push({ url, stripUrl, cacheSecondsTime });
		}
	}

	removeCache(url){
		localStorage.removeItem(url); //then remove it
	}

	get(req: HttpRequest<any>): HttpResponse<any> {
		let cachedItem = JSON.parse(localStorage.getItem(req.urlWithParams)); //get cached item if it exists
		if (cachedItem && this.checkCacheAddedTime(cachedItem, req.urlWithParams)) {
			//if it exists and it's within the time slot return it
			return cachedItem.res;
		}
	}

	checkCacheAddedTime(res, reqUrl): boolean {
		const found = this.cacheLists.filter((element) => element.url === reqUrl);
		if (!found || found.length == 0) {
			return false;
		}
		const foundSecondsTime = found[0].cacheSecondsTime;
		const now = Date.now();
		const addedMinutes = new Date(
			res.addedTime + foundSecondsTime * 1000
		).getTime();
		if (now < addedMinutes) {
			return true; //if the time the cache runs out is less than now return true
		} else {
			localStorage.removeItem(reqUrl); //then remove it
			return false;
		}
	}

	put(req: HttpRequest<any>, res: HttpResponse<any>): void {
		const url = req.urlWithParams;
		const entry = { url, res, addedTime: new Date().getTime() }; //create the entry object
		const found = this.cacheLists.filter((element) => element.url === url);
		if (!found || found.length == 0) {
			return;
		}
		if (found && found[0].cacheSecondsTime) {

			if (found[0].stripUrl) {
				const keys = Object.keys(localStorage)
					.map((key) => key)
					.filter((element) => element.includes(found[0].stripUrl));
				if (keys.length > 2) {
					keys.forEach((element) => {
						localStorage.removeItem(element);
					});
				}
			}


			localStorage.setItem(req.urlWithParams, JSON.stringify(entry)); //save to local storage
			const index = this.cacheLists.indexOf(found[0]);
			if (index !== -1) {
				this.cacheLists.splice(index, 1); //quick delete from view
			}
		} else {
			return;
		}
	}
}
