import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateStaffAccountComponent } from './create-staff-account.component';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClibButtonModule } from '@broadstone/clib-button';




@NgModule({
    declarations: [CreateStaffAccountComponent],
    exports: [CreateStaffAccountComponent],
    imports: [
        CommonModule,
        FormsModule,
        ClibButtonModule,
        NzGridModule,
        ReactiveFormsModule
    ]
})
export class CreateStaffAccountModule { }
