import { Output, EventEmitter, Injectable, Directive } from '@angular/core';
import { DatesHelper } from '@broadstone/helpers';
import { ApiService } from '@broadstone/api-service';
export interface SearchQuery {
    applicant_name: string;
    client_name: string;
    location: string;
    start_at: any;
    end_at: any;
    status: string;
    site_reference: string;
    job_type: any;
}
@Directive()
@Injectable({
    providedIn: 'root'
})
export class FiltersServices {
    constructor(public date_helper: DatesHelper, private api: ApiService) {
    }
    @Output() isSearchingSite: EventEmitter<any> = new EventEmitter();
    @Output() calendarReset: EventEmitter<any> = new EventEmitter();
    active_toggle: boolean = true;
    applicant_name: string = '';
    client_name: string = '';
    location: string = '';
    site_reference: string = '';
    start_at: any = '';
    end_at: any = '';
    status: string = '';
    job_type: any = '';
    assigned_sites: boolean = true;

    clear_site_filters() {
        this.reset_applicant_client_location();
        this.reset_status();
        this.reset_job_type();
        this.reset_calendar_range();
        this.set_toggle_base_range();
        this.reset_sites_toggle();
        this.isSearchingSite.emit('filters_cleared');
    }



    check_active_filters() {
        let filters_on = false;
        if (this.check_applicant_client_location() || this.check_range_active_filters()) {
            filters_on = true;
        }
        return filters_on;
    }




    check_range_active_filters() {
        if (this.end_at || !this.active_toggle) {
            return true;
        }
        return false;
    }

    check_applicant_client_location() {
        if (this.applicant_name || this.client_name || this.location || this.site_reference) {
            return true;
        }
        return false;
    }
    check_job_type() {
        if (this.job_type) {
            return true;
        }
        return false;
    }
    reset_client_location() {
        this.client_name = '';
        this.location = '';
        this.site_reference = '';
    }
    reset_applicant_client_location() {
        this.reset_client_location();
        this.reset_applicant();
    }
    reset_applicant() {
        this.applicant_name = '';
    }
    reset_status() {
        this.status = '';
    }

    reset_job_type() {
        this.job_type = '';
    }
    reset_calendar_range() {
        this.start_at = '';
        this.end_at = '';
        this.calendarReset.emit();
    }

    set_inactive_toggle() {
        this.active_toggle = false;
        this.reset_calendar_range();
    }




    reset_sites_toggle() {
        this.assigned_sites = true;
    }

    set_toggle_base_range() {
        const today = new Date();
        if (this.date_helper.compare(this.start_at, today) == -1 && this.date_helper.compare(this.end_at, today) == 1) {
            this.active_toggle = true;
        } else {
            if (this.date_helper.compare(today, this.start_at) == 1) {
                this.active_toggle = false;

            } else {
                this.active_toggle = true;
            }
        }
    }
    adapt_filters(filters: SearchQuery, date_helper) {
        const new_filters = { ...filters };
        for (var key in new_filters) {
            if (new_filters.hasOwnProperty(key) && new_filters[key]) {
                if (key == 'applicant_name' || key == 'client_name' || key == 'location') {
                    new_filters[key] = this.api.encode_submission(new_filters[key]);
                }
                if (key == 'start_at') {
                    const start = new_filters[key];
                    new_filters[key] = date_helper.utc_date(start);
                }
                if (key == 'end_at') {
                    const end = new_filters[key];
                    new_filters[key] = date_helper.utc_date(end);
                }

            }
        }
        return new_filters;
    }
}