import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthTwofaComponent } from './auth-twofa.component';
import { FormsModule } from '@angular/forms';
import { ClibButtonModule } from '@broadstone/clib-button';


@NgModule({
    declarations: [AuthTwofaComponent],
    exports: [AuthTwofaComponent],
    imports: [
        CommonModule,
        FormsModule,
        ClibButtonModule

    ]
})
export class AuthTwofaModule { }
