import { Injectable } from "@angular/core";
import { HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse, HttpEvent } from "@angular/common/http";
// Add RxJS observable
import { Observable, of, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { PopNotificationService } from '@broadstone/pop-notification-service';
import { AuthSessionService } from '@broadstone/auth-session-service';
import { UsersService } from './users/users.service';
import { CachingService } from './caching-service';
import { LoggingService } from './logging/logging.service';


@Injectable()
export class HttpErrorsInterceptor implements HttpInterceptor {
    constructor(
        private popNotif: PopNotificationService,
        private router: Router,
        private session: AuthSessionService,
        private user: UsersService,
		public logger: LoggingService
    ) {
    }

    // check if pre-defined error message exists from API - if so then provide it.
    getErrorDescription(err: any) {
        if (err.error.errors) {
            // reference below is how a pre-defined message returns in our req body (not the standard err.message)
            return err.error.errors.name;
        } else {
            // default
            return 'Sorry about that. Please contact us if this continues to occur.';
        }
    }

    public intercept(httpRequest: HttpRequest<any>, handler: HttpHandler) {
        return handler.handle(httpRequest).pipe(
            catchError((err: any) => {
                if (err instanceof HttpErrorResponse) {
                    // creating notification as per error code
                    switch (err.status) {
                        case 0:
                            this.popNotif.createNotification('error', 'There has been a problem.', "Please wait a few minutes and try loading the page again.");
                            break;
                        case 401:
                            if (!this.user.runOnce) {
								this.popNotif.createNotification('error', 'Your session has expired.', "You will need to Sign In again.");
                                this.router.navigate(['/login']);
                                this.session.logout();
                                this.user.runOnce = true;
                            }
                            break;
                        // case 500:
                        //     this.popNotif.createNotification('error', 'There has been a problem.', 'Internal server error.');
                        //     break;
                    }
                }
                return throwError(err);
            }));
    }


}
@Injectable()
export class AdminInterceptor implements HttpInterceptor {

    constructor(private session: AuthSessionService) {
    }
    // add bearer token to authorisation header for each http request
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const adminHeader = this.session.geAdminHeader();
        if (adminHeader) {
            const cloned = req.clone({
                headers: req.headers.append('x-broadstone-company', adminHeader['x-broadstone-company'])
            });
            return next.handle(cloned);
        }
        else {
            return next.handle(req);
        }
    }
}

 @Injectable()
 export class HttpRequestInterceptor implements HttpRequestInterceptor {
 
	 constructor(
		 private _cache: CachingService
	 ) {}
 
	 intercept(request: HttpRequest<any>, next: HttpHandler) {
		 if (request.method !== 'GET') {
			return next.handle(request); //only cache get endpoints
		  }
		const cachedResponse = this._cache.get(request); //get cached response if there is one
		 if (cachedResponse){
			 return of(new HttpResponse<any>(cachedResponse)); //return cached response
		 }
			return next.handle(request)
			.pipe( //otherwise if it is the first time an endpoint has been called process the http request and cache the response
				tap<HttpEvent<any>>((httpEvent: HttpEvent<any>) => { 
					if (httpEvent instanceof HttpResponse) {
						this._cache.put(request, httpEvent);
					}
				}),
				catchError((err: HttpErrorResponse) => {
					throw err;
				}),
			);
		
	 }
 }
 

