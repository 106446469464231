import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeofencingMapComponent } from './geofencing-map.component';



@NgModule({
    declarations: [GeofencingMapComponent],
    exports: [GeofencingMapComponent],
    imports: [
        CommonModule
    ]
})
export class GeofencingMapModule { }
