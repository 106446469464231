import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputSuggestionsModule } from '../../input-suggestions/input-suggestions.module';
import { PostSiteComponent } from './post-site.component';
import { FormsModule } from '@angular/forms';
import { ListStaffModule } from '../../list-staff/list-staff.module';
import { LoadingSpinnerModule } from '@broadstone/loading-spinner';
import { ClibButtonModule } from '@broadstone/clib-button';
import { ApplicationGlobals } from '@broadstone/helpers';
import { GeofencingMapModule } from '../../geofencing-map/geofencing-map.module';
import { ModalModule } from '@broadstone/modal';
import { DirectivesModule } from 'src/directives/directives.module';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzSliderModule } from 'ng-zorro-antd/slider';

@NgModule({
    declarations: [PostSiteComponent],
    exports: [PostSiteComponent],
    imports: [
        CommonModule,
        FormsModule,
        InputSuggestionsModule,
        ListStaffModule,
        ClibButtonModule,
        NzSwitchModule,
		NzPopoverModule,
		NzSliderModule,
		NzToolTipModule,
        DirectivesModule,
        LoadingSpinnerModule,
        ApplicationGlobals,
        GeofencingMapModule,
        ModalModule
    ]
})
export class PostSiteModule { }
