import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormResetPasswordComponent } from './form-reset-password.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClibButtonModule } from '@broadstone/clib-button';
import { AuthService, UsersService } from 'src/services/public_api';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';



@NgModule({
    declarations: [FormResetPasswordComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NzCarouselModule,
        ClibButtonModule

    ],
    exports: [FormResetPasswordComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [AuthService, UsersService]
})
export class FormResetPasswordModule {
    static forRoot(): ModuleWithProviders<FormResetPasswordModule> {
    return {
        ngModule: FormResetPasswordModule
    };
}
}
