<div *ngIf="!costView" class="b-card-large">
	<div class="shift-header">
		<div class="col calendar">
			<h5>DATE</h5>
		</div>
		<div class="col clock">
			<h5>SITE TIME</h5>
		</div>
		<div class="col pay">
			<h5>HOURLY PAY</h5>
		</div>
		<div class="col staff">
			<h5># OF STAFF</h5>
		</div>
		<!-- <div class="col pools-area-shift">
	
		</div> -->
		<div class="col lone-worker">
			<h5>LONE WORKER</h5>
		</div>
		<div class="col delete">

		</div>
	</div>
	<form [formGroup]="form">
		<div *ngIf="form.controls.shifts_added" formArrayName='shifts_added'>
			<div *ngFor="let date of form.controls.shifts_added.controls; let index = index;">
				<div class="shift-row" formGroupName="{{index}}">
					<div class="col">

						<div class="calendar border">
							<a (click)="openDatePicker(calendar_range)" class="calendar-icon"><img
									*ngIf="!date.controls.is_bank_holiday.value" src="assets/icons/calendarIcon.svg" />
								<img *ngIf="date.controls.is_bank_holiday.value"
									src="assets/icons/bankHolidayPay.svg" /></a>
							<textarea (blur)="typed(index)" (keyup)="set()" formControlName='start_date'
								placeholder='dd/mm/yyyy' required class="b-textarea-white"
								[ngClass]="date.controls.is_bank_holiday.value ? 'b-textarea-green-font' : 'b-textarea'"
								(paste)="pasteData(index, 'start_date')" type="text" rows="1">
                            </textarea>
							<!-- <input formControlName='start_date' placeholder='dd/mm/yy' (paste)="pasteData(index)" (change)="onInputDate(index)"
                class="b-input b-input-white" type="text"> -->
							<nz-date-picker nzInputReadOnly nzFormat='dd/MM/yyyy' formControlName="picker_date"
								(nzOnOpenChange)="onClose(calendar_range, $event, index)" #calendar_range
								class="nz-date-picker-button"
								nzDropdownClassName='nz-override-date-range-drop drop-position'>
							</nz-date-picker>
						</div>
						<div class="error calendar" *ngIf="date.controls.start_date?.errors">
							<p class="error-box"
								*ngIf="!date.controls.start_date?.errors.dateinvalid && date.controls.start_date?.touched">
								Date is
								required.</p>
							<p class="error-box" *ngIf="date.controls.start_date?.errors.dateinvalid">Date must be
								dd/mm/yyyy</p>
						</div>
					</div>
					<div class="col">
						<div class="clock border">
							<a class="clock-icon"><img src="assets/icons/ic_clock.svg" /></a>
							<textarea (keyup)="amendTime(index, 'start_at')" formControlName='start_at'
								placeholder="07:00" required class="b-textarea b-textarea-white start-time"
								(paste)="pasteData(index, 'start_at')" type="text" rows="1">
                    </textarea>
							<p>-</p>
							<textarea (keyup)="amendTime(index, 'end_at')" formControlName='end_at' placeholder="16:00"
								required class="b-textarea b-textarea-white end-time"
								(paste)="pasteData(index, 'end_at')" type="text" rows="1">
                    </textarea>
						</div>
						<div class="error" *ngIf="(date.controls.start_at?.errors || date.controls.end_at?.errors)">
							<p class="error-box"
								*ngIf="date.controls.start_at?.errors?.pattern || date.controls.end_at?.errors?.pattern">
								Time must be
								entered in HH:MM format</p>
							<p class="error-box"
								*ngIf="(date.controls.start_at?.errors?.required && date.controls.start_at.touched) || ( date.controls.end_at?.errors?.required && date.controls.end_at.touched)">
								Shift time is
								required</p>
							<p class="error-box"
								*ngIf="(date.controls.start_at?.errors?.maxHours || date.controls.end_at?.errors?.maxHours) ">
								The shift
								must be
								less than {{ max_hours }} hours
							</p>
						</div>
					</div>
					<div class="col">

						<div class="pay border">

							<p>&pound;</p>
							<textarea (keyup)="updateBankHolidayRate(index, hourly_rate); set()"
								formControlName='hourly_rate' min="min_pay_rate" [placeholder]="min_pay_rate" required
								class="b-textarea-white b-textarea" (paste)="pasteData(index, 'hourly_rate')"
								type="number" rows="1">
              </textarea>


							<!-- <input (keyup)="set()" formControlName='hourly_rate' min="9" placeholder="9.00"
                class="b-input b-input-white" type="number"> -->
							<p>ph</p>
							<!-- <p *ngIf="date.controls?.regular_hours?.value"> x {{date?.controls?.regular_hours?.value}}
                            </p> -->
						</div>
						<ng-template #bankHolidayPopover>
							<div class="bankHolidayPopover">
								<h3 class="b-primary b-font-demibold">Bank Holiday Pay</h3>
								<p class="b-primary b-font-demibold">x{{date.controls?.bank_holiday_rate?.value}}
									Standard Pay</p>
								<p class="explainer">This shift falls on a bank holiday so we have automatically boosted
									the pay based on your job settings. You can update the rate below for this shift or
									head
									to the edit job if you want to
									change the rate for the entire job.
								</p>


								<div class="bank-holiday-rate-wrapper">

									<p class='input-pound x'>x</p>

									<input (keyup)="updateBankHolidayRate(index, hourly_rate); set()"
										formControlName='bank_holiday_rate' min="1" max="2" placeholder="2"
										class="b-input b-input-white" type="number">


								</div>
								<p class="explainer">Enter a value between 1 and 2</p>


								<p class="explainer b-font-demibold">
									*The bank holiday pay rate is only applied to shift hours which fall on the bank
									holiday
								</p>
							</div>
						</ng-template>

						<div class="pay border" *ngIf="date.controls.is_bank_holiday.value" nz-popover
							[nzPopoverContent]="bankHolidayPopover" nzPopoverTrigger="hover">
							<img class="pay-calendar" *ngIf="date.controls.is_bank_holiday.value"
								src="assets/icons/bankHolidayPay.svg" />
							<p [ngClass]="date.controls.is_bank_holiday.value ? 'b-primary' : ''">&pound;</p>
							<textarea readonly formControlName='display_holiday_hourly_rate' min="min_pay_rate"
								[placeholder]="min_pay_rate" required class="b-textarea-white"
								[ngClass]="date.controls.is_bank_holiday.value ? 'b-textarea-green-font' : 'b-textarea'"
								type="number" rows="1">
              				</textarea>

							<p [ngClass]="date.controls.is_bank_holiday.value ? 'b-primary' : ''">ph</p>
						</div>
						<div class="error-rate"
							*ngIf="date.controls.hourly_rate?.errors && date.controls.hourly_rate?.touched">
							<p class="error-box"
								*ngIf="date.controls.hourly_rate?.errors?.min && !date.controls.below_min.value">
								Min &pound;
								{{min_pay_rate}}</p>
							<p class="error-box"
								*ngIf="!date.controls.hourly_rate?.errors?.min || date.controls.hourly_rate?.errors?.numberinvalid">
								Hourly rate is required.</p>

						</div>
						<div class="error-rate" *ngIf="date.controls && date.controls.hourly_rate">
							<p class="error-box" *ngIf="date.controls.below_min.value">
								Min &pound;{{upcomingMinPayRate.rate}}</p>
						</div>
					</div>
					<div class="col">
						<div class="staff border">
							<input (keyup)="set()" formControlName='slots' min="1" placeholder="1"
								class="b-input b-input-white" type="number">
						</div>
						<div class="error error-staff"
							*ngIf="date.controls.slots?.errors && date.controls.slots?.touched">
							<p class="error-box">Staff required.</p>
						</div>
					</div>
					<div class="col">
						<div class="lone-worker">
							<label class="b-checkbox">
								<input type="checkbox" formControlName="lone_worker_shift" name="lone_worker_shift">
								<span class="checkmark" nz-tooltip
									nzTooltipTitle="Check box if this is a lone worker shift"></span>
							</label>

						</div>
					</div>

					<div class="col delete">
						<a (click)="shiftsRemove(index)"><img src="assets/icons/ic-delete.svg" /></a>
					</div>

				</div>
			</div>
		</div>
	</form>

	<div class="add_row">
		<app-clib-button class="add_btn" type="outline" (pressAction)="shiftsAdd($event)" label="Add">
		</app-clib-button>
		<div class="add_shift" nz-popover [nzPopoverContent]="shiftPatternPopover" nzPopoverTrigger="click"
			[(nzPopoverVisible)]="popover_open" (click)="openPopover()" nzPopoverPlacement="bottom">
			<app-clib-button label="Add Shift Pattern">
			</app-clib-button>
		</div>
	</div>
	<hr>
	<div class="pools-area">
		<app-pools-create-shift *ngIf="!costView" (nextButtonActionChange)="nextButtonActionCallback($event)"
			[nextButtonAction]="nextButtonAction" [selectedWorkers]="selectedWorkers" [resetPools]="resetPools"
			[(poolSelected)]="poolsOptions" [shifts]="shifts_data" [locationTimezone]="location_timezone"
			[siteUuid]="siteUuid" [autoApprove]="job?.auto_fill_enabled">
		</app-pools-create-shift>
	</div>
	<ng-template #shiftPatternPopover>
		<div class="b-result-box">
			<ul>
				<li (click)="postShiftPattern(postShiftPatterns.fourOnFourOff)">4 on 4 off</li>
				<li (click)="postShiftPattern(postShiftPatterns.sixOnThreeOff)">6 on 3 off</li>
				<li (click)="postShiftPattern(postShiftPatterns.new)">New Shift Pattern</li>
			</ul>
		</div>
	</ng-template>




	<app-post-shift-patterns-modal (sendShiftPatternData)="handleShiftPatternData($event)"
		[openShiftPatternModal]="openShiftPatternModal" [jobUuid]="jobUuid" [new_pattern]="new_pattern"
		(closeShiftPatternModal)="handleCloseShiftPatternModal($event)"></app-post-shift-patterns-modal>
</div>

<app-cancellation-policy [payment_method]="payment_method" [location_timezone]="location_timezone"
	[shifts_data]="shifts_data" [jobUuid]="jobUuid" *ngIf="costView">
</app-cancellation-policy>


<div class="costBreakdown" *ngIf="!costView && shifts_data.length > 0">
	<app-loading-spinner *ngIf="loading" size="small" class="link-loading"
		style="padding-right: 20px; padding-top: 20px;">
	</app-loading-spinner>
	<div class="innerDiv" *ngIf="!loading">
		<div class="breakdownRow row-padding" *ngFor="let item of worker_costs.amountObject.bankHolArr | keyvalue">
			<p class="b-font-demibold small-font b-primary" *ngIf="item.value > 0">&pound;{{item.key}}<span class="b-font-normal"
					*ngIf="item.value > 0"> x
					{{costHelper.to_fixed(item.value)}}
					hours </span>(BH)</p>
			<p class="b-font-demibold   small-font" *ngIf="item.value > 0">
				&pound;{{costHelper.to_fixed(item.key * item.value)}}</p>
		</div>
		<div class="breakdownRow row-padding" *ngFor="let item of worker_costs.amountObject.normalHrsArr | keyvalue">
			<p class="b-font-demibold small-font" *ngIf="item.value > 0">&pound;{{item.key}}<span class="b-font-normal"
					*ngIf="item.value > 0"> x {{costHelper.to_fixed(item.value)}}
					hours</span></p>
			<p class="b-font-demibold small-font" *ngIf="item.value > 0">
				&pound;{{costHelper.to_fixed(item.key * item.value)}}
			</p>
		</div>
		<hr>
		<div class="breakdownRow">
			<p class="b-font-demibold">Total Cost</p>
			<p class="b-font-demibold">£{{worker_costs.total}}</p>
		</div>
		<div class="breakdownRow">
			<p class="sub-row">(Excluding VAT)</p>
		</div>
	</div>

</div>



<div class="group-buttons row-spacing">
	<app-clib-button type="outline" (pressAction)="cancel()" label="Back">
	</app-clib-button>
	<app-clib-button *ngIf="!costView" [disabled]="checkShifts()" label="Next" (pressAction)="costConfirmation()">
	</app-clib-button>
	<app-clib-button *ngIf="costView" [loading]="save_loading" label="Post Shifts" (pressAction)="publishShifts()">
	</app-clib-button>


</div>