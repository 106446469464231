import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
    selector: 'app-timesheets',
    templateUrl: './timesheets.component.html',
    styleUrls: ['./timesheets.component.scss']
})
export class TimesheetsComponent {

    constructor(private router: Router) { }


    redirectJobPage() {
        this.router.navigate(['jobs/manage-site']);
    }
}
