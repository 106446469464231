import { Component, OnInit } from '@angular/core';
import { JobsService } from 'src/services/public_api';
import { Router } from '@angular/router';


@Component({
	selector: 'app-site-select',
	templateUrl: './site-select.component.html',
	styleUrls: ['./site-select.component.scss']
})
export class SiteSelectComponent implements OnInit {
	all_sites: any = [];
	page: number = 1;
	loading_more: boolean = false;
	limit: number = 10;
	loading: boolean = false;
	filters: any = {
		location: '',
		assigned_sites: '',
		sort_by: 'shift.created_at',
		order: 'DESC'
	}
	job_filters: any = {
		sort_by: 'shift.created_at',
		order: 'DESC'
	}
	job_type_dropdown = [
		{ title: 'All', img: null, value: '' },
		{ title: 'Ad Hoc', img: 'assets/icons/adhoc.svg', value: 'AdHoc' },
		{ title: 'Temp to Perm', img: 'assets/icons/smartstaff.svg', value: 'SmartStaff' }
	]
	jobs: any = [];
	choose_site: boolean = true;
	site: string = '';
	site_address: any = {};
	total_pages: number;

	constructor(
		private router: Router,
		private jobs_service: JobsService
	) { }

	ngOnInit(): void {
		this.loading = true;
		this.all_sites.length = 0;
		this.getAllSites(1);
		this.site_address = {};
	}

	ngOnDestroy() { }

	clearSearch() {
		this.loading = true;
		this.filters.location = '';
		this.getAllSites(1);
	}

	checkApi() {
		this.loading = true;
		this.resetData();
		this.getAllSites(1);
	}

	resetData() {
		this.all_sites.length = 0
		this.page = 1;
	}

	assignedSitesChange(e) {
		this.checkApi();
	}

	pageChange(e) {
		this.loading = true;
		this.all_sites.length = 0
		this.page = e;
		this.getAllSites(this.page);
		window.scroll(0, 0);
	}

	getAllSites(page) {
		this.jobs_service.getAllSites(page, this.filters, this.limit).then((result) => {
			if (!this.filters.location && result.data.length === 0) {
				this.createNewSite();
			}
			this.all_sites = result['data'];
			this.total_pages = result.metaData.last_page;
			this.loading_more = false; //loading animation for more results
			this.loading = false; //loading for initial load
		}).catch((err) => {
			this.loading_more = false;
			this.loading = false;
			// this.logger.error(err);
		});
	}

	siteSelected(siteUuid, client, address) {
		this.getJobs(siteUuid)
		this.site_address.client = client
		this.site_address.line1 = address.address_1;
		this.site_address.city = address.city;
		this.site_address.postcode = address.postcode
	}

	jobSelected(jobUuid) {
		this.router.navigate(['/jobs/manage-shifts/' + jobUuid]);
	}

	openSchedule(jobUuid) {
		this.router.navigate(['/jobs/role/' + jobUuid]);
	}

	getJobs(siteUuid) {
		this.site = siteUuid
		this.jobs_service.getAllJobs(this.site, this.job_filters).then((res) => {
			if (res.data.length === 0) {
				this.createNewJob();
			}
			else {
				this.loading = true;
				this.jobs = res.data;
				this.choose_site = false;
				this.loading = false;
			}
		}).catch(e => {
			// this.logger.error(e);
			this.loading = false;
		})
		this.loading = false;
	}

	goBack() {
		if (this.choose_site) {
			this.router.navigate(['/jobs/']);
		}
		else {
			this.choose_site = true;
		}
	}

	createNewSite() {
		this.router.navigate(['/jobs/manage-site'], { queryParams: { postcode: this.filters.location }, queryParamsHandling: 'merge' });
	}

	createNewJob() {
		this.router.navigate(['jobs/role/' + this.site + '/create']);
	}

}
