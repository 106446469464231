import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthSessionService } from "@broadstone/auth-session-service";

@Injectable()
export class AuthGuardAdminService {
	constructor(private session: AuthSessionService, private router: Router) {}

	canActivate() {
		if (this.session.isAuthenticated() && this.session.getIsAdmin()) {
			// if user is authenticated
			return true;
		} else {
			//if not authenticated
			this.router.navigate(["login"]);
			return false;
		}
	}
}
