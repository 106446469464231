import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthSessionService } from "@broadstone/auth-session-service";

@Injectable()
export class AuthGuardService {
	constructor(private session: AuthSessionService, private router: Router) {}

	canActivate() {
		if (this.session.isAuthenticated()) {
			return true;
		} else {
			this.router.navigate(["login"]);
			return false;
		}
	}
}
