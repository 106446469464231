import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-invoice-settings',
    templateUrl: './invoice-settings.component.html',
    styleUrls: ['./invoice-settings.component.scss']
})
export class InvoiceSettingsComponent implements OnInit {


    constructor() { }

    ngOnInit() { }



}
