import { Component, OnInit } from '@angular/core';
import { UsersService } from 'src/services/public_api';
import { PopNotificationService } from '@broadstone/pop-notification-service';
import { GlobalHelpers } from '@broadstone/helpers';

@Component({
	selector: 'app-notification-pref',
	templateUrl: './notification-pref.component.html',
	styleUrls: ['./notification-pref.component.scss']
})
export class NotificationPrefComponent implements OnInit {
	my_notif_pref: any = {
		notifications: []
	}
	current_pref: any = [
		{
			title: 'general',
			notif_types: []
		},
		{
			title: 'applications',
			notif_types: []
		},
		{
			title: 'cancellations',
			notif_types: []
		},
		{
			title: 'shifts',
			notif_types: []
		},
		{
			title: 'clock in',
			notif_types: []
		},
		{
			title: 'welfare checks',
			notif_types: []
		},
		{
			title: 'clock out',
			notif_types: []
		},
		{
			title: 'timesheets',
			notif_types: []

		},]

	constructor(
		private user_service: UsersService,
		private popNotif: PopNotificationService,
		private globalHelpers: GlobalHelpers
	) {
		this.getNotifPref();
	}

	ngOnInit() {
	}

	async getNotifPref() { // Get my current notification preferences and save to current_pref array, this will then be disabled on screen
		await this.user_service.getMyNotifications().then((result) => {
			result.forEach(element1 => {
				this.current_pref.forEach(element2 => {
					if (element1.group === element2.title) {
						element2.notif_types.push(
							{
								description: element1.description,
								alias: element1.alias,
								mail: element1.preferences.mail ? true : false,
								fcm: element1.preferences.fcm ? true : false
							}
						)
					}
				})
			})
			this.createNotificationsObject(result)
		}).catch((err) => {
			this.popNotif.createNotification('error', 'There has been a problem.', err);
			// this.logger.error(err);
		})
	}

	createNotificationsObject(current) { // Create object ready to submit to api, this can then be edited when you click the checkboxes
		current.forEach(element1 => {
			this.my_notif_pref.notifications.push({
				alias: element1.alias,
				fcm: element1.preferences.fcm ? 1 : 0,
				mail: element1.preferences.mail ? 1 : 0
			})
		});
	}

	updateNotifPref(notification, alias, type) { // detect changes on the html and update those in your my_notif_pref object
		this.my_notif_pref.notifications.forEach(element => {
			if (element.alias === alias && notification === false) {
				if (type === 'fcm') {
					element.fcm = 1
				}
				else if (type === 'mail') {
					element.mail = 1
				}
			}
			else if (element.alias === alias && notification === true) {
				if (type === 'fcm') {
					element.fcm = 0
				}
				else if (type === 'mail') {
					element.mail = 0
				}
			}
			else {
				return;
			}
		});
		this.saveNotificationPref();
	}

	saveNotificationPref() { // save new preferences to the api
		this.user_service.amendMyNotifications(this.my_notif_pref.notifications).then((result) => {
			this.popNotif.createNotification('success', 'Success', result.messages)
		}).catch((err) => {
			this.popNotif.createNotification('error', 'There has been a problem.', err);
			// this.logger.error(err);
		});
	}

	openLink() {
		this.globalHelpers.open_new_tab('https://intercom.help/orkatech/en/collections/755690-companies');
	}

}
