import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BannerCardComponent } from './banner-card.component';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';



@NgModule({
  declarations: [BannerCardComponent],
  exports:[BannerCardComponent],
  imports: [
	CommonModule,
	NzCarouselModule
  ]
})
export class BannerCardModule { }
