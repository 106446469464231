import { AuthSessionService } from '@broadstone/auth-session-service';
import { ApiService } from '@broadstone/api-service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TimesheetAdapter } from './timesheet.model';
import { DatesHelper } from '@broadstone/helpers';
import { CachingService } from '../caching-service';

@Injectable({
    providedIn: 'root'
})
export class TimesheetService {
    constructor(
        public http: HttpClient,
        public api: ApiService,
        private session: AuthSessionService,
        private adapter: TimesheetAdapter,
        public date_helper: DatesHelper,
		private cachingService: CachingService
    ) { }

    public getTimesheets(page, limit, status?, date?): Promise<any> {
        return new Promise((resolve, reject) => {
            let filter = `company/timesheets?page=${page}`;
            if (status) {
                filter += `&status=${status}`;
            }

            if (date.start && date.end) {
                const start = this.date_helper.adapt_dates('start_at', date.start, this.date_helper);
                const end = this.date_helper.adapt_dates('end_at', date.end, this.date_helper);
                filter += `&shift.start_at=${start}<>${end}`;
            }
            filter += `&limit=${limit}`;
            const url = this.api.getFullUrl(filter);
            const headers = this.api.getDefaultHeaders(this.session.getToken());
			this.cachingService.addCache(url, 30, true);
            return this.http.get(url, { headers })
                .subscribe(
                    (data) => {
                        const adapted_data = data['data'].map(item => {
                            return this.adapter.adaptTimesheet(item);
                        });
                        const result = this.api.adapt_pagination(adapted_data, data);
                        resolve(result);
                    },
                    (error) => reject(this.api.handleErrors(error))
                );
        });
    }

    update_timesheet(uuid, amended_mapped) {
        return new Promise((resolve, reject) => {
            const url = this.api.getFullUrl('company/timesheets/:uuid', { uuid });
            const headers = this.api.getDefaultHeaders(this.session.getToken());
            return this.http.patch(url, amended_mapped, { headers })
                .subscribe(
                    data => resolve(data),
                    error => reject(this.api.handleErrors(error))
                );
        });
    }
    approve_timesheets(timesheets) {
        const url = this.api.getFullUrl('company/timesheets/accept');
        const headers = this.api.getDefaultHeaders(this.session.getToken());
        return new Promise((resolve, reject) => {
            this.http.post(url, timesheets, { headers }).subscribe(
                (data) => {
                    resolve(data);
                },
                (error) => reject(this.api.handleErrors(error))
            )
        });
    }
    decline_timesheets(timesheets) {
        const url = this.api.getFullUrl('company/timesheets/decline');
        const headers = this.api.getDefaultHeaders(this.session.getToken());
        return new Promise((resolve, reject) => {
            this.http.post(url, timesheets, { headers }).subscribe(
                (data) => {
                    resolve(data);
                },
                (error) => reject(this.api.handleErrors(error))
            )
        });
    }

}
