import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FormBuilder, Validators, AbstractControlOptions } from '@angular/forms';
import { PasswordValidation, GlobalHelpers } from '@broadstone/helpers';
import { StaffService } from 'src/services/public_api';
import { PopNotificationService } from '@broadstone/pop-notification-service';
import jwt_decode from 'jwt-decode';
import * as moment from 'moment';
import { PhoneNumberValidator } from 'src/directives/validators/custom-phone-validator.directive';
import { phone } from 'phone';


@Component({
	selector: 'app-create-staff-account',
	templateUrl: './create-staff-account.component.html',
	styleUrls: ['./create-staff-account.component.scss']
})
export class CreateStaffAccountComponent implements OnInit {
	form: any;
	name: any;
	surname: any;
	email_address: any;
	phone_number: any;
	password: any;
	confirm_password: any;
	terms: any;

	save_loading: boolean = false;
	submitted: boolean = false;
	company: any; // {avatar, uuid, title}

	passPattern: string = '^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{6,}$';
	pattern: string = '^[0-9]+$';
	@Output() success: EventEmitter<any> = new EventEmitter();
	@Output() onFaildToken: EventEmitter<any> = new EventEmitter();
	@Input() token: string;

	constructor(
		private formBuilder: FormBuilder,
		private globalHelpers: GlobalHelpers,
		private staff_services: StaffService,
		private popNotif: PopNotificationService,
	) {
		this.form = this.formBuilder.group({
			name: ['', Validators.required],
			surname: ['', Validators.required],
			email_address: [{ value: '', disabled: true }, Validators.required],
			phone_number: ['', [Validators.required, Validators.pattern(this.pattern), PhoneNumberValidator('+44')]],
			password: ['', Validators.compose([Validators.pattern(this.passPattern), Validators.required])],
			confirm_password: ['', Validators.compose([Validators.pattern(this.passPattern), Validators.required])],
			terms: ['', Validators.required]
		},
			{
				validator: PasswordValidation.MatchPassword
			} as AbstractControlOptions);

		this.name = this.form.get('name');
		this.surname = this.form.get('surname');
		this.email_address = this.form.get('email_address');
		this.phone_number = this.form.get('phone_number');
		this.password = this.form.get('password');
		this.confirm_password = this.form.get('confirm_password');
		this.terms = this.form.get('terms');
	}

	ngOnInit() {

	}
	ngOnChanges(): void {
		if (this.token) {
			try {
				const decodedToken = jwt_decode(this.token) as any;

				// Check token expiry
				if (moment.utc().valueOf() / 1000 > decodedToken.exp) {
					throw new Error('This invite has expired');
				}

				// Add token details to page
				this.form.controls['email_address'].patchValue(decodedToken.data.email);
				this.company = decodedToken.data.companyUuid;


			} catch (error) {
				this.onFaildToken.emit('login');
				this.popNotif.createNotification('error', 'There has been a problem.', 'Invite is invalid');
				// this.logger.error(error);
			}
		}
	}

	linkToTerms() {
		this.globalHelpers.open_new_tab('http://www.orka.works/client-terms');
	}

	createStaffMember() {
		let errors = [];
		this.submitted = true;
		if (!this.terms.value) {
			return;
		}
		const form_elements = Object.keys(this.form.controls).map(key => {
			return key;
		});
		for (let key of form_elements) {
			if (this.form.controls[key].invalid) {
				let filled_id = key + '_fill';
				this.globalHelpers.scroll_to_section(filled_id);
				errors.push(this.form.controls[key]);
				break;
			}


		}
		if (errors.length > 0) {
			return;
		}
		const confirmMobile = phone('+44' + this.phone_number.value);
		const newStaff = {
			token: this.token,
			user: {
				name: this.name.value,
				surname: this.surname.value,
				mobileNumber: confirmMobile.phoneNumber,
				password: this.password.value
			}
		};

		const account = {
			email: this.email_address.value,
			password: this.password.value
		};

		this.staff_services.CreateStaff(newStaff).then((result) => {
			this.success.emit({ company: this.company, account: account });
		}).catch((err) => {
			if (err && err['mobile_number']) {
				this.popNotif.createNotification('error', 'There has been a problem.', err['mobile_number']);
				this.globalHelpers.scroll_to_section('phone_number_fill');
			} else if (err && err['email']) {
				this.popNotif.createNotification('error', 'There has been a problem.', err['email']);
			} else if (err) {
				this.popNotif.createNotification('error', 'There has been a problem.', err);
			} else {
				// this.logger.error(err);
				this.popNotif.createNotification('error', 'There has been a problem.', 'We cannot create this staff member.');
			}

		});
	}

}
