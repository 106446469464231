import { AuthSessionService } from '@broadstone/auth-session-service';
import { ApiService } from '@broadstone/api-service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JobAdapter, JobSetup } from './job.model';
import { map } from 'rxjs/operators';
import { SectorsServices } from './sectors-services.model';
import { RequirementsAdapter, Requirements } from './requirements.model';
import { DatesHelper } from '@broadstone/helpers';
import { FiltersServices } from '../filters/filters.service';
import { LoggingService } from '../logging/logging.service';
import { CachingService } from '../caching-service';

@Injectable({
	providedIn: 'root',
})
export class JobsService {
	shiftsPosted: boolean = false;
	constructor(
		public http: HttpClient,
		public api: ApiService,
		private session: AuthSessionService,
		private sectors_services: SectorsServices,
		private adapter: JobAdapter,
		private requirements_adapter: RequirementsAdapter,
		public date_helper: DatesHelper,
		private filters_service: FiltersServices,
		public logger: LoggingService,
		private cachingService: CachingService,
	) { }

	public createJob(site: JobSetup): Promise<any> {
		return new Promise((resolve, reject) => {
			const url = this.api.getFullUrl('companies/jobs');
			const headers = this.api.getDefaultHeaders(this.session.getToken());
			return this.http.post(url, site, { headers }).subscribe(
				(data) =>
					resolve(
						this.adapter.adaptRoleCreation(
							data['data'],
							this.logger,
						),
					),
				(error) => reject(this.api.handleErrors(error)),
			);
		});
	}
	public updateJob(site: JobSetup, jobUuid): Promise<any> {
		return new Promise((resolve, reject) => {
			const url = this.api.getFullUrl('companies/jobs/:jobUuid', {
				jobUuid,
			});
			const headers = this.api.getDefaultHeaders(this.session.getToken());
			return this.http.patch(url, site, { headers }).subscribe(
				(data) =>
					resolve(
						this.adapter.adaptRoleCreation(
							data['data'],
							this.logger,
						),
					),
				(error) => reject(this.api.handleErrors(error)),
			);
		});
	}

	public searchJobs(q): Promise<any> {
		const url = this.api.getFullUrl('companies/jobs?search=:q', { q });
		const headers = this.api.getDefaultHeaders(this.session.getToken());
		return this.http
			.get(url, { headers })
			.pipe(
				map((data) => {
					data['data'] = data['data'].map((item) => {
						return this.adapter.adaptRole(item, this.logger);
					});
					return data;
				}),
			)
			.toPromise();
	}

	public findJobSiteTitle(site, job_title) {
		const url = this.api.getFullUrl(
			'companies/jobs?site=:site&title=:job_title',
			{ site, job_title },
		);
		const headers = this.api.getDefaultHeaders(this.session.getToken());
		return this.http
			.get(url, { headers })
			.pipe(
				map((data) => {
					data['data'] = data['data'].map((item) => {
						return this.adapter.adaptRole(item, this.logger);
					});
					return data;
				}),
			)
			.toPromise();
	}
	public siteJobs(reference, filters?, limit = 99999): Promise<any> {
		const now = this.date_helper.utc_date(new Date());
		filters = this.filters_service.adapt_filters(filters, this.date_helper);
		let filter = `companies/jobs?site=${reference}`;
		if (filters.applicant_name) {
			filter += `&applicant_name=${filters.applicant_name}`;
		}
		if (filters.start_at && filters.end_at) {
			const start = this.date_helper.adapt_dates(
				'start_at',
				filters.start_at,
				this.date_helper,
			);
			const end = this.date_helper.adapt_dates(
				'end_at',
				filters.end_at,
				this.date_helper,
			);
			filter += `&shift.start_at=${start}<>${end}`;
		} else if (filters.active_toggle) {
			filter += `&shift.end_at=>=${now}`;
		} else if (!filters.active_toggle) {
			filter += `&shift.end_at=<=${now}`;
		}
		if (filters.active_toggle !== null) {
			filter += `&active=${filters.active_toggle ? 1 : 0}`;
		}
		filter += `&page=1&limit=${limit}`;

		const url = this.api.getFullUrl(filter);
		const headers = this.api.getDefaultHeaders(this.session.getToken());
		return this.http
			.get(url, { headers })
			.pipe(
				map((data) => {
					data['data'] = data['data'].map((item) => {
						return this.adapter.adaptRole(item, this.logger);
					});
					return data;
				}),
			)
			.toPromise();
	}

	public getAllJobs(reference, filters?, limit = 99999): Promise<any> {
		filters = this.filters_service.adapt_filters(filters, this.date_helper);
		let filter = `companies/jobs?site=${reference}`;
		if (filters.order) {
			filter += `&order=${filters.order}`;
		}
		filter += `&page=1&limit=${limit}`;
		const url = this.api.getFullUrl(filter);
		const headers = this.api.getDefaultHeaders(this.session.getToken());
		return this.http
			.get(url, { headers })
			.pipe(
				map((data) => {
					data['data'] = data['data'].map((item) => {
						return this.adapter.adaptRole(item, this.logger);
					});
					return data;
				}),
			)
			.toPromise();
	}

	public getJobs(
		page,
		filters,
		limit,
		sortBy,
		sortDirection,
		order?,
	): Promise<any> {
		return new Promise((resolve, reject) => {
			const now = this.date_helper.utc_date(new Date().setSeconds(0, 0));
			filters = this.filters_service.adapt_filters(
				filters,
				this.date_helper,
			);
			let filter = `companies/jobs?page=${page}&limit=${limit}`;
			if (filters.applicant_name) {
				filter += `&applicant_name=${filters.applicant_name}`;
			}
			if (filters.client_name) {
				filter += `&client=${filters.client_name}`;
			}
			if (filters.location) {
				filter += `&location=${filters.location}`;
			}
			if (filters.site_reference) {
				filter += `&reference=${filters.site_reference}`;
			}
			if (filters.start_at && filters.end_at) {
				const start = this.date_helper.adapt_dates(
					'start_at',
					filters.start_at,
					this.date_helper,
				);
				const end = this.date_helper.adapt_dates(
					'end_at',
					filters.end_at,
					this.date_helper,
				);
				filter += `&shift_start_at=${start}<>${end}`;
			} else if (filters.active_toggle) {
				filter += `&shift_end_at=>=${now}`;
			} else if (!filters.active_toggle) {
				filter += `&shift_end_at=<=${now}`;
			}

			if (filters.assigned_sites !== null) {
				filter += `&assigned_to=${filters.assigned_sites ? 1 : 0}`;
			}
			if (filters.job_type) {
				filter += `&smart_staff=${filters.job_type == 'SmartStaff' ? 1 : 0
					}`;
			}
			if (sortBy && sortDirection) {
				filter += `&sort_by=${sortBy}&order=${sortDirection}`;
			}
			if (sortBy && order) {
				filter += `&sort_by=${sortBy}&order=${order}`;
			}

			const url = this.api.getFullUrl(filter);
			const headers = this.api.getDefaultHeaders(this.session.getToken());
			// If admin user don't want to cache
			if (!this.session.getIsAdmin()) {
				this.cachingService.addCache(url, 30, true);
			}

			return this.http.get(url, { headers }).subscribe(
				(data) => {
					const adapted_data = data['data'];
					const result = this.api.adapt_pagination(
						adapted_data,
						data,
					);
					resolve(result);
				},
				(error) => reject(this.api.handleErrors(error)),
			);
		});
	}

	public getAllSites(page, filters, limit): Promise<any> {
		return new Promise((resolve, reject) => {
			const now = this.date_helper.utc_date(new Date());
			filters = this.filters_service.adapt_filters(
				filters,
				this.date_helper,
			);
			let filter = `company/sites?page=${page}&limit=${limit}`;
			if (filters.location) {
				filter += `&location=${filters.location}`;
			}
			if (filters.order) {
				filter += `&order=${filters.order}`;
			}
			if (filters.sort_by) {
				filter += `&sort_by=${filters.sort_by}`;
			}
			if (filters.assigned_sites !== null) {
				filter += `&assigned_sites_only=${filters.assigned_sites ? 1 : 0
					}`;
			}
			const url = this.api.getFullUrl(filter);
			const headers = this.api.getDefaultHeaders(this.session.getToken());
			return this.http.get(url, { headers }).subscribe(
				(data) => {
					const adapted_data = data['data'];
					const metaData = { ...data['meta'] };
					const result = {
						data: adapted_data,
						metaData,
					};

					resolve(result);
				},
				(error) => reject(this.api.handleErrors(error)),
			);
		});
	}

	public getJob(uuid, cache: boolean = true) {
		const url = this.api.getFullUrl('companies/jobs/:uuid', { uuid });
		const headers = this.api.getDefaultHeaders(this.session.getToken());

		if (cache) {
			this.cachingService.addCache(url, 30, true);
		} else {
			this.cachingService.removeCache(url);
		}
		return this.http
			.get(url, { headers })
			.pipe(
				map((data) => {
					return this.adapter.adaptRole(data['data'], this.logger);
				}),
			)
			.toPromise();
	}
	public deleteJob(uuid, remarks) {
		return new Promise((resolve, reject) => {
			const url = this.api.getFullUrl('companies/jobs/:uuid', { uuid });
			const headers = this.api.getDefaultHeaders(this.session.getToken());
			return this.http.post(url, remarks, { headers }).subscribe(
				(data) =>
					resolve(
						this.adapter.adaptRoleCreation(
							data['data'],
							this.logger,
						),
					),
				(error) => reject(this.api.handleErrors(error)),
			);
		});
	}

	public getJobLink(uuid) {
		const url = this.api.getFullUrl('job/:uuid/share', { uuid });
		const headers = this.api.getDefaultHeaders(this.session.getToken());
		return this.http
			.get(url, { headers })
			.pipe(
				map((data) => {
					return data['data'];
				}),
			)
			.toPromise();
	}

	public getSectors() {
		const url = this.api.getFullUrl('company/sectors');
		const headers = this.api.getDefaultHeaders(this.session.getToken());
		this.cachingService.addCache(url, 300);
		return this.http
			.get(url, { headers })
			.pipe(
				map((data) => {
					return data['data'].map((sector) => {
						return this.sectors_services.adapt_sectors(sector);
					});
				}),
			)
			.toPromise();
	}

	public getIndustries() {
		const url = this.api.getFullUrl('company/industries');
		const headers = this.api.getDefaultHeaders(this.session.getToken());
		this.cachingService.addCache(url, 300);
		return this.http
			.get(url, { headers })
			.pipe(
				map((data) => {
					return data['data'].map((industry) => {
						return this.sectors_services.adapt_industries(industry);
					});
				}),
			)
			.toPromise();
	}

	public getRequirements(): Promise<any> {
		const url = this.api.getFullUrl('qualifications/requirements');
		const headers = this.api.getDefaultHeaders(this.session.getToken());
		this.cachingService.addCache(url, 300);
		return this.http
			.get(url, { headers })
			.pipe(
				map((data: Array<Requirements>) =>
					data['data'].map((item) => {
						return this.requirements_adapter.adapt_requrements(
							item,
						);
					}),
				),
			)
			.toPromise();
	}

	public getClientRequirements(): Promise<any> {
		const url = this.api.getFullUrl('company/client-requirements');
		const headers = this.api.getDefaultHeaders(this.session.getToken());
		this.cachingService.addCache(url, 300);
		return this.http
			.get(url, { headers })
			.pipe(
				map((data: Array<any>) =>
					data['data'].map((item) => {
						return this.requirements_adapter.adapt_client_requirements(
							item,
						);
					}),
				),
			)
			.toPromise();
	}

	public getPay(payment): Promise<any> {
		return new Promise((resolve, reject) => {
			const url = this.api.getFullUrl('companies/jobs/pay');
			const headers = this.api.getDefaultHeaders(this.session.getToken());
			return this.http.post(url, payment, { headers }).subscribe(
				(data) => resolve(this.adapter.adapt_pay(data)),
				(error) => reject(this.api.handleErrors(error)),
			);
		});
	}

	public shareJob(jobUuid, workerUUids): Promise<any> {
		return new Promise((resolve, reject) => {
			const url = this.api.getFullUrl(
				'company/company/workers/:jobUuid/message',
				{ jobUuid },
			);
			const headers = this.api.getDefaultHeaders(this.session.getToken());
			return this.http.post(url, workerUUids, { headers }).subscribe(
				(data) => resolve(data),
				(error) => reject(this.api.handleErrors(error)),
			);
		});
	}

	public getPayRates(): Promise<any> {
		return new Promise((resolve, reject) => {
			const url = this.api.getFullUrl('companies/jobs/pay/rates');
			const headers = this.api.getDefaultHeaders(this.session.getToken());
			this.cachingService.addCache(url, 300);
			return this.http.get(url, { headers }).subscribe(
				(data) => resolve(this.adapter.adapt_pay_rate(data)),
				(error) => reject(this.api.handleErrors(error)),
			);
		});
	}

	public getJobReference(reference, page): Promise<any> {
		const url = this.api.getFullUrl(
			`companies/jobs?page=${page}&reference=${reference}`,
		);
		const headers = this.api.getDefaultHeaders(this.session.getToken());
		return this.http
			.get(url, { headers })
			.pipe(
				map((data: any[]) =>
					data['data'].map((item) => {
						return this.adapter.adaptRole(item, this.logger);
					}),
				),
			)
			.toPromise();
	}
}
