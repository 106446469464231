import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthSessionService } from '@broadstone/auth-session-service';
import { AuthService, CompaniesService, LocalHelpers } from 'src/services/public_api';

@Component({
	selector: 'app-companies',
	templateUrl: './companies.component.html',
	styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent implements OnInit {
	filters: any = {
		title: ''
	}
	loading: boolean = true;
	companies: any = [];
	page: number = 1;
	totalPages: number;
	@Output() leavePage: EventEmitter<any> = new EventEmitter();
	constructor(
		private session: AuthSessionService,
		private router: Router,
		private companyService: CompaniesService,
		private auth: AuthService,
		private localHelpers: LocalHelpers
	) { }

	ngOnInit(): void {
		this.getCompanies();
	}


	logout() {
		this.session.logout();
		this.auth.logout().catch(err => {
			console.log(err)
		});
		this.router.navigate(['/login']);
	}

	createCompany() {
		this.router.navigate(['/admin/company-setup']);
	}

	searchApi() {
		this.page = 1;
		this.loading = true;
		this.getCompanies();
	}

	getCompanies() {
		this.companyService.getAllCompanies(this.page, 10, this.filters.title).then((result: any) => {
			this.companies = result.data;
			this.totalPages = result.meta.last_page;
			this.page = result.meta.current_page;
			this.loading = false;
		}).catch((err) => {
			// this.logger.error(err);
			this.loading = false;
		});
	}

	companySelected(uuid, company, logo) {
		this.leavePage.emit();
		this.session.setAdminHeader({ 'x-broadstone-company': uuid, 'name': company, 'avatar': logo });
		this.router.navigate(['/dashboard']);
		this.localHelpers.intercomBobbleVisibility('block');


	}

	pageChange(e) {
		this.loading = true;
		this.companies.length = 0;
		this.page = e;
		this.getCompanies();
		window.scroll(0, 0);
	}
}
