import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClibButtonModule } from '@broadstone/clib-button';
import { ApplicationGlobals } from '@broadstone/helpers';
import { WorkerRowComponent } from './worker-row.component';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';



@NgModule({
    declarations: [WorkerRowComponent],
    exports: [WorkerRowComponent],
    imports: [
        CommonModule,
        NzAvatarModule,
		NzToolTipModule,
        ApplicationGlobals,
        ClibButtonModule
    ]
})
export class WorkerRowModule { }
