<div class="notifications-wrapper">
    <div class="notifications-header">
        <h2 class="b-font-demibold">Notification Preferences</h2>
        <p>Setup your notification preferences so you are always in the loop of what’s happening</p>
    </div>
    <div class="notifications-body" *ngFor="let pref of current_pref">
        <div class="section-header-wrapper">
            <h4 class="b-font-demibold">{{pref.title | titlecase}}</h4>
            <p class="b-Eh5 b-font-demibold">email</p>
            <p class="b-Eh5 b-font-demibold" *ngIf="pref.title != 'general' && pref.title != 'timesheets'">app <img
                    src="./assets/icons/toolTip.svg" nz-popover [nzPopoverContent]="fcmPopover" nzPopoverTrigger="hover"
                    nzPlacement="right">
            </p>
        </div>
        <ng-template #fcmPopover>
            <div class="fcmPopover">
                <h2 class="b-primary">App Notifications</h2>
                <p class="b-Eh4">These are the notifications you are subscribed to for the <span
                        class="highlight b-font-demibold">Orka Works Manager mobile
                        app.</span></p>
                <p class="b-Eh4 highlight b-font-demibold">
                    To find out more about the app <span (click)="openLink()" class="b-link">click here</span>
                </p>

            </div>
        </ng-template>
        <div class="section-wrapper" *ngFor="let type of pref.notif_types">
            <p>{{type.description}}</p>
            <div class="checkbox-div">
                <label class="b-checkbox">
                    <input type="checkbox" (click)="updateNotifPref(type.mail, type.alias, 'mail')"
                        [(ngModel)]="type.mail" name="mail">
                    <span class="checkmark"></span>
                </label>
            </div>
            <div class="checkbox-div">
                <label class="b-checkbox"
                    *ngIf="pref.title != 'general' && pref.title != 'timesheets' && type.alias != 'shifts_about_to_expire'">
                    <input type="checkbox" (click)="updateNotifPref(type.fcm, type.alias, 'fcm')" [(ngModel)]="type.fcm"
                        name="fcm">
                    <span class="checkmark"></span>
                </label>
            </div>
        </div>
    </div>
</div>