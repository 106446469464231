<div class="basic-search-wrapper">
    <img src="assets/icons/search-icon.svg" />
    <input nzPlacement="bottom" nz-popover [(nzPopoverVisible)]="show_sugestions" nzPopoverTrigger="null"
        [nzPopoverContent]="contentSugestionsTemplate" class="b-input b-input-white" [style.width]="element_width"
        type="text" [(ngModel)]="value" (keyup)="checkApi()" (blur)="onBlur($event)" #input="ngModel" [id]='myid'
        [required]=required [placeholder]="placeholder">
    <app-loading-spinner *ngIf="loading" color="color_grey_4" size="small" class="input-loading"></app-loading-spinner>
</div>

<ng-template #contentSugestionsTemplate>
    <div [style.width]="element_width" class="b-result-box">
        <ul>
            <li *ngFor="let suggestion of data_array" (click)="setValue(suggestion)">
                <div class="suggestion-li-wrapper">
                    <div class="suggestion-info">
                        <nz-avatar *ngIf="avatar" class="suggestion-avatar" [nzText]='suggestion.title[0]'
                            [nzSrc]="suggestion.avatar" nzSize="small"></nz-avatar>
                        <p>{{suggestion.title}}</p>
                    </div>
                    <p *ngIf="suggestion.subtitle">{{suggestion.subtitle}}</p>
                </div>
            </li>
        </ul>
    </div>
</ng-template>