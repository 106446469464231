import { trigger, style, animate, transition } from '@angular/animations';

export class AppAnimations {
    public static site_job_animation =
        trigger(
            'jobExpand', [
                transition(':enter', [
                    style({ transform: 'translateX(-2%)', opacity: 0 }),
                    animate('.1s ease-in-out', style({ transform: 'translateX(0)', opacity: 1 }))
                ]),
                transition(':leave', [
                    style({ transform: 'translateX(0)', opacity: 1 }),
                    animate('.1s ease-in-out', style({ transform: 'translateX(-2%)', opacity: 0 }))
                ])
            ]
        );
    public static fade_in_out =
        trigger(
            'fadeInOut', [
                transition(':enter', [
                    style({ opacity: 0 }),
                    animate(600, style({ opacity: 1 }))
                ]),
                transition(':leave', [
                    style({ opacity: 1 }),
                    animate(0, style({ opacity: 0 }))
                ])
            ]
        );
    public static expand_animation =
        trigger(
            'expand_y', [
                transition(':enter', [
                    style({ transform: 'translateY(-5%)', opacity: 0 }),
                    animate(0, style({ transform: 'translateY(0)', opacity: 1 }))
                ]),
                transition(':leave', [
                    style({ transform: 'translateY(0)', opacity: 1 }),
                    animate(200, style({ transform: 'translateY(-5%)', opacity: 0 }))
                ])
            ]
        );

}
