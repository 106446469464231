<div class="invoice-settings-container">
    <div class='header'>
        <h1>Invoice Separators</h1>
        <p>Setup invoice separators here and you can
            easily attach them to a job you post for organised
            invoicing</p>
    </div>


    <div class='form-section'>
        <div class='input po-number'>
            <label>Invoice Separator</label>
            <input type="text" class='b-input po-num' [(ngModel)]="poNumberInput"
                [disabled]="buttonLabel === 'Update' ? true : false">
        </div>

        <div class="form-lower">
            <div class='input description'>
                <label>Description<span class='optional'> (Optional)</span> </label>
                <input type="text" class='b-input input-description' [(ngModel)]="desciptionInput">
            </div>

            <div class="button">
                <app-clib-button (pressAction)="addPoNumber()" [label]="buttonLabel"></app-clib-button>
            </div>
        </div>

    </div>

    <div class="search-container">
        <div class="search">
            <app-search-input placeholder='Search invoice separators' [(inputModel)]="searchNumber" debounce='500'
                (onKey)="search()">
            </app-search-input>
        </div>
    </div>


    <div class="po-list-section" (clickOutside)="onClickedOutside($event)" [exclude]="'input, button'">
        <div class="po-header-section">
            <div class="po-col">
                <label class="custom-text">INVOICE SEPARATOR</label>
            </div>
            <div class="po-col">
                <label class="custom-text">DESCRIPTION</label>
            </div>
            <div class="po-col">
            </div>
        </div>
        <div *ngFor="let item of allPos" [class]="item.selected ? 'row selected' : 'row'">
            <div class='po-list'>
                <div class="po-col">
                    <div class="tag tag-light">
                        {{item.purchase_order}}
                    </div>

                </div>
                <div class="po-col">
                    <p>{{item.description}}</p>
                </div>
                <div *ngIf="item?.sites?.length === 0" class="po-col icons">
                    <div (click)="handleEdit(item)">
                        <img src="/assets/icons/edit-icon.svg">
                    </div>
                    <div (click)="handleDelete(item)">
                        <img src="/assets/icons/delete.svg">
                    </div>
                </div>

                <div *ngIf="item?.sites?.length > 0" class="po-col icons">
                    <p class="custom-text">IN USE</p>

                </div>
            </div>


        </div>
    </div>



    <div class="pagination">
        <nz-pagination nzHideOnSinglePage="true" class="b-nz-pagination" [nzSize]="'medium'" [nzPageIndex]="page"
            [nzPageSize]="1" [nzTotal]="totalPages" (nzPageIndexChange)="changePage($event)">
        </nz-pagination>
    </div>

</div>