import { Injectable } from "@angular/core";



@Injectable()

export class LocalHelpers {

	intercomBobbleVisibility(display: 'none' | 'block') {
		const intercomElements1 = document.getElementsByClassName('intercom-lightweight-app') as HTMLCollectionOf<HTMLElement>;
		const intercomElements2 = document.getElementsByClassName('intercom-app') as HTMLCollectionOf<HTMLElement>;
		for (let i = 0; i < intercomElements1.length; i++) {
			intercomElements1[i].style.display = display;
		}

		for (let i = 0; i < intercomElements2.length; i++) {
			intercomElements2[i].style.display = display;
		}
	}
}