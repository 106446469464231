<section>
	<div class="create-job-container">
		<h2 *ngIf="choose_site" class="b-font-demibold">Where is the job?</h2>
		<h2 *ngIf="!choose_site" class="b-font-demibold">{{site_address?.client}}

		</h2>
		<p *ngIf="!choose_site" class="sub-heading">
			<span *ngIf="site_address.line1">{{site_address?.line1}}, </span><span
				*ngIf="site_address?.city">{{site_address?.city}}, </span>{{site_address?.postcode}}
		</p>


		<div class="row-spacing">
			<label *ngIf="choose_site">Search from your existing sites</label>

			<p *ngIf="choose_site" class="client-visible-info">
				Let’s check if the site already exists by searching the postcode (eg. M33AQ)<br> If it doesn’t exist,
				you
				can create a new one!
			</p>
			<p *ngIf="!choose_site" class="choose-job sub-heading">
				Select an existing job to add shifts to. If it doesn’t already exist, create a new job for this site.
			</p>
		</div>
		<div class="title-container">

			<div *ngIf="choose_site" class="search-bar-area mobile-hide">
				<app-search-input debounce='500' placeholder='Search by postcode' (onKey)="checkApi()"
					[(inputModel)]="filters.location"></app-search-input>
			</div>
			<div *ngIf="choose_site" class="switch-wrapper">
				<label class="custom-text" for="fname"><span *ngIf="filters.assigned_sites">My Sites</span><span
						*ngIf="!filters.assigned_sites">All Sites</span></label>
				<nz-switch class="nz-override-switch" [(ngModel)]="filters.assigned_sites"
					(ngModelChange)="assignedSitesChange($event)">
				</nz-switch>
			</div>
		</div>
		<nz-skeleton *ngIf="loading" class="nz-cites-loading" [nzParagraph]="{ rows: 5 }" [nzTitle]="false"
			[nzActive]="true">
		</nz-skeleton>

		<div *ngIf="choose_site">
			<div class="row-spacing" *ngFor="let oneSite of all_sites">
				<div class="site-details-container" *ngIf="!loading">
					<div class="details-container">
						<p class="address">{{oneSite?.client?.name}}<span *ngIf="oneSite?.client?.name">,</span>
							{{oneSite?.location?.address_1}},
							{{oneSite?.location?.city}}, {{oneSite?.location?.postcode}}</p>
						<p class="client-visible-info" *ngIf="oneSite?.last_shift_created_at">
							Last updated:
							{{oneSite?.last_shift_created_at | dateFormat:'DD/MM/YY' : oneSite?.location?.timezoneId}}
						</p>
						<p class="client-visible-info" *ngIf="!oneSite?.last_shift_created_at">
							Last updated:
							{{oneSite?.updated_at | dateFormat:'DD/MM/YY' : oneSite?.location?.timezoneId}}
						</p>
					</div>
					<img class="img-position" *ngIf="oneSite.source ==='timegate'" src="./assets/icons/timegate-icon.svg" />
					<div class="schedule-container">
						<app-clib-button *ngIf="!oneSite.source" label="Select"
							(pressAction)="siteSelected(oneSite?.uuid, oneSite?.client?.name, oneSite?.location)">
						</app-clib-button>
						
					</div>

				</div>
			</div>
			<div class="pagination">
				<nz-pagination nzSimple nzHideOnSinglePage="true" class="b-nz-pagination" [nzSize]="'medium'"
					[nzPageIndex]="page" [nzPageSize]="1" [nzTotal]="total_pages"
					(nzPageIndexChange)="pageChange($event)">
				</nz-pagination>
			</div>
		</div>

		<div *ngIf="choose_site && all_sites.length === 0 && filters.location" class="no-data">
			<h4 class="b-light">No sites exist at <strong>{{filters.location | uppercase}}</strong> yet, click the
				button below to
				create a new site.
			</h4>

		</div>



		<div *ngIf="!choose_site">
			<div class="row-spacing" *ngFor="let job of jobs">
				<div class="site-details-container" *ngIf="!loading">

					<div>
						<p class="address">{{job?.vacancy_service?.title}}</p>
						<p class="job-details">{{job?.title}}</p>
						<p class="job-details smart-staff" *ngIf="job?.smart_staff"><img
								src="./assets/icons/medium.svg">
							Temp to Perm Job</p>
						<p class="job-details smart-staff" *ngIf="!job?.smart_staff">
							<img src="./assets/icons/adhoc.svg" height="16px" width="16px"> Ad Hoc Job
						</p>
						<p class="job-details">
							Total Shifts Posted: {{job?.shifts_count}}</p>
						<p class="client-visible-info" *ngIf="job?.last_shift_created_at">
							Last updated:
							{{job?.last_shift_created_at | dateFormat:'DD/MM/YY' : oneSite?.location?.timezoneId}}
						</p>
						<p class="client-visible-info" *ngIf="!job?.last_shift_created_at">
							Last updated:
							{{job?.updated_at | dateFormat:'DD/MM/YY' : oneSite?.location?.timezoneId}}
						</p>

					</div>

					<div class="schedule-container">
						<app-clib-button label="View Schedule" type="outline" (pressAction)="openSchedule(job?.uuid)">
						</app-clib-button>
						<app-clib-button id="shifts" label="Add Shifts" (pressAction)="jobSelected(job?.uuid)">
						</app-clib-button>
					</div>
				</div>

			</div>
		</div>


		<hr>
		<div class="row-spacing">
			<div class="group-buttons">
				<app-clib-button label="Back" type="outline" (pressAction)="goBack()">
				</app-clib-button>
				<app-clib-button *ngIf="choose_site" label="Create new site" (pressAction)="createNewSite()">
				</app-clib-button>
				<app-clib-button *ngIf="!choose_site" label="Create new job" (pressAction)="createNewJob()">
				</app-clib-button>
			</div>
		</div>
	</div>
</section>