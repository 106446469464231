<form #form="ngForm" class="post-job-area">
	<div *ngIf="!site_review">

		<div class="create-site-header">
			<h2 *ngIf="!siteUuid" class="b-font-demibold">Create a
				Job Site</h2>
			<h2 *ngIf="siteUuid" class="b-font-demibold">Edit a
				Job Site</h2>
		</div>

		<div>

			<div *ngIf="addressForm" [@fadeInOut] class="form-card review-site">
				<div class="address-wrapper">
					<div id="postcode-id" class="input-underlined row-spacing-custom" id="custom-width">
						<input class=" b-input b-input-white no-padding" [(ngModel)]='site_object.location.postcode'
							placeholder="Enter postcode *" (blur)="searchMapByPostcode()" required #postcode="ngModel"
							type="text" name="job-postcode" id='job-postcode'>
					</div>
					<div *ngIf="(postcode.touched || submitted) && postcode.invalid && postcode.errors.required">
						<p class="client-width error-box-small">Please fill in this field.</p>
					</div>
					<div *ngIf="!postcodeValid">
						<p class="client-width error-box-small">Please enter a valid postcode.</p>
					</div>
					<div class="input-underlined row-spacing-custom" id="custom-width">
						<input class="b-input b-input-white  no-padding" [value]="site_object.location.name_number"
							#name_number="ngModel" [(ngModel)]="site_object.location.name_number" type="text"
							name="job-namenum" placeholder="Building number/name" id='job-namenum'>
					</div>
					<div id="address-id" class="input-underlined row-spacing-custom" id="custom-width">
						<input class=" b-input b-input-white  no-padding" [(ngModel)]='site_object.location.address_1'
							type="text" name="job-Address" id='job-Address' required #address1="ngModel"
							placeholder="Address line 1 *">
					</div>
					<div *ngIf="(address1.touched || submitted) && address1.invalid && address1.errors.required">
						<p class="client-width error-box-small">Please fill in this field.</p>
					</div>
					<div class="input-underlined row-spacing-custom" id="custom-width">
						<input class=" b-input b-input-white  no-padding" [(ngModel)]='site_object.location.address_2'
							type="text" #address2="ngModel" placeholder="Address line 2" name="job-Address2"
							id='job-Address2'>
					</div>
					<div id="city-id" class="input-underlined row-spacing-custom" id="custom-width">
						<input class=" b-input b-input-white  no-padding" [(ngModel)]='site_object.location.city'
							required #city="ngModel" type="text" name="job-city" id='job-city'
							placeholder="Town/City *">
					</div>
					<div *ngIf="(city.touched || submitted) && city.invalid && city.errors.required">
						<p class="client-width error-box-small">Please fill in this field.</p>
					</div>
					<div id="county-id" class="input-underlined row-spacing-custom">
						<input class="b-input b-input-white  no-padding" required #county="ngModel"
							[(ngModel)]="site_object.location.county" type="text" name="job-county" id='job-county'
							placeholder="County *">
					</div>

					<div *ngIf="(county.touched || submitted) && county.invalid && county.errors.required">
						<p class="client-width error-box-small">Please fill in this field.</p>
					</div>


					<p class="required">* Required fields</p>
				</div>
				<div class="edit-wrapper">
					<div class="no-postcode-wrapper" *ngIf="site_object.location.postcode && postcodeValid">
						<div class="postcode">
							<app-geofencing-map *ngIf="lat && lng" [lat]="lat" [lng]="lng"
								[radius]="this.site_object?.clock_distance_limit" [metricUnits]="metricUnits">
							</app-geofencing-map>
						</div>

					</div>
					<div class="no-postcode-wrapper" *ngIf="!site_object.location.postcode || !postcodeValid">
						<div class="no-postcode">
							<img class="map-pin" src="./assets/icons/mapLocation.svg">
						</div>

					</div>

					<div class="switch-wrapper" *ngIf="site_object.location.postcode">
						<label class="custom-text"><span *ngIf="metricUnits">km</span><span
								*ngIf="!metricUnits">miles</span></label>
						<nz-switch class="nz-override-switch" [(ngModel)]="metricUnits"
							(ngModelChange)="onSwitchChange($event)" name="unitConverter" [disabled]="noFixedArea">
						</nz-switch>
					</div>
					<div class="slider-wrapper" *ngIf="site_object.location.postcode">
						<div nz-popover [nzPopoverContent]="radiusPopover" nzPopoverTrigger="hover" nzPlacement="right"
							style="cursor: help;">
							<p *ngIf="site_object.clock_distance_limit > 0"
								class="radius-text b-primary b-font-demibold">
								{{site_object.clock_distance_limit}} <span *ngIf="!metricUnits">mile</span><span
									*ngIf="metricUnits">km</span> clock in/out
								area</p>
							<p *ngIf="site_object.clock_distance_limit === 0"
								class="radius-text b-primary b-font-demibold">
								No clock in/out
								area</p>
						</div>
						<nz-slider [(ngModel)]="site_object.clock_distance_limit" [nzMax]="max" [nzMin]="0.1"
							[nzStep]="0.1" name="radius-distance" [nzTipFormatter]="null" tooltipVisible="false"
							[disabled]="noFixedArea">
						</nz-slider>
						<div class="values">
							<p>0.1 <span *ngIf="!metricUnits">miles</span><span *ngIf="metricUnits">km</span></p>
							<p><span *ngIf="!metricUnits">3 miles</span><span *ngIf="metricUnits">5 km</span></p>
						</div>
						<div class="checkbox-wrapper">
							<label class="b-checkbox">
								<input type="checkbox" [checked]="noFixedArea" (click)="selectMobileResponse()">
								<span class="checkmark"></span>
							</label>
							<p class="no-fixed">No Fixed Area (Mobile Response)</p>
						</div>

					</div>

					<ng-template #radiusPopover>
						<div class="radiusPopover">
							<h3 class="b-primary b-font-demibold">Clock In-Out Area</h3>
							<p class="b-Eh4 popover-text">This is the area where workers will be able to clock in and
								out of their shift successfully. For larger sites and mobile response jobs it is
								recommended to increase the area appropriately.
							</p>
						</div>
					</ng-template>


				</div>


			</div>

			<!-- SEARCH  -->
			<div>
				<div class="row-spacing client-width" *ngIf="!addressForm">
					<label for="job-suggest">Search site address</label>
					<p class="client-visible-info">
						Search the postcode of the job site, select one of the suggestions and fill in the full address
						once selected.
					</p>
					<p class="client-visible-info b-font-demibold">Workers
						will only see the full address once you have accepted them for the role.</p>
					<app-input-suggestions required='true' value_input=true (valueChange)="getLocation($event)"
						(valueSelected)="valueSelected($event)" [loading]="suggestions_loading" element_width="589px"
						[data_array]="gPlacesSuggestions" myid="job-suggest" placeholder='eg. M3 3AQ'
						[value]='postcode'>
					</app-input-suggestions>
					<div [style.width]="'589px'">
						<p class="manualAddressMessage" (click)="addressFormControl('toggle')">Click here to
							manually
							enter
							your
							address</p>
					</div>
				</div>

			</div>
		</div>

		<div>

			<div>
				<div id="client-id" class="row-spacing">
					<label for="job-client">Client name *</label>
					<p class="client-visible-info">Start typing your end client’s name and either select a client that
						already exists or create a new one.</p>
					<app-input-suggestions required='true' [value]="site_object.client_title" element_width="589px"
						[data_array]="client_suggestions" [loading]="client_loading"
						(valueSelected)="setClientName($event)" (valueChange)="getClients($event)" myid="job-client"
						placeholder='Enter Client Name'>
					</app-input-suggestions>
					<div *ngIf="client_postcode_lnk">
						<p class="error-box client-width">Looks like this job site already exists.<br> <a
								(click)="openReference()">Click here to view</a></p>
					</div>
					<div *ngIf="!site_object.client_title && client_touched">
						<p class="error-box client-width">Please fill in this field.</p>
					</div>

				</div>
				<div class="row-spacing">
					<label>Make this client name visible to workers? *</label>

					<p *ngIf="site_object.client_visible" class="client-visible-info">By selecting <strong>yes</strong>
						workers will be
						able to view the client name
						<strong>before</strong> applying, otherwise this will be hidden.
					</p>
					<p *ngIf="!site_object.client_visible" class="client-visible-info">Workers will only be able to view
						the client
						name <strong>after</strong> they are approved for their shift(s).</p>
					<div class="checkbox-wrapper">
						<label class="b-checkbox">Yes
							<input
								(click)="site_object.client_visible = true; globalHelpers.change_check_box($event, 'yes','show_client_name', 'hide_client_name', this)"
								[checked]="show_client_name" name="show_client_name" type="checkbox">
							<span class="checkmark"></span>
						</label>
						<label class="b-checkbox">No
							<input
								(click)="site_object.client_visible = false; globalHelpers.change_check_box($event, 'no', 'show_client_name', 'hide_client_name', this)"
								[checked]="hide_client_name" name="hide_client_name" type="checkbox">
							<span class="checkmark"></span>
						</label>
					</div>
				</div>
				<div class="row-spacing">
					<label>Please select the site type *</label>
					<p class="client-visible-info">Choose the option which applies to all or most of the work carried out at this site.
					</p>
					<div class="checkbox-wrapper">
						<div *ngFor="let type of siteTypes">
						<label class="b-checkbox custom-check">{{type.type}}
							<input
								(click)="selectSiteType(type.id)"
								[checked]="type.selected"  type="checkbox">
							<span class="checkmark"></span>
						</label>
					</div>
					</div>
				</div>
				<div class="row-spacing">
					<label>Is car parking available on-site? *</label>
					<div class="checkbox-wrapper">
						<label class="b-checkbox">Yes
							<input
								(click)="site_object.car_parking_available = true; globalHelpers.change_check_box($event, 'yes','show_parking', 'hide_parking', this)"
								[checked]="show_parking" type="checkbox" name="show_parking">
							<span class="checkmark"></span>
						</label>
						<label class="b-checkbox">No
							<input
								(click)="site_object.car_parking_available = false; globalHelpers.change_check_box($event, 'no', 'show_parking', 'hide_parking', this)"
								[checked]="hide_parking" type="checkbox" name="hide_parking">
							<span class="checkmark"></span>
						</label>
					</div>
				</div>
				<div class="row-spacing">
					<label>Is on-site training required for workers at this site? *</label>
					<p class="client-visible-info">If you select <strong>yes</strong>, you will only be able to book
						site-trained
						workers.
					</p>
					<div class="checkbox-wrapper">
						<label class="b-checkbox">Yes
							<input
								(click)="site_object.training_required = true; globalHelpers.change_check_box($event, 'yes','show_training_required', 'hide_training_required', this)"
								[checked]="show_training_required" type="checkbox" name="show_training_required">
							<span class="checkmark"></span>
						</label>
						<label class="b-checkbox">No
							<input
								(click)="site_object.training_required = false; globalHelpers.change_check_box($event, 'no', 'show_training_required', 'hide_training_required', this)"
								[checked]="hide_training_required" type="checkbox" name="hide_training_required">
							<span class="checkmark"></span>
						</label>
					</div>
				</div>
				<div class="row-spacing">
					<label for="site-instructions">Additional Site Information </label>
					<p class="client-visible-info">Use the space below to provide additional instructions for workers to
						make their arrival and experience on site as seamless as possible. You can also include images
						such as photos of the site or a map.</p>

					<textarea [(ngModel)]="site_object.instructions" name="instructions" id="site-instructions"
						placeholder="Where is the entrance? Who should they ask for upon arrival? Are there any codes needed?..."
						class="b-textarea b-textarea-white"></textarea>
					<div class="img-container">
						<div *ngFor="let image of imagesrc; let i = index">
							<div *ngIf="!image.src" class="blank-overlay cursor-pointer" (click)="file.click()">
								<img src="./assets/icons/imgUpload.svg">
								<p>Upload image</p>
								<input class="hide" #file type="file" accept="image/*" (change)="getImg($event, i)">
							</div>
							<div *ngIf="image.src" class="image-overlay">
								<img class="eye cursor-pointer" src="./assets/icons/eye.svg"
									(click)="openImageViewer(image.src)">
								<img class="bin cursor-pointer" src="./assets/icons/bin.svg" (click)="removeImage(i)">
							</div>

							<div class="uploaded-img-container">
								<img *ngIf="image.src" [src]="image.src ? image.src : ''">

							</div>
						</div>
					</div>
				</div>

				<lib-modal [show_modal]="modalVisible" [close_icon]='true' (closeModal)="closeModal()" [width]="1000">
					<div class="img-display-container">
						<img [src]="modalImage">
					</div>
				</lib-modal>

				<div class="row-spacing">
					<label>Site Contact Name</label>
					<p class="client-visible-info">If there is a specific person that workers should contact
						regarding questions or issues for this site, please add it below.</p>
					<input type="text" class="b-input b-input-white custom-width" placeholder="Enter name"
						#control_name="ngModel" [(ngModel)]="site_object.control_room_name" name="control_room_name">
				</div>
				<div class="row-spacing">
					<label>Site Contact Number</label>
					<p class="client-visible-info">If there is a specific phone number that workers should contact
						regarding questions or issues for this site, please add it below.</p>
					<p class="client-visible-info">If you leave this blank, we
						will show them your <strong class="cursor-pointer" (click)="editCompany()">Control Room</strong>
						phone number
						by default.</p>
					<input type="text" class="b-input b-input-white custom-width" placeholder="eg. 07777888888"
						#control_no="ngModel" [pattern]='numberPattern' [(ngModel)]="site_object.control_room_number"
						name="control_room_number">
					<div *ngIf="control_no.dirty || control_no.touched">
						<div *ngIf="control_no.invalid && control_no.errors.pattern">
							<p [style.width]="'589px'" class="error-box">Please enter a valid contact number.</p>
						</div>
					</div>

				</div>

			</div>
		</div>
	</div>
	<div *ngIf="site_review">
		<div>
			<div>
				<div class="row-spacing">
					<h4>Job Site Details</h4>
					<p>Review the address that your workers will be directed to via the app once approved.</p>
					<!-- <p>Workers
                        will only see the full address once you have accepted them for the role.</p> -->
				</div>
			</div>
			<div>
				<div class="row-spacing row-spacing-wrapper">
					<div class="form-card review-site">

						<div class="site-details">
							<h4 class=" b-font-demibold">{{site_object.client_title}}</h4>

							<p *ngIf="site_object.location.address_1">
								{{site_object.location.address_1}},</p>
							<div *ngIf=" site_object.location.address_2">
								<p>{{site_object.location.address_2}},</p>
							</div>
							<div *ngIf="site_object.location.county">
								<p> {{site_object.location.county}},</p>
							</div>
							<p>{{site_object.location.city}},</p>

							<p>{{site_object.location.postcode}} </p>
							<div *ngIf="site_object.control_room_name" class="ref-wrapper wrapper-margin">
								<p>Contact:</p>
								<p class="b-font-demibold">{{site_object.control_room_name}}</p>
							</div>
							<div *ngIf="site_object.control_room_number" class="ref-wrapper">
								<p>Phone:</p>
								<p class="b-font-demibold">{{site_object.control_room_number}}</p>
							</div>


							<div *ngIf="site_object.reference" class="ref-wrapper wrapper-margin">
								<p>Ref:</p>
								<p class="b-font-demibold">{{site_object.reference}}</p>
							</div>
							<div *ngIf="site_object.purchase_order" class="ref-wrapper">
								<p>PO:</p>
								<p class="b-font-demibold"> {{site_object.purchase_order}}</p>
							</div>

							<div class="ref-wrapper">
								<p>Car Park:</p>
								<p *ngIf="site_object.car_parking_available" class="b-font-demibold"> Yes</p>
								<p *ngIf="!site_object.car_parking_available" class="b-font-demibold"> No</p>
							</div>
							<div class="ref-wrapper">
								<p>Training Required:</p>
								<p *ngIf="site_object.training_required" class="b-font-demibold"> Yes</p>
								<p *ngIf="!site_object.training_required" class="b-font-demibold"> No</p>
							</div>
							<div class="ref-wrapper">
								<p>Client name visible:</p>
								<p *ngIf="site_object.client_visible" class="b-font-demibold"> Yes</p>
								<p *ngIf="!site_object.client_visible" class="b-font-demibold"> No</p>
							</div>
						</div>
						<div class="edit-wrapper">
							<img src="assets/icons/pencil.svg" (click)="edit()" nz-tooltip nzTooltipTitle="edit site">


							<app-geofencing-map *ngIf="lat && lng" [lat]="lat" [lng]="lng" [radius]="
                                    this.site_object?.clock_distance_limit" [metricUnits]="metricUnits" nz-tooltip
								nzTooltipTitle="clock in/out area">
							</app-geofencing-map>


						</div>


					</div>

				</div>
			</div>
		</div>
		<div>
			<div>
				<div class=" row-spacing">
					<h4>Assignees</h4>
					<p>Any staff members you assign will receive updates on applications and cancellations on this
						job
						site.

					</p>
				</div>
			</div>
			<div>

				<div id="assigned-staff-id" class="row-spacing container-width">
					<label for="search-staff">Staff Member <app-loading-spinner *ngIf="staff_select_loading"
							color="color_grey_4" size="small" class="link-loading">
						</app-loading-spinner></label>
					<app-input-suggestions [avatar]="true" [clear_input]="true" [loading]="staff_loading"
						[data_array]="staff_suggestions" element_width='589px' class="input-suggestions"
						(valueSelected)="setStaffOnEdit($event)" (valueChange)="getStaff($event)" myid="search-staff"
						placeholder='Enter Staff Members Name'></app-input-suggestions>
					<app-list-staff width='589px' (onDelete)="deleteStaff($event)" [assigned_staff]="assigned_staff">
					</app-list-staff>
					<div *ngIf="assigned_staff.length == 0 && submittedSite">
						<p class="error-box client-width">You must assign a staff member when creating a job site.
						</p>
					</div>
				</div>

			</div>
		</div>
	</div>

	<div>

		<div *ngIf="!site_review">
			<div id="reference-id" class="row-spacing">
				<label for="job-reference">Your Reference </label>
				<p class="
                client-visible-info">Add the site or reference code below to keep things organised.</p>
				<input [disabled]="siteUuid" (change)="checkReference()" class="b-input b-input-white custom-width"
					[(ngModel)]="site_object.reference" type="text" name="job-reference" #reference="ngModel"
					id='job-reference' placeholder='Enter Site Reference'>
				<div *ngIf="reference_lnk">
					<p class="error-box custom-width">Looks like this reference has already been used on another
						site.<br><a (click)="openReference()" class="error-box custom-width">Click here to view</a></p>
				</div>
			</div>
			<div class="row-spacing">
				<label for="po-nr">Invoice Separator</label>
				<p class="
                client-visible-info">Add a new invoice separator or pick your client contract for this job</p>
				<app-input-suggestions required='true' value_input=true (valueChange)="getPoNumbers($event)"
					(valueSelected)="poValueSelected($event)" [loading]="po_suggestions_loading" element_width="589px"
					[data_array]="poSuggestions" myid="po-nr" placeholder='Enter Invoice Separator'
					[value]="site_object.purchase_order">
				</app-input-suggestions>
			</div>
			<div class="required">
				<p class="b-font-demibold">Fields marked with * are required.</p>
			</div>

		</div>
	</div>

	<div class="group-buttons">

		<app-clib-button type="outline" (pressAction)="goBack()" label="Back">
		</app-clib-button>
		<app-clib-button *ngIf="!site_review" (pressAction)="switchToReview()" [disabled]="!postcodeValid"
			label="Review"> </app-clib-button>
		<app-clib-button *ngIf="site_review" label="Save" (pressAction)="savingClient()" [loading]="save_loading">
		</app-clib-button>
	</div>
</form>