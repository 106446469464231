import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-workers-on-map",
  templateUrl: "./workers-on-map.component.html",
  styleUrls: ["./workers-on-map.component.scss"],
})
export class WorkersOnMapComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {}
  postJobs() {
	this.router.navigate(['jobs/select-job']);
  }
}
