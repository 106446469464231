<section class="jobs-section">
	<div class="search-bar-area">
		<app-job-search-area [search_box_data]="search_box_input" [status_filter]='true'
			[buttonConfig]="!url_job.site.source ? subNavButton : null">
		</app-job-search-area>
	</div>
	<app-site-jobs (goToAdminWorkers)="goToAdminWorkers($event)" (onAddShifts)="editShifts()" (onExpand)="expanded($event)" [job]="url_job"
		[edit_applications]="edit_applications" [can_edit_shifts]="can_edit_shifts">
	</app-site-jobs>

</section>