import { Component, OnInit, ElementRef, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { PopNotificationService } from '@broadstone/pop-notification-service';
import { AlertService } from '@broadstone/alert';
import { AuthSessionService } from '@broadstone/auth-session-service';
import { ApplicationsService, CalendarRange, EventsTracker, TimesheetService, UsersService } from 'src/services/public_api';


@Component({
	selector: 'app-timesheets-comp',
	templateUrl: './timesheets-comp.component.html',
	styleUrls: ['./timesheets-comp.component.scss']
})
export class TimesheetsCompComponent implements OnInit {
	page: number = 1;
	has_n_p: boolean = false;
	loading_more: boolean = false;
	indexed_data: any = {};
	loading: boolean = false;
	limit: number = 25;
	selected_timesheets: any = [];
	timesheets: any = [];
	auto_approval: string;
	extend_field: any = {};
	range_dates: any = [];
	floating_footer_loading: boolean = false;
	status_dropdown = [
		{ title: 'Pending', img: 'assets/icons/oval-copy-26.svg', value: 'pending' },
		{ title: 'Approved', img: 'assets/icons/check-icon.svg', value: 'approved' },
		{ title: 'Declined', img: 'assets/icons/cross.svg', value: 'denied' },
		{ title: 'No Shows', img: 'assets/icons/no-show-icon.svg', value: 'no-show' }
	];
	status: any = '';
	applicant: any = null;
	profile: any = {};
	filters_cleared: boolean = false;
	profileVisible: boolean = false;
	response_count: number = 0;
	request_sent: number = 0;
	@Output() redirectPage: EventEmitter<any> = new EventEmitter();
	edit_timesheets: boolean;

	constructor(
		private event_tracker: EventsTracker,
		private cdr: ChangeDetectorRef,
		private popNotif: PopNotificationService,
		private timesheets_service: TimesheetService,
		private elRef: ElementRef,
		private applicationService: ApplicationsService,
		public user: UsersService,
		private alert: AlertService,
		private session: AuthSessionService,
		private calendarRange: CalendarRange
	) {
		if (this.session.geAdminHeader()) {
			this.edit_timesheets = true;
		}
		else {
			this.user.getCurrentUser().then((res) => {
				this.checkPermissions(res.permissions)
			}).catch(e => {
				console.log(e);
			});
		}
	}

	ngOnInit() { }

	ngAfterViewInit() {
		this.calendarRange.init(this.elRef);
	}

	checkPermissions(permissions) {
		if (!permissions) {
			return;
		}
		permissions.forEach(element => {
			if (element.type === 'company.timesheets.edit') {
				this.edit_timesheets = true;
			}
		});
	}

	getTimesheets(page, clear_data = false) {
		this.request_sent += 1; // track requests
		return new Promise(resolve => {
			let date = { start: this.range_dates[0], end: this.range_dates[1] };
			this.timesheets_service.getTimesheets(page, this.limit, this.status, date).then((result) => {
				this.response_count += 1; // track response
				if (clear_data) { // clear data on page 1 Will make sure when select a filter to clear data
					this.reset_data();
				}
				this.has_n_p = result['links'].next ? true : false;
				this.auto_approval = result['meta'].custom.auto_approval || '';
				let data = result['data'];
				for (let index in data) {
					let timesheet = data[index];
					this.indexed_data[timesheet.uuid] = timesheet;
				}
				let self = this;
				// CONVERT THE OBJECT TO ARRAYS
				this.timesheets = Object.keys(self.indexed_data).map((key) => {
					return self.indexed_data[key];
				});
				if (this.response_count == this.request_sent) {
					this.loading = false;
				}
				this.loading_more = false;
				resolve(data);
			}).catch((err) => {
				this.loading = false;
				this.loading_more = false;
				// this.logger.error(err);
			});
		});
	}


	reset_data() {
		this.indexed_data = {};
		this.timesheets.length = 0;
		this.page = 1;
		this.selected_timesheets.length = 0;
	}

	onCloseRange(refresh = true) {
		this.calendarRange.onClose(refresh, this.range_dates, () => {
			this.reset_data();
			this.loading = true;
			this.getTimesheets(1, true);
		});

	}

	status_selected(e) {
		this.filters_cleared = false;
		this.status = e.value;
		this.reset_data();
		this.loading = true;
		this.getTimesheets(1, true);
		this.cdr.detectChanges();
	}

	check_status_filter() { // Check for resetting status
		return this.filters_cleared;
	}

	clear_filters() {
		this.range_dates = [];
		this.calendarRange.calendarSeparator();
		this.reset_data();
		this.filters_cleared = true;
		this.cdr.detectChanges();
	}

	onScroll() {
		if (this.has_n_p && !this.loading_more) {
			this.loading_more = true;
			this.page += 1;
			this.getTimesheets(this.page, false);
		}
	}

	closeFooter() {
		this.selected_timesheets.length = 0;
		this.timesheets.forEach(element => {
			element.checked = false; // remove all checkboxes
		});
	}

	selectTimesheet(timesheet) {
		if (!this.checkSelectedTimesheet(timesheet)) {
			if (timesheet && timesheet.uuid) {
				this.selected_timesheets.push(timesheet);
			}
		} else {
			this.selected_timesheets.splice(this.selected_timesheets.indexOf(timesheet), 1);
		}
	}

	checkSelectedTimesheet(timesheet) {
		return this.selected_timesheets.filter(selected => {
			if (selected && selected.uuid) {
				return selected.uuid === timesheet.uuid;
			}
			return false;
		}).length > 0;
	}

	updateApprove(timesheet) {
		const timesheets = {
			timesheets: [timesheet.uuid]
		};
		this.event_tracker.trackEvent('amend_timesheets', timesheet);
		this.approveTimesheets(timesheets);
	}

	applicationAction(action) {
		this.floating_footer_loading = true;
		const application_uuid = this.selected_timesheets.map(element => {
			if (element && element.error) {  // clear all errors before approving 
				element.error = null;
			}
			return element.uuid;
		});
		const timesheets = {
			timesheets: application_uuid
		};
		if (action === 'approved') {
			this.alert.showConfirm('Approve Timesheets?', 'This will approve the clocked in and out times not the shift listed times.', 'Approve', 'Cancel').then(() => {
				this.approveTimesheets(timesheets);
			}).catch((err) => {
				console.log(err);
			});
		} else if (action === 'noShow') {
			this.noShow(application_uuid);
		} else {
			this.declineTimesheets(timesheets);
		}
	}

	approveTimesheets(timesheets) {
		this.timesheets_service.approve_timesheets(timesheets).then((result) => {
			if (result['errors'] === null) {
				this.event_tracker.trackEvent('approve_timesheets', timesheets);
				this.popNotif.createNotification('success', 'Success', 'All selected timesheets have been approved.');
			} else {
				if (typeof result['errors'] === 'object') {
					const errors = Object.keys(result['errors']).length;
					if (errors > 1) {
						this.popNotif.createNotification('error', 'There has been a problem.', `We cannot approve ${errors} timesheets. Please try again later.`);
					} else {
						this.popNotif.createNotification('error', 'There has been a problem.', 'We cannot approve 1 timesheet. Please try again later.');
					}
					this.approveDeclineErrors(result, 'approved');
					this.closeFooter();
				}
			}
			if (result && result['messages']) {
				this.approveDeclineOnFly(result, 'approved');
			}
			this.floating_footer_loading = false;
			this.onCloseRange(false);
			this.selected_timesheets.length = 0; // hide footer
		}).catch((err) => {
			this.floating_footer_loading = false;
			// this.logger.error(err);
			this.popNotif.createNotification('error', 'There has been a problem.', err);
			this.onCloseRange(true);
		});
	}

	declineTimesheets(timesheets) {
		this.timesheets_service.decline_timesheets(timesheets).then((result) => {
			if (result['errors'] === null) {
				this.event_tracker.trackEvent('decline_timesheets', timesheets);
				this.popNotif.createNotification('success', 'Success', 'All selected timesheets have been declined.');
			} else {
				if (typeof result['errors'] === 'object') {
					const errors = Object.keys(result['errors']).length;
					if (errors > 1) {
						this.popNotif.createNotification('error', 'There has been a problem.', `We cannot decline ${errors} timesheets. Please try again later.`);
					} else {
						this.popNotif.createNotification('error', 'There has been a problem.', 'We cannot decline 1 timesheet. Please try again later.');
					}
					this.approveDeclineErrors(result, 'approved');
					this.closeFooter();
				}
			}
			if (result && result['messages']) {
				this.approveDeclineOnFly(result, 'denied');
			}
			this.floating_footer_loading = false;
			this.onCloseRange(false);
			this.selected_timesheets.length = 0;
		}).catch((err) => {
			this.floating_footer_loading = false;
			// this.logger.error(err);
			this.popNotif.createNotification('error', 'There has been a problem.', err);
			this.onCloseRange(true);
		});
	}

	approveDeclineOnFly(res, status) {
		const approved_list = Object.keys(res.messages);
		approved_list.forEach(approved_timesheet => {
			const match_timesheet = this.timesheets.filter(element => {
				return approved_timesheet === element.uuid;
			});
			if (match_timesheet.length > 0) {
				match_timesheet[0].timesheet.timesheet_status = status;
			}
		});
	}

	noShowOnFly(data, status) {
		const match_timesheet = this.timesheets.filter(element => {
			return data.uuid === element.uuid;
		});
		if (match_timesheet.length > 0) {
			match_timesheet[0].timesheet.timesheet_status = status;
		}
	}

	approveDeclineErrors(res, status) {
		const errors_list = Object.entries(res.errors).map(([key, value]) => ({ key, value }));
		// key == timesheet error uuid from api
		// value == timesheet error message from api
		errors_list.forEach(api_error_uuid => {
			const match_timesheet = this.timesheets.filter(element => {
				return api_error_uuid.key === element.uuid;
			});
			if (match_timesheet.length > 0) {
				match_timesheet[0].error = api_error_uuid.value;
			}
		});
	}

	noShow(timesheets) {
		const promisesCompleted = [];
		timesheets.forEach(uuid => {
			promisesCompleted.push(this.applicationService.setNoShow(uuid)
				.then(res => {
					this.noShowOnFly(res['data'], 'no-show');
				}));
		});
		Promise.all(promisesCompleted)
			.then(res => {
				this.popNotif.createNotification('success', 'Success', 'All selected application marked as no show.');
				this.floating_footer_loading = false;
				this.onCloseRange(false);
				this.selected_timesheets.length = 0;
			})
			.catch(err => {
				this.floating_footer_loading = false;
				// this.logger.error(err);
				this.popNotif.createNotification('error', 'There has been a problem.', err);
				this.onCloseRange(true);
			});
	}

	openProfile(applicant_uuid) {
		this.getProfile(applicant_uuid)
	}

	getProfile(uuid) {
		this.applicationService.getApplicantProfile(uuid).then((result) => {
			this.profile = { ...result };
			this.profileVisible = true;
		}).catch((err) => {
			// this.logger.error(err);
		});
	}

	closeProfile() {
		this.profile = {};
		this.profileVisible = false;
	}

	postJob() {
		this.redirectPage.emit();
	}

}
