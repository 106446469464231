<nz-skeleton class="nz-cites-loading" [nzLoading]="loading_anim" [nzParagraph]="{ rows: 20 }" [nzTitle]="false"
	[nzActive]="true"></nz-skeleton>
<div class="main-container">
	<div *ngIf="!loading_anim" class="page-container">
		<div class="create-site-header">
			<h2 *ngIf="!editJob" class="b-font-demibold">Create a
				Role</h2>
			<h2 *ngIf="editJob" class="b-font-demibold">Edit a
				Role</h2>
		</div>
		<div>

			<div class="sector-wrapper" *ngIf="!editJob">
				<label>Job Type *<p>Please select a job type.</p></label>
				<div class="select-sector">
					<div [ngClass]="sectorType.selected ? 'selected' : ''" class="s-item"
						*ngFor="let sectorType of data_sectors">
						<div (click)="selectSector(sectorType)">
							<div><img src="{{sectorType.avatar_src}}" /></div>
							<p class="sector-item">{{sectorType.title}}</p>
						</div>
					</div>
				</div>
				<label>Service *<p>Please select a service.</p></label>
				<!-- security -->
				<div id="vacancy_service_id" *ngIf="data_sectors[0]?.selected" class="sector-wrapper">
					<div [ngClass]="securityRole.selected ? 'selected' : ''" class="s-item"
						*ngFor="let securityRole of data_sectors[0]?.services">
						<div (click)="selectService(securityRole)">
							<div><img src="{{securityRole.avatar_src}}" /></div>
							<p class="role-item">{{securityRole.title}}</p>
						</div>
					</div>
				</div>
				<!-- cleaning -->
				<div id="vacancy_service_id" *ngIf="data_sectors[1]?.selected" class="sector-wrapper">
					<div [ngClass]="cleaningRole.selected ? 'selected' : ''" class="s-item"
						*ngFor="let cleaningRole of data_sectors[1]?.services">

						<div (click)="selectService(cleaningRole)">
							<div><img src="{{cleaningRole.avatar_src}}" /></div>
							<p class="role-item">{{cleaningRole.title}}</p>
						</div>
					</div>
				</div>
				<div *ngIf="api_errors && api_errors.vacancy_service_id">
					<p class="error-box error-box-width">Please select a service type.</p>

				</div>

				<div id="vacancy_service_role_uuid" *ngIf="dataServiceRoles.length > 0">
					<label>Service Role *<p>Please select a role type.</p></label>
					<div id="services-roles " class="sector-wrapper">
						<div [ngClass]="serviceRoleType.selected ? 'selected' : ''" class="s-item"
							*ngFor="let serviceRoleType of dataServiceRoles">
							<div (click)="selectServiceRoleType(serviceRoleType)">
								<div><img src="{{serviceRoleType.avatar_src}}" /></div>
								<p class="role-item">{{serviceRoleType.title}}</p>
							</div>
						</div>
					</div>
					<div *ngIf="api_errors && api_errors.vacancy_service_role_uuid">
						<p class="error-box error-box-width">Please select an role type.</p>

					</div>
				</div>

				<label>Industry *<p>Please select an industry.</p></label>
				<div id="vacancy_industry_id " class="sector-wrapper">
					<div [ngClass]="industryType.selected ? 'selected' : ''" class="s-item"
						*ngFor="let industryType of data_industries">
						<div (click)="selectIndustryType(industryType)">
							<div><img src="{{industryType.avatar_src}}" /></div>
							<p class="role-item">{{industryType.title}}</p>
						</div>
					</div>
				</div>
				<div *ngIf="api_errors && api_errors.vacancy_industry_id">
					<p class="error-box error-box-width">Please select an industry type.</p>

				</div>
			</div>


		</div>
		<hr *ngIf="!editJob">
		<div>

			<div>
				<div id="title" class="row-spacing">
					<label for="role-title">Title *<p>Provide a title that best describes this role.</p></label>

					<input id="role-title" required (keydown)="clearApiErrors('title')"
						class="b-input b-input-white custom-width" [(ngModel)]="roleSetup.title" type="text"
						placeholder="Examples. Nights, Days, Weekend, FoH, Gate Keeping">
					<div *ngIf="api_errors && api_errors.title">
						<p *ngIf="roleSetup.title" class="error-box error-box-width">This Role Title already exists.</p>
						<p *ngIf="!roleSetup.title" class="error-box error-box-width">Please fill in this field.</p>
					</div>
				</div>
				<div *ngIf="roleSetup.sector_title == 'Security'" class="row-spacing">
					<label>Licence Requirements <p>Only workers with the licences you select will be able to apply.
						</p></label>

					<p class="row-spacing b-font-demibold">Main SIA Licences</p>
					<div class="checkbox-wrapper checkbox-custom">

						<div *ngFor="let requirement of all_requirements">
							<label *ngIf="requirement.type ==='main_sia'"
								class="b-checkbox">{{requirement.title}}<br><span class="b-Eh5"
									*ngIf="requirement.id === 1">(Includes
									Close Protection)</span><span class="b-Eh5" *ngIf="requirement.id === 6">(Includes
									Door
									Supervision &
									Close Protection)</span>
								<input (click)="selectRequirement(requirement)" [checked]="requirement.selected"
									value="requirement.id" type="radio">
								<span class="checkmark"></span>
							</label>
						</div>

					</div>

					<p class="row-spacing b-font-demibold">Other SIA Licences</p>
					<div class="checkbox-wrapper checkbox-custom">
						<div *ngFor="let requirement of all_requirements">
							<label *ngIf="requirement.type ==='additional_sia'"
								class="b-checkbox">{{requirement.title}}<br>
								<input (ngModelChange)="selectRequirement(requirement)" [ngModel]="requirement.selected"
									value="requirement.id" type="checkbox">
								<span class="checkmark"></span>
							</label>
						</div>
					</div>

				</div>
				<div *ngIf="roleSetup.sector_title == 'Security'" class="row-spacing">
					<label>Additional Requirements <p>Workers will have to confirm they meet the selected requirements
							to
							be able to apply
						</p></label>
					<div *ngFor="let req of clientReq; let i = index">
						<div>
							<p class="row-spacing b-font-demibold">
								{{req.type_name}} <img [src]="arrows[i].direction" (click)="expandSection(i)">
							</p>
							<div class="checkbox-wrapper checkbox-custom">
								<div *ngFor="let rment of req.requirements">
									<div *ngIf="arrows[i].direction === up">
										<label class="b-checkbox">{{rment.title}}<br>
											<input (ngModelChange)="selectClientRequirement(rment, i)"
												[ngModel]="rment.selected" value="rment.uuid" type="checkbox">
											<span class="checkmark"></span>
										</label>
									</div>
								</div>
							</div>
						</div>


					</div>
					<p class="row-spacing b-font-demibold">Do you require a worker within 20 miles?</p>
					<div class="checkbox-wrapper checkbox-custom">
						<label class="b-checkbox">Yes
							<input (click)="selectWorkerDistance('yes')" [checked]="requireWorkerWithin" type="radio"
								name="require_worker_within">
							<span class="checkmark"></span>
						</label>
						<label class="b-checkbox">No
							<input (click)="selectWorkerDistance('no')" [checked]="requireWorkerWithin === false"
								type="radio" name="dont_require_worker_within">
							<span class="checkmark"></span>
						</label>
					</div>
					<div class="requirement-wrapper">
						<div class="input-wrapper" *ngIf="requireWorkerWithin">
							<input class="b-input b-input-white bank-hol-input req" type="number"
								[(ngModel)]="roleSetup.require_worker_within" max="20" id="require_worker_within">
							<p>Mile Radius</p>
						</div>
						<p class="mile-radius">Please specify the mile radius you require workers to be in to apply</p>
						<div *ngIf="api_errors && api_errors.require_worker_within">
							<p class="error-box error-box-width">Radius cannot be greater than 20.</p>
						</div>
					</div>



				</div>
				<div id="description" class="row-spacing">
					<label for="add_resposabilities">Roles & Responsibilities *<p class="role-details-p">Please provide
							roles
							and
							responsibilities to include any Patrolling/PPE requirements as well as any emergency
							procedures
						</p>
					</label>
					<textarea (keydown)="clearApiErrors('description')" #description="ngModel"
						[(ngModel)]="roleSetup.description" id="add_resposabilities"
						placeholder="Please provide Roles & Responsibilities" required
						class="b-textarea b-textarea-white"></textarea>
					<div
						*ngIf="(description.invalid && (description.dirty || description.touched)) || api_errors && api_errors.description">
						<p class="error-box error-box-width">Please fill in this field.</p>
					</div>
				</div>
				<!-- SmartStaff -->
				<div class="row-spacing">
					<label>Employment Model * <p class="pricing">Is this role going to be <span nz-tooltip
								nzTooltipTitle="Ad Hoc jobs are temporary requirements.">Ad Hoc</span> or
							<span nz-tooltip nzTooltipTitle="Temp to Perm roles are temp to perm.">Temp to Perm</span>?
						</p></label>


					<p *ngIf="price_data.length > 0" class="pricing">Find out more about our Temp to Perm pricing <span
							nz-popover [nzPopoverContent]="pricingPopover" nzPopoverTrigger="click">here</span></p>
					<p class="pricing italic">(Workers will be encouraged to apply to all shifts on Temp to Perm jobs)</p>

					<ng-template #pricingPopover>
						<div class="pricingPopover">
							<div>
								<img src="./assets/icons/briefcase.svg">
								<h2>Temp to Perm</h2>
							</div>
							<p>Pricing</p>
							<p>The more hours a worker completes with you, the cheaper it becomes to hire them!</p>
							<!-- <img src="./assets/images/pricingBreakdown.svg"> -->
							<div class="price-breakdown-header">
								<div class="row">
									<p>Hours Worked</p>
								</div>
								<div class="row grey-header">
									<p>Temp to Perm Price</p>
								</div>
							</div>
							<div class="price-breakdown-table" *ngFor="let prices of price_data">
								<div class="row">
									<p>{{prices.hoursWorked}}</p>
								</div>

								<div class="row grey">
									<p>&pound;{{prices.price}}</p>
								</div>
							</div>
						</div>
					</ng-template>

				</div>
				<div class="row-spacing smart-staff-row">

					<div class="checkbox-wrapper">

						<label class="b-checkbox">Ad Hoc
							<input
								(click)="roleSetup.smart_staff = false; globalHelpers.change_check_box($event, 'no', 'yes_staffing', 'no_staffing', this)"
								[checked]="no_staffing" type="checkbox">
							<span class="checkmark"></span>
						</label>
						<label class="b-checkbox">Temp to Perm
							<input
								(click)="roleSetup.smart_staff = true; globalHelpers.change_check_box($event, 'yes','yes_staffing', 'no_staffing', this)"
								[checked]="yes_staffing" type="checkbox">
							<span class="checkmark"></span>
						</label>
					</div>
				</div>


			</div>
		</div>

		<hr>




		<div id="worker_hourly_rate">

			<div>
				<div class="row-spacing">
					<label for="pay-rate">Pay Rate * <p>Enter the rate you would like the worker to receive and we
							will show you a breakdown of the charges that
							will
							be added on top.</p></label>
					<div class="pay-wrapper">
						<div class="pay-field">
							<label for="pay-rate">

							</label>
							<p class='input-pound pound'>&pound;</p>
							<div class="input-wrapper">
								<input [customMin]="paye_min_rate" #pay_field="ngModel" (keyup)="checkPay()"
									[(ngModel)]="roleSetup.worker_hourly_rate" required class="b-input b-input-white"
									type="number" name="worker_hourly_rate" id='pay-rate'>
								<h4>P/H</h4>
							</div>
							<div *ngIf="pay_field.dirty || pay_field.touched">
								<p class="error-box" *ngIf="pay_field.invalid && pay_field.errors.required">Pay Rate is
									required.</p>
								<p class="error-box"
									*ngIf="pay_field.invalid && pay_field.errors.customMin && !pay_field.errors.required">
									The minimum pay
									rate is &pound;{{paye_min_rate}}.
								</p>
							</div>
							<div *ngIf="!pay_field.touched && api_errors && api_errors.worker_hourly_rate">
								<p *ngIf="roleSetup.worker_hourly_rate" class="error-box error-box-width">
									The minimum pay
									rate is &pound;{{paye_min_rate}}.</p>
								<p *ngIf="!roleSetup.worker_hourly_rate" class="error-box error-box-width">
									Please fill in
									this field.</p>
							</div>
						</div>
						<div class="form-card">
							<div class="pay-details-wrapper">
								<div class="b-Eh4 b-light">Pay Rate </div>
								<p class="b-font-demibold">&pound;{{costHelper.to_fixed(pay_type.requested_amount)}}</p>
							</div>
							<div *ngIf="roleSetup.payment_method == 'paye'" class="pay-details-wrapper">
								<div class="b-Eh4 b-light">Holiday Pay ({{pay_type.holiday}}%)</div>
								<p class="b-font-demibold">&pound;{{costHelper.to_fixed(pay_type.holiday_amount)}}</p>
							</div>
							<div class="pay-details-wrapper">
								<div class="b-Eh4 b-light" *ngIf="roleSetup.payment_method == 'contractor'">Orka Works
									Fee
									(12.5%)
								</div>
								<div class="b-Eh4 b-light" *ngIf="roleSetup.payment_method == 'paye'">Employment Costs
								</div>
								<p class="b-font-demibold">&pound;{{costHelper.to_fixed(broadstone_fee_amount)}}</p>
							</div>
							<hr>
							<div class="pay-details-wrapper">
								<p class="b-font-demibold">Total <span class="b-light">(GBP)</span></p>
								<p class="b-font-demibold">&pound;{{costHelper.to_fixed(pay_type.total_no_vat)}} p/h</p>
							</div>
							<app-loading-spinner *ngIf="pay_loading" color="color_grey_4" size="small"
								class="link-loading">
							</app-loading-spinner>
						</div>


					</div>

					<label class="bank-hol-label" for="bank-holiday-pay-rate">How would you like us to treat bank
						holidays? <p>We will automatically apply the pay rate boost you select below on shifts that fall
							on
							a bank holiday.</p></label>
					<div class="pay-wrapper">
						<div class="pay-field">
							<label for="bank-holiday-pay-rate">

							</label>
							<p class='input-pound x'>x</p>
							<div class="input-wrapper">
								<input [(ngModel)]="roleSetup.bank_holiday_rate" required
									class="b-input b-input-white bank-hol-input" type="number" name="bank_holiday_rate"
									placeholder="1.5" id='bank-holiday-pay-rate'>

							</div>
							<p>Enter a value between 1 and 2</p>

						</div>

					</div>
					<div *ngIf="api_errors && api_errors.bank_holiday_rate">
						<p *ngIf="roleSetup.bank_holiday_rate" class="error-box error-box-width">Please enter a
							number between 1 & 2.</p>
						<p *ngIf="!roleSetup.bank_holiday_rate" class="error-box error-box-width">Please fill in
							this field.</p>
					</div>
				</div>
			</div>

			<hr>

			<div id="location_type">
				<div class="row-spacing">
					<label>Workplace *<p>Let the workers know what type of environment they will be working in for this
							role.</p></label>
					<div class="checkbox-wrapper checkbox-env">
						<div *ngFor="let env of workEnvironments">
							<label class="b-checkbox">{{env.type}}
								<input (click)="selectWorkEnv(env.type)" [checked]="env.selected" type="radio"
									name="location_type">
								<span class="checkmark"></span>
							</label>
						</div>

					</div>
					<div *ngIf="api_errors && api_errors.location_type">
						<p *ngIf="!roleSetup.location_type" class="error-box error-box-width">Please select an
							environment type.</p>

					</div>
				</div>

			</div>


			<div id="uniform_description">

				<div>
					<div class="row-spacing">
						<label>Uniform * <p>Please select a uniform option so that the workers knows what to wear for
								the
								shift.
							</p>
						</label>
						<div *ngIf="roleSetup.sector_title == 'Security'" class="uniform-wrapper" text-center>
							<div [style.opacity]="uniform.selected ? '1': ''" class="uniform-item"
								*ngFor="let uniform of uniforms; let i = index;">
								<div (click)="selectUniform(uniform, i)">
									<!-- <img *ngIf="uniform.selected" class="uniform-marked" src="assets/icons/ic-check-mark.svg" /> -->
									<img class="uniform-img" [src]="uniform.src" />
									<p [innerHTML]="uniform.value ? uniform.value : uniform.info"></p>
								</div>
							</div>
						</div>
						<div *ngIf="roleSetup.sector_title !== 'Security'" class="uniform-wrapper" text-center>
							<div [style.opacity]="uniform.selected ? '1': ''"
								*ngFor="let uniform of uniforms; let i = index; last as isLast">
								<div *ngIf="isLast" class="uniform-item" (click)="selectUniform(uniform, i)">
									<!-- <img *ngIf="uniform.selected" class="uniform-marked" src="assets/icons/ic-check-mark.svg" /> -->
									<img class="uniform-img" [src]="uniform.src" />
									<p [innerHTML]="uniform.value ? uniform.value : uniform.info"></p>
								</div>
							</div>
						</div>
						<div *ngIf="api_errors && api_errors.uniform_description">
							<p class="error-box error-box-width">Please select a uniform description.</p>
						</div>
					</div>
				</div>
			</div>
			<hr>
			<div id="reference" class="row-spacing">
				<label for="job-reference">Your Reference <p>Add the job reference below to keep things organised.</p>
				</label>
				<app-input-suggestions required='false' value_input=true (valueChange)="checkReference($event)"
					(valueSelected)="refValueSelected($event)" [loading]="refSuggestionsLoading" element_width="589px"
					[data_array]="refSuggestions" myid="ref-nr" placeholder='Enter Job Reference'
					[value]="roleSetup.reference">
				</app-input-suggestions>

			</div>
			<div id="invoice_desc" class="row-spacing">
				<label for="job-reference">PO Number <p>Add a PO Number or pick your client contract for this job.</p>
				</label>
				<input id="invoice_desc" required (keydown)="clearApiErrors('invoice-desc')"
					class="b-input b-input-white custom-width" [(ngModel)]="roleSetup.invoice_desc" type="text"
					placeholder="Enter PO Number">

			</div>
			<div class="required">
				<p class="b-font-demibold">Fields marked with * are required.</p>
			</div>
			<div class="group-buttons">
				<app-clib-button class="delete_btn" [loading]="delete_loading" *ngIf="editJob"
					(pressAction)="openDeleteModal()" color="tertiary" label="Delete this Role"></app-clib-button>
				<app-clib-button type="outline" (pressAction)="back()" label="Back"></app-clib-button>
				<app-clib-button [loading]="save_loading" label="Save" (pressAction)="save()"></app-clib-button>


			</div>



		</div>
		<lib-modal [show_modal]="isVisible" (closeModal)="handleCancel(modal)" [width]='668'
			[btn_label_primary]="!editJob ? 'Create':'Update'" btn_color_primary="primary"
			(clickPrimary)="saveUniform()" [btn_disabled_primary]="!modal.value_uniform" [title]='modal.title'
			[close_icon]='true' [body]="modalContent">
			<div class="input-wrapper-modal uniform">
				<input *ngIf="modal.type == 'uniform'" class="b-input" [(ngModel)]="modal.value_uniform" type="text"
					[placeholder]="modal.placeholder">
			</div>
		</lib-modal>


		<lib-modal [width]="462" [show_modal]="deleteModalVisible" [btn_disabled_primary]="!deleteShiftsReason?.trim()"
			(closeModal)="closeDeleteModal()" btn_label_primary="Confirm" btn_color_primary="primary"
			btn_label_secondary="Cancel" (clickSecondary)="closeDeleteModal()" (clickPrimary)="deleteRole()"
			[close_icon]='false' [body]="modalContent">
			<div class="input-wrapper-modal">
				<h4 class="b-font-demibold title-margin">Please provide a reason for deleting this role.
				</h4>
				<div *ngFor="let i of jobProvider.deleteReasons" class="checkbox-div">
					<label class="b-checkbox">
						<p>{{i.reason === 'Smart-Staffed Worker' ? 'Temp to Perm Worker' : i.reason}}</p>
						<input type="radio" [checked]="i.selected" (click)="selectDeleteReason(i.reason)">
						<span class="checkmark"></span>
					</label>
					<input *ngIf="i.reason === 'Other' && i.selected" class=" b-input" required
						[(ngModel)]="deleteShiftsReason" type="text" placeholder="Enter reason">
				</div>

			</div>
		</lib-modal>
	</div>
	<app-job-card *ngIf="roleSetup && currentUser && currentUser.company" class="title-sticky"
		[ngClass]="editJob ? 'edit-job-margin' : ''" StickyPolyFill [sitePostcode]="sitePostcode" [editJob]="editJob"
		[roleType]="getServiceRole()" [serviceTitle]="roleSetup.vacancy_service_title"
		[workerHourlyRate]="roleSetup.worker_hourly_rate" [roleDescription]="roleSetup.description"
		[smartStaff]="roleSetup.smart_staff" [logo]="logo" [holidayPayRate]="pay_type.holiday_amount"></app-job-card>
</div>