<lib-modal [show_modal]="openModal" [footer]="false" (closeModal)='handleCancel()' [width]="500">

	<h4 class="b-font-demibold">Auto Approval is turned on</h4>
	<div class="list-item-wrapper">

		<div *ngIf="modalCopyType === 'All workers'" class="item-list">
			<img src="assets/icons/chat-icon.svg" />
			<span class="b-Eh4"><span class="b-font-demibold">All workers </span>will be notified about these
				shifts.</span>
		</div>
		<div *ngIf="modalCopyType === 'Previously worked'" class="item-list">
			<img src="assets/icons/chat-icon.svg" />
			<span class="b-Eh4">Workers who have <span class="b-font-demibold">previously worked </span>on this site
				will be notified about these shifts.</span>
		</div>
		<div *ngIf="modalCopyType === 'Specific workers'" class="item-list">
			<img src="assets/icons/chat-icon.svg" />
			<span class="b-Eh4">All of the shifts will be sent to your <span class="b-font-demibold">specific worker.
				</span></span>
		</div>


		<div *ngIf="modalCopyType === 'All workers' || 'Previously worked' && modalCopyType !== 'Specific workers'" class="item-list">
			<img src="assets/icons/profile-accept.svg" />
			<span class="b-Eh4">The top ranked site trained worker will be <span class="b-font-demibold">automatically
					approved.</span></span>
		</div>

		<div *ngIf="modalCopyType === 'Specific workers'">
			<div class="item-list">
				<img src="assets/icons/profile-accept.svg" />
				<span class="b-Eh4">If they <span class="b-font-demibold">apply </span>they will be automatically
					approved.</span>
			</div>
			<div class="item-list">
				<img src="assets/icons/profile-decline.svg" />
				<span class="b-Eh4">If they <span class="b-font-demibold">don’t respond </span>to the shift with in 48 hours the shifts will be sent to all workers</span>
			</div>
		</div>

		<div *ngIf="autoApprove" class="item-list">
			<img src="assets/icons/notification-icon.svg" />
			<span class="b-Eh4">If no worker with a high rating and site trained badge applies to these shifts <span
					class="b-font-demibold">no one will be automatically approved</span> and we will <span
					class="b-font-demibold">notify you </span> to fill the shifts manually.</span>
		</div>

	</div>

	<div class="check-button-wrapper">
		<div class="checkbox-flex">
			<label class="b-checkbox">
				<div class="b-Eh4">Don’t show me this again</div>
				<input (click)="markCheckbox()" [checked]="notShowModalCheckbox" type="checkbox">
				<span class="checkmark"></span>
			</label>

		</div>

		<app-clib-button label="Got it" (pressAction)="handleOk()">
		</app-clib-button>
	</div>

</lib-modal>