import { AuthSessionService } from '@broadstone/auth-session-service';
import { ApiService } from '@broadstone/api-service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { CompaniesAdapter, Site, Clients } from './companies.model';
import { DatesHelper } from '@broadstone/helpers';
import { FiltersServices } from '../filters/filters.service';
import { LoggingService } from '../logging/logging.service';
import { CachingService } from '../caching-service';

@Injectable({
    providedIn: 'root'
})
export class CompaniesService {



    constructor(
        public http: HttpClient,
        public api: ApiService,
        private session: AuthSessionService,
        private adapter: CompaniesAdapter,
        public date_helper: DatesHelper,
        private filters_service: FiltersServices,
		public logger: LoggingService,
		private cachingService:CachingService
    ) {
    }


    public getClient(name): Promise<any> {
        const url = this.api.getFullUrl('companies/clients?search=:name', { name });
        const headers = this.api.getDefaultHeaders(this.session.getToken());
        return this.http.get(url, { headers })
            .pipe(
                map((data: any[]) => data['data'].map(item => {
                    return this.adapter.adaptClients(item);
                }))
            )
            .toPromise();
    }
    public createClient(client: Clients): Promise<any> {

        const url = this.api.getFullUrl('companies/clients');
        const headers = this.api.getDefaultHeaders(this.session.getToken());
        return this.http.post(url, client, { headers })
            .pipe(
                map((data: any) => {
                    return this.adapter.adaptClients(data['data']);
                })
            )
            .toPromise();
    }
    public createSite(site: Site): Promise<any> {
        const url = this.api.getFullUrl('companies/sites');
        const headers = this.api.getDefaultHeaders(this.session.getToken());
        return new Promise((resolve, reject) => {
            this.http.post(url, site, { headers }).subscribe(
                (data) => {
                    const adapted_data = this.adapter.adaptNewSites(data['data'], this.logger);
                    resolve(adapted_data);
                },
                (error) => reject(this.api.handleErrors(error))
            )
        });
    }

    public editSite(site: Site, siteUuid): Promise<any> {
        const url = this.api.getFullUrl('companies/sites/:siteUuid', { siteUuid });
        const headers = this.api.getDefaultHeaders(this.session.getToken());
        return new Promise((resolve, reject) => {
            this.http.patch(url, site, { headers }).subscribe(
                (data) => {
                    const adapted_data = this.adapter.adaptSites(data['data'], this.logger);
                    resolve(adapted_data);
                },
                (error) => reject(this.api.handleErrors(error))
            )
        });
    }

	public getSiteType(): Promise<any> {
		const url = this.api.getFullUrl('company/sites/site-types');
		const headers = this.api.getDefaultHeaders(this.session.getToken());
		this.cachingService.addCache(url, 300);
		return this.http.get(url, { headers })
			.pipe(
				map((data: any[]) => data['data'].map(item => {
					return item;
				}))
			)
			.toPromise();
	}

    public getSiteReference(reference): Promise<any> {
        const url = this.api.getFullUrl('companies/sites?reference=:reference', { reference });
        const headers = this.api.getDefaultHeaders(this.session.getToken());
        return this.http.get(url, { headers })
            .pipe(
                map((data: any[]) => data['data'].map(item => {
                    return this.adapter.adaptSites(item, this.logger);
                }))
            )
            .toPromise();
    }

    public getPostcodeClient(postcode, client_name): Promise<any> {
        const url = this.api.getFullUrl('companies/sites?location=:postcode&client_name=:client_name', { postcode, client_name });
        const headers = this.api.getDefaultHeaders(this.session.getToken());
        return this.http.get(url, { headers })
            .pipe(
                map((data: any[]) => data['data'].map(item => {
                    return this.adapter.adaptSites(item, this.logger);
                }))
            )
            .toPromise();
    }

    public getSiteUuid(siteUuid): Promise<any> {
        const url = this.api.getFullUrl('companies/sites/:siteUuid', { siteUuid });
        const headers = this.api.getDefaultHeaders(this.session.getToken());
        return this.http.get(url, { headers })
            .pipe(
                map((data: any) => {
                    return this.adapter.adaptSites(data['data'], this.logger);
                })
            )
            .toPromise();
    }

    public assignStaff(siteUuid, staffProfileUuid): Promise<any> {
        const url = this.api.getFullUrl('companies/sites/:siteUuid/assigned/:staffProfileUuid', { siteUuid, staffProfileUuid });
        const headers = this.api.getDefaultHeaders(this.session.getToken());
        return this.http.put(url, null, { headers })
            .pipe(
                map((data: any) => {
                    return this.adapter.adaptSites(data['data'], this.logger);
                })
            )
            .toPromise();
    }

    public removeStaff(siteUuid, staffProfileUuid): Promise<any> {
        const url = this.api.getFullUrl('companies/sites/:siteUuid/assigned/:staffProfileUuid', { siteUuid, staffProfileUuid });
        const headers = this.api.getDefaultHeaders(this.session.getToken());
        return this.http.delete(url, { headers })
            .pipe(
                map((data: any) => {
                    return this.adapter.adaptSites(data['data'], this.logger);
                })
            )
            .toPromise();
    }
    public getDashboardShifts(applicant_name, range, assigned_sites, page, limit) {

        return new Promise((resolve, reject) => {
            let filter = 'companies/dashboard?';
            if (applicant_name) {
                filter += `applicant_name=${applicant_name}&`;
            }
            if (range && range.start && range.end) {
                const start = this.date_helper.adapt_dates('start_at', range.start, this.date_helper);
                const end = this.date_helper.adapt_dates('end_at', range.end, this.date_helper);
                filter += `shift.start_at=${start}<>${end}&`;
            }
            if (assigned_sites !== null) {
                filter += `assigned_sites_only=${assigned_sites ? 1 : 0}&`;
            }

            filter += `page=${page}&limit=${limit}`;
            const url = this.api.getFullUrl(filter);
            const headers = this.api.getDefaultHeaders(this.session.getToken());
            return this.http.get(url, { headers })
                .subscribe(
                    (data) => {
                        const adapted_data = data['data'].map(item => {
                            return this.adapter.adaptDashboardShift(item, this.logger);
                        });
                        const result = this.api.adapt_pagination(adapted_data, data);
                        resolve(result);
                    },
                    (error) => reject(this.api.handleErrors(error))
                );
        });

    }

    public getCompanyInfo() {
        return new Promise((resolve, reject) => {
            const url = this.api.getFullUrl('company/company');
            const headers = this.api.getDefaultHeaders(this.session.getToken());
            return this.http.get(url, { headers })
                .subscribe(
                    (data) => {
                        const result = this.adapter.adaptCompany(data['data'], this.logger);
                        resolve(result);
                    },
                    (error) => reject(this.api.handleErrors(error))
                );
        })


    }

    public getAllCompanies(page, limit, company?) {
        return new Promise((resolve, reject) => {
            let filter = `admin/companies?page=${page}&limit=${limit}`
            if (company) {
                filter += `&title=${company}`;
            }
            const url = this.api.getFullUrl(filter);
            const headers = this.api.getDefaultHeaders(this.session.getToken());
            return this.http.get(url, { headers })
                .subscribe(
                    (data) => {
                        const result = data;
                        resolve(result);
                    },
                    (error) => reject(this.api.handleErrors(error))
                );
        })


    }


    public updateCompany(profile) {
        return new Promise((resolve, reject) => {
            const url = this.api.getFullUrl('company/company');
            const headers = this.api.getDefaultHeaders(this.session.getToken());
            return this.http.patch(url, profile, { headers })
                .subscribe(
                    (data) => {
                        resolve(data);
                    },
                    (error) => reject(this.api.handleErrors(error))
                );
        });
    }










}
