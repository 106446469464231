import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { JobsProviders, JobsService, SmartStaffService } from 'src/services/public_api';
import { PopNotificationService } from '@broadstone/pop-notification-service';
import { AlertService } from '@broadstone/alert';
import { GlobalHelpers, Costs } from '@broadstone/helpers';
import { JobSetup } from 'src/services/api/jobs/job.model';
import { AuthSessionService } from '@broadstone/auth-session-service';

@Component({
	selector: 'app-post-setup-role',
	templateUrl: './post-setup-role.component.html',
	styleUrls: ['./post-setup-role.component.scss']
})
export class PostSetupRoleComponent implements OnInit {
	@Input() siteId: string;
	@Input() sitePostcode: string;
	@Input() job: any;
	@Input() editJob: boolean;
	@Output() onBack: EventEmitter<any> = new EventEmitter();
	@Output() onDeleted: EventEmitter<any> = new EventEmitter();
	@Output() onSaved: EventEmitter<any> = new EventEmitter();
	@Output() onEdited: EventEmitter<any> = new EventEmitter();
	smart_staff: string = 'no';
	isVisible: boolean = false;
	uniforms: any = [
		{ src: 'assets/icons/uniform/suit-avatar.svg', info: ' <p>Make sure you’re wearing a <span class="b-font-demibold">black jacket or blazer, plain white shirt, black trousers, and smart black shoes.</span></p>' },
		{ src: 'assets/icons/uniform/white-shirt-avatar.svg', info: '<p>Make sure you’re wearing a <span class="b-font-demibold">plain white shirt, black tie, black trousers, and smart black shoes.</span></p>' },
		{ src: 'assets/icons/uniform/black-shirt-avatar.svg', info: '  <p>Make sure you’re wearing a <span class="b-font-demibold">plain black shirt or polo shirt, black trousers, black boots or shoes.</span></p>' },
		{ src: 'assets/icons/uniform/custom-uniform.svg', info: '<p>Enter your custom uniform requirements.</p>', value: '' }
	];
	modal: any = {
		title: 'Enter Custom Uniform',
		placeholder: 'Enter Custom Uniform requirements',
		value_uniform: '',
		type: ''
	};
	workEnvironments: Array<object> = [{ type: 'Indoors', selected: false }, { type: 'Outdoors', selected: false }, { type: 'Both', selected: false }]
	price_data: any = [];
	handleOk: any; // @todo added for build to pass - update type and default value
	modalContent: any; // @todo added for build to pass - update type and default value
	all_requirements: any = [];
	yes_staffing: any;
	no_staffing: any;
	components_width = '487px';
	delete_loading: boolean = false;
	save_loading: boolean = false;
	pay_loading: boolean = false;
	api_errors: any;
	timer: any = null;
	loading_anim: boolean = false;
	roleSetup: JobSetup = {
		site: "",
		title: "",
		sector_title: 'Security',
		description: '',
		invoice_desc: '',
		vacancy_service_id: '',
		vacancy_service_title: '',
		vacancy_service_role_uuid: '',
		vacancy_industry_id: '',
		requirements: [],
		client_requirements: [],
		payment_method: "paye",
		worker_hourly_rate: 0,
		bank_holiday_rate: 2,
		smart_staff: false,
		uniform_required: false,
		uniform_description: "",
		uniform_image: "",
		reference: '',
		require_worker_within: 0,
		location_type: '',
		uuid: '',
	}
	pay_type: any = {
		requested_amount: 0,
		fee: 0, //%
		fee_amount: 0,
		total_no_vat: 0,
		minimum_rate: 0,
		holiday_amount: 0,
		holiday: 0, //%,
	}
	broadstone_fee_amount: number = 0;
	contractor_min_rate: number = 0;
	paye_min_rate: number = 0;
	title: string;
	data_sectors: any = [];
	data_industries: any = [];
	dataServiceRoles: any = [];
	role_uuid: string;
	add_a_role: any = { sector_title: 'Security' };
	disabled: boolean = true;
	up: string = './assets/icons/chevron-up.svg'
	down: string = './assets/icons/chevron-down.svg';
	arrows: any = [
		{ id: 1, direction: this.down },
		{ id: 2, direction: this.down },
		{ id: 3, direction: this.down },
		{ id: 4, direction: this.down },
		{ id: 5, direction: this.down }
	]

	refSuggestionsLoading: boolean = false;
	refSuggestions: any = [];
	clientReq: Array<any>;
	requireWorkerWithin: boolean;
	deleteShiftsReason: string = '';
	deleteModalVisible: boolean = false;
	currentUser: any;
	serviceRoleTitle: string = '';
	logo: string;
	constructor(
		private popNotif: PopNotificationService,
		private alert: AlertService,
		public globalHelpers: GlobalHelpers,
		private jobservice: JobsService,
		public costHelper: Costs,
		private smart_staff_service: SmartStaffService,
		private session: AuthSessionService,
		public jobProvider: JobsProviders,
	) {
		if (this.session.isAuthenticated()) {
			this.currentUser = this.session.getUser();
			if (!this.session.getIsAdmin()) {
				this.logo = this.currentUser.company.logo;
			}
		}
	}

	ngOnChanges() {
		if (this.editJob && this.job) {// Load if this is edit page
			if (this.job.site.client_visible) {
				this.sitePostcode = this.job.site.location.postcode;
			} else {
				const postcodeArr = this.job.site.location.postcode.split(' ');
				this.sitePostcode = postcodeArr[0]
			}
			this.serviceRoleTitle = this.job.vacancy_service_role?.title;
			this.getRequirements();
			this.getClientRequirements();
			this.fillTemplate();
			this.checkDataCheckbox();
			this.uniformIsSelected();
			this.getPayRate();
			this.loadPrices();
		}
	}

	ngOnInit() {
		window.scroll(0, 0);
		if (this.editJob) { // Don`t load if this is edit page
			return;
		}
		if (!this.siteId) {
			this.popNotif.createNotification('error', 'There has been a problem.', 'Please reload this page and try again.');
			return false;
		}
		this.roleSetup.site = this.siteId;
		this.getRequirements();
		this.getClientRequirements();
		this.checkDataCheckbox();
		this.getPayRate();
		this.loadPrices();
		this.loading_anim = true;
		this.getAllSectors();
	}

	getAllIndustries() {
		this.jobservice.getIndustries().then(res => {
			this.data_industries = res;
			this.data_industries.forEach(element => {
				element['selected'] = false
			})
		})
			.catch(e => {
				// this.logger.error(e);
				this.popNotif.createNotification('error', 'There has been a problem.', 'Please reload this page and try again.');
				this.loading_anim = false;
			});
	}

	getAllSectors() {
		this.jobservice.getSectors().then(res => {
			this.getAllIndustries();
			this.data_sectors = res;
			this.data_sectors.forEach(element => {
				element['selected'] = false
				this.sortByOther(element.services);
				element.services.forEach(serviceRoles => {
					this.sortByOther(serviceRoles.service_roles);
				});
			})
			this.data_sectors[0].selected = true;
			this.loading_anim = false;
		})
			.catch(e => {
				this.loading_anim = false;
				// this.logger.error(e);
				this.popNotif.createNotification('error', 'There has been a problem.', 'Please reload this page and try again.');
			});
	}
	sortByOther(items) {
		items.sort((a, b) => {
			if (a.title === "Other") {
				return 1;
			} else if (b.title === "Other") {
				return -1;
			} else {
				return 0;
			}
		});
	}

	selectSector(sector) {
		this.resetJobCard();
		this.resetUniform();
		this.resetServicesIndustry();
		this.resetServiceRole(true);

		this.data_sectors.forEach(element => {
			if (element !== sector) {
				element.selected = false;
			}
		})
		sector.selected = true;
		this.roleSetup.sector_title = sector.title;
		if (this.roleSetup && this.roleSetup.sector_title && this.roleSetup.vacancy_service_id && this.roleSetup.vacancy_industry_id) {
			this.disabled = false;
		}
	};

	resetJobCard() {
		this.serviceRoleTitle = '';
		this.roleSetup.vacancy_service_title = '';
	}

	resetServiceRole(clear) {

		this.dataServiceRoles.forEach(element => {
			element.selected = false
		});
		if (clear) {
			this.dataServiceRoles.length = 0;
		}
		this.roleSetup.vacancy_service_role_uuid = '';
		this.disabled = true;

	}

	resetServicesIndustry() {
		this.data_sectors.forEach(element => {
			element.services.forEach(element => {
				element['selected'] = false;
			})
		});
		this.data_industries.forEach(element => {
			element.selected = false
		});
		this.roleSetup.vacancy_service_id = '';
		this.roleSetup.vacancy_industry_id = '';
		this.disabled = true;
	}

	selectService(role) {
		this.serviceRoleTitle = '';
		this.data_sectors.forEach(element => {
			element.services.forEach(element => {
				element['selected'] = false;
				if (element == role) {
					role['selected'] = true;
					this.resetServiceRole(false);
					this.dataServiceRoles = [...role.service_roles];
					this.roleSetup.vacancy_service_id = role.uuid;
					this.roleSetup.vacancy_service_title = role.title;
				}
			})
		})
		if (this.roleSetup && this.roleSetup.sector_title && this.roleSetup.vacancy_service_id && this.roleSetup.vacancy_industry_id) {
			this.disabled = false;
		}
	}

	selectServiceRoleType(serviceRole) {
		this.dataServiceRoles.forEach(element => {
			if (element !== serviceRole) {
				element.selected = false
			}
		})
		serviceRole.selected = true;
		this.roleSetup.vacancy_service_role_uuid = serviceRole.uuid;
		this.serviceRoleTitle = serviceRole.title;
	}

	getServiceRole(){
		return this.serviceRoleTitle ==='Other' ? this.roleSetup.title : this.serviceRoleTitle
	}

	selectIndustryType(industry) {
		this.data_industries.forEach(element => {
			if (element !== industry) {
				element.selected = false
			}
		});
		industry.selected = true;
		this.roleSetup.vacancy_industry_id = industry.uuid;
		if (this.roleSetup && this.roleSetup.sector_title && this.roleSetup.vacancy_service_id && this.roleSetup.vacancy_industry_id) {
			this.disabled = false;
		}
	}

	loadPrices() {
		this.smart_staff_service.getStaffPrices().then((res) => {
			const { prices } = res;
			this.price_data = prices;
		}).catch(e => { });
	}

	expandSection(arrow, onOpen?) {
		this.arrows.forEach((arr, index) => {
			if (arrow == index) {
				if (arr.direction === this.down) {
					arr.direction = this.up
				}
				else if (arr.direction === this.up && !onOpen) {
					arr.direction = this.down
				}
			}
		});
	}

	selectWorkerDistance(e) {
		if (e === 'yes') {
			this.requireWorkerWithin = true;
		}
		else if (e === 'no') {
			this.requireWorkerWithin = false;
			this.roleSetup.require_worker_within = 0;
		}
	}

	getRequirements() {
		this.jobservice.getRequirements().then(res => {
			// res[0].selected = true;
			this.all_requirements = this.groupSia(res);
			if (this.job && this.job.requirements.length > 0) {// SELECT EACH REQUIREMENT
				this.job.requirements.forEach(element => {
					this.selectRequirement(element);
				});
			}
		}).catch(e => {
			// this.logger.error(e);
			this.popNotif.createNotification('error', 'There has been a problem', 'Please reload this page and try again.')
		})
	}

	getClientRequirements() {
		this.jobservice.getClientRequirements().then(res => {
			this.clientReq = res;
			if (this.job && this.job.client_requirements.length > 0) {// SELECT EACH REQUIREMENT
				this.job.client_requirements.forEach((element) => {
					this.populateClientReq(element);
				});
			}
		}).catch((err) => {
			// this.logger.error(err);
			this.popNotif.createNotification('error', 'There has been a problem', 'Please reload this page and try again.')
		});
	}

	groupSia(res) {
		const requirements = res.map(requirement => {
			if (requirement.id === 1 || requirement.id === 2 || requirement.id === 6) {
				requirement.type = 'main_sia';
			} else {
				requirement.type = 'additional_sia';
			}
			return requirement;
		});
		return requirements;
	}

	selectRequirement(qualif) {
		let qualif_item = this.all_requirements.filter(element => {
			return element.id == qualif.id;
		})[0];
		if (!qualif_item) {
			return;
		}
		if (qualif_item.type === 'main_sia') {
			this.all_requirements.forEach(element => {
				if (element.type == 'main_sia') {
					if (qualif.id !== element.id) {
						element.selected = false;
					}
				}
			});
			qualif_item.selected = !qualif_item.selected;
		} else {
			qualif_item.selected = !qualif_item.selected;
		}
		this.roleSetup.requirements = this.all_requirements.filter(element => {
			return element.selected;
		}).map(element => { return element.id });
	}

	selectClientRequirement(req, i) {
		this.clientReq[i].requirements.forEach(element => {
			if ((element.uuid !== req.uuid && (i === 1 || i === 3)) || (element.selected && element.uuid === req.uuid)) {
				element.selected = false;
			}
			else if (element.uuid === req.uuid) {
				element.selected = true;
			}
		});
		let myReq = [];
		this.clientReq.forEach(type => {
			type.requirements.forEach(element => {
				if (element.selected) {
					myReq.push(element.uuid)
				}
			});
		});
		this.roleSetup.client_requirements = myReq;
	}

	populateClientReq(req) {
		this.clientReq.forEach((type, index) => {
			type.requirements.forEach(element => {
				if (req.uuid === element.uuid) {
					element.selected = true;
					this.roleSetup.client_requirements.push(element.uuid)
					this.expandSection(index, 'yes');
				}
			});
		});
	}

	smartStaffCheck(value) {
		if (value == this.smart_staff) {
			return;
		}
		this.smart_staff = value;
	}

	resetUniform() {
		this.uniforms.forEach(element => {
			element.selected = false;
		});
		this.roleSetup.uniform_description = '';
		this.roleSetup.uniform_image = '';
	}

	selectUniform(uniform, index?) {
		this.uniforms.forEach(element => {
			if (element !== uniform) {
				element.selected = false;
			}
		});
		uniform.selected = true;
		this.clearApiErrors('uniform_description');
		if (index == 3 && uniform.selected) {
			this.showModal('Enter Custom Uniform', 'Enter Custom Uniform requirements', 'uniform', uniform);
		} else {
			this.modal.value_uniform = '';
			this.updateUniform('');
		}
		this.storePresetUniform(uniform);
	}

	showModal(modal_title, modal_placeholder, type, item) {
		this.modal.title = modal_title;
		this.modal.placeholder = modal_placeholder;
		this.modal.type = type;
		this.modal.uniform = item;
		this.isVisible = true;
	}

	saveUniform() {
		this.updateUniform(this.modal.value_uniform);
		this.close_modal();
	}

	updateUniform(data) {
		if (this.modal.uniform) {
			let index = this.uniforms.indexOf(this.modal.uniform);
			this.uniforms[index].value = data;
			this.storeCustomUniform(this.uniforms[index]);
		}
	}

	storePresetUniform(uniform) {
		if (uniform.selected) {
			this.roleSetup.uniform_required = true;
			this.roleSetup.uniform_description = uniform.info.replace(/(<([^>]+)>)/ig, "");
			this.roleSetup.uniform_image = uniform.src;
		} else {
			this.roleSetup.uniform_required = false;
			this.roleSetup.uniform_description = null;
			this.roleSetup.uniform_image = null;
		}
	}

	storeCustomUniform(uniform) {
		this.roleSetup.uniform_description = uniform.value ? uniform.value : '';
		this.roleSetup.uniform_image = '';
	}

	handleCancel(modal) {
		if (!this.editJob) {
			if (!modal.value_uniform) {
				this.resetUniform();
			}
		}
		this.close_modal();
	}

	close_modal() {
		this.isVisible = false;
	}

	uniformIsSelected() {
		const { uniform_description } = this.roleSetup;
		if (uniform_description) {
			this.uniforms = this.uniforms.map(uniform => {
				if (uniform_description == uniform.info.replace(/(<([^>]+)>)/ig, "")) {
					uniform.selected = true;
				}
				return uniform;
			});
			const check_uniform = this.uniforms.filter(element => {
				return element.selected;
			}).length > 0;
			if (!check_uniform) { // if no uniform is selected and we have uniform description that don`t match the description info from the uniforms array . select the custom uniform.
				const custom_uniform = this.uniforms[this.uniforms.length - 1];
				custom_uniform.selected = true;
				custom_uniform.value = uniform_description; // add custom uniform description to the template
				this.modal.value_uniform = uniform_description;
			}
		}
	}

	checkDataCheckbox() {
		if (this.roleSetup.hasOwnProperty('smart_staff')) {
			let { smart_staff } = this.roleSetup;
			this.yes_staffing = smart_staff;
			this.no_staffing = !smart_staff;
		}
	}

	fillTemplate() {
		this.roleSetup.site = this.siteId;
		this.roleSetup.description = this.job.description;
		this.roleSetup.vacancy_industry_id = this.job.vacancy_industry.uuid;
		this.roleSetup.sector_title = this.job.vacancy_service.sector.title;
		this.roleSetup.vacancy_service_id = this.job.vacancy_service.uuid;
		this.roleSetup.vacancy_service_title = this.job.vacancy_service?.title;
		this.roleSetup.vacancy_service_role_uuid = this.job.vacancy_service_role?.uuid;
		this.roleSetup.title = this.job.title;
		this.roleSetup.smart_staff = this.job.smart_staff;
		this.roleSetup.uniform_description = this.job.uniform_description;
		this.roleSetup.uniform_image = this.job.uniform_image;
		this.roleSetup.uniform_required = this.job.uniform_required;
		this.roleSetup.worker_hourly_rate = parseFloat(this.job.worker_hourly_rate);
		this.roleSetup.bank_holiday_rate = this.job.bank_holiday_rate;
		this.roleSetup.uuid = this.job.uuid;
		this.roleSetup.payment_method = this.job.payment_method;
		this.roleSetup.reference = this.job.reference;
		this.roleSetup.invoice_desc = this.job.invoice_desc;
		if (this.job.require_worker_within) {
			this.requireWorkerWithin = true;
			this.roleSetup.require_worker_within = this.job.require_worker_within;
		}
		else {
			this.requireWorkerWithin = false;
		}
		this.loadLocationType(this.job.location_type);
	}

	loadLocationType(e) {
		this.workEnvironments.forEach(env => {
			if (env['type'] === e) {
				env['selected'] = true;
				this.roleSetup.location_type = e;
			}
		});
	}

	save() {
		if (this.editJob) {
			this.updateJob();
		} else {
			this.postJob();
		}
	}

	postJob() {
		this.save_loading = true;
		this.jobservice.createJob(this.roleSetup).then(res => {
			this.save_loading = false;
			this.onSaved.emit(res);
		}).catch(e => {
			if (!e) {
				return;
			}
			this.showErrors(e);
			this.save_loading = false;
		});
	}

	updateJob() {
		if (!this.job.uuid) {
			return;
		}
		this.save_loading = true;
		this.jobservice.updateJob(this.roleSetup, this.job.uuid).then(res => {
			this.save_loading = false;
			this.onEdited.emit(res);
		}).catch(e => {
			if (!e) {
				return;
			}
			this.showErrors(e);
			this.save_loading = false;
		});
	}

	showErrors(e) {
		this.api_errors = e;
		const first_error = this.sortAscending(Object.keys(e))[0]; // sort and get first error
		if (first_error == 'title' || first_error == 'description' || first_error == 'uniform_description' || first_error == 'worker_hourly_rate' || first_error == 'bank_holiday_rate' || first_error == 'require_worker_within' || first_error == 'invoice_desc' || first_error == 'location_type') {
			this.globalHelpers.scroll_to_section(first_error);
		} else if (first_error == 'vacancy_service_id' || first_error == 'vacancy_industry_id' || first_error == 'vacancy_service_role_uuid') {
			window.scroll(0, 0)
		}
		else {
			this.popNotif.createNotification('error', 'There has been a problem.', e);
		}
	}

	clearApiErrors(error_key) {
		if (this.api_errors && this.api_errors[error_key]) {
			this.api_errors[error_key] = '';
		}
	}
	sortAscending(e) {
		return e.sort((a: any, b: any) => {
			if (a.includes('vacancy') && !b.includes('vacancy')) {
				return -1; // a comes before b
			}
			if (!a.includes('vacancy') && b.includes('vacancy')) {
				return 1; // b comes before a
			}
			if (a < b) {
				return -1; // sort string ascending
			}
			if (a > b) {
				return 1;
			}
			return 0; // default return value (no sorting)
		});
	}

	openDeleteModal() {
		this.alert.showConfirm("Are you sure?", "This role will be deleted and cannot be recovered.").then(() => {
			this.deleteModalVisible = true;
		}).catch(() => {
			//cancel alert
		});
	}

	deleteRole() {
		const { uuid } = this.roleSetup;
		const reason = { 'remarks': this.deleteShiftsReason };
		if (!uuid || !reason) {
			return;
		}
		this.delete_loading = true;
		this.jobservice.deleteJob(uuid, reason).then(res => {
			if (!this.siteId) {
				return;
			}
			this.onDeleted.emit(res);
			this.popNotif.createNotification('success', 'Success', 'The role has been deleted successfully.');
			this.delete_loading = false;
			this.closeDeleteModal();
		}).catch(errors => {
			errors.forEach(error => {
				this.popNotif.createNotification('error', 'There has been a problem.', error);
				this.delete_loading = false;
				this.closeDeleteModal();
			});
		})
	}

	back() {
		this.onBack.emit();
	}

	checkPay() {
		if (!this.roleSetup.worker_hourly_rate) {
			clearTimeout(this.timer);
			this.pay_loading = false;
			return;
		}
		this.pay_loading = true;
		clearTimeout(this.timer);
		this.timer = setTimeout(() => {
			const pay = {
				"payment_method": this.roleSetup.payment_method,
				"amount": this.roleSetup.worker_hourly_rate
			};
			this.jobservice.getPay(pay).then((result) => {
				this.pay_type = this.costHelper.map_pay(result);
				this.broadstone_fee_amount = this.pay_type.fee_amount + this.pay_type.additional_fee_amount;
				this.pay_loading = false;
			}).catch((err) => {
				this.pay_loading = false;
				// this.logger.error(err);
				if (err.amount) {
					this.popNotif.createNotification('error', 'There has been a problem,', err.amount)
				}
			});
			this.clearApiErrors('worker_hourly_rate');
		}, 500);
	}

	getPayRate() {
		this.jobservice.getPayRates().then((result) => {
			this.contractor_min_rate = result.contractor.minimum_worker_hourly_rate;
			this.paye_min_rate = result.paye.minimum_worker_hourly_rate; //preset roleSetup.worker_hourly_rate when first loading as default contractor
			if (!this.editJob) {
				this.roleSetup.worker_hourly_rate = this.paye_min_rate;
			}
			this.checkPay();  // and call checkPay base on contractor min rate
		}).catch((err) => {
			// this.logger.error(err);
		});
	}

	replaceStr(str) {
		return str[0].replace('worker_hourly_rate', 'Pay Rate');
	}

	checkReference(e) {
		this.refSuggestionsLoading = true;
		this.roleSetup.reference = e;
		this.jobservice.getJobReference(e, 1)
			.then((res: any) => {
				if (res.length > 0) {
					this.refSuggestions = res.map(element => {
						this.refSuggestionsLoading = false;
						return {
							title: element.reference,
						};
					});
				} else {
					this.refSuggestions = [];
					this.refSuggestionsLoading = false;
				}
			})
			.catch(e => {
				this.refSuggestionsLoading = false;
				// this.logger.error(e);
			})
	}

	refValueSelected(value: any) {
		this.roleSetup.reference = value.title;
	}

	selectWorkEnv(env) {
		this.roleSetup.location_type = env
		this.workEnvironments.forEach(workEnv => {
			if (env === workEnv['type']) {
				workEnv['selected'] = true;
			}
			else {
				workEnv['selected'] = false;
			}
		})
	}

	selectDeleteReason(reason) {
		if (reason === 'Other') {
			this.deleteShiftsReason = '';
		}
		else {
			this.deleteShiftsReason = reason;
		}
		this.jobProvider.deleteReasons.map(elem => {
			if (reason === elem.reason) {
				elem.selected = true;
			}
			else {
				elem.selected = false;
			}
		});
	}

	closeDeleteModal() {
		this.deleteShiftsReason = '';
		this.deleteModalVisible = false;
		this.jobProvider.deleteReasons.map(elem => {
			elem.selected = false;
		});
	}

}
