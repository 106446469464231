<nz-skeleton *ngIf="!worker" class="nz-timesheets-loading margin-top-32" [nzParagraph]="{ rows: 7 }" [nzTitle]="false"
	[nzActive]="true">
</nz-skeleton>
<div *ngIf="worker" class="edit-worker-wrapper">
	<div class="avatar-area">
		<div *ngIf="worker.profile?.strike_stats?.total > 0" class="strikes-container" nz-popover
			[nzPopoverContent]="strikesPopover" nzPopoverTrigger="hover" nzPlacement="right">
			<div class="strike-inner">
				<img [src]="worker.profile?.strike_stats?.total > 0 ? darkStrike : lightStrike">
				<img [src]="worker.profile?.strike_stats?.total > 1 ? darkStrike : lightStrike">
				<img [src]="worker.profile?.strike_stats?.total > 2 ? darkStrike : lightStrike">
			</div>

		</div>
		<nz-avatar [nzText]='worker.details.name[0] | uppercase' [nzSrc]="worker.details.avatar" nzSize="large">
		</nz-avatar>
		<h3 class="margin-top-16">{{worker.details.name | upperFirst}} {{worker.details.surname | upperFirst}}</h3>

		<ng-template #strikesPopover>
			<div class="strikesPopover">
				<h3 class="b-font-demibold">Strikes</h3>
				<div class="strike-inner">
					<img [src]="worker.profile?.strike_stats?.total > 0 ? darkStrike : lightStrike"
						[alt]="worker.details.name + worker.details.surname + 'has 2/3 strikes'">
					<img [src]="worker.profile?.strike_stats?.total > 1 ? darkStrike : lightStrike">
					<img [src]="worker.profile?.strike_stats?.total > 2 ? darkStrike : lightStrike">
				</div>
				<p class="expiry">Expire in: {{worker.profile?.strike_stats?.expires_in}} <span
						*ngIf="worker.profile?.strike_stats?.expires_in === 1">day</span><span
						*ngIf="worker.profile?.strike_stats?.expires_in > 1">days</span></p>
				<hr>
				<div class="info-header">
					<p class="b-font-demibold row reason">Reason</p>
					<p class="b-font-demibold row">Date</p>

				</div>

				<div *ngFor="let info of worker.profile?.strike_stats?.breakdown">
					<div class="info-body">
						<p class="row reason">{{info.type.notes}}</p>
						<p class=" row">{{info.dateIssued | dateFormat:'DD.MM.YY'}}</p>

					</div>
				</div>


			</div>
		</ng-template>
	</div>

	<div class="rating-profile">
		<img src="/assets/icons/star-rating.svg">
		<div class="rating">
			<h4 class="b-font-demibold margin-right-8">{{ worker.profile?.gamification_stats?.rating?.current }}</h4>
			<p class="small"> out of {{ worker.profile?.gamification_stats?.rating?.max }} rating</p>
		</div>

	</div>
	<div tappable class="rating-profile">
		<img src="/assets/icons/orkaPoints.svg">
		<div class="rating">
			<h4 class="b-font-demibold margin-right-8">{{worker.profile?.gamification_stats?.points?.points}}</h4>
			<p class="small"> points</p>
		</div>
	</div>

	<div>
		<p class="b-font-demibold margin-top-16 margin-bottom-16">
			Future Applications<br>
			<!-- <span class="b-font-normal b-Eh4 b-light ">(Security, Staffing)</span> -->
		</p>
		<div class="feature-applications-wrapper">
			<div class="aligned-flex margin-top-8 margin-bottom-16">
				<div class="margin-right-16">
					<p><span class="application-dot dot-green"></span>
						Accepted
					</p>
					<h4 class="b-font-demibold">{{worker?.profile?.future_applications_accepted}} <span class="b-Eh4 b-light">Security, Staffing</span></h4>
				</div>
				<div>
					<p><span class="application-dot dot-grey"></span>
						Pending
					</p>
					<h4 class="b-font-demibold">{{worker?.profile?.future_applications_pending}} <span class="b-Eh4 b-light">Security, Staffing</span></h4>
				</div>

			</div>
			<div>
				<p><img class="registered-icon" src="/assets/icons/registered-interest.svg" />
					Registered Interest
				</p>
				<div class="aligned-flex">
					<h4 class="b-font-demibold margin-right-16">{{worker?.profile?.future_security_applications_held}} <span class="b-Eh4 b-light">Security</span></h4>
					<h4 class="b-font-demibold">{{worker?.profile?.future_staffing_applications_held}} <span class="b-Eh4 b-light">Staffing</span></h4>
				</div>
			</div>
		</div>

	</div>

	<div class="aligned-flex space-between margin-bottom-8 margin-top-32">
		<p class="b-font-demibold">First Worked Shift</p>
		<p *ngIf="worker.profile?.first_worked_shift">
			{{worker.profile?.first_worked_shift?.end_at | dateFormat:'DD/MM/YY' : 'Europe/London'}}
		</p>
		<p *ngIf="!worker.profile?.first_worked_shift">
			N/A
		</p>
	</div>
	<div class="aligned-flex space-between">
		<p class="b-font-demibold">Last Worked Shift</p>
		<p *ngIf="worker.profile?.last_worked_shift">
			{{worker.profile?.last_worked_shift?.shift?.end_at | dateFormat:'DD/MM/YY' : 'Europe/London'}}
		</p>
		<p *ngIf="!worker.profile?.last_worked_shift">
			N/A
		</p>
	</div>

	<form [formGroup]="form">

		<!-- TITLE -->

		<div class="row-spacing">
			<div *ngIf="worker.taxInformation?.title" class="aligned-flex space-between">
				<p class="b-font-demibold">Title</p>
				<div>
					<nz-select [(ngModel)]="worker.taxInformation.title">
						<nz-option nzValue="Mr" nzLabel="Mr"></nz-option>
						<nz-option nzValue="Mrs" nzLabel="Mrs"></nz-option>
						<nz-option nzValue="Miss" nzLabel="Miss"></nz-option>
						<nz-option nzValue="Ms" nzLabel="Ms"></nz-option>
					</nz-select>
				</div>

			</div>

		</div>

		<!-- GENDER -->

		<div class="row-spacing">
			<div *ngIf="worker.taxInformation?.gender" class="aligned-flex space-between">
				<p class="b-font-demibold">Gender</p>
				<div>
					<nz-select [(ngModel)]="worker.taxInformation.gender">
						<nz-option nzValue="M" nzLabel="Male"></nz-option>
						<nz-option nzValue="F" nzLabel="Female"></nz-option>

					</nz-select>
				</div>

			</div>

		</div>

		<!-- MARITAL STATUS -->

		<div class="row-spacing">
			<div *ngIf="worker.taxInformation?.maritalStatus" class="aligned-flex space-between">
				<p class="b-font-demibold">Marital Status</p>
				<div>
					<nz-select [(ngModel)]="worker.taxInformation.maritalStatus">
						<nz-option nzValue="Single" nzLabel="Single"></nz-option>
						<nz-option nzValue="Married" nzLabel="Married"></nz-option>
						<nz-option nzValue="Divorced" nzLabel="Divorced"></nz-option>
						<nz-option nzValue="Widowed" nzLabel="Widowed"></nz-option>
						<nz-option nzValue="Civil Partnership" nzLabel="Civil Partnership"></nz-option>
						<nz-option nzValue="Other" nzLabel="Other"></nz-option>
					</nz-select>
				</div>

			</div>

		</div>

		<!-- DATE OF BIRTH -->

		<div class="row-spacing">
			<div *ngIf="worker.taxInformation?.dateOfBirth" class="aligned-flex space-between">
				<p class="b-font-demibold">Date Of Birth</p>
				<div>
					<nz-date-picker [nzAllowClear]="false" [(ngModel)]="worker.taxInformation.dateOfBirth" required
						class="nz-override-date-range nz-override-date-b-input nz-date-no-icon"
						nzDropdownClassName='nz-override-date-range-drop' nzFormat='yyyy/MM/dd'>
					</nz-date-picker>
				</div>
			</div>
		</div>


		<!-- NI -->

		<div class="row-spacing">
			<div *ngIf="niDefault" class="aligned-flex space-between">
				<p class="b-font-demibold">NI Number</p>
				<div class="ni-number-area">
					<input [pattern]="ninoPattern" class="b-input b-input-white custom-width" #nino="ngModel"
						[(ngModel)]="worker.profile.ni_number" type="text" name="name" required>

					<div *ngIf="(nino.invalid && (nino.dirty || nino.touched)) && nino.errors.pattern">
						<p class="error-box custom-width">NI is invalid.</p>
					</div>

					<div *ngIf="(nino.invalid && (nino.dirty || nino.touched)) && nino.errors.required">
						<p class="error-box custom-width">NI is required.</p>
					</div>
				</div>

			</div>

		</div>

		<!-- Left-->

		<div class="row-spacing">
			<div class="aligned-flex space-between">
				<p class="b-font-demibold">Left Day</p>
				<div>
					<nz-date-picker [nzDisabledDate]="disabledDate" [(ngModel)]="worker.details.leftAt"
						class="nz-override-date-range nz-override-date-b-input nz-date-no-icon"
						nzDropdownClassName='nz-override-date-range-drop' nzFormat='yyyy/MM/dd'>
					</nz-date-picker>
				</div>
			</div>
		</div>



		<!-- FIRST NAME -->

		<div class="row-spacing">
			<p class="b-font-demibold margin-bottom-8">First Name</p>
			<input class="b-input b-input-white custom-width" [(ngModel)]="worker.details.name" type="text" name="name"
				required>
			<!-- <div *ngIf="name.hasError('required') && name.touched">
				<p class="error-box custom-width">Please fill in this field.</p>
			</div> -->
		</div>

		<!-- LAST NAME -->

		<div class="row-spacing">
			<p class="b-font-demibold margin-bottom-8">Last Name</p>
			<input class="b-input b-input-white custom-width" [(ngModel)]="worker.details.surname" type="text"
				name="surname" required id='last-name-id'>
		</div>

		<!-- EMAIL ADDRESS -->

		<div class="row-spacing">
			<p class="b-font-demibold margin-bottom-8">Email Address</p>
			<input class="b-input b-input-white custom-width" [(ngModel)]="worker.details.email" email="true"
				type="email" name="email" required>
		</div>

		<!-- PHONE NUMBER -->

		<div class="row-spacing">
			<p class="b-font-demibold margin-bottom-8">Phone Number</p>

			<input class="b-input b-input-white custom-width" [(ngModel)]="worker.details.phone" type="text"
				name="phone">

		</div>

	</form>

	<div class="button-area">
		<app-clib-button label='Save' (click)="save()"></app-clib-button>
	</div>
</div>