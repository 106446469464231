import { Injectable } from "@angular/core";
import * as moment from 'moment';
import { FiltersServices } from '../filters/filters.service';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';


@Injectable()

export class UrlHelpers {
    queryString: string;
    currentPath: any;
	clientNavBar: boolean = false;
    constructor(
        public filters: FiltersServices,
        private route: ActivatedRoute,
        private location: Location,
        public router: Router
    ) {
    }
    formatDate(date, returnFormat = "YYYY-MM-DD") {
        return moment(date, "DD/MM/YYYY").format(returnFormat);

    }

    subscribeToUrlParams() {
        let status_param: number;
        this.route.queryParams
            .subscribe(params => {
                switch (params.future) { //check if future or past is set in url 
                    case 'true':
                        this.filters.active_toggle = true;
                        break;
                    case 'false':
                        this.filters.active_toggle = false;
                        break;
                }
                switch (params.status) { //check if status is set in url
                    case 'pending':
                        status_param = 2;
                        break;
                    case 'cancelled':
                        status_param = 4;
                        break;
                    case 'noshow':
                        status_param = 5;
                        break;
                    case 'approved':
                        status_param = 1;
                        break;
                    case 'declined':
                        status_param = 2;
                        break;

                }
                if (params.start && params.end) { //check if date range is set in url
                    this.filters.start_at = params.start;
                    this.filters.end_at = params.end;


                }
                switch (params.jobType) { //check if future or past is set in url 
                    case 'adhoc':
                        this.filters.job_type = 'Adhoc';
                        break;
                    case 'smartstaff':
                        this.filters.job_type = 'SmartStaff';
                        break;
                }

            })

        return status_param;
    }

    getUrlWithoutParams() {
        let urlTree = this.router.parseUrl(this.router.url);
        urlTree.queryParams = {};
        return urlTree.toString();
    }

    onFilterChange() {
        this.queryString = '?';
        const currentPath = this.getUrlWithoutParams();
        if (this.filters.active_toggle) {
            this.queryString += `future=true`
        }
        else if (!this.filters.active_toggle) {
            this.queryString += `future=false`
        }

        if (this.filters.start_at && this.filters.end_at) { //check if date range is set in url
            let start = this.formatDate(this.filters.start_at)
            let end = this.formatDate(this.filters.end_at)
            this.queryString += `&start=${start}&end=${end}`

        }
        if (this.filters.status) {
            this.queryString += `&status=`;
            switch (this.filters.status) { //check if status is set in url
                case 'pending':
                    this.queryString += `pending`
                    break;
                case 'cancelled':
                    this.queryString += `cancelled`
                    break;
                case 'no-show':
                    this.queryString += `noshow`
                    break;
                case 'accepted':
                    this.queryString += `approved`
                    break;
                case 'declined':
                    this.queryString += `declined`
                    break;
            }
        }
        if (this.filters.job_type) {
            this.queryString += `&jobType=`;
            switch (this.filters.job_type) {
                case 'AdHoc':
                    this.queryString += `adhoc`
                    break;
                case 'SmartStaff':
                    this.queryString += `smartstaff`
            }
        }
        this.location.go(currentPath, this.queryString)
    }

}