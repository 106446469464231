import { AuthSessionService } from '@broadstone/auth-session-service';
import { ApiService } from '@broadstone/api-service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CachingService } from '../caching-service';


@Injectable({
	providedIn: 'root'
})
export class PermissionsService {
	all_permissions: any = [];
	constructor(
		public http: HttpClient,
		public api: ApiService,
		private session: AuthSessionService,
		private cachingService: CachingService
	) {

	}

	public getStaffPermissions(): Promise<any> {
		return new Promise((resolve, reject) => {
			const url = this.api.getFullUrl('company/staff/permissions');
			const headers = this.api.getDefaultHeaders(this.session.getToken());
			this.cachingService.addCache(url, 300);
			return this.http.get(url, { headers })
				.subscribe(
					(data) => {
						this.all_permissions = (data['data']);
						resolve(this.all_permissions);
					},
					(error) => reject(this.api.handleErrors(error))
				);
		});

	}



}
