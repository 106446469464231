<div class="workers-container">
	<div>
		<div class="search-box">
			<app-list-drop-down (selectedItem)="selectedSearchItem($event)" [title_uppercase]='true'
				[border_right]='false' element_width="150px" [data]="searchBoxInput">
			</app-list-drop-down>
			<app-search-input class="employee-search-bar" debounce='800'
				placeholder="Search by {{selectedSearchValue?.title}}" (onKey)="searchAction()"
				[(inputModel)]="searchValue" class_style="no-border-left">
			</app-search-input>

			<div class="search-calendar">
				<label class="custom-text">Date</label>
				<nz-range-picker nzInputReadOnly [(ngModel)]='rangeDates' #calendar_range
					[nzDisabledDate]="disabledDate" (ngModelChange)="onCloseRange()"
					[nzPlaceHolder]="['dd/yy/mm', 'dd/yy/mm']" class="nz-override-date-range"
					nzDropdownClassName='nz-override-date-range-drop' nzFormat='dd/MM/yyyy'>
				</nz-range-picker>
			</div>
			<div nz-tooltip nzTooltipTitle='Expand Filters' (click)="expandFilters()"
				[ngClass]="showFilters ? 'active-filter-btn':''" class="filters-button">
				<div *ngIf="!showFilters && checkActiveFilters()" class="activated-filters"></div>
				Filters <img src="assets/icons/filterdark.svg" />
			</div>
			<div nz-tooltip nzTooltipTitle='Clear Filters' (click)="clearFilters()" class="clear-filters-icon-area">
				<img src="assets/icons/refresh.svg" />
			</div>

		</div>
		<div [ngClass]="showFilters ? 'show' : 'hide'" class="filters-area-wrapper">

			<div>
				<label class="custom-text">Register Sector</label>
				<div *ngFor="let filterSector of registerSectors">
					<label class="b-checkbox margin-bottom-8">
						<p>{{filterSector.title}}</p>
						<input type="radio" [checked]="filterSector.active"
							(click)="selectSector(filterSector, 'registerSectors')">
						<span class="checkmark"></span>
					</label>
				</div>
			</div>
			<div>
				<label class="custom-text">Can Work Sector</label>
				<div *ngFor="let filterSector of canWorkSectors">
					<label class="b-checkbox margin-bottom-8">
						<p>{{filterSector.title}}</p>
						<input type="radio" [checked]="filterSector.active"
							(click)="selectSector(filterSector, 'canWorkSectors')">
						<span class="checkmark"></span>
					</label>
				</div>
			</div>


			<div *ngFor="let filterWorker of filterWorkers">
				<label class="custom-text">{{filterWorker.title}}</label>
				<app-list-drop-down [reset]="filterWorker.reset"
					(selectedItem)="selectFilterDropDown(filterWorker, $event)" [title_uppercase]='false'
					[border_right]='false' element_width="180px" [data]="filterWorker.dropDown">
				</app-list-drop-down>
			</div>
		</div>

	</div>
	<div class="workers-list">


		<lib-no-search-data *ngIf="allWorkers.length === 0 && !loading"
			img_src='assets/icons/magnifying-glass-tilted-left.svg' title='No Results Found'
			body='Could not find any matches. Click the button below to clear the filters.' button_name='Clear Filters'
			button_class='outline' (onButtonClick)="clearFilters()">
		</lib-no-search-data>

		<nz-skeleton *ngIf="allWorkers.length === 0 && loading" class="nz-cites-loading" [nzParagraph]="{ rows: 5 }"
			[nzTitle]="false" [nzActive]="true">
		</nz-skeleton>

		<div *ngIf="allWorkers.length > 0 && !loading">
			<div class=" worker-details d-label">
				<label class="custom-text row1">Worker</label>
				<label class="custom-text row2">Created</label>
				<label class="custom-text row3">Action</label>
			</div>
			<div [ngClass]="{'suspended': worker?.suspendedAt}" class="row-spacing" *ngFor="let worker of allWorkers">

				<div class="worker-details">
					<nz-avatar class="cursor-pointer" (click)="openWorkerDrawer(worker)"
						[nzText]='worker.name[0] | uppercase' [nzSrc]="worker.avatar" nzSize="small">
					</nz-avatar>
					<div class="details row1">
						<h5 class="b-font-demibold">{{worker.name}} {{worker.surname}}</h5>
						<p>{{worker.phone}}</p>
						<p class="b-Eh4 b-light">{{worker.email}}</p>
						<div class="can-work-sectors">
							<img *ngIf="worker.registerSecurity" nzTooltipPlacement="top"
								[nzTooltipColor]="worker.workSecurity ? '#1dc7b2' : '#9aa5b1'" nz-tooltip
								[nzTooltipTitle]="worker.workSecurity ? 'Can Work Security': 'Not Work Security'"
								src="assets/images/securityBadge.svg" [ngClass]="!worker.workSecurity ? 'suspended':''"
								class="worker-icon" />
							<img *ngIf="worker.registerStaffing" nzTooltipPlacement="top"
								[nzTooltipColor]="worker.workStaffing ? '#1dc7b2' : '#9aa5b1'" nz-tooltip
								[nzTooltipTitle]="worker.workStaffing ? 'Can Work Staffing': 'Not Work Staffing'"
								src="assets/images/staffingBadge.svg" [ngClass]="!worker.workStaffing ? 'suspended':''"
								class="worker-icon" />
						</div>
					</div>

					<p class="b-light row2">{{worker.userCreatedAt | dateFormat:'DD/MM/YY' : 'Europe/London'}}</p>
					<div class="buttons-wrapper row3">
						<app-clib-button type="outline" (click)="showStatus(worker)" label="Status">
						</app-clib-button>
						<app-clib-button *ngIf="!worker.suspendedAt" label='Suspend' color="warning"
							(click)="suspendAction(worker)"></app-clib-button>
						<app-clib-button *ngIf="worker.suspendedAt" nzTooltipPlacement="right" nzTooltipColor='#fd6d6d'
							nz-tooltip nzTooltipTitle='Suspended: {{worker?.suspendedMessage}}' color="warning"
							label='Unsuspend' (click)="suspendAction(worker)"></app-clib-button>

						<p class="last-seen">Last Seen: {{worker.lastActive}}</p>
					</div>
				</div>

			</div>
			<div class="pagination">
				<nz-pagination nzSimple nzHideOnSinglePage="true" class="b-nz-pagination" [nzSize]="'medium'"
					[nzPageIndex]="page" [nzPageSize]="1" [nzTotal]="totalPages"
					(nzPageIndexChange)="pageChange($event)">
				</nz-pagination>
			</div>
		</div>

	</div>

	<app-suspend-worker *ngIf="suspendModalVisible" (refreshWorkers)="refreshWorkers()" [worker]="workerInfo"
		[(suspendModalVisible)]="suspendModalVisible" />
	<app-worker-statuses *ngIf="statusModalVisible" (refreshWorkers)="refreshWorkers()" [worker]="workerInfo"
		[(statusModalVisible)]="statusModalVisible" />
	<nz-drawer [(nzVisible)]="viewWorkerDrawer" nzWidth="425px" [nzClosable]="false" (nzOnClose)="closeWorkerDrawer()">
		<ng-container *nzDrawerContent>
			<div class="close-btn text-right"><a class="cursor-pointer" (click)="closeWorkerDrawer()"><img
						src="assets/icons/crossIcon.svg" /></a>
			</div>
			<app-edit-worker (refreshWorkers)="closeWorkerDrawer(); refreshWorkers()"
				[worker]="workerInfo"></app-edit-worker>
		</ng-container>
	</nz-drawer>
</div>