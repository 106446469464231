import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-staff-account-edit',
  templateUrl: './staff-account-edit.component.html',
  styleUrls: ['./staff-account-edit.component.scss']
})
export class StaffAccountEditComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }

}
