import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SetupFormComponent } from './setup-form.component';
import { FormsModule } from '@angular/forms';
import { InputListBuilderModule } from '../input-list-builder/input-list-builder.module';
import { ClibButtonModule } from '@broadstone/clib-button';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzSwitchModule } from 'ng-zorro-antd/switch';




@NgModule({
    declarations: [SetupFormComponent],
    exports: [SetupFormComponent],
    imports: [
        CommonModule,
        FormsModule,
        ClibButtonModule,
        NzGridModule,
		NzSwitchModule,
        InputListBuilderModule
    ]
})
export class SetupFormModule { }
