import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationPrefComponent } from './notification-pref.component';
import { FormsModule } from '@angular/forms';
import { NzPopoverModule } from 'ng-zorro-antd/popover';



@NgModule({
    declarations: [NotificationPrefComponent],
    exports: [NotificationPrefComponent],
    imports: [
        CommonModule,
        FormsModule,
        NzPopoverModule
    ]
})
export class NotificationPrefModule { }
