import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { MaintenanceService } from './maintenance.service';
import { AuthSessionService } from '@broadstone/auth-session-service';


@Injectable({
	providedIn: 'root'
})
export class MaintenanceGuardService  {
	constructor(
		private router: Router,
		private maintenance: MaintenanceService,
		private session: AuthSessionService

	) {

	}

	async canActivate() {
		return await this.maintenance.checkMaintenanceMode().then((result: any) => {
			if (result.data.maintenance_mode) {
				this.session.logout();
				this.router.navigateByUrl('maintenance-mode')
				return false;
			}
			else {
				return true;
			}
		}).catch((err) => {
			return true;
		});
	}


}
