<div *ngIf="assigned_staff.length > 0" [style.width]="width" class="row-spacing">
  <div class="form-card staff">
    <h4>Assigned</h4>
    <div class="items">
      <div class="staff-item" *ngFor="let staff of assigned_staff">
        <div class="staff-wrapper">
          <div class="staff-info">
            <nz-avatar class="staff-avatar" [nzText]='staff.title[0]' [nzSrc]="staff.avatar" nzSize="small"></nz-avatar>
            <div class="staff-name">
              <h5>{{staff.title}}</h5>
              <p *ngIf="staff.subtitle">{{staff.subtitle}}</p>
             
            </div>
           
          </div>
          <div class="staff-actions">
            <!-- <app-select-drop-down class='grey-drop-down' element_width='195px' [data]="['All', '48hrs']">
            </app-select-drop-down> -->
            <a *ngIf="delete_icon" (click)="delete(staff)"><img src="assets/icons/ic-delete.svg" /></a>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>