import { phone } from "phone";
import { ValidatorFn, AbstractControl, ValidationErrors } from "@angular/forms";

export function PhoneNumberValidator(countryCode: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const phoneCheck = phone(countryCode + control.value);
    if (!phoneCheck.isValid && phoneCheck.countryIso2 !== "GB") {
      return { invalidPhoneNumber: true };
    }
    return null;
  };
}
