<div class="floating-footer-wrapper">
    <div class="count-selection">
        <h2 class="b-font-demibold b-white">{{nr}}</h2>
    </div>
    <div class="selections">
        <h4 class="b-font-demibold">{{bar_title}} <app-loading-spinner *ngIf="loading" size="small"
                class="link-loading">
            </app-loading-spinner>
        </h4>
        <div class="data-selections">
            <div *ngFor="let dot of nr_array" class="b-dot-large"></div>
        </div>
    </div>
    <div class="actions">
        <div *ngIf="!no_accept" (click)="approve()" class="action cursor-pointer">
            <img src="assets/icons/approve-icon.svg" />
            <div class="b-Eh4">Approve</div>
        </div>
        <div *ngIf="!no_decline" (click)="decline()" class="action cursor-pointer">
            <img src="assets/icons/decline-icon.svg" />
            <div class="b-Eh4">Decline</div>
        </div>
        <div *ngIf="extra_action" (click)="action()" class="action cursor-pointer">
            <img src="assets/icons/{{extra_action.icon}}.svg" />
            <div class="b-Eh4">{{extra_action.name}}</div>
        </div>
        <div *ngIf="extra_action1" (click)="action1()" class="action cursor-pointer">
            <img src="assets/icons/{{extra_action1.icon}}.svg" />
            <div class="b-Eh4">{{extra_action1.name}}</div>
        </div>
        <div *ngIf="extra_action2" (click)="action2()" class="action cursor-pointer">
            <img src="assets/icons/{{extra_action2.icon}}.svg" />
            <div class="b-Eh4">{{extra_action2.name}}</div>
        </div>


    </div>
    <div (click)="close()" class="close-button cursor-pointer">
        <img src="assets/icons/close_modal.svg" />
    </div>
</div>