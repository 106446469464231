<div class="main-container">
        <app-clib-button id="center" label="Create Company" (pressAction)="createCompany()">
        </app-clib-button>

        <div class="row-spacing">
            <h3 class="client-visible-info b-font-demibold">
                OR
            </h3>
        </div>
        <div class="search-bar-area">
            <app-search-input debounce='500' placeholder='Search company to login as' (onKey)="searchApi()"
                [(inputModel)]="filters.title"></app-search-input>
        </div>

        <nz-skeleton *ngIf="loading" class="nz-cites-loading" [nzParagraph]="{ rows: 5 }" [nzTitle]="false"
            [nzActive]="true">
        </nz-skeleton>

        <div class="row-spacing" *ngFor="let company of companies">
            <div class="site-details-container" *ngIf="!loading">
                <div class="details-container">
                    <nz-avatar [nzText]="company && company.title ? company.title[0] : ''"
                        [nzSrc]="company && company.logo ? company.logo : ''" nzSize="large">
                    </nz-avatar>
                    <div class="company-info">
                        <h4>{{company.title}}</h4>
                        <p *ngIf="company && company.description" nz-tooltip nzTooltipTitle="{{company.description}}">
                            {{company.description}}</p>
                        <p>Unfilled shifts: {{ company.shifts_unfilled_count ? company.shifts_unfilled_count : 0}}</p>
                    </div>
                </div>

                <div class="schedule-container">
                    <app-clib-button label="Select"
                        (pressAction)="companySelected(company?.uuid, company.title, company.logo)">
                    </app-clib-button>
                </div>

            </div>
        </div>
        <div class="pagination">
            <nz-pagination nzSimple nzHideOnSinglePage="true" class="b-nz-pagination" [nzSize]="'medium'"
                [nzPageIndex]="page" [nzPageSize]="1" [nzTotal]="totalPages" (nzPageIndexChange)="pageChange($event)">
            </nz-pagination>
        </div>

    </div>