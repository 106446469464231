import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-list-drop-down',
	templateUrl: './list-drop-down.component.html',
	styleUrls: ['./list-drop-down.component.scss'],
})
export class ListDropDownComponent implements OnInit {
	@Input() status_param: number;
	@Input() disableDropDownItem: string[] = [];
	@Input() removedDropDownItem: string[] = [];
	@Input() data: any = [];
	@Input() element_width;
	@Input() reset: any = false;
	@Input() border_right: boolean = true;
	@Input() title_uppercase: boolean = false;
	@Output() selectedItem: EventEmitter<any> = new EventEmitter();
	active: boolean = false;
	value: string;
	show_list: boolean = false;

	constructor() {}

	ngOnInit() {
		this.default();
	}

	ngOnChanges() {
		if (this.reset) {
			// when clear filters
			this.default();
		}
	}

	default() {
		if (this.data && this.data.length > 0) {
			if (this.status_param) {
				this.value = this.data[this.status_param].title;
				this.selectedItem.emit(this.data[this.status_param]);
			} else {
				this.value = this.data[0].title; //set default for first;
				this.selectedItem.emit(this.data[0]);
			}
		}
	}

	togglePopover() {
		this.show_list = !this.show_list;
	}

	setValue(element) {
		if (this.disableDropDownItem.includes(element.title)) {
			return;
		}
		this.value = element.title;
		this.show_list = false;
		this.selectedItem.emit(element);
	}

	showDropDown() {
		// Show dropdown if only more than one item
		if (this.data && this.data.length > 1) {
			this.show_list = !this.show_list;
		}
	}
}
