

import { Component, OnInit } from '@angular/core';
import { AuthService, EventsTracker, UsersService } from 'src/services/public_api';
import { AuthSessionService } from '@broadstone/auth-session-service';
import { PopNotificationService } from '@broadstone/pop-notification-service';
import { Router } from '@angular/router';
import { AlertService } from '@broadstone/alert';
import { CookieService } from 'ngx-cookie-service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
	logo_src = 'assets/logo/orka-word.svg';
	loggedInUser: any;
	carousel_data = [
		{ image: 'assets/icons/emoji/workforce-emoji.svg', title: 'On-demand Workforce', subtitle: 'Thousands of workers eager and ready to work for you!' },
		{ image: 'assets/icons/emoji/man-juggling.svg', title: 'Scheduling', subtitle: 'Let us do the hard work for you!' },
		{ image: 'assets/icons/emoji/memo.svg', title: 'Timesheets', subtitle: 'Manage timesheets with ease!' },
	]
	twoFA: boolean = false;
	contactMethod: any;
	remember: boolean;
	bannerVisible: boolean = true;
	selectMethod: boolean = false;
	userAccount: any;
	codeFailed: boolean = false;

	constructor(
		private event_tracker: EventsTracker,
		private popNotif: PopNotificationService,
		private router: Router,
		private session: AuthSessionService,
		public auth: AuthService,
		private alert: AlertService,
		private cookie: CookieService,
		private user: UsersService) {
	}

	ngOnInit() {
		if (this.session.isAuthenticated()) {
			this.router.navigate(['worker-map']);
		}
		if (this.checkIE() && !this.cookie.get('ie_warning_dismissed')) {  // Check if the user is using IE
			this.alert.showConfirm('Browser upgrade required',
				'It looks like you\'re using a web browser version that we don\'t support. ' +
				'We recommend the latest version of Chrome, Firefox, Edge or Safari', 'Continue', ' ').then(() => {
					this.cookie.set('ie_warning_dismissed', 'true');
				});
		}
	}

	checkIE() {
		const userAgent = navigator.userAgent;
		const isIe = userAgent.indexOf('MSIE ') > -1 || userAgent.indexOf('Trident/') > -1;
		return isIe;
	}

	login(event) {
		if (!event) {
			return;
		}
		this.remember = event.remember;
		this.auth.authenticate(event.account ? event.account : event).then((data: any) => {
			if (data.message == '2FA Enabled') {
				this.twoFactorAuth(event.account, data.via);
				event.loading.save_loading = false;
			}
			else {
				event.loading.save_loading = false;
				this.session.setToken(data.token);
				this.getUserForSession(data.token, data.is_admin);
			}
		}).catch(e => {
			console.log(e)
			if (e.error === 'is_suspended') {
				this.popNotif.createNotification('error', 'Account Deactivated', 'Sorry it looks like your account is no longer active. Please message us for further information.');
			} else {
				this.popNotif.createNotification('error', 'There has been a problem.', 'Your credentials are wrong, please try again.');
			}
			event.loading.save_loading = false;
		});
	}

	selectAuthMethod(e) {
		if (e === 'sms') {
			this.twoFactorAuth(this.userAccount, { sms: true })
		}
		if (e === 'email') {
			this.twoFactorAuth(this.userAccount, { email: true })
		}
	}

	twoFactorAuth(account, method,) {
		var tfaMethod = '';
		if (method.email && !method.sms) {
			tfaMethod = 'email';
		}
		else if (method.sms && !method.email) {
			tfaMethod = 'sms';
		}
		else {
			this.selectMethod = true;
			this.userAccount = account;
			return;
		}
		const tfaDetails = {
			email: account.email,
			password: account.password,
			tfa: tfaMethod
		}
		this.auth.authenticate(tfaDetails).then((data: any) => {
			this.twoFA = true;
			this.selectMethod = false;
			if (tfaMethod === 'email') {
				this.contactMethod = {
					format: 'An email',
					contact: data.to,
					token: data.token
				};
			}
			else if (tfaMethod === 'sms') {
				this.contactMethod = {
					format: 'A text',
					contact: data.to,
					token: data.token
				};
			}
		}).catch(e => {
			console.log(e);
		})
	}

	forgotPassword() {
		this.router.navigate(['/reset-password']);
	}

	getUserForSession(token, admin) {
		this.user.getCurrentUser().then((data: any) => {
			this.session.login(token, data, admin, this.remember);
			this.event_tracker.trackEvent('login', this.session['currentUser']);
		}).catch((err) => {
			console.log(err);
		});

	}

	loginTFA(e) {
		this.codeFailed = false;
		this.auth.authenticateTFA(e).then((data: any) => {
			this.session.setToken(data.token);
			this.getUserForSession(data.token, data.is_admin);
		}).catch(e => {
			this.popNotif.createNotification('error', 'There has been a problem.', e.message);
			this.codeFailed = true;
		})
	}

}
