import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './client/dashboard/dashboard.component';
import { JobsComponent } from './client/jobs/jobs.component';
import { TimesheetsComponent } from './client/timesheets/timesheets.component';
import { SetupRoleComponent } from './client/jobs/post-job/setup-role/setup-role.component';

import { ManageShiftsComponent } from './client/jobs/post-job/manage-shifts/manage-shifts.component';
import { ManageSiteComponent } from './client/jobs/post-job/manage-site/manage-site.component';

import { SiteComponent } from './client/jobs/site/site.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AuthGuardService, PermissionsGuardService, MaintenanceGuardService } from 'src/services/public_api';
import { StaffComponent } from './client/staff/staff.component';
import { CodeErrorComponent } from './code-error/code-error.component';
import { CompanySetupComponent } from './admin/company-setup/company-setup.component';
import { StaffAccountComponent } from './client/staff/staff-account/staff-account.component';
import { ResetPasswordComponent } from './login/reset-password/reset-password.component';
import { StaffAccountEditComponent } from './client/staff/staff-account-edit/staff-account-edit.component';
import { CompanyEditComponent } from './client/company-edit/company-edit.component';
import { AccountComponent } from './client/account/account.component';
import { InvoiceSettingsComponent } from './client/invoice-settings/invoice-settings.component';
import { NotificationsComponent } from './client/notifications/notifications.component';

import { SelectSiteComponent } from './client/jobs/select-site/select-site.component';
import { AdminComponent } from './admin/admin.component';
import { IncidentReportsComponent } from './client/incident-reports/incident-reports.component';
import { MaintenanceModeComponent } from './maintenance-mode/maintenance-mode.component';
import { WorkersOnMapComponent } from './client/workers-on-map/workers-on-map.component';
import { AdminWorkersComponent } from './admin/workers/admin-workers.component';
import { AdminCompaniesComponent } from './admin/companies/admin-companies.component';
import { ClientComponent } from './client/client.component';
import { AuthGuardAdminService } from 'src/services/api/auth/auth-guard-admin.service';


const routes: Routes = [
	{ path: 'login', component: LoginComponent, canActivate: [MaintenanceGuardService] },
	{ path: 'reset-password', component: ResetPasswordComponent, canActivate: [MaintenanceGuardService] },
	{ path: 'reset-password/:token', component: ResetPasswordComponent, canActivate: [MaintenanceGuardService] },
	{ path: 'staff-account', component: StaffAccountComponent },
	{
		path: 'admin', component: AdminComponent, canActivate: [AuthGuardAdminService],
		children: [
			{ path: '', redirectTo: 'companies', pathMatch: 'full' }, // Default route
			{ path: 'companies', component: AdminCompaniesComponent },
			{ path: 'workers', component: AdminWorkersComponent },
			{ path: 'workers/:mobile', component: AdminWorkersComponent },
			{ path: 'company-setup', component: CompanySetupComponent},

		]
	},
	{
		path: '', component: ClientComponent, canActivate: [AuthGuardService],
		children: [
			{ path: 'account/invoice-settings', component: InvoiceSettingsComponent, canActivate: [AuthGuardService] },
			{ path: 'account', component: AccountComponent, canActivate: [AuthGuardService] },
			{ path: 'dashboard', component: DashboardComponent, canActivate: [MaintenanceGuardService, AuthGuardService] },
			{ path: 'jobs', component: JobsComponent, canActivate: [MaintenanceGuardService, AuthGuardService] },
			{ path: 'jobs/select-job', component: SelectSiteComponent, canActivate: [MaintenanceGuardService, AuthGuardService, PermissionsGuardService] },
			{ path: 'jobs/site/:site_uuid', component: SiteComponent, canActivate: [AuthGuardService] },
			{ path: 'jobs/role/:job_uuid', component: SiteComponent, canActivate: [MaintenanceGuardService, AuthGuardService] },
			{ path: 'jobs/manage-site', component: ManageSiteComponent, canActivate: [MaintenanceGuardService, AuthGuardService, PermissionsGuardService] },
			{ path: 'jobs/manage-site/:site_uuid', component: ManageSiteComponent, canActivate: [MaintenanceGuardService, AuthGuardService, PermissionsGuardService] },
			{ path: 'jobs/role/:site_uuid/create', component: SetupRoleComponent, canActivate: [MaintenanceGuardService, AuthGuardService, PermissionsGuardService] }, //jobs/role/create?site_uuid=93847593475
			{ path: 'jobs/role/:job_uuid/edit', component: SetupRoleComponent, canActivate: [MaintenanceGuardService, AuthGuardService, PermissionsGuardService] }, // jobs/role/983475983475/edit
			{ path: 'jobs/manage-shifts/:job_uuid', component: ManageShiftsComponent, canActivate: [MaintenanceGuardService, AuthGuardService] },
			{ path: 'account/notification-preferences', component: NotificationsComponent, canActivate: [AuthGuardService] },
			{ path: 'incidents', component: IncidentReportsComponent, canActivate: [AuthGuardService] },
			{ path: 'timesheets', component: TimesheetsComponent, canActivate: [AuthGuardService] },

			{ path: '', redirectTo: 'worker-map', pathMatch: 'full'},
			{ path: 'worker-map', component: WorkersOnMapComponent, canActivate: [MaintenanceGuardService] },

			{ path: 'app-company-edit', redirectTo: 'account/app-company-edit', pathMatch: 'full' },
			{ path: 'account/app-company-edit', component: CompanyEditComponent, canActivate: [AuthGuardService] },

			{ path: 'staff', redirectTo: 'account/staff', pathMatch: 'full' },
			{ path: 'account/staff', component: StaffComponent, canActivate: [AuthGuardService] },

			{ path: 'staff-profile-edit', redirectTo: 'account/staff-profile-edit', pathMatch: 'full' },
			{ path: 'account/staff-profile-edit', component: StaffAccountEditComponent, canActivate: [AuthGuardService] },
		]
	},


	{ path: '404', component: PageNotFoundComponent },
	{ path: 'code-error', component: CodeErrorComponent },
	{ path: 'error', redirectTo: '/code-error' },
	{ path: 'maintenance-mode', component: MaintenanceModeComponent }
	// { path: '**', redirectTo: '/404' },
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {
		scrollPositionRestoration: 'enabled' // For scrolling to top when switch routes
	})],
	exports: [RouterModule]
})
export class AppRoutingModule {

}
