import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-code-error',
    templateUrl: './code-error.component.html',
    styleUrls: ['./code-error.component.scss']
})
export class CodeErrorComponent implements OnInit {
    time: any;

    constructor(
        private router: Router
    ) {
        alert('There has been a problem. You will be redirected to the main page');
        this.time = setTimeout(() => {
            clearTimeout(this.time);
            this.router.navigateByUrl('/jobs').then(() => {
                window.location.reload();
            });
        }, 10);
    }


    ngOnInit() { }

}
