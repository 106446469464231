
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CachingService } from '../caching-service';

@Injectable()
export class MaintenanceService {


	constructor(
		private http: HttpClient,
		private cachingService:CachingService
	) {

	}

	checkMaintenanceMode(): Promise<any> {
		return new Promise((resolve, reject) => {
			let requestUrl = environment.maintenanceUrl;
			this.cachingService.addCache(requestUrl, 120);
			return this.http.get(requestUrl)
				.subscribe(
					data => {
						resolve(data);
					},
					(err) => {
						reject(err);

					}
				);
		});
	}


}