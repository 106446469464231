import { Component, OnInit, Input } from '@angular/core';
import { GlobalHelpers } from '@broadstone/helpers';

@Component({
  selector: 'app-job-card',
  templateUrl: './job-card.component.html',
  styleUrls: ['./job-card.component.scss']
})
export class JobCardComponent implements OnInit {
	@Input() sitePostcode: string;
	@Input() editJob: boolean;
	@Input() roleType: string;
	@Input() serviceTitle: string;
	@Input() workerHourlyRate: number;
	@Input() roleDescription: string;
  @Input() smartStaff: boolean;
	@Input() logo: string;
	@Input() holidayPayRate: number;
  constructor(public globalHelpers: GlobalHelpers) { }

  ngOnInit(): void {
  }

  scrollToResponsibilities () {
    this.globalHelpers.scroll_to_section('description');
  }

}
