import { NgModule } from "@angular/core";

import { JobCardModule } from "./job-card/job-card.module";
import { AuthTwofaModule } from "./auth-twofa/auth-twofa.module";
import { GeofencingMapModule } from "./geofencing-map/geofencing-map.module";
import { PoolsCreateShiftModule } from "./pools-create-shift/pools-create-shift.module";
import { SiteSelectModule } from "./site-select/site-select.module";
import { JobSearchAreaModule } from "./job-search-area/job-search-area.module";
// import { InputSuggestionsModule } from "./input-suggestions/input-suggestions.module";
import { PostSetupRoleModule } from "./post-job-components/post-setup-role/post-setup-role.module";
import { PostSiteModule } from "./post-job-components/post-site/post-site.module";
// import { InputListBuilderModule } from "./input-list-builder/input-list-builder.module";
import { PostShiftsModule } from "./post-job-components/post-shifts/post-shifts.module";
import { SelectDropDownModule } from "./select-drop-down/select-drop-down.module";
import { CancellationPolicyModule } from "./cancellation-policy/cancellation-policy.module";
import { SiteJobsModule } from "./site-jobs/site-jobs.module";
import { ListStaffModule } from "./list-staff/list-staff.module";
import { StaffPermissionsModule } from "./staff-permissions/staff-permissions.module";
// import * from './errors/index';
import { ListDropDownModule } from "./list-drop-down/list-drop-down.module";
import { FloatingFooterModule } from "./floating-footer/floating-footer.module";
import { NoDataModule } from "./no-data/no-data.module";
import { EditShiftsModule } from "./edit-shifts/edit-shifts.module";
import { PolicyModule } from "./policy/policy.module";
import { TimesheetsCompModule } from "./timesheets-comp/timesheets-comp.module";
// import { ExpandableTimesheetModule } from "./timesheets-comp/expandable-timesheet/expandable-timesheet.module";
import { SetupFormModule } from "./setup-form/setup-form.module";
import { CreateStaffAccountModule } from "./create-staff-account/create-staff-account.module";
import { FormResetPasswordModule } from "./form-reset-password/form-reset-password.module";
import { EditStaffProfileModule } from "./edit-staff-profile/edit-staff-profile.module";
import { InvoiceSettingsViewModule } from "./invoice-settings-view/invoice-settings-view.module";
import { BannerCardModule } from "./banner-card/banner-card.module";
import { WorkerRowModule } from "./worker-row/worker-row.module";
import { NotificationPrefModule } from "./notification-pref/notification-pref.module";
import { StaticBannerModule } from "./static-banner/static-banner.module";
import { EditStaffModule } from "./edit-staff/edit-staff.module";
import { AutoApproveModalModule } from "./auto-approve-modal/auto-approve-modal.module";
import { JobViewModule } from "./job-view/job-view.module";
import { MapWorkersModule } from "./map-workers/map-workers.module";
import { CompaniesModule } from "./admin/companies/companies.module";
import { WorkersModule } from "./admin/workers/workers.module";
import { EditWorkerModule } from './admin/edit-worker/edit-worker.module';

@NgModule({
  exports: [
    JobSearchAreaModule,
    PostSetupRoleModule,
    PostSiteModule,
    PostShiftsModule,
    SelectDropDownModule,
    CancellationPolicyModule,
    SiteJobsModule,
    ListStaffModule,
    StaffPermissionsModule,
    ListDropDownModule,
    FloatingFooterModule,
    NoDataModule,
    EditShiftsModule,
    PolicyModule,
    TimesheetsCompModule,
    SetupFormModule,
    CreateStaffAccountModule,
    FormResetPasswordModule,
    EditStaffProfileModule,
    InvoiceSettingsViewModule,
    WorkerRowModule,
    NotificationPrefModule,
    BannerCardModule,
    AutoApproveModalModule,
    SiteSelectModule,
    PoolsCreateShiftModule,
    GeofencingMapModule,
    AuthTwofaModule,
    EditStaffModule,
    StaticBannerModule,
    JobCardModule,
    JobViewModule,
    MapWorkersModule,
	CompaniesModule,
	WorkersModule,
	EditWorkerModule
  ],
})
export class AppCustomComponentsModule {}
