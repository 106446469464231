<div class="no-data-found" *ngFor="let item of no_data">
  <div *ngIf="item.img_position == 'right'" nz-row nzType="flex" nzJustify="space-between">
    <div class="title-sticky" StickyPolyFill nz-col nzSpan="8">
      <div class="no-data-spacing">
        <h3 class="b-font-demibold">{{item.title}}</h3>
        <h5 class="text-spacing b-light">{{item.body_1}}</h5>
        <h5 class="text-spacing b-light" *ngIf="item.body_2">{{item.body_2}}</h5>
        <h5 class="text-spacing b-light" *ngIf="item.body_3">{{item.body_3}}</h5>
      </div>
    </div>
    <div nz-col nzSpan="14">
      <div class="no-data-spacing text-center">
        <img src="{{item.img_src_1}}" />
        <img *ngIf="item.img_src_2" src="{{item.img_src_2}}" />
        <img *ngIf="item.img_src_3" src="{{item.img_src_3}}" />
      </div>
    </div>
  </div>
  <div *ngIf="item.img_position == 'left'" nz-row nzType="flex" nzJustify="space-between">
    <div nz-col nzSpan="12">
      <div class="no-data-spacing text-center">
        <img src="{{item.img_src_1}}" />
        <img *ngIf="item.img_src_2" src="{{item.img_src_2}}" />
        <img *ngIf="item.img_src_3" src="{{item.img_src_3}}" />
      </div>

    </div>
    <div class="title-sticky" StickyPolyFill nz-col nzSpan="10">
      <div class="no-data-spacing">
        <h3 class="b-font-demibold">{{item.title}}</h3>
        <h5 class="text-spacing b-light">{{item.body_1}}</h5>
        <h5 class="text-spacing b-light" *ngIf="item.body_2">{{item.body_2}}</h5>
        <h5 class="text-spacing b-light" *ngIf="item.body_3">{{item.body_3}}</h5>
      </div>
    </div>
  </div>
</div>