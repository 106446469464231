import { Injectable } from '@angular/core';
import { AuthSessionService } from '@broadstone/auth-session-service';
import { ApiService } from '@broadstone/api-service';
import { HttpClient } from '@angular/common/http';
import { hireRequest } from './smart_staff.model';
import { CachingService } from '../caching-service';

@Injectable({
    providedIn: 'root'
})
export class SmartStaffService {

    constructor(
        public http: HttpClient,
        public api: ApiService,
        private session: AuthSessionService,
		private cachingService:CachingService
    ) { }

    public getStaffPrices(): Promise<any> {
        return new Promise((resolve, reject) => {
            const url = this.api.getFullUrl('company/smart-staff/pricing');
            const headers = this.api.getDefaultHeaders(this.session.getToken());
			this.cachingService.addCache(url, 300);
            return this.http.get(url, { headers })
                .subscribe(
                    (data) => {
                        resolve(data['data']);
                    },
                    (error) => reject(this.api.handleErrors(error))
                );
        });

    }

    public sendHireRequest(hireRequest: hireRequest): Promise<any> {
        return new Promise((resolve, reject) => {
            const url = this.api.getFullUrl('company/smart-staff/request-to-hire');
            const headers = this.api.getDefaultHeaders(this.session.getToken());
            return this.http.post(url, hireRequest, { headers })
                .subscribe(
                    (data) => {
                        resolve(data);
                    },
                    (error) => reject(this.api.handleErrors(error))
                );
        });
    }
}
