import { NgModule } from '@angular/core';
import { StickypolifillDirective } from './stickypolifill/stickypolifill.directive';
import { CustomMaxDirective } from './validators/custom-max-validator.directive';
import { CustomMinDirective } from './validators/custom-min-validator.directive';


@NgModule({
    declarations: [StickypolifillDirective, CustomMaxDirective, CustomMinDirective],
    imports: [],
    exports: [StickypolifillDirective, CustomMaxDirective, CustomMinDirective]
})
export class DirectivesModule { }
