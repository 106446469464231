<div class="map-workers-wrapper">
	<div class="map-area">
		<div *ngIf="loading" class="loading-spinner">
			<app-loading-spinner color="color_primary" size="small">
			</app-loading-spinner>
		</div>
		<iframe class="embeded" frameborder="0" name="eventsPage" [src]="safeUrl" allowfullscreen
			referrerpolicy="no-referrer" (load)="handleIframeLoad()">
		</iframe>
	</div>
	<div class="content-area">
		<div>
			<h1 class="custom-title">Post jobs that you know will get filled.</h1>
			<h3>Our new interactive map shows you where our workforce is, right now. All fully vetted, all interested,
				all ready to go. </h3>
			<div class="button-area">
				<app-clib-button (pressAction)="postJobs()" label="Post Jobs">
				</app-clib-button>
			</div>

		</div>
	</div>
</div>