<div class="pools-container">

	<!-- POOLS BAR COMPONENT -->
	<div class="inner-container">
		<div class="left-div">
			<p class="b-font-demibold">Who do you want to notify about these shifts?</p>
		</div>
		<div class="right-center">


			<div class="wrapper noselect cursor-pointer">
				<div class="dropdown-suggested-wrapper" nzPopoverPlacement="left" nz-popover
					[nzPopoverVisible]="showDropdownList" (nzPopoverVisibleChange)="onPopOverClose($event)"
					nzPopoverTrigger="click" [nzPopoverContent]="numberOfWorkers > 0 ? contentDropTemplate : ''">
					<span class="b-Eh4">{{poolSelected}}
						<span *ngIf="numberOfWorkers > 0">
							<span *ngIf="poolSelected === 'Specific workers'">({{selectedWorkers.length}}
								selected)</span>
							<span *ngIf="poolSelected === 'Previously worked'">({{numberOfPreviousWorkers}}
								workers)</span>
							<span *ngIf="poolSelected === 'All workers'">({{numberOfWorkers}} workers)</span>
						</span>
					</span>

					<div *ngIf="numberOfWorkers > 0">
						<img *ngIf="!showDropdownList" src="assets/icons/arrow-down.svg" />
						<img *ngIf="showDropdownList" src="assets/icons/arrow-up.svg" />
					</div>

				</div>
			</div>

		</div>

		<div *ngIf="selectedWorkers.length === 0 && autoApprove" class="right-div-warning">

			<img src="assets/icons/info-warning.svg" />
			<span class="b-Eh4">The top ranked site trained worker will be <span class="b-font-demibold">automatically
					approved</span></span>
			<span (click)="openModal()" class="dark-link">More</span>

		</div>

		<div class="selected-workers-right" *ngIf="selectedWorkers.length > 0">
			<div class="cursor-pointer" (click)="openProfile(selectedWorker)"
				*ngFor="let selectedWorker of selectedWorkers; let index = index">
				<div *ngIf="index <= 4">
					<div class="selected-mark">
						<img [src]="'assets/icons/ic-check-mark.svg'">
					</div>
					<div class="avatar-area-wrapper">
						<nz-avatar (click)="open(applicant)"
							[nzTooltipTitle]="selectedWorker ? selectedWorker?.name +' '+selectedWorker?.surname : ''"
							nzPlacement="top" nz-tooltip [nzText]='" "+selectedWorker?.name[0]'
							[nzSrc]="selectedWorker?.avatar" nzSize="small">
						</nz-avatar>
					</div>
				</div>
			</div>
			<div *ngIf="selectedWorkers.length > 5" class="over-select">+ {{overSelectNr}}</div>
		</div>


	</div>

	<!-- POOLS MODAL -->

	<app-auto-approve-modal [modalCopyType]="poolSelected" [reverseApplyAutoAccept]="reverseApplyAutoAccept"
		[autoApprove]="autoApprove" (openModalChange)="onCloseModal($event)" [openModal]="openModalToggle">
	</app-auto-approve-modal>

	<!-- POOLS DROPDOWN -->
	<ng-template #contentDropTemplate>
		<div [style.width]="'425px'" class="drop-down-items b-result-box">

			<div class="search-worker-area">
				<app-search-input (click)="searchOnClick()" debounce='500' placeholder='Search by applicant'
					(onKey)="searchWorkerAction()" [(inputModel)]="searchWorkerName"></app-search-input>
			</div>
			<div *ngIf="isSearching">
				<nz-skeleton *ngFor="let number of [].constructor(5)" class="nz-staff-loading"
					[nzParagraph]="{ rows: 1 }" [nzTitle]="false" [nzAvatar]="true" [nzActive]="true"></nz-skeleton>
			</div>

			<div *ngIf="!isSearching" class="notify-workers-area">
				<div *ngIf="!hideNotifyRows" class="notify-items-wrapper">
					<img src="assets/icons/notify.svg" />
					<span class="b-Eh4"><span class="b-font-demibold">Notify all workers</span><span class="b-light">
							({{numberOfWorkers}} workers)</span></span>
					<div class="checkbox-area">
						<label class="b-checkbox">
							<input (click)="markCheckbox('All workers')" [checked]="poolSelected === 'All workers'"
								type="radio">
							<span class="checkmark"></span>
						</label>
					</div>

				</div>
				<div *ngIf="!hideNotifyRows" class="notify-items-wrapper">
					<img src="assets/icons/site-train-no-border.svg" />
					<span class="b-Eh4"><span class="b-font-demibold">Notify all previously worked</span><span
							class="b-light"> ({{numberOfPreviousWorkers}} workers)</span></span>
					<div class="checkbox-area">
						<label class="b-checkbox">
							<input [disabled]="numberOfPreviousWorkers === 0"
								(click)="markCheckbox('Previously worked')"
								[checked]="poolSelected === 'Previously worked'" type="radio">
							<span [ngClass]="numberOfPreviousWorkers === 0 ? 'checkmark-disabled':''"
								class="checkmark"></span>
						</label>
					</div>

				</div>
				<div *ngIf="allWorkers.length > 0">
					<span *ngIf="autoApprove" class="b-Eh4 b-light">Send all shifts to be auto approved</span>
					<span *ngIf="!autoApprove" class="b-Eh4 b-light">Select specific worker</span>
				</div>

				<!-- LIST WORKERS -->
				<div *ngFor="let worker of allWorkers" class="notify-items-wrapper cursor-pointer">
					<div *ngIf="worker?.site_trained === 1" class="alert-avatar-icon">
						<img [src]="'assets/icons/onsite-training-icon.svg'" nz-tooltip nzTooltipTitle="Site trained">
					</div>
					<div (click)="openProfile(worker)" class="avatar-area-wrapper">
						<nz-avatar (click)="open(applicant)" [nzTooltipTitle]="worker ? worker?.name +' '+worker?.surname : ''"
							nzPlacement="top" nz-tooltip [nzText]='" "+worker?.name[0]' [nzSrc]="worker?.avatar"
							nzSize="xs">
						</nz-avatar>
					</div>

					<div nz-tooltip nzPlacement="bottom" [nzOverlayClassName]="'selected-tool-tip'"
						[nzTooltipTitle]="conflictingShiftsTip(worker)">
						<span (click)="openProfile(worker)" class="b-Eh4"><span class="b-font-demibold">{{worker.name}}
								{{worker.surname}}</span><span class="b-light"> {{worker.ranking_score}}</span></span>
						<div *ngIf="worker.cantWorkShifts && worker.cantWorkShifts.length > 0" class="b-Eh5 b-danger">
							Worker is unable to work selected shift</div>
					</div>
					<div class="checkbox-area">
						<app-loading-spinner color="color_primary" size="small" *ngIf="worker.loadingCheck">
						</app-loading-spinner>
					</div>



					<div *ngIf="!worker.loadingCheck" class="checkbox-area">
						<label class="b-checkbox">
							<input (click)="markSelectedWorkers(worker,'Specific workers')" [checked]="worker.selected"
								type="checkbox">
							<span class="checkmark"></span>
						</label>
					</div>

				</div>
				<div class="text-center" *ngIf="allWorkers.length == 0">
					<p>No workers found.</p>
					<p>We couldn't find any workers.</p>
				</div>
			</div>


		</div>
	</ng-template>

	<!-- POOLS WORKER PROFILE -->

	<nz-drawer [(nzVisible)]="profileVisible" nzWidth="375px" [nzClosable]="false" (nzOnClose)="closeProfile()">
		<ng-container *nzDrawerContent>
			<div class="close-btn text-right"><a class="cursor-pointer" (click)="closeProfile()"><img
						src="assets/icons/crossIcon.svg" /></a>
			</div>
			<lib-worker-profile *ngIf="profile && profile.user" (onClose)="closeProfile()" [profile]="profile">
			</lib-worker-profile>
		</ng-container>
	</nz-drawer>

</div>