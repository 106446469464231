import { Injectable } from '@angular/core';
import { User } from '../users/user.model';

export interface StaffRole {

    type: string
}

export interface StaffPermission {
    type: string
}

export interface Staff {
    uuid: string;
    user: User,
    permissions: Array<StaffPermission>;
    user_uuid: string;
    full_name: string;
    avatar: string;
    role: StaffRole;
    created_at: string;
    email: string;
}

export interface StaffInvite {
    email: string;
    companyUuid: string;
    permissions: Array<string>;
}



export interface StaffInviteLink {
    company: {
        uuid: string;
        avatar: string;
        title: string;
    };
    name: string;
    email: string;
}

export interface newStaff {
    token: string,
    user: {
        name: string,
        surname: string,
        mobileNumber: string,
        password: string
    }
}

@Injectable({
    providedIn: 'root'
})
export class StaffAdapter {

    adapt_staff(data): Staff {
        return {
            uuid: data.uuid,
            user_uuid: data.user.uuid,
            user: data.user,
            full_name: data.user.name + ' ' + data.user.surname,
            avatar: data.user.avatar,
            permissions: data.permissions,
            role: data.role ? data.role.type : '',
            created_at: data.created_at,
            email: data.user.email,
        };
    }

    adapt_role(data): StaffRole {
        return {
            type: data.role,
        };
    }

    adapt_invite(data): StaffInviteLink {
        return {
            company: data.company,
            name: data.name,
            email: data.email,
        }
    }


}
