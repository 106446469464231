import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StaticBannerComponent } from './static-banner.component';

@NgModule({
  declarations: [StaticBannerComponent],
  exports:[StaticBannerComponent],
  imports: [
    CommonModule
  ]
})
export class StaticBannerModule { }
