<form #form="ngForm">
    <div class="setup-form">
        <!-- Company Information -->
        <div id="company-information" nz-row nzType="flex" nzJustify="space-between">
            <div class="title-sticky" StickyPolyFill nz-col nzSpan="8">
                <div class="row-spacing">
                    <h3>Company Information</h3>
                    <br>
                    <h5><span>This can not be edited without our knowledge or consent. </span>The Legal Company Name is
                        the formal legal entity that is carried out for the business.</h5>
                    <br>
                    <h5 *ngIf="editComponent">Please contact <br><span>community@orka.works</span> if you
                        would like to amend this
                        section.</h5>

                </div>
            </div>
            <div nz-col nzSpan="14">
                <!-- Company legal name -->

                <div class="row-spacing">
                    <label for="legal-name" [ngClass]="editComponent ? 'greyed-out' : ''">Legal Company Name</label>
                    <input class="b-input b-input-white custom-width" [(ngModel)]="legal_name" type="text"
                        name="legal_name" #legalName="ngModel" required id='legal-name'
                        placeholder='Enter Legal Company Name' [disabled]="editComponent">

                    <div *ngIf="(legalName.touched || submitted) && legalName.invalid && legalName.errors.required">
                        <p class="error-box custom-width">Please fill in this field.</p>
                    </div>
                </div>



                <!-- Company Name -->
                <div class="row-spacing">
                    <label for="company-name" [ngClass]="editComponent ? 'greyed-out' : ''">Company Name <h5
                            class="label-subtitle">This can either
                            be visible to the
                            worker
                            before OR after they are approved.</h5></label>

                    <input class="b-input b-input-white" [(ngModel)]="company_name" type="text" required
                        name="company_name" #companyName="ngModel" id='company-name' placeholder="Enter Company Name"
                        [disabled]="editComponent">

                    <div
                        *ngIf="(companyName.touched || submitted) && companyName.invalid && companyName.errors.required">
                        <p [style.width]="'625px'" class="error-box">Please fill in this field.</p>
                    </div>
                </div>
                <!-- Company Registration Number -->
                <div class="row-spacing">
                    <label for="company-number" [ngClass]="editComponent ? 'greyed-out' : ''">Company Registration
                        Number
                        <h5 class="label-subtitle">This can be found
                            on
                            Companies House</h5>
                    </label>
                    <input class="b-input b-input-white custom-width" [(ngModel)]="company_number" type="number"
                        #companyNumber="ngModel" required name="company_number" id='company-number'
                        placeholder="Enter Company Registration Number " [disabled]="editComponent">

                    <div
                        *ngIf="(companyNumber.touched || submitted) && companyNumber.invalid && companyNumber.errors.required">
                        <p class="error-box custom-width">Please fill in this field.</p>
                    </div>
                </div>

            </div>

        </div>
        <hr>
        <!-- Invoicing & Timesheets -->
        <div id="invoicing-timesheets" nz-row nzType="flex" nzJustify="space-between">
            <div class="title-sticky" StickyPolyFill nz-col nzSpan="8">
                <div class="row-spacing">
                    <h3>Invoicing & Timesheets</h3>
                </div>
            </div>
            <div nz-col nzSpan="14">
                <!-- Billing Email Address-->
                <div class="row-spacing">
                    <label for="billing-email">Billing Email Address</label>
                    <input class="b-input b-input-white custom-width" [(ngModel)]="billing_email" email="true"
                        type="email" name="billing_email" #billingEmail="ngModel" required id='billing-email'
                        placeholder='Enter Billing Email Address'>
                    <div
                        *ngIf="(billingEmail.touched || submitted) && billingEmail.invalid && billingEmail.errors.required">
                        <p class="error-box custom-width">Please fill in this field.</p>
                    </div>
                    <div
                        *ngIf="(billingEmail.touched || submitted) && billingEmail.invalid && billingEmail.errors.email">
                        <p class="error-box custom-width">Email is not valid.</p>
                    </div>

                </div>
                <!-- Billing Phone Number -->
                <div class="row-spacing">
                    <label for="billing-phone">Billing Phone Number</label>

                    <input class="b-input b-input-white custom-width" [(ngModel)]="billing_phone" type="text"
                        name="billing_phone" id='billing-phone' placeholder="Enter Billing Phone Number">
                </div>
                <!-- Send Timesheet Reminders to -->
                <div class="row-spacing">

                    <label for="timesheet-remiders">Send Timesheet Reminders to</label>
                    <app-input-list-builder [inputted_data]="timesheets_emails" (ListStored)="save_list($event)"
                        width="625px" email='email' save_limit="5" input_text_limit="100" myid="timesheet-remiders"
                        placeholder="Enter Email Address">
                    </app-input-list-builder>

                </div>
            </div>

        </div>
        <hr>
        <!-- Additional info -->
        <div id='aditional-info' nz-row nzType="flex" nzJustify="space-between">
            <div class="title-sticky" StickyPolyFill nz-col nzSpan="8">
                <div class="row-spacing">
                    <h3>Additional Info</h3>
                </div>
            </div>
            <div nz-col nzSpan="14">
                <!-- Company Logo-->
                <div class="row-spacing">
                    <h3 class="b-font-demibold">Company Logo</h3>
                    <div class="company-logo-wrapper">
                        <div class="company-logo">
                            <img class="img-icon" *ngIf="!imagesrc" src="assets/icons/img-icon.svg" />
                            <img class="img-upload" *ngIf="imagesrc" src="{{imagesrc}}" />
                        </div>
                        <input class="hide" #file type="file" accept="image/*" (change)="getImg($event)">
                        <app-clib-button type="outline" color="dark" (pressAction)="file.click()" label="Add">
                        </app-clib-button>
                    </div>
                </div>
                <!--Company Description -->
                <div class="row-spacing">
                    <label for="company-description">Company Description</label>
                    <textarea #companyDescription="ngModel" [(ngModel)]="company_description" name="company_description"
                        id="company-description" class="b-textarea b-textarea-white" required
                        placeholder="Enter Company Description"></textarea>
                    <div
                        *ngIf="(companyDescription.touched || submitted) && companyDescription.invalid && companyDescription.errors.required">
                        <p [style.width]="'625px'" class="error-box">Please fill in this field.</p>
                    </div>



                </div>
                <div class="row-spacing" id='clock-distance'>
                    <label for="clock-distance">Clock in/out area<h5>This is the distance away from site where
                            workers will be able to
                            clock in and out of their shift successfully.</h5></label>

                    <div class="checkbox-wrapper">
                        <label class="b-checkbox">
                            <input type="checkbox" [checked]="noFixedArea" (click)="selectMobileResponse()">
                            <span class="checkmark"></span>
                        </label>
                        <p>No Restriction (Mobile Response)</p>
                    </div>
                    <div class="clock-wrapper" *ngIf="!noFixedArea">
                        <input class="b-input b-input-white clock-width" [(ngModel)]="clock_distance_limit"
                            type="number" name="clock_distance_limit" id='clock-distance' placeholder="0.1" step="0.1"
                            [max]="max" min="0.1" [disabled]="noFixedArea" required>
                        <h4 class="b-font-demibold" *ngIf="metricUnits">km</h4>
                        <h4 class="b-font-demibold" *ngIf="!metricUnits">miles</h4>
                        <div class="switch-wrapper">
                            <label class="custom-text"><span *ngIf="metricUnits">km</span><span
                                    *ngIf="!metricUnits">miles</span></label>
                            <nz-switch class="nz-override-switch" [(ngModel)]="metricUnits"
                                (ngModelChange)="onSwitchChange($event)" name="unitConverter" [disabled]="noFixedArea">
                            </nz-switch>
                        </div>
                    </div>
                    <div *ngIf="clock_distance_limit > 5 && metricUnits">
                        <p [style.width]="'625px'" class="error-box">Maximum value is 5km.</p>
                    </div>
                    <div *ngIf="clock_distance_limit > 3 && !metricUnits">
                        <p [style.width]="'625px'" class="error-box">Maximum value is 3 miles.</p>
                    </div>
                    <div *ngIf="clock_distance_limit === null">
                        <p [style.width]="'625px'" class="error-box">Please fill in this field.</p>
                    </div>

                </div>
            </div>

        </div>
        <hr>
        <!-- CONTROL ROOM -->
        <div id='control-room' nz-row nzType="flex" nzJustify="space-between">
            <div class="title-sticky" StickyPolyFill nz-col nzSpan="8">
                <div class="row-spacing">
                    <h3>Control Room</h3>
                    <br>
                    <h5>Set your default contact details for your company's Control Room. We will provide the Control
                        Room phone number to any workers approved for shifts with you. We will use the Control Room
                        email to notify your company if any worker welfare check calls are missed.</h5>
                </div>
            </div>
            <div nz-col nzSpan="14">
                <!-- Phone Number -->
                <div class="row-spacing">
                    <label for="control-phone">Phone Number</label>
                    <input class="b-input b-input-white custom-width" [(ngModel)]="control_phone" type="text"
                        name="control_phone" required #controlPhone="ngModel" id='control-phone'
                        placeholder='Enter Phone Number'>
                    <div
                        *ngIf="(controlPhone.touched || submitted) && controlPhone.invalid && controlPhone.errors.required">
                        <p class="error-box custom-width">Please fill in this field.</p>
                    </div>

                </div>
                <!-- Email Address -->
                <div class="row-spacing">
                    <label for="control-email">Email Address</label>

                    <input class="b-input b-input-white custom-width" [(ngModel)]="control_email" type="text"
                        #controlEmail="ngModel" email="true" name="control_email" id='control-email'
                        placeholder="Enter Email Address">
                    <div
                        *ngIf="(controlEmail.touched || submitted) && controlEmail.invalid && controlEmail.errors.email">
                        <p class="error-box custom-width">Email is not valid.</p>
                    </div>

                </div>
            </div>

        </div>
        <hr *ngIf="!editComponent || owner">
        <!-- OWNER -->
        <div *ngIf="!editComponent || owner" id="owner" nz-row nzType="flex" nzJustify="space-between">
            <div class="title-sticky" StickyPolyFill nz-col nzSpan="8">
                <div class="row-spacing">
                    <h3>Owner</h3>
                    <br>
                    <h5>The Owner will have full access to the platform.
                        The Owner can not be edited but is able to add multiple admins to the platform. </h5>
                    <br>
                    <h5 *ngIf="editComponent">Please contact <br><span>community@orka.works</span> if you
                        would like to amend this
                        section.</h5>
                </div>
            </div>
            <div nz-col nzSpan="14">
                <!-- Full Name -->
                <div class="row-spacing">
                    <label for="full-name" [ngClass]="editComponent ? 'greyed-out' : ''">Name</label>
                    <input class="b-input b-input-white custom-width" [(ngModel)]="full_name" type="text"
                        name="full_name" #fullName="ngModel" required id='full-name' placeholder='Enter Name'
                        [disabled]="editComponent">
                    <div *ngIf="(fullName.touched || submitted) && fullName.invalid && fullName.errors.required">
                        <p class="error-box custom-width">Please fill in this field.</p>
                    </div>


                </div>
                <!-- Email Address -->
                <div class="row-spacing">
                    <label for="owner-email" [ngClass]="editComponent ? 'greyed-out' : ''">Email Address</label>

                    <input class="b-input b-input-white custom-width" [(ngModel)]="owner_email" type="text"
                        #ownerEmail="ngModel" name="owner_email" id='owner-email' email="true" required
                        placeholder="Enter Email Address" [disabled]="editComponent">
                    <div *ngIf="(ownerEmail.touched || submitted) && ownerEmail.invalid && ownerEmail.errors.required">
                        <p class="error-box custom-width">Please fill in this field.</p>
                    </div>
                    <div *ngIf="(ownerEmail.touched || submitted) && ownerEmail.invalid && ownerEmail.errors.email">
                        <p class="error-box custom-width">Email is not valid.</p>
                    </div>

                </div>
            </div>

        </div>

    </div>
</form>
<hr>
<div class="group-buttons">
    <app-clib-button *ngIf="!editComponent" type="outline" label="Back" (pressAction)="back()">
    </app-clib-button>

    <app-clib-button *ngIf="!editComponent" [loading]="save_loading" label="Create" (pressAction)="create(form)">
    </app-clib-button>
    <app-clib-button *ngIf="editComponent && company_edit" [loading]="save_loading" label="Save"
        (pressAction)="update(form)">
    </app-clib-button>
</div>