import { ErrorHandler, Injectable } from '@angular/core';
import { LoggingService } from './logging/logging.service';


@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
	constructor(
		private logger: LoggingService
	) {
	}

	handleError(error) {
		if (error) {
			this.logger.log('Stack error', 'ERROR','2a0aad03-f126-4a19-8024-022007bbdf4b', error);
		}
	}
}