import { Component, OnInit, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { FiltersServices } from 'src/services/api/filters/filters.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { UrlHelpers } from 'src/services/api/helpers/url-filters';
import { AuthSessionService } from '@broadstone/auth-session-service';
import { CalendarRange, EventsTracker, JobsProviders } from 'src/services/public_api';
import { DatesHelper } from '@broadstone/helpers';


@Component({
	selector: 'app-job-search-area',
	templateUrl: './job-search-area.component.html',
	styleUrls: ['./job-search-area.component.scss']
})
export class JobSearchAreaComponent implements OnInit {
	@Input() status_filter = false;
	@Input() job_type_filter = false;
	@Input() assing_filter = false;
	@Input() search_box_data: any;
	@Input() dropdown_reset: boolean = false;
	@Output() onSelectedSearch: EventEmitter<any> = new EventEmitter();
	@Input() buttonConfig: any;
	range_dates: any = [];
	date: any;
	extend_field: any = {};
	selectedValue: any; // @todo added for build to pass - update type and default value
	status_dropdown = [
		{ title: 'All', img: null, value: '' },
		{ title: 'Approved', img: 'assets/icons/check-icon.svg', value: "accepted" },
		{ title: 'Pending', img: 'assets/icons/oval-copy-26.svg', value: "pending" },
		{ title: 'Expiring', img: 'assets/icons/expiring.svg', value: "expiring" },
		{ title: 'Expired', img: 'assets/icons/expired.svg', value: "expired" },
		{ title: 'Declined', img: 'assets/icons/oval-copy-27.svg', value: 'declined' },
		{ title: 'Cancelled', img: 'assets/icons/cancel-icon.svg', value: 'cancelled' },
		{ title: 'No Show', img: 'assets/icons/no-show-icon.svg', value: 'no-show' }
	];
	job_type_dropdown = [
		{ title: 'All', img: null, value: '' },
		{ title: 'Ad Hoc', img: 'assets/icons/adhoc.svg', value: 'AdHoc' },
		{ title: 'Temp to Perm', img: 'assets/icons/smartstaff.svg', value: 'SmartStaff' }
	]
	assign_dropdown = [
		{ title: 'Choose' },
		{ title: 'Mark' },
		{ title: 'Smith' }
	];
	selected_search_value: any = '';
	search_value: string;
	status_param: number;
	queryString: string = '';

	constructor(
		public filters: FiltersServices,
		private elRef: ElementRef,
		private cookie: CookieService,
		public router: Router,
		public urlfilters: UrlHelpers,
		private session: AuthSessionService,
		public jobData: JobsProviders,
		private eventTracker: EventsTracker,
		private dateHelper: DatesHelper,
		private calendarRange: CalendarRange
	) {
		if (this.session.geAdminHeader()) {
			this.filters.assigned_sites = false;
		}
		if(this.session.getIsAdmin()){
			this.status_dropdown.push({ title: 'Registered Interest', img: 'assets/icons/registered-interest.svg', value: 'held' });
		}
		this.status_param = this.urlfilters.subscribeToUrlParams();
		if (this.status_param) {
			this.statusSelected(this.status_dropdown[this.status_param])
		}
	}

	ngOnInit() { }

	ngAfterViewInit() {
		this.calendarRange.init(this.elRef);
		this.checkCalendarReset();
		this.setCalendarFilter();
	}

	ngOnChanges() {
		if (!this.status_filter || !this.job_type_filter) { // Check if status box show or not if not reset status
			this.filters.reset_status();
			this.filters.reset_job_type();
		}

	}

	setCalendarFilter() {
		if (this.filters.start_at && this.filters.end_at) {
			if (new Date(this.filters.start_at).toString() !== new Date(this.filters.end_at).toString()) {
				this.calendarRange.calendarSeparator(true);
				this.range_dates[0] = this.filters.start_at;
				this.range_dates[1] = this.filters.end_at;
			} else {
				this.calendarRange.calendarSeparator();
				this.range_dates[0] = this.filters.start_at;
				this.range_dates[1] = this.filters.end_at;
			}
		}
	}

	onClose() {
		if (this.range_dates && this.range_dates.length > 0) {
			const titleDateFormat: string = 'DD/MM/YYYY';
			if (new Date(this.range_dates[0]).toString() !== new Date(this.range_dates[1]).toString()) {
				this.calendarRange.calendarSeparator(true);
				this.filters.start_at = this.range_dates[0];
				this.filters.end_at = this.range_dates[1];
				this.logFilterEvent('calendar_filter', this.dateHelper.format_date(this.range_dates[0], titleDateFormat) + '-' + this.dateHelper.format_date(this.range_dates[1], titleDateFormat))
			} else {
				this.calendarRange.calendarSeparator();
				this.filters.start_at = this.range_dates[0];
				this.filters.end_at = this.range_dates[0];
			}
			this.filters.isSearchingSite.emit('calendar_filter');
		} else {  //if no range dates means when clear the calendar. from 
			this.filters.active_toggle = true; // when choose active or inactive and emit the call
			this.onSwitchChange(true); // Switch auto toggle;
			this.calendarRange.calendarSeparator();
		}
		this.filters.set_toggle_base_range();
		this.urlfilters.onFilterChange();
	}

	clearCalendarData() {
		this.filters.reset_calendar_range();
	}

	checkCalendarReset() {
		this.filters.calendarReset.subscribe(() => {
			this.range_dates = [];
			this.calendarRange.calendarSeparator();
		});
	}

	selectedSearchItem(e) {
		if (e && e.value) {
			if (this.selected_search_value !== e.value) {
				this.selected_search_value = e;
				if (this.filters.check_applicant_client_location() && this.dropdown_reset) {
					this.filters.reset_applicant_client_location();
					this.checkApi();
				}
			}
		}
	}

	statusSelected(e) {
		if (this.filters.status !== e.value) {
			this.status_param = null;
			this.logFilterEvent('job_status_filter', e.value)
			this.filters.status = e.value;
			this.filters.isSearchingSite.emit('status_filter');
			this.urlfilters.onFilterChange();
		}

	}

	assignedSitesChange(e) {
		this.filters.isSearchingSite.emit('assigned_sites_filter');
		if (this.filters.assigned_sites) {
			localStorage.setItem('assigned_sites', JSON.stringify(true));
		}
		else {
			localStorage.setItem('assigned_sites', JSON.stringify(false));
		}
	}

	jobTypeSelected(e) {
		if (this.filters.job_type !== e.value) {
			this.filters.job_type = e.value;
			this.filters.isSearchingSite.emit('job_type_filter');
			this.urlfilters.onFilterChange();
		}
	}

	checkStatusFilter() { // Check for resetting status
		return !this.filters.status;
	}

	checkJobTypeFilter() { // Check for resetting status
		return !this.filters.job_type;
	}

	onSwitchChange(e) {
		this.clearCalendarData(); // switch take priority over calendar
		this.logFilterEvent('future_past_toggle_filter', 'toggle-' + e)
		this.filters.isSearchingSite.emit('active_filter');
		this.urlfilters.onFilterChange();
	}

	checkApi() { //Set filter when typing and emit
		if (this.filters.applicant_name) {
			this.logFilterEvent('search_applicant_filter', this.filters.applicant_name)
		}
		this.filters.isSearchingSite.emit('search_filter');
	}

	searchModel() {
		return this.search_value;
	}


	buttonAction() {
		this.router.navigate([this.buttonConfig.redirectUrl])
		if (this.buttonConfig.label === 'Add Shifts') {
			this.jobData.site_to_shifts = true;
		}
	}

	logFilterEvent(action, data) {
		const currentRouteArr = this.router.url.split('/');
		if (currentRouteArr[2] === 'role') {
			this.eventTracker.trackEvent(action, data);
		}
	}

}
