
import { Injectable } from '@angular/core';
import { User, UserAdapter } from '../users/user.model';

export interface Credentials {
    email?: string;
    username?: string;
    password: string;
}

export interface Auth {
    token: string;
    is_admin: boolean;
    message: string;
    via: {
        email: boolean,
        sms: boolean
    };
    to: string;

}




@Injectable({
    providedIn: 'root'
})
export class AuthAdapter {

    adapt_auth(item): Auth {
        return {
            'token': item.token ? item.token : null,
            'is_admin': item.admin,
            'message': item.message ? item.message : null,
            'via': item.via ? {
                email: item.via.email,
                sms: item.via.sms
            } : null,
            'to': item.to ? item.to : null,

        }
    }


}