import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimesheetsCompComponent } from './timesheets-comp.component';
import { ExpandableTimesheetModule } from './expandable-timesheet/expandable-timesheet.module';
import { FloatingFooterModule } from '../floating-footer/floating-footer.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FormsModule } from '@angular/forms';
import { WorkerMapModule } from '@broadstone/worker-map';
import { ListDropDownModule } from '../list-drop-down/list-drop-down.module';
import { WorkerProfileModule } from '@broadstone/worker-profile';
import { NoSearchDataModule } from '@broadstone/no-search-data';
import { ApplicationGlobals } from '@broadstone/helpers';
import { AlertService } from '@broadstone/alert';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';



@NgModule({
    declarations: [TimesheetsCompComponent],
    exports: [TimesheetsCompComponent],
    imports: [
        FormsModule,
        WorkerMapModule,
        ExpandableTimesheetModule,
        FloatingFooterModule,
		NzDatePickerModule,
		NzSkeletonModule,
		NzToolTipModule,
		NzDrawerModule,
        InfiniteScrollModule,
        CommonModule,
        ListDropDownModule,
        WorkerProfileModule,
        NoSearchDataModule,
        ApplicationGlobals
    ],
    providers: [AlertService]
})
export class TimesheetsCompModule { }
