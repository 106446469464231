import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-select-site',
    templateUrl: './select-site.component.html',
    styleUrls: ['./select-site.component.scss']
})
export class SelectSiteComponent implements OnInit {


    constructor() { }

    ngOnInit(): void {
    }

}
