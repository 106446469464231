import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FiltersServices, UsersService, JobsService } from 'src/services/public_api';
import { GlobalHelpers } from '@broadstone/helpers';
import { AuthSessionService } from '@broadstone/auth-session-service';




@Component({
	selector: 'app-jobs',
	templateUrl: './jobs.component.html',
	styleUrls: ['./jobs.component.scss'],
})
export class JobsComponent implements OnInit {
	pageSetup = {
		breadcrumbs: [],
		pageTitle: 'jobs',
		extraInfo: ''
	};
	search_box_input = [{ title: 'Address', value: 'location' }, { title: 'Applicant', value: 'applicant_name' }, { title: 'Client', value: 'client_name' }, { title: 'Reference', value: 'site_reference' }];
	all_sites: any = [];
	page: number = 1;
	has_n_p: boolean = false;
	loading_more: boolean = false;
	limit: number = 25;
	loading: boolean = false;
	filter_subscription: boolean = true;
	filters_on: boolean = false;
	job_type_filter: boolean = true;
	arrow_up: string = './assets/icons/upArrow.svg';
	arrow_down: string = './assets/icons/filter-arrow.svg';
	locationAscending: boolean = false; //sorting columns
	clientAscending: boolean = false;
	refAscending: boolean = false;
	sortDirection: string = '';
	sortBy: string = '';
	currentUser: any;
	edit_jobs: boolean;
	subNavButton: any = {
		label: 'Post Job +',
		redirectUrl: 'jobs/select-job'
	}

	constructor(
		private router: Router,
		private filters: FiltersServices,
		private global_helper: GlobalHelpers,
		private jobs_service: JobsService,
		public user: UsersService,
		private session: AuthSessionService
	) {
		if (this.session.geAdminHeader()) {
			this.edit_jobs = true;
		}
		else {
			this.user.getCurrentUser().then((res) => {
				this.checkPermissions(res.permissions)
			}).catch(e => {
				console.log(e);
			});
		}
	}


	ngOnInit() {
		this.filter_subscription = true;
		if (!this.filters.applicant_name) {
			this.loading = true;
			this.all_sites.length = 0;
			this.getAllSites(1);
		}
		this.subscribeToFilters();
	}

	ngOnDestroy() {
		this.filter_subscription = false;
	}

	checkPermissions(permissions) {
		if (!permissions) {
			return;
		}
		permissions.forEach(element => {
			if (element.type === 'company.jobs.edit') {
				this.edit_jobs = true;
			}
		})
	}

	subscribeToFilters() {
		this.filters.isSearchingSite.subscribe((data) => {
			this.filters_on = this.filters.check_active_filters() || this.filters.check_active_filters();
			if (this.filter_subscription) {
				this.all_sites.length = 0;
				this.loading = true;
				this.page = 1;
				this.getAllSites(1);
			}
		});
	}

	openManageSite() {
		this.router.navigate(['jobs/select-job']);
	}

	selectedSearchDropdown(e) {
		this.clearFilters();
	}

	clearFilters() {
		this.filters.clear_site_filters();
	}

	sortJobs(sortBy, direction, sortTerm) {
		this.loading = true;
		const sortMatches = [
			'locationAscending',
			'clientAscending',
			'refAscending',
			'jobAscending'
		];
		const resetArray = sortMatches.filter(match => match !== sortTerm);
		resetArray.forEach(el => {
			this[el] = false;
		})
		this.sortDirection = direction ? 'ASC' : 'DESC';
		this.sortBy = sortBy;
		this.all_sites.length = 0;
		this.page = 1;
		this.getAllSites(this.page);
	}

	getAllSites(page) {
		this.jobs_service.getJobs(page, this.filters, this.limit, this.sortBy, this.sortDirection).then((result) => {
			this.has_n_p = result['links'].next ? true : false; //has next page
			let data = result['data'];
			data.forEach(row_element => {
				const found = this.all_sites.filter(element => {
					return element.uuid == row_element.uuid;
				}).length > 0;
				if (!found) {
					this.all_sites.push(row_element); //push results into new array
				}
			});
			this.loading_more = false; //loading animation for more results
			this.loading = false; //loading for initial load
			this.createFullName();
		}).catch((err) => {
			this.loading_more = false;
			this.loading = false;
			// this.logger.error(err);
		});
	}

	createFullName() {
		this.all_sites.forEach(element => {
			let names = [];
			element.site.assigned_staff.forEach(element => {
				names.push(element.user.name + ' ' + element.user.surname);
			});
			element['full_names'] = names.join(", ");
		})
	}

	onScroll() {
		if (this.has_n_p && !this.loading_more) {
			this.loading_more = true;
			this.page += 1; //increment the page number
			this.getAllSites(this.page); //get the page number
		}
	}

	openJob(job_uuid) {
		// this.filters.reset_calendar_range();
		this.router.navigateByUrl('jobs/role/' + job_uuid);
	}

	showAssignedStaffTotal(oneSite) {
		let number = "+";
		if (oneSite && oneSite.site && oneSite.site.assigned_staff.length - 1) {
			number += oneSite.site.assigned_staff.length - 1;
		}
		return number;
	}

	editAssignedStaff(e, openJob) {
		if (!openJob.site.source) { // open only for orka jobs and sites
			const siteUuid = openJob.site.uuid;
			e.stopPropagation();
			this.router.navigate(['jobs/manage-site/' + siteUuid], { queryParams: { staff: 'edit' }, queryParamsHandling: 'merge' });
		}

	}

}


