import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListDropDownComponent } from './list-drop-down.component';
import { NzPopoverModule } from 'ng-zorro-antd/popover';


@NgModule({
  declarations: [ListDropDownComponent],
  exports: [ListDropDownComponent],
  imports: [
    CommonModule,
    NzPopoverModule,
  ]
})
export class ListDropDownModule { }
