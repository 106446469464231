<div class="assign-row">
    <div class='worker-info-wrapper'>
        <div class="worker-details-area">
            <div class="avatar-area">
                <div class="training-icon" *ngIf="worker.site_trained">
                    <img src="./assets/icons/onsite-training-icon.svg" nz-tooltip nzTooltipTitle="Site trained">
                </div>
                <nz-avatar (click)="openProfileAction(worker)" class="avatar-margin cursor-pointer" nzPlacement="top"
                    [nzText]="worker.name[0]" [nzSrc]="worker?.avatar" nzSize="small">
                </nz-avatar>
            </div>
            <div class="details-area"
                [ngClass]="(!worker.time_since_applied && !worker.ranking_score) ? 'full-width' : '' ">
                <p (click)="openProfileAction(worker)" class="b-font-demibold avatar-name cursor-pointer">
                    {{helper.full_name(worker)|titlecase}}
                </p>
                <div class="b-font-normal b-Eh4 grey">{{worker?.mobile_number}}</div>
            </div>
        </div>
        <div class='worker-extra-details'>
            <p *ngIf="worker.time_since_applied" class="grey"><img
                    src="./assets/icons/Contractor (1).svg">{{worker.time_since_applied}}</p>
            <div *ngIf="worker.ranking_score" class="rating-area">
                <p class="grey"><img src="./assets/icons/rating.svg" alt="">{{worker?.ranking_score}}
                </p>
            </div>
        </div>
	
    </div>
	<div *ngIf="blockedWorkers" class="blocked-details">
		<p class="grey">Date blocked: {{worker?.blacklistedDate | dateFormat:'DD/MM/YY' : 'Europe/London'}}</p>
		<p class="grey reason">Reason: {{worker?.blacklistedReason}}</p>
	</div>
    <div class="text-right">
        <app-clib-button *ngIf="secondary_button" class="accept" color="dark" type="outline"
            [loading]='loading.loading_secondary' [label]="secondary_button" (pressAction)="secondaryButtonAction()">
        </app-clib-button>
        <app-clib-button *ngIf="primary_button" class="accept" color="primary" [loading]='loading.loading_primary'
            [label]="primary_button" (pressAction)="primaryButtonAction()">
        </app-clib-button>
    </div>
</div>