import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AutoApproveModalComponent } from './auto-approve-modal.component';
import { ModalModule } from '@broadstone/modal';
import { ClibButtonModule } from '@broadstone/clib-button';



@NgModule({
  declarations: [AutoApproveModalComponent],
  exports:[AutoApproveModalComponent],
  imports: [
	CommonModule,
	ModalModule,
	ClibButtonModule,

  ]
})
export class AutoApproveModalModule { }
