import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.scss']
})
export class PolicyComponent implements OnInit {
  @Input() fee: any;
  @Input() edit_shift: boolean;
  constructor() { }

  ngOnInit() {
  }

}
