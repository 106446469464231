import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EmployeesService } from 'src/services/public_api';
import { PopNotificationService } from '@broadstone/pop-notification-service';

@Component({
	selector: 'app-suspend-worker',
	templateUrl: './suspend-worker.component.html',
	styleUrls: ['./suspend-worker.component.scss']
})
export class SuspendWorkerComponent {
	@Input() suspendModalVisible: boolean = false;
	@Input() worker: any;
	@Output() suspendModalVisibleChange: EventEmitter<any> = new EventEmitter();
	suspendReason: string = '';
	suspendMessage: string = '';
	suspendReasons: any = [
		{ reason: 'Expired Right to Work and ID Verification', selected: false },
		{ reason: 'Not providing docs/information for BS7858', selected: false },
		{ reason: 'SIA expired', selected: false },
		{ reason: 'Time failed - 12 weeks BS7858 (not completed in 12 weeks)', selected: false },
		{ reason: '3 strikes', selected: false },
		{ reason: 'Breach of community guidelines (behaviour/performance)', selected: false },
		{ reason: 'Other', selected: false },
	];
	modalContent: any; // @todo added for build to pass - update type and default value
	@Output() refreshWorkers: EventEmitter<any> = new EventEmitter();
	constructor(private employeesService: EmployeesService, private popNotif: PopNotificationService) { }


	closeSuspendModal() {
		this.suspendModalVisible = false;
		this.suspendModalVisibleChange.emit(this.suspendModalVisible);
	}
	suspendRole() {

		const userObject = {
			user_uuid: this.worker.details.userUuid,
			reason: this.suspendReason,
		};
		if (this.suspendMessage) {
			userObject['message'] = this.suspendMessage;
		}
		this.employeesService.suspendWorker(userObject).then((result) => {
			this.popNotif.createNotification('success', 'Success', 'Worker has been suspended.');
			this.refreshWorkers.emit();
			this.closeSuspendModal();
		}).catch((err) => {
			this.popNotif.createNotification('error', 'There has been a problem.', 'Please try again later.');
		});
	}



	selectSuspendReason(reason) {
		this.suspendMessage = '';
		this.suspendReason = reason;

		this.suspendReasons.map(elem => {
			if (reason === elem.reason) {
				elem.selected = true;
			}
			else {
				elem.selected = false;
			}
		});
	}

}
