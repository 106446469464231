import { Component, OnInit, Input } from '@angular/core';
import { JobsService, ShiftsService } from 'src/services/public_api';
import { Costs, DatesHelper } from '@broadstone/helpers';


@Component({
	selector: 'app-cancellation-policy',
	templateUrl: './cancellation-policy.component.html',
	styleUrls: ['./cancellation-policy.component.scss']
})
export class CancellationPolicyComponent implements OnInit {
	@Input() shifts_data: any;
	@Input() payment_method: string;
	@Input() location_timezone: string;
	@Input() jobUuid: string;
	shift_total: any = 0;
	pay_type: any = {
		subtotal: 0,
		fee: 0, //%
		fee_amount: 0,
		total_no_vat: 0,
		holiday_amount: 0,
		holiday: 0, //%,

	}
	worker_fee_total = 0;
	broadsone_fee = 0; //%
	broadstone_fee_amount = 0;
	employment_cost: number = 0;
	costsLoading: boolean = false;
	constructor(
		public costHelper: Costs,
		public date_helper: DatesHelper,
		private jobservice: JobsService,
		private shifts_service: ShiftsService
	) { }

	ngOnInit() {
		this.costsLoading = true;
		this.shiftsTotal();
	}

	shiftsTotal() {
		let shifts: any = [];
		let number_of_hours: number = 0;
		let number_of_bank_holiday_hours: number = 0;
		let pay_rate: number = 0;
		let bank_holiday_pay_rate: number = 0;
		let total: number = 0;

		this.shifts_data.forEach(shift => {
			shifts.push({
				"start_at": shift.start_at,
				"end_at": shift.end_at,
				"slots": shift.slots,
				"hourly_rate": shift.hourly_rate,
				"bank_holiday_rate": shift.bank_holiday_rate
			})
		});
		let shifts_obj = {
			"shifts": shifts,
			"job": this.jobUuid
		}
		this.shifts_service.getShiftPay(shifts_obj).then((result) => {
			result.forEach(element => {
				number_of_hours = element.shift_pay.hours;
				number_of_bank_holiday_hours = element.bank_holiday_pay.hours ? element.bank_holiday_pay.hours : 0;
				pay_rate = element.shift_pay.hourly_rate;
				bank_holiday_pay_rate = element.bank_holiday_pay.hourly_rate ? element.bank_holiday_pay.hourly_rate : 0;
				total = total + ((number_of_hours * pay_rate) + (number_of_bank_holiday_hours * bank_holiday_pay_rate));

			});
			this.checkPay(total)


		}).catch(() => {
			return;
		});

	}


	checkPay(total) {
		if (!this.payment_method) {
			return;
		}
		const pay = {
			"payment_method": this.payment_method,
			"amount": total,

		};
		this.jobservice.getPay(pay).then((result) => {
			this.pay_type = this.costHelper.map_pay(result);
			this.worker_fee_total = this.shift_total + this.pay_type.holiday_amount;
			this.broadsone_fee = this.pay_type.fee + this.pay_type.additional_fee;
			this.broadstone_fee_amount = this.pay_type.fee_amount + this.pay_type.additional_fee_amount;
			this.employment_cost = this.pay_type.additional_fee_amount + this.pay_type.holiday_amount;
			this.costsLoading = false;

		}).catch((err) => {
			// this.logger.error(err);
		});

	}

}
