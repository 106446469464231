import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { ClibButtonModule } from '@broadstone/clib-button';

import { SearchInputModule } from '@broadstone/search-input';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { WorkersComponent } from './workers.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { ApplicationGlobals } from '@broadstone/helpers';
import { ModalModule } from '@broadstone/modal';
import { SuspendWorkerModule } from '../suspend-worker/suspend-worker.module';
import { WorkerStatusesModule } from '../worker-statuses/worker.statuses.module';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NoSearchDataModule } from '@broadstone/no-search-data';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { EditWorkerModule } from '../edit-worker/edit-worker.module';
import { ListDropDownModule } from 'src/components/public_api';
import { NzSelectModule } from 'ng-zorro-antd/select';



@NgModule({
	declarations: [WorkersComponent],
	exports: [WorkersComponent],
	imports: [
		CommonModule,
		FormsModule,
		SearchInputModule,
		ClibButtonModule,
		NzPaginationModule,
		NzAvatarModule,
		ListDropDownModule,
		BrowserAnimationsModule,
		HttpClientModule,
		NzDatePickerModule,
		NzSkeletonModule,
		ApplicationGlobals,
		ModalModule,
		SuspendWorkerModule,
		WorkerStatusesModule,
		NzToolTipModule,
		NoSearchDataModule,
		NzDrawerModule,
		EditWorkerModule,
		NzSelectModule
	]
})
export class WorkersModule { }
