<div class="edit-profile-wrapper">
	<form [formGroup]="form">
		<div class="post-head-wrapper custom-width">
			<div>
				<div class='avatar-overlay cursor-pointer'>
					<img src="./assets/icons/camera.svg" (click)="file.click()">
					<input class="hide" #file type="file" accept="image/*" (change)="getImg($event)">
				</div>
				<nz-avatar [nzText]='currentUser?.fullName[0]' [nzSrc]="imagesrc ? imagesrc : currentUser?.userAvatar"
					[nzSize]="136">
				</nz-avatar>
			</div>
			<div>
				<h1>{{currentUser?.fullName}}</h1>
			</div>
			<div>
				<h4 class='info-title'>Personal Info</h4>
			</div>
		</div>



		<div class="row-spacing">
			<label for="name-id">First Name</label>
			<input class="b-input b-input-white custom-width" formControlName="name" type="text" name="name" required
				id='name-id'>
			<div *ngIf="name.hasError('required') && name.touched">
				<p class="error-box custom-width">Please fill in this field.</p>
			</div>
		</div>

		<div class="row-spacing">
			<label for="last-name-id">Last Name</label>
			<input class="b-input b-input-white custom-width" formControlName="last_name" type="text" name="last_name"
				required id='last-name-id'>
			<div *ngIf="last_name.hasError('required') && last_name.touched">
				<p class="error-box custom-width">Please fill in this field.</p>
			</div>
		</div>

		<div class="row-spacing">
			<label for="email-address">Email Address</label>
			<input class="b-input b-input-white custom-width" formControlName="email_address" email="true" type="email"
				name="email_address" required id='email-address'>
		</div>

		<!-- Phone Number -->
		<div class="row-spacing">
			<label for="phone-number">Phone Number</label>

			<input class="b-input b-input-white custom-width" formControlName="phone_number" type="text"
				name="phone_number" id='phone-number'>
			<div *ngIf="phone_number.hasError('required') && phone_number.touched">
				<p class="error-box custom-width">Please fill in this field.</p>
			</div>
		</div>
	</form>
	<div class="row-spacing" id="auth-update">
		<label for="phone-number">Two-Factor Login <img src="./assets/icons/info.svg" nz-popover
				[nzPopoverContent]="tfaPopover" nzPopoverTrigger="hover" nzPlacement="right"></label>
		<div class="auth-container">
			<div class="switch-wrapper">
				<label class="custom-text" for="fname"><span>Email</span>

				</label>

				<nz-switch class="nz-override-switch" [(ngModel)]="tfa.email"
					(ngModelChange)="onSwitchChange($event, 'email')">
				</nz-switch>

			</div>
			<div class="switch-wrapper">

				<label class="custom-text" for="fname"><span>SMS</span>

				</label>
				<nz-switch class="nz-override-switch" [(ngModel)]="tfa.sms"
					(ngModelChange)="onSwitchChange($event, 'sms')">
				</nz-switch>
			</div>
		</div>

	</div>
	<ng-template #tfaPopover>
		<div class="popoverContainer">
			<p>By enabling two-factor login you will increase security on your account. You will be required to enter a
				unique code that will be sent via email or sms to you each time you login. Please select your preference
				below. If both are selected you will prompted on login to select one of the options each time.</p>
		</div>
	</ng-template>

	<div class="group-buttons">
		<app-clib-button [disabled]="!form.valid" [loading]="update_loading" (pressAction)="updateProfile()"
			label="Update">
		</app-clib-button>
	</div>
</div>

<lib-modal title="Please enter your password" [show_modal]="isVisible" (closeModal)="modalClose()"
	btn_color_primary="primary" body="To update your login preferences you are required to enter your password."
	btn_label_primary="Submit" (clickPrimary)="updateAuthPref()" btn_label_secondary="Cancel"
	(clickSecondary)="modalClose()" [width]="450">
	<form>
		<br>
		<div class="input-wrapper-modal">
			<input class="b-input" [(ngModel)]="password" name="password" type="password">
		</div>
	</form>
</lib-modal>