import { Component, OnInit } from '@angular/core';
import { MaintenanceService } from 'src/services/public_api';
import { Router } from '@angular/router';

@Component({
	selector: 'app-maintenance-mode',
	templateUrl: './maintenance-mode.component.html',
	styleUrls: ['./maintenance-mode.component.scss']
})
export class MaintenanceModeComponent implements OnInit {
	maintenanceWindow: any;
	constructor(
		private maintenance: MaintenanceService,
		private router: Router
	) { }

	ngOnInit(): void {
		this.maintenance.checkMaintenanceMode().then((res) => {
			if(res.data.maintenance_mode === 0){
				this.router.navigateByUrl('worker-map');
			}else {
				let maintenanceHours = res.data.maintenance_window
				let time = maintenanceHours > 1 ? ' hours' : ' hour';
				this.maintenanceWindow = maintenanceHours + time;
			}
		}).catch((err) => {
			console.log(err)
		});
	}

}
