<div class="input-wrapper">
  <input class="b-input b-input-white" #field="ngModel" [maxlength]="input_text_limit" [email]="email ? true : false"
    [type]="email ?'email':'text'" [(ngModel)]="value" [id]='myid' [placeholder]="placeholder">

  <app-clib-button type="outline" color="dark" [disabled]="!field.valid" label="Save" [loading]="save_loading"
    (pressAction)="save()">
  </app-clib-button>
</div>
<div *ngIf="field.touched && field.invalid && field.errors.email && email">
  <p [style.width]="width" class="error-box">Email is not valid.</p>
</div>
<div class="list-wrapper">
  <div *ngFor="let element of data; let i = index" class="list-element">
    <p class="b-light">{{element}}</p>
    <a (click)="delete(i)"><img src="assets/icons/ic-delete.svg" /></a>
  </div>
</div>