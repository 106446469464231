import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-list-staff',
    templateUrl: './list-staff.component.html',
    styleUrls: ['./list-staff.component.scss']
})
export class ListStaffComponent implements OnInit {
    @Input() assigned_staff: any = [];
    @Input() width: string;
    @Input() delete_icon: boolean = true;
    @Output() onDelete: EventEmitter<any> = new EventEmitter();

    constructor() { }

    ngOnInit() {
    }

    delete(value) {
        this.assigned_staff.splice(this.assigned_staff.indexOf(value), 1);
        this.onDelete.emit(value);
    }

}
