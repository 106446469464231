import { Injectable } from '@angular/core';
import { Staff } from '../staff/staff.model';
import { GlobalAdapter } from '../adapter';

export interface Site {
	location: object,
	clock_distance_limit: any,
	client: any,
	reference: string,
	purchase_order: string
	client_visible?: boolean,
	car_parking_available?: boolean,
	training_required?: boolean,
	instructions?: string,
	control_room_name?: string,
	control_room_number?: string,
	uuid?: string
	assigned_staff?: Array<Staff>,
	image_1?: string,
	image_2?: string,
	image_3?: string,
	site_type_id: number
}

export interface Clients {
	uuid?: string,
	name: string
}

export interface DashboardShift {
	accepted_applications: number,
	end_at: string,
	late_applications: Number,
	site: Site,
	no_show_applications: number,
	start_at: string,
	uuid: string,
	job_uuid: string,
	slots: number,
	cancelled_applications: number,
	status: number
	pending_applications: number,
}

export interface Company {
	legal_name: string,
	company_reg: number,
	company_name: string,
	clock_distance_limit: number,
	billing_email: Array<string>,
	billing_phone: string,
	timesheet_email: Array<string>,
	company_logo: string,
	company_description: string,
	control_phone: string,
	control_email: string,
	owner_name: string,
	owner_email: string,
	reverse_apply_flow_auto_accept_enabled:number,

}


@Injectable({
	providedIn: 'root'
})
export class CompaniesAdapter {

	constructor(
		public adapter: GlobalAdapter
	) {

	}

	siteModel = [
		'location',
		'clock_distance_limit',
		'client',
		'reference',
		'purchase_order',
		'client_visible',
		'car_parking_available',
		'training_required',
		'instructions',
		'control_room_name',
		'control_room_number',
		'uuid',
		'assigned_staff',
		'image_1',
		'image_2',
		'image_3',
		'site_type_id'
	]

	newSiteModel = [
		'location',
		'clock_distance_limit',
		'client',
		'reference',
		'purchase_order',
		'client_visible',
		'car_parking_available',
		'training_required',
		'instructions',
		'control_room_name',
		'control_room_number',
		'uuid',
		'image_1',
		'image_2',
		'image_3',
		'site_type_id'
	]

	companyModel = [
		'legal_title',
		'company_registration_number',
		'title',
		'clock_distance_limit',
		'invoice_email',
		'invoice_phone',
		'timesheet_email',
		'logo',
		'description',
		'contact_number',
		'check_email',
		'owner',
		'reverse_apply_flow_auto_accept_enabled'
	]

	dashboardShiftModel = [
		'accepted_applications',
		'end_at',
		'late_applications',
		'site',
		'no_show_applications',
		'start_at',
		'uuid',
		'job_uuid',
		'slots',
		'cancelled_applications',
		'status',
		'pending_applications'
	]


	adaptCompany(data, logger): Company {
		logger.logMissingObjProps('Company', data, this.companyModel);
		return {
			'legal_name': data.legal_title,
			'company_reg': data.company_registration_number,
			'company_name': data.title,
			'clock_distance_limit': data.clock_distance_limit,
			'billing_email': data.invoice_email,
			'billing_phone': data.invoice_phone,
			'timesheet_email': data.timesheet_email,
			'company_logo': data.logo,
			'company_description': data.description,
			'control_phone': data.contact_number,
			'control_email': data.check_email,
			'reverse_apply_flow_auto_accept_enabled': data.reverse_apply_flow_auto_accept_enabled,
			'owner_name': data.owner && data.owner.name && data.owner.surname ? data.owner.name + ' ' + data.owner.surname : null,
			'owner_email': data.owner && data.owner.email ? data.owner.email : null
		}
	}


	adaptNewSites(site, logger): Site {
		logger.logMissingObjProps('New Sites', site, this.newSiteModel);
		return this.adapter.globalAdapting(this.newSiteModel, site) as Site;
	}


	adaptSites(site, logger): Site {
		logger.logMissingObjProps('Sites', site, this.siteModel);
		return this.adapter.globalAdapting(this.siteModel, site) as Site;
	}

	adaptDashboardShift(shift, logger): DashboardShift {
		logger.logMissingObjProps('Dashboard Shifts', shift, this.dashboardShiftModel);
		return this.adapter.globalAdapting(this.dashboardShiftModel, shift) as DashboardShift;
	}


	adaptClients(data): Clients {
		return {
			'uuid': data.uuid,
			'name': data.name
		}
	}

}