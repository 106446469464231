import { Component, OnInit } from '@angular/core';
import { ApplicationsService } from 'src/services/public_api';
import { PopNotificationService } from '@broadstone/pop-notification-service';



@Component({
	selector: 'app-incident-reports',
	templateUrl: './incident-reports.component.html',
	styleUrls: ['./incident-reports.component.scss']
})
export class IncidentReportsComponent implements OnInit {
	incidentReports: any;
	loading: boolean = false;
	page: number;
	totalPages: number;

	constructor(
		private applicationsService: ApplicationsService,
		private popNotif: PopNotificationService
	) {
	}

	ngOnInit(): void {
		this.page = 1;
		this.getIncidents();
	}

	getIncidents() {
		this.loading = true;
		this.applicationsService.getIncidentReports(this.page).then((result: any) => {
			this.incidentReports = result.data;
			this.loading = false;
			this.totalPages = result.metaData.last_page
		}).catch((err) => {
			// this.logger.error(err);
			this.popNotif.createNotification('error', 'There has been a problem.', err);
			this.loading = false;
		});
	}

	async changePage(event) {
		window.scrollTo(0, 0);
		this.page = event;
		this.getIncidents();
	}

}
