import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService, EventsTracker, UsersService } from 'src/services/public_api';
import { AuthSessionService } from '@broadstone/auth-session-service';
import { AlertService } from '@broadstone/alert';

@Component({
	selector: 'app-staff-account',
	templateUrl: './staff-account.component.html',
	styleUrls: ['./staff-account.component.scss']
})
export class StaffAccountComponent implements OnInit {
	signup_completed: boolean = false;
	company: any;
	token: any;
	private sub: any;
	message: string;
	success_data: any;
	constructor(
		private event_tracker: EventsTracker,
		private router: Router,
		private route: ActivatedRoute,
		private session: AuthSessionService,
		private auth: AuthService,
		private user: UsersService,
		private alert: AlertService,
	) { }

	ngOnInit() {
		this.sub = this.route.queryParams.subscribe(params => {
			this.token = params['token'];
		});
	}
	done(data) {
		this.success_data = data; // {company:{}, account:{}}
		this.signup_completed = true;
		this.company = data.company;
		this.message = `You are now successfully registered! Tap the button below to explore the app and get started.`;
		this.event_tracker.trackEvent('create_user', this.success_data);
	}

	login() {
		if (!this.success_data.account) {
			this.redirect('login');
			return;
		}
		this.auth.authenticate(this.success_data.account).then(data => {
			this.session.setToken(data.token);
			this.getUserForSession(data.token, data.is_admin);
		}).catch(e => {
			// this.logger.error(e); 
			this.redirect('login');
		});
	}

	getUserForSession(token, admin) {
		this.user.getCurrentUser().then((data: any) => {
			this.session.login(token, data, admin, true);
			this.event_tracker.trackEvent('login', this.session['currentUser']);
		}).catch((err) => {
			console.log(err);
		});

	}


	ngOnDestroy() {
		this.sub.unsubscribe();
	}
	redirect(e) {
		const redirect = '/' + e;
		this.router.navigate([redirect]);
	}

}
