import { AuthSessionService } from '@broadstone/auth-session-service';
import { ApiService } from '@broadstone/api-service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ShiftsAdapter, Shifts } from './shifts.model';
import { DatesHelper } from '@broadstone/helpers';
import { FiltersServices } from '../filters/filters.service';
import { LoggingService } from '../logging/logging.service';
import { CachingService } from '../caching-service';


@Injectable({
	providedIn: 'root'
})
export class ShiftsService {



	constructor(
		public http: HttpClient,
		public api: ApiService,
		private session: AuthSessionService,
		private adapter: ShiftsAdapter,
		public date_helper: DatesHelper,
		private filters_service: FiltersServices,
		public logger: LoggingService,
		private cachingService: CachingService
	) {


	}

	public createShifts(shifts: Array<Shifts>, worker_uuids, jobUuid, pools_option: 'previously_worked' | 'all' | 'specific' = 'all'): Promise<any> {
		const url = this.api.getFullUrl('companies/shifts');
		const headers = this.api.getDefaultHeaders(this.session.getToken());
		const obj = {
			shifts: shifts,
			pools_option,
			job: jobUuid
		};
		if (pools_option === 'specific') {
			obj['worker_uuids'] = worker_uuids
		}

		return this.http.post(url, obj, { headers })
			.pipe(
				map((data: Shifts) => data['data'].map(item => {
					return this.adapter.adaptNewShifts(item, this.logger);
				}))
			)
			.toPromise();
	}

	public getBankHolidays(shift_info): Promise<any> {
		const url = this.api.getFullUrl('company/bank-holiday');
		const headers = this.api.getDefaultHeaders(this.session.getToken());
		return this.http.post(url, shift_info, { headers })
			.pipe(
				map((data) => data['data'].map(item => {
					return item;
				}))
			)
			.toPromise();
	}

	public getShiftPay(shifts): Promise<any> {
		const url = this.api.getFullUrl('companies/shifts/pay');
		const headers = this.api.getDefaultHeaders(this.session.getToken());
		return this.http.post(url, shifts, { headers })
			.pipe(
				map((data) => data['data'].map(item => {
					return item;
				}))
			)
			.toPromise();
	}

	public escalateShifts(shifts) {
		return new Promise((resolve, reject) => {
			const url = this.api.getFullUrl('company/shifts/escalate');
			const headers = this.api.getDefaultHeaders(this.session.getToken());
			return this.http.post(url, shifts, { headers })
				.subscribe(
					data => resolve(data),
					error => reject(this.api.handleErrors(error))
				)
		})
	}


	public checkBulkEditDates(shifts) {
		return new Promise((resolve, reject) => {
			const url = this.api.getFullUrl('companies/shifts/bank-holidays');
			const headers = this.api.getDefaultHeaders(this.session.getToken());
			return this.http.post(url, shifts, { headers })
				.subscribe(
					data => resolve(data),
					error => reject(this.api.handleErrors(error))
				);
		});
	}



	public getShifts(job, page, limit, filters?, cache: boolean = false): Promise<any> {
		const now = this.date_helper.utc_date(new Date().setSeconds(0, 0));
		filters = this.filters_service.adapt_filters(filters, this.date_helper);
		return new Promise((resolve, reject) => {
			let filter = `companies/shifts?job=${job}`;

			if (filters.start_at && filters.end_at) {
				const start = this.date_helper.adapt_dates('start_at', filters.start_at, this.date_helper);
				const end = this.date_helper.adapt_dates('end_at', filters.end_at, this.date_helper);
				filter += `&shift.start_at=${start}<>${end}`;
			}
			else if (filters.active_toggle) {
				filter += `&shift.end_at=>=${now}`;
			}
			else if (!filters.active_toggle) {
				filter += `&shift.end_at=<=${now}`;
			}

			filter += `&page=${page}&limit=${limit}`;

			const url = this.api.getFullUrl(filter);
			const headers = this.api.getDefaultHeaders(this.session.getToken());
			if (cache) {
				this.cachingService.addCache(url, 30, true);
			}else{
				this.cachingService.removeCache(url);
			}

			this.http.get(url, { headers })
				.subscribe(
					(data) => {
						const adapted_data = data['data'].map(item => {
							return this.adapter.adaptShifts(item, this.logger);
						});


						const result = {
							data: adapted_data,
							metaData: data['meta'],
							total_slots: data['total_slots']
						};

						resolve(result);
					},
					(error) => reject(this.api.handleErrors(error))
				)

		});
	}
	public editShift(shift, shiftUuid) {
		return new Promise((resolve, reject) => {
			const url = this.api.getFullUrl('companies/shifts/:shiftUuid', { shiftUuid });
			const headers = this.api.getDefaultHeaders(this.session.getToken());
			return this.http.patch(url, shift, { headers })
				.subscribe(
					data => resolve(data),
					error => reject(this.api.handleErrors(error))
				);
		});

	}

	public editShifts(shifts) { //edit multiple shifts
		return new Promise((resolve, reject) => {
			const url = this.api.getFullUrl('companies/shifts');
			const headers = this.api.getDefaultHeaders(this.session.getToken());
			return this.http.patch(url, shifts, { headers })
				.subscribe(
					data => resolve(data),
					error => reject(this.api.handleErrors(error))
				);
		});

	}

	public deleteShifts(shifts) {
		return new Promise((resolve, reject) => {
			const url = this.api.getFullUrl('company/shifts/delete');
			const headers = this.api.getDefaultHeaders(this.session.getToken());
			return this.http.post(url, shifts, { headers })
				.subscribe(
					data => resolve(data),
					error => reject(this.api.handleErrors(error))
				)
		})


	}

	public getShiftsDetails(shifts): Promise<any> {
		const url = this.api.getFullUrl(`companies/shifts?shifts=${shifts}`);
		const headers = this.api.getDefaultHeaders(this.session.getToken());
		return this.http.get(url, { headers })
			.pipe(
				map((data: Shifts) => data['data'].map(item => {
					return this.adapter.adaptAccepted(item);
				}))
			)
			.toPromise();
	}






}