import { Component, EventEmitter, Output } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { environment } from 'src/environments/environment';

@Component({
  selector: "app-map-workers",
  templateUrl: "./map-workers.component.html",
  styleUrls: ["./map-workers.component.scss"],
})
export class MapWorkersComponent {
  @Output() postJobsAction: EventEmitter<any> = new EventEmitter();
  loading: boolean = true;
  safeUrl: any;
  constructor(private domSanit: DomSanitizer) {
    this.safeUrl = this.getSafeUrl();
  }

  getSafeUrl() {
    return this.domSanit.bypassSecurityTrustResourceUrl(environment.lookerMap);
  }

  postJobs() {
    this.postJobsAction.emit();
  }

  handleIframeLoad() {
    this.loading = false;
  }
}
