import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';

@Component({
    selector: 'app-view-schedule-component',
    templateUrl: './view-schedule-component.component.html',
    styleUrls: ['./view-schedule-component.component.scss']
})
export class ViewScheduleComponentComponent implements OnInit {
    @Input() validDays: any;
    date: any;

    constructor() { }

    ngOnInit() {
    }

    handleSelection(current) {
        const currentMoment = moment(current).format('DD-MM-YYYY')
        if (this.validDays) {
            for (let i = 0; i < this.validDays.length; i++) {
                if (this.validDays[i] === currentMoment) {
                    return currentMoment;
                }
            }
        }
    }

}
