import { AuthSessionService } from '@broadstone/auth-session-service';
import { ApiService } from '@broadstone/api-service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StaffAdapter, StaffInvite, newStaff } from './staff.model';
import { map } from 'rxjs/operators';


export interface StaffSearchParameters {
    search?: string;
    role?: string;
}

@Injectable({
    providedIn: 'root'
})
export class StaffService {


    constructor(
        public http: HttpClient,
        public api: ApiService,
        private session: AuthSessionService,
        private adapter: StaffAdapter,
    ) {

    }

    public searchStaff(q: StaffSearchParameters, page: number, limit: number): Promise<any> {
        return new Promise((resolve, reject) => {
            const url = this.api.getFullUrl('companies/staff?search=:search&role=:role&page=:page&limit=:limit', { 'search': q.search ? q.search : '', 'role': q.role ? q.role : '', page, limit });
            const headers = this.api.getDefaultHeaders(this.session.getToken());
            return this.http.get(url, { headers })
                .subscribe(
                    (data) => {
                        const adapted_data = data['data'].map(item => {
                            return this.adapter.adapt_staff(item);
                        });
                        const result = this.api.adapt_pagination(adapted_data, data);
                        resolve(result);
                    },
                    (error) => reject(this.api.handleErrors(error))
                );
        });

    }
    public getStaffProfile(staffProfileUuid): Promise<any> {
        return new Promise((resolve, reject) => {
            const url = this.api.getFullUrl('companies/staff/:staffProfileUuid', { staffProfileUuid });
            const headers = this.api.getDefaultHeaders(this.session.getToken());
            return this.http.get(url, { headers })
                .subscribe(
                    (data) => {
                        const result = this.adapter.adapt_staff(data['data']);
                        resolve(result);
                    },
                    (error) => reject(this.api.handleErrors(error))
                );
        });

    }



    // put request to amend staff role

    public amendStaffPermissions(uuid: string, permissions: Array<string>): Promise<any> {
        return new Promise((resolve, reject) => {
            const url = this.api.getFullUrl('company/staff/:uuid', { uuid });
            const headers = this.api.getDefaultHeaders(this.session.getToken());
            return this.http.patch(url, { permissions }, { headers })
                .subscribe(
                    (data) => {
                        const result = data['data'];
                        resolve(result);
                    },
                    (error) => reject(this.api.handleErrors(error))
                );
        })

    }


    // delete staff profile

    public deleteStaffProfile(uuid: string): Promise<any> {
        const url = this.api.getFullUrl('company/staff/:uuid', { uuid });
        const headers = this.api.getDefaultHeaders(this.session.getToken());
        return this.http.delete(url, { headers })
            .pipe(
                map((data: any) => {
                    return data;
                })
            )
            .toPromise();
    }

    // post request to create a company staff invite

    public sendStaffInvite(staffInvite: StaffInvite): Promise<any> {
        return new Promise((resolve, reject) => {
            const url = this.api.getAuthUrl('company/auth/invite');
            const headers = this.api.getDefaultHeaders(this.session.getToken());
            return this.http.post(url, staffInvite, { headers, withCredentials: true })
                .subscribe(
                    (data) => {
                        resolve(data);
                    },
                    (error) => reject(this.api.handleErrors(error))
                );
        });
    }




    public CreateStaff(newStaff: newStaff): Promise<any> {
        return new Promise((resolve, reject) => {
            const url = this.api.getAuthUrl('company/auth/invite/redeem');
            const headers = this.api.getDefaultHeaders(this.session.getToken());
            return this.http.post(url, newStaff, { headers })
                .subscribe(
                    (data) => {
                        resolve(data);
                    },
                    (error) => reject(this.api.handleErrors(error))
                );
        });
    }

    public CreateOwner(owner) {
        return new Promise((resolve, reject) => {
            const url = this.api.getFullUrl('companies');
            const headers = this.api.getDefaultHeaders(this.session.getToken());
            return this.http.post(url, owner, { headers })
                .subscribe(
                    (data) => {
                        resolve(data);
                    },
                    (error) => reject(this.api.handleErrors(error))
                );
        });
    }

    public updateStaff(profile, uuid) {
        return new Promise((resolve, reject) => {
            const url = this.api.getFullUrl('company/staff/:uuid', { uuid });
            const headers = this.api.getDefaultHeaders(this.session.getToken());
            return this.http.patch(url, profile, { headers })
                .subscribe(
                    (data) => {
                        resolve(data);
                    },
                    (error) => reject(this.api.handleErrors(error))
                );
        });
    }

    public updateMe(profile) {
        return new Promise((resolve, reject) => {
            const url = this.api.getFullUrl('company/staff');
            const headers = this.api.getDefaultHeaders(this.session.getToken());
            return this.http.patch(url, profile, { headers })
                .subscribe(
                    (data) => {
                        resolve(data);
                    },
                    (error) => reject(this.api.handleErrors(error))
                );
        });
    }

}
