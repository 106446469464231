import { Injectable } from '@angular/core';
import { GoogleAnalyticsService } from './google-analytics.service';
import { HotJarService } from './hotjar.service';




@Injectable()
export class EventsTracker {
	private environment: any;
	private company_name: any;
	private user_id: any;

	constructor(
		private googleAnalyticsService: GoogleAnalyticsService,
		private hotjar: HotJarService
	) {

	}

	setCompanyName(current_user) {
		this.company_name = (current_user && current_user.company && current_user.company.name) ? current_user.company.name : (current_user && current_user.fullName) ? current_user.fullName : '';
	}

	setUserId(current_user) {
		this.user_id = (current_user && current_user.company && current_user.company.uuid) ? current_user.company.uuid : null;
	}

	setEnv(environment, current_user) {
		this.environment = environment;
		this.setCompanyName(current_user);
		this.setUserId(current_user);
		this.googleAnalyticsService.appendGaTrackingCode(this.environment);
		this.hotjar.appendHotJarTrackingCode();
	}

	trackEvent(name, data?) {
		switch (name) {
			case 'login':
			case 'logout':
				this.authentication(name, data);
				break;

			case 'create_user':
				this.trackSignup(name, data);
				break;

			case 'create_site':
				this.trackCreateSite(name, data);
				break;

			case 'post_role':
			case 'edit_role':
			case 'delete_role':
				this.trackRole(name, data);
				break;

			case 'post_shifts':
				this.trackPostShifts(name, data);
				break;

			case 'edit_shift':
			case 'delete_shift':
				this.trackAmendShifts(name, data);
				break;

			case 'approve_applicant':
			case 'decline_applicant':
				this.trackApplication(name, data);
				break;

			case 'approve_timesheets':
			case 'decline_timesheets':
				this.trackTimesheet(name, data);
				break;

			case 'amend_timesheets':
				this.trackAmendTimesheet(name, data);
				break;

			case 'All workers':
			case 'Previously worked':
			case 'Specific workers':
				this.trackPools('Pools ' + name);
				break;
				
			case 'dashboard_filters':
				this.trackDashboardFilters('Dashboard filters - ' + data);
				break;

			case 'search_applicant_filter':
			case 'job_status_filter':
			case 'future_past_toggle_filter':
			case 'calendar_filter':
				this.trackSchedularFilters(name, data);
				break;

			default:
				break;
		}
	}

	trackDashboardFilters(name) {
		this.googleAnalyticsService.emitEvent(this.company_name, name, this.user_id);
	}

	trackPools(name) {
		this.googleAnalyticsService.emitEvent(this.company_name, name, this.user_id);
	}


	trackSchedularFilters(name, data) {
		this.googleAnalyticsService.emitEvent(this.company_name, name, data, this.user_id);
	}

	trackAmendTimesheet(name, data) {
		const uuid = data.uuid;
		this.googleAnalyticsService.emitEvent(this.company_name, name, uuid);
		this.hotjar.emit_hotjar(this.user_id, { [name]: uuid });
	}

	trackTimesheet(name, data) {
		const timesheets_nr = data.timesheets.length;
		data.timesheets.forEach(timesheet => {
			this.googleAnalyticsService.emitEvent(this.company_name, name, timesheet, timesheets_nr);
		});
		this.hotjar.emit_hotjar(this.user_id, { [name]: timesheets_nr });
	}

	trackApplication(name, data) {
		const uuid = data.uuid;
		this.googleAnalyticsService.emitEvent(this.company_name, name, uuid);
		this.hotjar.emit_hotjar(this.user_id, { [name]: uuid });
	}




	trackAmendShifts(name, data) {
		if (name === 'delete_shift') {
			this.googleAnalyticsService.emitEvent(this.company_name, name, this.user_id);
		} else {
			const uuid = data.data.uuid;
			this.googleAnalyticsService.emitEvent(this.company_name, name, uuid);
			this.hotjar.emit_hotjar(this.user_id, { [name]: uuid });
		}



	}

	trackPostShifts(name, data) {
		const shifts_nr = data.shifts.length;
		data.shifts.forEach(shift => {
			this.googleAnalyticsService.emitEvent(this.company_name, name, shift.uuid, shifts_nr);
		});
		this.hotjar.emit_hotjar(this.user_id, { [name]: shifts_nr });

	}

	trackRole(name, data) {
		const uuid = data.uuid;
		this.googleAnalyticsService.emitEvent(this.company_name, name, uuid);
		this.hotjar.emit_hotjar(this.user_id, { [name]: uuid });
	}


	trackCreateSite(name, data) {
		const uuid = data.uuid;
		this.googleAnalyticsService.emitEvent(this.company_name, name, uuid);
		this.hotjar.emit_hotjar(this.user_id, { [name]: uuid });
	}

	trackSignup(name, data) {
		const uuid = data.uuid;
		this.googleAnalyticsService.emitEvent(this.company_name, name, uuid);
		this.hotjar.emit_hotjar(this.user_id, { [name]: this.company_name });
	}


	authentication(name, data) {
		this.setCompanyName(data);
		this.setUserId(data);
		const uuid = data.uuid;
		this.googleAnalyticsService.emitEvent(this.company_name, name, uuid);
		this.hotjar.emit_hotjar(this.user_id, { [name]: this.company_name });

		this.trackTiming(name);
	}

	trackTiming(name) {
		const time = Math.round(window.performance.now());
		this.googleAnalyticsService.userTiming('timing', 'Front End Interactions', name, time);
	}



}