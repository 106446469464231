import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
    selector: 'app-floating-footer',
    templateUrl: './floating-footer.component.html',
    styleUrls: ['./floating-footer.component.scss']
})
export class FloatingFooterComponent implements OnInit {
    @Output() onApprove: EventEmitter<any> = new EventEmitter();
    @Output() onDecline: EventEmitter<any> = new EventEmitter();
    @Output() onClose: EventEmitter<any> = new EventEmitter();
    @Output() extraAction: EventEmitter<any> = new EventEmitter();
    @Output() extraAction1: EventEmitter<any> = new EventEmitter();
    @Output() extraAction2: EventEmitter<any> = new EventEmitter();
    @Input() nr: any;
    @Input() loading: any;
    @Input() bar_title: string;
    @Input() extra_action: any; // {name:'name for button'}
    @Input() extra_action1: any; // {name:'name for button'}
    @Input() extra_action2: any; // {name:'name for button'}
    @Input() no_accept: any; // {name:'name for button'}
    @Input() no_decline: any; // {name:'name for button'}
    nr_array = [];
    constructor() { }

    ngOnInit() {
    }

    ngOnChanges() {
        if (this.nr) {
            const max_nr = this.nr > 21 ? 21 : this.nr; // maxim nr of dots on view
            this.nr_array = new Array(max_nr);
        } else {
            this.nr_array.length = 0;
        }
    }

    close() {
        this.onClose.emit();
    }

    approve() {
        this.onApprove.emit();
    }

    decline() {
        this.onDecline.emit();
    }

    action() {
        this.extraAction.emit();
    }

    action1() {
        this.extraAction1.emit();
    }

    action2() {
        this.extraAction2.emit();
    }




}
