import { Injectable } from '@angular/core';


export interface Adapter<T> {
    adapt(item: any): T;
}


@Injectable({
	providedIn: 'root'
})
export class GlobalAdapter {

	globalAdapting(modelList, incomeData){
		let obj = {};
		modelList.forEach(element => {
			if (incomeData.hasOwnProperty(element)) {
				obj[element] = incomeData[element];
			}
		});
		return obj;
	}

}