<div class="search-area-wrapper">

    <div class="search-box">
        <app-list-drop-down (selectedItem)="selectedSearchItem($event)" [title_uppercase]='true' [border_right]='false'
            [element_width]="(search_box_data && search_box_data.length > 1) ? '150px': '125px'"
            [data]="search_box_data">
        </app-list-drop-down>
        <app-search-input class="custom-search-bar" debounce='500' placeholder="Search {{selected_search_value.title}}"
            (onKey)="checkApi()" [(inputModel)]="filters[selected_search_value.value]" class_style="no-border-left">
        </app-search-input>
        <!-- <input (keyup)="checkApi()" class="no-border-left b-input-search b-input" [(ngModel)]='filters[selected_search_value.value]' type="text" name="fname" id='fname'
      placeholder="Search {{selected_search_value.title}}"> -->
    </div>
    <div *ngIf="job_type_filter" class="drop-down-list">
        <label class="custom-text">Job Type</label>
        <app-list-drop-down (selectedItem)="jobTypeSelected($event)" [element_width]="'186px'"
            [reset]="checkJobTypeFilter()" [data]="job_type_dropdown"></app-list-drop-down>
    </div>
    <div *ngIf="status_filter" class="drop-down-list">
        <label class="custom-text">Status</label>
        <app-list-drop-down (selectedItem)="statusSelected($event)" [element_width]="'200px'"
            [reset]="checkStatusFilter()" [data]="status_dropdown" [status_param]="status_param"></app-list-drop-down>
    </div>
    <div class="switch-wrapper">
        <label class="custom-text" for="fname"><span *ngIf="filters.active_toggle">Upcoming</span><span
                *ngIf="!filters.active_toggle">Past</span></label>
        <nz-switch class="nz-override-switch" [(ngModel)]="filters.active_toggle"
            (ngModelChange)="onSwitchChange($event)">
        </nz-switch>
    </div>
    <div *ngIf="job_type_filter" class="switch-wrapper">
        <label class="custom-text" for="fname"><span *ngIf="filters.assigned_sites">My Sites</span><span
                *ngIf="!filters.assigned_sites">All Sites</span></label>
        <nz-switch class="nz-override-switch" [(ngModel)]="filters.assigned_sites"
            (ngModelChange)="assignedSitesChange($event)">
        </nz-switch>
    </div>
    <div class="search-calendar">
        <label class="custom-text">Date</label>
        <nz-range-picker nzInputReadOnly [(ngModel)]='range_dates' #calendar_range (ngModelChange)="onClose()"
            [nzPlaceHolder]="['dd/yy/mm', 'dd/yy/mm']" class="nz-override-date-range"
            nzDropdownClassName='nz-override-date-range-drop' nzFormat='dd/MM/yyyy'></nz-range-picker>
    </div>

    <div *ngIf="assing_filter" class="drop-down-list">
        <label class="custom-text">Assigned To</label>
        <app-list-drop-down [element_width]="'186px'" [data]="assign_dropdown">
        </app-list-drop-down>
    </div>
	<div *ngIf="buttonConfig">
		<app-clib-button [label]="buttonConfig.label" (pressAction)="buttonAction()"></app-clib-button>
	</div>
</div>