import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthSessionService } from '@broadstone/auth-session-service';
import { AuthService, LocalHelpers, UrlHelpers } from 'src/services/public_api';

@Component({
	selector: 'app-admin',
	templateUrl: './admin.component.html',
	styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
	constructor(
		private session: AuthSessionService,
		private router: Router,
		private auth: AuthService,
		private localHelpers: LocalHelpers,
		private urlHelpers: UrlHelpers,
	) { }

	ngOnInit(): void {
		this.urlHelpers.clientNavBar = false;
		setTimeout(() => {
			this.localHelpers.intercomBobbleVisibility('none');
		}, 800);

	}

	logout() {
		this.session.logout();
		this.auth.logout().catch(err => {
			console.log(err)
		});
		this.router.navigate(['/login']);
	}



}
