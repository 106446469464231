import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnChanges } from '@angular/core';
import { ShiftsService, ApplicationsService, JobsService, EventsTracker } from 'src/services/public_api';
import { DatesHelper, CustomValidator, Costs } from '@broadstone/helpers';
import { NgModel } from '@angular/forms';
import { PopNotificationService } from '@broadstone/pop-notification-service';
import { AlertService } from '@broadstone/alert';
import * as moment from 'moment';

@Component({
	selector: 'app-edit-shifts',
	templateUrl: './edit-shifts.component.html',
	styleUrls: ['./edit-shifts.component.scss']
})
export class EditShiftsComponent {
	@Input() show_modal = false;
	@Output() closeModal: EventEmitter<any> = new EventEmitter();
	@ViewChild("end_at_element") end_at_element: ElementRef;
	@ViewChild("start_at_element") start_at_element: ElementRef;
	@ViewChild('start_at_time') start_at_time: NgModel;
	@ViewChild('end_at_time') end_at_time: NgModel;
	@Input() shift: any;
	@Input() site_timezone: string;
	@Input() site_postcode: string;
	@Input() jobUuid: string;
	date: any;
	isVisible = false;
	start_at: any;
	end_at: any;
	positions: any;
	lone_worker_shift: any;
	pay: any;
	time_pattern = '^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$';
	contractor_min_rate: number = 0;
	paye_min_rate: number = 0;
	decline_loading: boolean = false;
	minimum_slots: number = 1;
	applicants: any = [];
	contractor_fee: any = 0;
	paye_fee: any = 0;
	loading_applicants: boolean = false;
	check_interval: any = null;
	max_hours: number = 13;
	bank_holiday_rate: number;
	bank_holiday_pay_rate: number;
	multipleShifts: boolean = false;
	hideDeleteButton: boolean = false;
	has_bank_holiday: boolean = false;
	upcomingMinRate:boolean = false;
	upcomingMinPayRate: any = {
		date:'',
		rate: null
	}
	constructor(
		private event_tracker: EventsTracker,
		public customvalid: CustomValidator,
		private jobservice: JobsService,
		private shifts_service: ShiftsService,
		private date_helper: DatesHelper,
		public applications_service: ApplicationsService,
		private popNotif: PopNotificationService,
		private alert: AlertService,
		public costHelper: Costs
	) { }

	ngOnChanges() {
		if (this.show_modal && this.shift) {
			this.upcomingMinRate = false;
			this.applicants.length = 0;
			this.checkShiftCount();
			this.bank_holiday_rate = this.shift.bank_holiday_rate;
			this.bank_holiday_pay_rate = this.costHelper.to_fixed(this.shift.bank_holiday_rate * (this.pay ? this.pay : this.shift.hourly_rate));
			this.getPayRate();
			this.getApplicants();
			this.showModal();
		}
	}

	amendTime(module = null, event) {
		if (!module) {
			return;
		}
		if (this[module] && this[module].indexOf(':') == -1) {
			const final_val = this[module].match(/.{1,2}/g).join(':');
			this[module] = final_val;

		}
		//when first input is fill focus next input
		if (this[module].length > 4) {
			if (module == 'start_at') {
				this.end_at_element.nativeElement.focus();
			}
		}
		//when backspace focus first input
		if (module == 'end_at' && event.keyCode == 8 && this[module] == '') {
			this.start_at_element.nativeElement.focus();
		}
		this.validateShiftHours();
	}

	checkPayRate() {
		const selectedDate = moment(this.date,'DD/MM/YYYY').format("YYYY-MM-DD");
		if (moment(selectedDate).isSameOrAfter(this.upcomingMinPayRate.date)) {
			if (this.pay < this.upcomingMinPayRate.rate) {
				this.upcomingMinRate = true;
			}
			else {
				this.upcomingMinRate = false;
			}
		}
		else {
			this.upcomingMinRate = false;
		}
	}

	formatBankHolidayShiftDate(date, returnFormat = "YYYY-MM-DD") {
		return moment(date, "DD/MM/YYYY").format(returnFormat);
	}

	checkForBankHolidays(date) {
		const transform_date = moment(date, "DD/MM/YYYY");
		if (transform_date.isValid()) {
			const reformatedDate = this.formatBankHolidayShiftDate(date);
			this.shifts_service.getBankHolidays({
				"dates": [reformatedDate],
				"postcode": this.site_postcode,

			}).then((result) => {
				result.forEach(element => {
					if (element.is_bank_holiday) {
						this.shift.is_bank_holiday = true;


					}
					else {
						this.shift.is_bank_holiday = false;
					}
					this.validateShiftHours();
				});
			}).catch(() => {
				return;
			});
		}
	}

	checkBulkEditShiftDates(uuids, f) {
		this.shifts_service.checkBulkEditDates(
			{
				"start_at": this.start_at,
				"end_at": this.end_at,
				"uuids": uuids
			}
		).then((res) => {
			if (res['data'].has_bank_holiday) {
				this.alert.showConfirm("Are you sure?", "One of the selected shifts falls on a bank holiday and so the pay rate will be automatically boosted based on your job settings.", 'Confirm', 'Cancel').then(() => {
					this.editMultipleShifts(event, f);
				}).catch((e) => {
					this.handleCancel();
					return;
				});
			}
			else if (!res['data'].has_bank_holiday) {
				this.editMultipleShifts(event, f);
			}
		}).catch(() => {
			return;
		});
	}

	checkBankHolidayRates(start_at, end_at) {
		this.shifts_service.getShiftPay({
			"shifts": [{
				"start_at": this.date_helper.reformat_moment(this.site_timezone, start_at),
				"end_at": this.date_helper.reformat_moment(this.site_timezone, end_at),
				"slots": this.shift.slots,
				"hourly_rate": this.shift.hourly_rate,
				"bank_holiday_rate": this.shift.job.bank_holiday_rate

			}]
			,
			"job": this.jobUuid
		}
		).then((result) => {
			if (result[0].bank_holiday_pay.hours) {
				this.shift.is_bank_holiday = true;
				this.shift.regular_hours = result[0].shift_pay.hours;
				this.shift.bank_holiday_hours = result[0].bank_holiday_pay.hours;
			}
		}).catch(() => {
			return;
		});
	}

	updateBankHolidayRate(pay) {
		this.checkPayRate();
		if (pay != this.shift.hourly_rate) {
			this.bank_holiday_pay_rate = this.costHelper.to_fixed(this.shift.bank_holiday_rate * pay);
		}
	}

	validateShiftHours() {
		const date = this.date ? this.date : new Date();
		const dates = this.date_helper.calculate_end_date(date, this.start_at, this.end_at);
		const start_at_timestamp = new Date(dates.start_date).getTime();
		const end_at_timestamp = new Date(dates.end_date).getTime();
		const hours = this.date_helper.calculate_hours(start_at_timestamp, end_at_timestamp);
		this.customvalid.set_hours_error(hours, this.max_hours, this.start_at_time.control, this.end_at_time.control);


		this.checkBankHolidayRates(dates.start_date, dates.end_date);
		this.checkPayRate();

	}



	checkShiftCount() {
		if (this.shift.uuids.length > 1) {
			this.multipleShifts = true;
			this.hideDeleteButton = true;
			this.setMultipleShiftData();


		}
		else if (this.shift.uuids.length === 1) {
			this.hideDeleteButton = false;
			this.multipleShifts = false;
			this.setShiftData();
		}
	}

	showModal(): void {
		this.isVisible = true;
	}

	setMultipleShiftData() {
		this.date = new Date(this.shift.start_at);
		this.start_at = this.date_helper.set_timezone_time(this.shift.start_at, this.site_timezone, 'HH:mm');
		this.end_at = this.date_helper.set_timezone_time(this.shift.end_at, this.site_timezone, 'HH:mm');
		this.positions = this.shift.slots;
		this.pay = this.shift.hourly_rate;
		this.lone_worker_shift = this.shift.lone_worker_shift;

	}

	setShiftData() {
		this.date = new Date(this.shift.start_at);
		this.start_at = this.date_helper.set_timezone_time(this.shift.start_at, this.site_timezone, 'HH:mm');
		this.end_at = this.date_helper.set_timezone_time(this.shift.end_at, this.site_timezone, 'HH:mm');
		this.positions = this.shift.slots;
		this.pay = this.shift.hourly_rate;
		this.lone_worker_shift = this.shift.lone_worker_shift;
	}

	saveChanges(event, f) {
		if (!f.valid) {
			return false;
		}
		if (this.shift.uuids.length > 1) {
			this.checkBulkEditShiftDates(this.shift.uuids, f);
			return;
		}
		event.loading_primary = true;
		const { start_date, end_date } = this.date_helper.calculate_end_date(this.date, this.start_at, this.end_at);
		const shift = {
			"start_at": this.date_helper.reformat_moment(this.site_timezone, start_date),
			"end_at": this.date_helper.reformat_moment(this.site_timezone, end_date),
			"slots": this.positions,
			"hourly_rate": this.pay,
			"lone_worker_shift": this.lone_worker_shift ? 1 : 0,
			"bank_holiday_rate": this.bank_holiday_rate,
			"is_bank_holiday": this.shift.is_bank_holiday ? this.shift.is_bank_holiday : false
		}
		this.shifts_service.editShift(shift, this.shift.uuid).then((result) => {
			event.loading_primary = false;
			this.event_tracker.trackEvent('edit_shift', result);
			this.popNotif.createNotification('success', 'Success', 'The shift has been updated.');
			this.handleOk();
		}).catch((err) => {
			event.loading_primary = false;
			if (err.shift) {
				this.popNotif.createNotification('error', 'There has been a problem.', err.shift);
			}
			else if (err.slots) {
				this.popNotif.createNotification('error', 'There has been a problem.', err.slots);
			}
			else {
				this.popNotif.createNotification('error', 'There has been a problem.', 'shift cannot be updated');
			}
			// this.logger.error(err);
		});
	}

	editMultipleShifts(event, f) {
		const { start_date, end_date } = this.date_helper.calculate_end_date(this.date, this.start_at, this.end_at);
		const shifts = {
			"uuids": this.shift.uuids,
		}

		if (this.date_helper.reformat_moment(this.site_timezone, this.shift.start_at) !== this.date_helper.reformat_moment(this.site_timezone, start_date)) {
			shifts['start_at'] = this.date_helper.reformat_moment(this.site_timezone, start_date);
		}

		if (this.date_helper.reformat_moment(this.site_timezone, this.shift.end_at) !== this.date_helper.reformat_moment(this.site_timezone, end_date)) {
			shifts['end_at'] = this.date_helper.reformat_moment(this.site_timezone, end_date);
		}
		if (this.positions !== this.shift.slots) {
			shifts['slots'] = this.positions;
		}
		if (this.lone_worker_shift !== this.shift.lone_worker_shift) {
			shifts['lone_worker_shift'] = this.lone_worker_shift ? 1 : 0;
		}
		if(this.pay !== this.shift.hourly_rate){
			shifts['hourly_rate'] = this.pay;
		}

		event.loading_primary = true;
		this.shifts_service.editShifts(shifts).then((result) => {
			event.loading_primary = false;
			this.event_tracker.trackEvent('edit_shift', result);
			this.popNotif.createNotification('success', 'Success', 'The selected shifts have been updated.');
			this.shift.uuids.length = 0;
			this.handleOk();
		}).catch((err) => {
			event.loading_primary = false;
			if (err.end_at) {
				this.popNotif.createNotification('error', 'There has been a problem.', err['end_at']);
			}
			else if (err.shift) {
				this.popNotif.createNotification('error', 'There has been a problem.', err['shift']);
			}
			else if (err.slots) {
				this.popNotif.createNotification('error', 'There has been a problem.', err.slots);
			}
			else {
				this.popNotif.createNotification('error', 'There has been a problem.', 'We cannot update these shifts.');
			}

		});
	}

	getApplicants() {
		this.loading_applicants = true;
		this.applications_service.getApplicantsByShift(this.shift.uuid, 'accepted').then((result) => {
			this.minimum_slots = result['meta'].total > 1 ? result['meta'].total : 1; // set min slots base on total applicants approved
			this.applicants = result['data'];
			this.loading_applicants = false;
		}).catch((err) => {
			// this.logger.error(err);
			this.loading_applicants = false;
		});
	}

	getPayRate() {
		this.jobservice.getPayRates().then((result) => {
			if (result && result.contractor && result.paye) {
				this.contractor_min_rate = result.contractor.minimum_worker_hourly_rate;
				this.paye_min_rate = result.paye.minimum_worker_hourly_rate;
				this.upcomingMinPayRate.date = result.paye.minimum_worker_worker_hourly_rate_effective_date;
				this.upcomingMinPayRate.rate = result.paye.post_effective_date_min_worker_hourly_rate;
				this.paye_fee = (result.paye.fee_rate * 100);
				this.contractor_fee = (result.contractor.fee_rate * 100);
			}
		}).catch((err) => {
			// this.logger.error(err);
		});
	}

	handleOk() {
		this.isVisible = false;
		this.closeModal.emit('save');
	}

	handleCancel() {
		this.isVisible = false;
		this.closeModal.emit('cancel');
	}

	decline(data) {
		if (!this.shift) {
			this.popNotif.createNotification('error', 'There has been a problem.', 'Shift not found.');
			return;
		}
		this.decline_loading = true;
		if (data && data.application && data.application.uuid) {
			const application = { applications: [data.application.uuid] };
			this.alert.showConfirm("Remove applicant", "Are you sure you want to remove the applicant from the shift? You might incur a charge for last minute removals.", 'Remove', 'Cancel').then(() => {
				this.applications_service.declineApplications(application).then((result) => {
					this.decline_loading = false;
					this.popNotif.createNotification('success', 'Success', `${data.applicant.user.name} ${data.applicant.user.surname} has been declined.`);
					this.getApplicants();
				}).catch((err) => {
					this.decline_loading = false;
					// this.logger.error(err);
					this.popNotif.createNotification('error', 'There has been a problem.', `We cannot decline ${data.applicant.user.name} ${data.applicant.user.surname}.`);
				});
			}).catch(() => {
				//cancel alert
				this.decline_loading = false;
			});
		} else {
			this.decline_loading = false;
			this.popNotif.createNotification('error', 'There has been a problem.', 'Applicant not found.');
			return;
		}
	}
}