import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PoolsCreateShiftComponent } from './pools-create-shift.component';
import { ClibButtonModule } from '@broadstone/clib-button';

import { AutoApproveModalModule } from '../auto-approve-modal/auto-approve-modal.module';
import { SearchInputModule } from '@broadstone/search-input';
import { WorkerProfileModule } from '@broadstone/worker-profile';
import { LoadingSpinnerModule } from '@broadstone/loading-spinner';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';

@NgModule({
    declarations: [PoolsCreateShiftComponent],
    exports: [PoolsCreateShiftComponent],
    imports: [
        CommonModule,
        ClibButtonModule,
        NzPopoverModule,
		NzAvatarModule,
		NzSkeletonModule,
		NzToolTipModule,
		NzDrawerModule,
		AutoApproveModalModule,
		SearchInputModule,
		WorkerProfileModule,
		LoadingSpinnerModule
    ]
})
export class PoolsCreateShiftModule { }
