import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-company-setup',
    templateUrl: './company-setup.component.html',
    styleUrls: ['./company-setup.component.scss']
})
export class CompanySetupComponent implements OnInit {
    currentUser: any;
    setup_completed: boolean = false;
    email: string;

    constructor() { }

    ngOnInit() {
    }

    done(email) {
        this.email = email;
        this.setup_completed = true;
    }

    refresh() {
        this.setup_completed = false;
    }

}
