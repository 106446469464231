<div class="b-card-large main-card">
	<div class="card-wrapper">
		<div class="card-left">
			<app-policy [fee]="pay_type.fee"></app-policy>
		</div>
		<div class="card-right">
			<div class="b-card-large costs">
				<!-- Break down cost -->
				<div>
					<div [ngClass]="payment_method == 'paye' ? 'gray-area': ''">
						<div *ngIf="payment_method == 'paye'">
							<div>
								<div class="row-wrapper">
									<!-- WORKER FEE TOTAL -->
									<h5>Worker Pay</h5>
									<app-loading-spinner color="color_grey_1" size="small" *ngIf="costsLoading">
									</app-loading-spinner>
									<h5 *ngIf="!costsLoading">&pound; {{costHelper.to_fixed(pay_type.requested_amount)}}
									</h5>
								</div>
								<div class="row-wrapper">
									<!-- WORKER COSTS -->
									<p class="b-Eh5">Holiday Pay (12.07%)</p>
									<app-loading-spinner color="color_grey_1" size="small" *ngIf="costsLoading">
									</app-loading-spinner>
									<h5 class="b-Eh4" *ngIf="!costsLoading">&pound;
										{{costHelper.to_fixed(pay_type.holiday_amount)}}</h5>
								</div>
							</div>
						</div>
					</div>
					<div *ngIf="payment_method == 'paye'">
						<!-- BROADSTONE FEE BRAKEDOWN -->
						<div class="row-wrapper">
							<p class="b-Eh4">Employment Costs</p>
							<app-loading-spinner color="color_grey_1" size="small" *ngIf="costsLoading">
							</app-loading-spinner>
							<h5 class="b-Eh4" *ngIf="!costsLoading">&pound;
								{{costHelper.to_fixed(broadstone_fee_amount)}}</h5>
						</div>
					</div>


				</div>



				<div class="b-border-top">
					<div class="row-wrapper">
						<h5>Total (GBP)</h5>
						<app-loading-spinner color="color_primary" size="small" *ngIf="costsLoading">
						</app-loading-spinner>
						<h5 class="b-primary" *ngIf="!costsLoading">&pound;
							{{costHelper.to_fixed(pay_type.total_no_vat)}}</h5>
					</div>
				</div>


			</div>
			<div class="cancellation-container">
				<p class="b-Eh4 b-light">By posting this shift, I agree to the <a target="_blank"
						href=" http://www.orka.works/service-policy" class="b-primary">Terms &
						Conditions, <a target="_blank" href=" http://www.orka.works/cancellation-policy"
							class="b-primary">Cancellation
							Policy</a> and
					</a> and the <a class="b-primary" target="_blank" href=" http://www.orka.works/app-policy">Community
						Guidelines.</a></p>
			</div>
		</div>
	</div>
</div>