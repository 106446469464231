<div class="search-bar-area">
	<!-- DATEPICKER  -->
	<div class="search-calendar">
		<label class="custom-text">Date</label>
		<nz-range-picker nzInputReadOnly [(ngModel)]='range_dates' #calendar_range (ngModelChange)="onCloseRange()"
			[nzPlaceHolder]="['dd/yy/mm', 'dd/yy/mm']" class="nz-override-date-range"
			nzDropdownClassName='nz-override-date-range-drop' nzFormat='dd/MM/yyyy'>
		</nz-range-picker>
	</div>
	<div class="status-filter">
		<label class="custom-text">Status</label>
		<app-list-drop-down (selectedItem)="status_selected($event)" [element_width]="'186px'"
			[reset]="check_status_filter()" [data]="status_dropdown"></app-list-drop-down>
	</div>


	<!-- AUTO APPROVE TEXT  -->
	<h4 [nzTooltipTitle]="'Every Monday at 5pm timesheets are automatically approved for the shift times loaded'" nz-tooltip
		*ngIf="auto_approval" class="auto-approval">
		<small>
			AUTO APPROVAL
		</small>
		<div>
			{{auto_approval | dateFormat:'ddd, MMM DD, HH:mm': 'Europe/London'}}
		</div>
	</h4>
</div>

<!-- CONTENT  -->
<nz-skeleton *ngIf="loading" class="nz-timesheets-loading" [nzParagraph]="{ rows: 7 }" [nzTitle]="false"
	[nzActive]="true">
</nz-skeleton>

<div class="timesheet-area" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="80"
	(scrolled)="onScroll()" [scrollWindow]="true">
	<div *ngFor="let timesheet of timesheets">
		<app-expandable-timesheet [edit_timesheets]="edit_timesheets" (onUpdate)=" updateApprove($event)"
			(openProfile)="openProfile($event)" [status_selected]="status" [timesheet]="timesheet"
			(onChecked)="selectTimesheet(timesheet)">
		</app-expandable-timesheet>
	</div>
	<div class="text-center">
		<nz-skeleton *ngIf="loading_more" class="nz-timesheets-loading" [nzParagraph]="{ rows: 1 }" [nzTitle]="false"
			[nzActive]="true">
		</nz-skeleton>

	</div>
</div>

<lib-no-search-data
	*ngIf="(timesheets.length == 0 && range_dates.length > 0 )||(timesheets.length == 0 && status !='pending')"
	img_src='assets/icons/magnifying-glass-tilted-left.svg' title='No Results Found'
	body='Could not find any matches. Click the button below to clear the filters.' button_name='Clear Filters'
	button_class='outline' (onButtonClick)="clear_filters()">
</lib-no-search-data>

<lib-no-search-data
	*ngIf="(timesheets.length == 0 && range_dates.length == 0 && status =='pending') && !loading && edit_timesheets"
	img_src="assets/icons/desert-island.svg" title='It’s empty in here…'
	body='To get started tap the button below to add your first job.' button_name='Post Jobs +'
	(onButtonClick)="postJob()">
</lib-no-search-data>

<nz-drawer [(nzVisible)]="profileVisible" nzWidth="375px" [nzClosable]="false" (nzOnClose)="closeProfile()">
	<ng-container *nzDrawerContent>
		<div class="close-btn text-right"><a class="cursor-pointer" (click)="closeProfile()"><img
					src="assets/icons/crossIcon.svg" /></a>
		</div>
		<lib-worker-profile *ngIf="profile && profile.user" (onClose)="closeProfile()" [profile]="profile">
		</lib-worker-profile>
	</ng-container>
</nz-drawer>

<app-floating-footer bar_title="Timesheets selected" [loading]="floating_footer_loading"
	*ngIf="selected_timesheets?.length > 0" [extra_action]="{name:'No Show', icon:'no-show-icon'}"
	(extraAction)="applicationAction('noShow')" [nr]="selected_timesheets.length" (onClose)="closeFooter()"
	(onApprove)="applicationAction('approved')" (onDecline)="applicationAction('decline')">
</app-floating-footer>