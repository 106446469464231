import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListStaffComponent } from './list-staff.component';
import { SelectDropDownModule } from '../select-drop-down/select-drop-down.module';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';


@NgModule({
  declarations: [ListStaffComponent],
  imports: [
    CommonModule,
    SelectDropDownModule,
    NzAvatarModule,
  ],
  exports: [ListStaffComponent]
})
export class ListStaffModule { }
