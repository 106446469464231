import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SiteSelectComponent } from './site-select.component';
import { ClibButtonModule } from '@broadstone/clib-button';
import { ApplicationGlobals } from '@broadstone/helpers';
import { FormsModule } from '@angular/forms';
import { SearchInputModule } from '@broadstone/search-input';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';

@NgModule({
    declarations: [SiteSelectComponent],
    exports: [SiteSelectComponent],
    imports: [
        CommonModule,
        ClibButtonModule,
		NzSkeletonModule,
		NzSwitchModule,
		NzPaginationModule,
        ApplicationGlobals,
        FormsModule,
        SearchInputModule,
        InfiniteScrollModule
    ]
})
export class SiteSelectModule { }
