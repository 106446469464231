import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { LogApplicationOrkaWorks, LogEnvironment, LogLevel } from '@broadstone/orka-monitoring/lib/enums';
import { createLog } from '@broadstone/orka-monitoring/lib/log';
import { Router } from '@angular/router';
import { AuthSessionService } from '@broadstone/auth-session-service';
import { FrontendErrorLogInterface } from '@broadstone/orka-monitoring/lib/interfaces';



@Injectable({
	providedIn: 'root'
})
export class LoggingService {
	logPropsOnce: any = [];
	currentUser;
	public preventSpamming: boolean = false;
	constructor(
		private http: HttpClient,
		private router:Router,
		protected session: AuthSessionService,
	) { 
		const userSession = this.session.getUser();
		this.currentUser = (userSession && userSession.company && userSession.company.uuid) ? userSession.company.uuid : '';
	}



	log(logName, level: 'CRITICAL' | 'ERROR' | 'WARNING' | 'DEBUG' | 'INFO', logIdentifier:string, stackError?) {

		if (!environment.production) {
			return;
		}
		const appPageUrl = (this.router && this.router.url) ? this.router.url : '';
		const data:FrontendErrorLogInterface = {
			level: LogLevel[level],
			logIdentifier: logIdentifier,
			message: logName,
			environment:
			LogEnvironment[environment.namespace.toUpperCase()],
			application: LogApplicationOrkaWorks.COMPANY_PORTAL,
			version: 'latest',
			time: new Date().toISOString(),
			context: `${appPageUrl}`,
			applicantUuid: this.currentUser,
			error: {
				stack: stackError ? this.convertError(stackError) : null,
			},
		};
		
		const log = createLog(data);
		this.sendLog(log);
	}

	convertError(obj) {
		let cache = [];
		const str = JSON.stringify(obj, function (key, value) {
			if (typeof value === 'object' && value !== null) {
				if (cache.indexOf(value) !== -1) {
					return;
				}
				cache.push(value);
			}
			return value;
		});
		cache = null;
		return str;
	}


	logMissingObjProps(name, apiObj, expectedObj) {
		const listMissingProps = [];
		expectedObj.forEach(requiredProp => { //loop through both objects and create an array if any from expectedObj are missing in apiObj
			if (!apiObj.hasOwnProperty(requiredProp)) {
				listMissingProps.push(requiredProp);
			}
		});
		if (listMissingProps.length > 0) { //if it does find anything
			const found = this.logPropsOnce.filter(element => element === name); // check through anything that is already in the array and put the unique values into a new array
			if (found.length == 0) { // This will log once in GCP per user per call to prevent spamming GCP logs
				this.logPropsOnce.push(name);
				this.log(`Missing Object Property ${name}`, 'WARNING', 'de002c1a-9e28-4cf6-baac-7a9e9c7a5715', listMissingProps.toString());
			}
		}
	}

	sendLog(data) {
		if (!this.preventSpammingLogger()) return;
		const url = environment.loggingUrl;
		const headers = {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			authorization: environment.loggingKey,
		};
		this.http.post(url, data, { headers }).subscribe(null, () => {
			return;
		});
	}

	preventSpammingLogger() {
		if (this.preventSpamming) {
			return false;
		}
		this.preventSpamming = true;
		setTimeout(() => {
			this.preventSpamming = false;
		}, 30000);
		return true;
	}
}
