import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditStaffProfileComponent } from './edit-staff-profile.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClibButtonModule } from '@broadstone/clib-button';
import { ModalModule } from '@broadstone/modal';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzSwitchModule } from 'ng-zorro-antd/switch';




@NgModule({
    declarations: [EditStaffProfileComponent],
    exports: [EditStaffProfileComponent],
    imports: [
        CommonModule,
		NzAvatarModule,
		NzPopoverModule,
		NzSwitchModule,
        FormsModule,
        ReactiveFormsModule,
        ClibButtonModule,
        ModalModule

    ]
})
export class EditStaffProfileModule { }
