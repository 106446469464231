import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { ClibButtonModule } from '@broadstone/clib-button';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { ModalModule } from '@broadstone/modal';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { EditWorkerComponent } from './edit-worker.component';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { ApplicationGlobals } from '@broadstone/helpers';


@NgModule({
	declarations: [EditWorkerComponent],
	exports: [EditWorkerComponent],
	imports: [
		CommonModule,
		FormsModule,
		ClibButtonModule,
		BrowserAnimationsModule,
		HttpClientModule,
		ModalModule,
		NzSkeletonModule,
		NzAvatarModule,
		NzSelectModule,
		NzDatePickerModule,
		NzPopoverModule,
		ApplicationGlobals,
	]
})
export class EditWorkerModule { }
