<lib-modal [width]="562" [show_modal]="suspendModalVisible"
	[btn_disabled_primary]="!suspendReason?.trim() || (suspendReason === 'Other' && !suspendMessage)"
	(closeModal)="closeSuspendModal()" btn_label_primary="Confirm" btn_color_primary="primary"
	btn_label_secondary="Cancel" (clickSecondary)="closeSuspendModal()" (clickPrimary)="suspendRole()"
	[close_icon]='false' [body]="modalContent">
	<div class="input-wrapper-modal">
		<h4 class="b-font-demibold title-margin">Please give a reason why {{worker?.details?.name}}
			{{worker?.details?.surname}} should be
			suspended.
		</h4>
		<nz-skeleton *ngIf="!worker" class="nz-timesheets-loading" [nzParagraph]="{ rows: 1 }" [nzTitle]="false"
			[nzActive]="true">
		</nz-skeleton>
		<div *ngIf="worker" class="feature-application-wrapper">
			<h5>
				Future Applications
			</h5>
			<div class="application-item">
				<div class="custom-text"><span class="application-dot dot-green"></span>
					{{worker?.profile?.future_applications_accepted}}
					Accepted
				</div>
				<div class="custom-text"><span class="application-dot dot-grey"></span>
					{{worker?.profile?.future_applications_pending}} Pending
				</div>
			</div>

		</div>
		<div class="b-Eh4 b-light"><span class="b-font-demibold">Note:</span> Suspending somebody with pending or
			accepted applications will cancel those applications.</div>
		<div *ngFor="let suspend of suspendReasons" class="checkbox-div">
			<label class="b-checkbox">
				<p>{{suspend.reason}}</p>
				<input type="radio" [checked]="suspend.selected" (click)="selectSuspendReason(suspend.reason)">
				<span class="checkmark"></span>
			</label>

			<textarea *ngIf="suspend.reason === 'Other' && suspend.selected" [(ngModel)]="suspendMessage"
				name="otherReason" placeholder="Enter reason" required
				class="b-textarea b-textarea-white textarea-margin"></textarea>


		</div>


	</div>
</lib-modal>