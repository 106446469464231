import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { StaffService, PermissionsService } from 'src/services/public_api';
import { PopNotificationService } from '@broadstone/pop-notification-service';
import { AlertService } from '@broadstone/alert';

@Component({
	selector: 'app-edit-staff',
	templateUrl: './edit-staff.component.html',
	styleUrls: ['./edit-staff.component.scss']
})
export class EditStaffComponent implements OnChanges {
	@Input() show_modal = false;
	@Input() staffuuid: string;
	@Input() companyUuid: string;
	@Output() closeModal: EventEmitter<any> = new EventEmitter();
	edit: boolean;
	loading: boolean = false;
	isVisible: boolean = false;
	staff: any;
	staff_permissions: any = [];
	form_data: any = {
		"email": "",
		"companyUuid": "",
		"permissions": []


	}
	submitted_button: boolean = false;

	constructor(
		private staff_services: StaffService,
		private popNotif: PopNotificationService,
		private alert: AlertService,
		public permissions: PermissionsService
	) { }

	ngOnChanges(): void {
		if (this.show_modal) {
			this.form_data.email = '';
			this.form_data.permissions = [];
			this.submitted_button = false;
			if (this.staffuuid === 'none') {
				this.edit = false;
				this.staff = {}
				this.staff.permission_type = 'view only';
				this.showModal();
			} else if (this.staffuuid != 'none') {
				this.getStaffByUuid(this.staffuuid);
				this.edit = true;

			}
		}
	}

	ngOnInit() {
		this.permissions.getStaffPermissions().then((res) => {
			this.getCustomPermissions();
		}).catch(e => {
			console.log(e);
		})
	}

	getStaffByUuid(uuid) {     // load the staff profile in the modal
		this.staff_services.getStaffProfile(uuid).then((result) => {
			this.staff = result;
			this.staff.permission_type = this.comparePermissions(this.staff.permissions, this.permissions.all_permissions);
			this.showModal();
		}).catch((err) => { });
	}

	comparePermissions(arr1, arr2) {  //check user permissions against api
		if (arr1.length >= arr2.length) {
			this.permissions.all_permissions.forEach(element => {
				this.form_data.permissions.push(element)
			});
			return 'full access';
		}
		else if (arr1.length === 0) {
			this.form_data.permissions = []
			return 'view only';
		}
		else {
			this.customPermissions(arr1, this.staff_permissions);
			return 'custom';
		}
	}

	customPermissions(arr1, arr2) {    // check which permissions they have
		arr1.forEach(elem1 => {
			arr2.forEach(elem2 => {
				if (elem1.type === elem2.title) {
					elem2.selected = true;
					this.form_data.permissions.push(elem1.type)
				}
			})
		});
	}

	selectPermissionType(type) {
		switch (type) {
			case 'full access':
				this.staff.permission_type = 'full access'
				this.form_data.permissions = []
				this.staff_permissions.forEach(element => {
					element.selected = false;
				});
				this.permissions.all_permissions.forEach(element => {
					this.form_data.permissions.push(element)
				});
				break;
			case 'view only':
				this.staff.permission_type = 'view only'
				this.form_data.permissions = []
				this.staff_permissions.forEach(element => {
					element.selected = false;
				});
				break;
			case 'custom':
				this.staff.permission_type = 'custom'
				this.form_data.permissions = []
				this.staff_permissions.forEach(element => {
					if (element.name) {
						this.form_data.permissions.push(element.title)
						element.selected = true;
					}
				});
				break;
		}
	}

	getCustomPermissions() { //create an array for custom checkboxes
		this.permissions.all_permissions.forEach(element => {
			if (element != 'company.staff.manage' && element != 'company.company.edit') {
				this.staff_permissions.push({
					'title': element,
					'name': '',
					'selected': false
				})
			}
		});
		this.staff_permissions.forEach(elem => {
			switch (elem.title) {
				case 'company.jobs.edit':
					elem.name = 'Post, Edit, Delete Jobs';
					break;
				case 'company.shifts.edit':
					elem.name = 'Add/Edit Shifts';
					break;
				case 'company.applications.edit':
					elem.name = 'Schedule Applicants';
					break;
				case 'company.timesheets.edit':
					elem.name = 'Actioning Timesheets';
					break;
			}
		})
	}

	changePermissions(permission) {    //unselect/select custom permissions
		permission.selected = !permission.selected
		if (permission.selected) {
			this.form_data.permissions.push(permission.title)
		}
		else if (!permission.selected) {
			this.form_data.permissions.forEach(function (element, index, object) {
				if (element === permission.title) {
					object.splice(index, 1);
				}
			});

		}
	}

	updateStaff() {   // submit permissions change to api
		this.submitted_button = true;
		if (this.staffuuid !== 'none' && this.form_data.permissions) {
			this.loading = true;
			this.staff_services.amendStaffPermissions(this.staffuuid, this.form_data.permissions)
				.then(() => {
					this.modalClose(true);
					this.popNotif.createNotification('success', 'Success', 'The staff member has been updated.');
					this.loading = false;
				})
				.catch(err => {
					this.popNotif.createNotification('error', 'There has been a problem.', err);
					this.loading = false;
				});
		}
	}

	showModal(): void {
		this.isVisible = true;
	}

	modalClose(refresh?: boolean): void {
		this.isVisible = false;
		this.closeModal.emit({ refresh });
		this.staff = null;
		this.staff_permissions.forEach(element => {
			element.selected = false;

		});
	}

	saveOrInvite() {    // if there is staff run save & if no staff run createNewStaff
		if (this.staffuuid != 'none') {
			this.updateStaff();
		} else {
			this.createNewStaff();
		}
	}

	public onCancelClick(): void {
		this.modalClose(true);
	}

	createNewStaff() { // send staff invite email
		this.submitted_button = true;
		if (this.form_data && this.form_data.email && this.form_data.permissions) {
			this.loading = true;
			this.form_data.companyUuid = this.companyUuid;
			this.staff_services.sendStaffInvite(this.form_data).then(() => {
				this.popNotif.createNotification('success', 'Success', `An email has been sent to ${this.form_data.email}`);
				this.modalClose(true);
				this.loading = false;
			})
				.catch(err => {
					this.popNotif.createNotification('error', 'There has been a problem.', err);
					this.loading = false;
				});
		}
	}

	deleteStaff() {    // delete staff member
		if (!this.staffuuid) {
			return;
		}
		this.alert.showConfirm("Are you sure?", "This staff member will be deleted and cannot be recovered.").then(() => {
			this.staff_services.deleteStaffProfile(this.staffuuid)
				.then(() => {
					this.modalClose(true);
					this.popNotif.createNotification('success', 'Success', 'The staff member has been deleted successfully.');

				}).catch(e => {
					this.popNotif.createNotification('error', 'There has been a problem.', e.error.message);
				});

		}).catch(() => {
		});
	}
}





