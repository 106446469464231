<nz-range-picker nzInline nzInputReadOnly [nzDateRender]="tplRender" [nzPlaceHolder]="['View Schedule']"
	class="nz-override-view-schedule" nzDropdownClassName="nz-override-date-range-drop nz-override-view-schedule-drop"></nz-range-picker>

<ng-template #tplRender let-current>
	<div class="ant-picker-cell-inner ">
		{{ current.getDate() }}
	</div>
	<div [class.selected]="handleSelection(current)">
	</div>

</ng-template>