<lib-modal [show_modal]="isVisible" (closeModal)="modalClose()" btn_color_primary="primary"
    [btn_label_primary]="!edit ? 'Invite':'Save'" (clickPrimary)="saveOrInvite()" btn_label_secondary="Cancel"
    (clickSecondary)="onCancelClick()" [btn_label_link]="!edit ? '' : 'Remove User'"
    (clickLink)="staff ? deleteStaff() : null">
    <div class="overlay-form-modal">
        <form *ngIf="show_modal" #form="ngForm">

            <div class="modal-staff-container">

                <!-- selected staff member -->
                <div *ngIf="staffuuid != 'none'" class="selected-staff-container">
                    <nz-avatar [nzText]='staff?.full_name[0] | uppercase' [nzSrc]="staff?.avatar" nzSize="small">
                    </nz-avatar>
                    <p class="b-font-demibold full-name">{{staff?.full_name}}</p>

                </div>

                <!-- invite staff member -->
                <div *ngIf="staffuuid === 'none'" class="no-selected-staff-container">
                    <!-- <p class="b-font-demibold invite-staff">Invite Staff Member</p> -->
                    <label for="staff-email">Invite Staff Member</label>
                    <div class="input-underlined">
                        <input class="b-input b-input-white" type="email" placeholder="Enter Email Address" name="email"
                            [(ngModel)]="form_data.email" email="true" required #emailAddress='ngModel'
                            id="staff-email">
                    </div>
                    <div *ngIf="(emailAddress.dirty || emailAddress.touched) && (submitted_button)">
                        <p class="error-box" *ngIf="emailAddress.invalid && emailAddress?.errors.required">Email is
                            required.</p>
                        <p class="error-box"
                            *ngIf="emailAddress.invalid && !emailAddress?.errors.required && emailAddress?.errors.email">
                            Email is invalid.</p>
                    </div>

                </div>

                <!-- new permissions -->
                <div class="new-permissions">
                    <div class="sub-title">
                        <p class="b-font-demibold invite-staff">Admin (Full access)</p>
                        <label class="b-checkbox">
                            <input type="radio" [checked]="staff?.permission_type === 'full access'"
                                (click)="selectPermissionType('full access')">
                            <span class="checkmark"></span>
                        </label>
                    </div>
                    <p class="perm-text">Admins will have full access to the platform, as well as the ability to
                        invite, edit and remove
                        staff.</p>
                </div>
                <div class="new-permissions">
                    <div class="sub-title">
                        <p class="b-font-demibold invite-staff">Member</p>
                        <label class="b-checkbox">
                            <input type="radio" [checked]="staff?.permission_type === 'custom'"
                                (click)="selectPermissionType('custom')"> <span class="checkmark"></span>
                        </label>
                    </div>
                    <p class="perm-text">Set the permissions you want to give a staff member from the options
                        below.
                        This can be edited
                        later. </p>
                    <div class="member-permissions">
                        <div class="member-items" *ngFor="let permission of staff_permissions">
                            <label class="b-checkbox">
                                <span class="p">{{permission?.name}}</span>
                                <input type="checkbox" (ngModelChange)="changePermissions(permission)"
                                    [name]="permission?.title" [ngModel]="permission?.selected"
                                    [disabled]="staff?.permission_type != 'custom'"> <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="new-permissions">
                    <div class="sub-title">
                        <p class="b-font-demibold invite-staff">view only</p>
                        <label class="b-checkbox">
                            <input type="radio" [checked]="staff?.permission_type === 'view only'"
                                (click)="selectPermissionType('view only')">
                            <span class="checkmark"></span>
                        </label>
                    </div>
                    <p class="perm-text">Staff will only be able to view the platform, they will not be able to perform
                        any actions. </p>
                </div>

            </div>


        </form>
    </div>
</lib-modal>