<div class="admin-bar-wrapper">
	<div class="orka-logo">
		<img width="24" height="28" src="assets/logo/orka-logo.svg" alt="Orka Logo" />
	</div>
	<div class="admin-bar">
		<div>
			<p [routerLink]="['./companies']" routerLinkActive="activeTab" class="b-font-demibold">Companies</p>
		</div>
		<div>
			<p [routerLink]="['./workers']" routerLinkActive="activeTab" class="b-font-demibold">Workers</p>
		</div>
		<div>
			<p class="b-font-demibold" (click)="logout()">Log Out</p>
		</div>
	</div>


</div>
<section>
	<router-outlet></router-outlet>
</section>