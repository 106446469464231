import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CancellationPolicyComponent } from './cancellation-policy.component';
import { PolicyModule } from '../policy/policy.module';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { LoadingSpinnerModule } from '@broadstone/loading-spinner';


@NgModule({
    declarations: [CancellationPolicyComponent],
    exports: [CancellationPolicyComponent],
    imports: [
        CommonModule,
        PolicyModule,
        NzPopoverModule,
        LoadingSpinnerModule
    ]
})
export class CancellationPolicyModule { }
