import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { JobSearchAreaComponent } from './job-search-area.component';

import { ListDropDownModule } from '../list-drop-down/list-drop-down.module';
import { SearchInputModule } from '@broadstone/search-input';
import { ClibButtonModule } from '@broadstone/clib-button';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';

@NgModule({
  declarations: [JobSearchAreaComponent],
  exports: [JobSearchAreaComponent],
  imports: [
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
	NzSwitchModule,
	NzDatePickerModule,
    CommonModule,
    ListDropDownModule,
    SearchInputModule,
	ClibButtonModule
  ]
})
export class JobSearchAreaModule { }
