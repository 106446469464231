import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { MapWorkersComponent } from './map-workers.component';
import { ClibButtonModule } from '@broadstone/clib-button';
import { LoadingSpinnerModule } from '@broadstone/loading-spinner';


@NgModule({
  declarations: [MapWorkersComponent],
  imports: [
    CommonModule,
	BrowserModule,
	ClibButtonModule,
	LoadingSpinnerModule
  ],
  exports: [MapWorkersComponent]
})
export class MapWorkersModule { }
