import { AuthSessionService } from '@broadstone/auth-session-service';
import { ApiService } from '@broadstone/api-service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DatesHelper } from '@broadstone/helpers';
import { LoggingService } from '../logging/logging.service';
import { EmployeeAdapter } from './employee.model';
import { UrlHelpers } from '../helpers/url-filters';

@Injectable({
	providedIn: 'root'
})
export class EmployeesService {



	constructor(
		public http: HttpClient,
		public api: ApiService,
		private session: AuthSessionService,
		private adapter: EmployeeAdapter,
		public date_helper: DatesHelper,
		public logger: LoggingService,
		public urlFilters: UrlHelpers,
	) {
	}


	public getAllWorkers(page, limit, filter?) {

		return new Promise((resolve, reject) => {
			let urlBuilder = `admin/employees?page=${page}&limit=${limit}`
			if (filter?.email) {
				urlBuilder += `&email=${this.api.encode_submission(filter?.email)}`;
			}
			if (filter?.mobile) {
				urlBuilder += `&mobile=${this.api.encode_submission(filter?.mobile)}`;
			}
			if (filter?.name) {
				urlBuilder += `&name=${this.api.encode_submission(filter?.name)}`;
			}
			if (filter?.sector) {
				urlBuilder += `&sector=${filter?.sector}`;
			}
			if (filter?.suspended !== null) {
				urlBuilder += `&suspended=${filter?.suspended}`;
			}
			if (filter?.banned !== null) {
				urlBuilder += `&banned=${filter?.banned}`;
			}
			if (filter?.canWork) {
				urlBuilder += `&can_work=${filter?.canWork}`;
			}
			if (filter?.left !== null) {
				urlBuilder += `&left=${filter?.left}`;
			}
			if (filter?.createdStart && filter?.createdEnd) {
				const todaysDate = new Date();
				const start = this.date_helper.adapt_dates('start_at', filter.createdStart, this.date_helper);
				let end = this.date_helper.adapt_dates('end_at', filter.createdEnd, this.date_helper);
				if (new Date(filter.createdEnd).setHours(0, 0, 0, 0) == todaysDate.setHours(0, 0, 0, 0)) {
					end = this.date_helper.utc_date(new Date().setMinutes(0, 0, 0));
				}
				urlBuilder += `&start=${start}&end=${end}`;
			}

			const url = this.api.getFullUrl(urlBuilder);
			const headers = this.api.getDefaultHeaders(this.session.getToken());
			return this.http.get(url, { headers })
				.subscribe(
					(data) => {
						const adaptedData = data['data'].map(item => {
							return this.adapter.adapt(item);
						});
						const metaData = { ...data['meta'] };

						const result = {
							data: adaptedData,
							metaData
						};
						resolve(result);
					},
					(error) => reject(this.api.handleErrors(error))
				);
		})


	}
	public getSpecificWorker(userUuid) {

		return new Promise((resolve, reject) => {
			let urlBuilder = `admin/employees/${userUuid}`
			const url = this.api.getFullUrl(urlBuilder);
			const headers = this.api.getDefaultHeaders(this.session.getToken());
			return this.http.get(url, { headers })
				.subscribe(
					(result) => {
						const adaptedData = this.adapter.adaptEmployeeWorkStatus(result['data']);
						resolve(adaptedData);
					},
					(error) => reject(this.api.handleErrors(error))
				);
		})
	}
	public updateWorker(user, userUuid) {
		return new Promise((resolve, reject) => {
			const url = this.api.getFullUrl('admin/employees/:userUuid', { userUuid });
			const headers = this.api.getDefaultHeaders(this.session.getToken());
			return this.http.patch(url, user, { headers })
				.subscribe(
					data => resolve(data),
					error => reject(this.api.handleErrors(error))
				);
		});
	}

	public suspendWorker(userObj): Promise<any> {
		const url = this.api.getFullUrl('admin/user/suspend');
		const headers = this.api.getDefaultHeaders(this.session.getToken());
		return new Promise((resolve, reject) => {
			this.http.post(url, userObj, { headers }).subscribe(
				(data) => {
					resolve(data);
				},
				(error) => reject(this.api.handleErrors(error))
			)
		});
	}

	public UnSuspendWorker(userObj): Promise<any> {
		const url = this.api.getFullUrl('admin/user/unsuspend');
		const headers = this.api.getDefaultHeaders(this.session.getToken());
		return new Promise((resolve, reject) => {
			this.http.post(url, userObj, { headers }).subscribe(
				(data) => {
					resolve(data);
				},
				(error) => reject(this.api.handleErrors(error))
			)
		});
	}
	public leftAtWorker(userObj): Promise<any> {
		const url = this.api.getFullUrl('admin/user/leaver');
		const headers = this.api.getDefaultHeaders(this.session.getToken());
		return new Promise((resolve, reject) => {
			this.http.patch(url, userObj, { headers }).subscribe(
				(data) => {
					resolve(data);
				},
				(error) => reject(this.api.handleErrors(error))
			)
		});
	}
	public reJoinWorker(userObj): Promise<any> {
		const url = this.api.getFullUrl('admin/user/rejoin');
		const headers = this.api.getDefaultHeaders(this.session.getToken());
		return new Promise((resolve, reject) => {
			this.http.patch(url, userObj, { headers }).subscribe(
				(data) => {
					resolve(data);
				},
				(error) => reject(this.api.handleErrors(error))
			)
		});
	}
}