import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { PostShiftsComponent } from './post-shifts.component';
import { ModalModule } from '@broadstone/modal';
import { SelectDropDownModule } from '../../select-drop-down/select-drop-down.module';
import { CancellationPolicyModule } from '../../cancellation-policy/cancellation-policy.module';
import { ClibButtonModule } from '@broadstone/clib-button';
import { PostShiftPatternsModalComponent } from './post-shift-patterns-modal/post-shift-patterns-modal.component';
import { ViewScheduleComponentComponent } from './view-schedule-component/view-schedule-component.component';
import { LoadingSpinnerModule } from '@broadstone/loading-spinner';
import { PoolsCreateShiftModule } from '../../pools-create-shift/pools-create-shift.module';
import { WorkerRowModule } from '../../worker-row/worker-row.module';
import { SearchInputModule } from '@broadstone/search-input';
import { WorkerProfileModule } from '@broadstone/worker-profile';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';





@NgModule({
    declarations: [PostShiftsComponent, PostShiftPatternsModalComponent, ViewScheduleComponentComponent],
    exports: [PostShiftsComponent, PostShiftPatternsModalComponent],
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        BrowserAnimationsModule,
        HttpClientModule,
        ReactiveFormsModule,
        NzDatePickerModule,
		NzPopoverModule,
		NzToolTipModule,
        SelectDropDownModule,
        CancellationPolicyModule,
        ClibButtonModule,
        ModalModule,
        LoadingSpinnerModule,
        PoolsCreateShiftModule,
        WorkerRowModule,
        SearchInputModule,
        WorkerProfileModule


    ]
})
export class PostShiftsModule { }
