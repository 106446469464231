import { Injectable } from '@angular/core';

export interface PurchaseOrder {
    uuid: string,
    purchase_order: string,
    description: string,
    sites: any
}

@Injectable({
    providedIn: 'root'
})
export class InvoiceSettingsAdapater {
    adapt_purchase_order(data): PurchaseOrder {
        return {
            "uuid": data.uuid,
            "purchase_order": data.purchase_order,
            "description": data.description,
            "sites": data.sites
           
        }
    }
}