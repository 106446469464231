import { Component } from '@angular/core';


@Component({
	selector: 'app-admin-companies',
	templateUrl: './admin-companies.component.html',
	styleUrls: ['./admin-companies.component.scss']
})
export class AdminCompaniesComponent {
	constructor(

	) { }



}
