<div class="main-container">
    <img src="./assets/logo/orka-word.svg" id="logo">
    <div class="sign-up-confirm-container" *ngIf="!selectMethod">
        <form #form="ngForm">
            <div class="phone-code">
                <div class="phone-input" *ngFor="let digit of authCode">
                    <input type="text" [id]="digit.id" [name]="digit.id" [(ngModel)]="digit.numValue" maxlength="1"
                        (keyup)="submitAuthCode(digit)" (paste)="onPaste($event)">
                </div>
            </div>
        </form>
        <p class="b-Eh4 b-light">{{ contactMethod.format }} with a verification code has been sent to <span
                class="b-font-demibold">{{ contactMethod.contact }}</span>. Please enter the code
            to
            continue.</p>
    </div>
    <div class="choose-method-container" *ngIf="selectMethod">
        <form #form="ngForm">
            <p>Two factor login is enabled for your account. Please select one of the
                options
                below to verify your account.</p>
            <div *ngFor="let option of options" class="row-spacing">
                <div class="options-container">
                    <div>
                        <p class="b-Eh4 b-light">{{option.text}}</p>
                    </div>
                    <div class="button-container">
                        <app-clib-button [label]="option.title" (pressAction)="selectTfaOption(option.option)">
                        </app-clib-button>
                    </div>

                </div>
            </div>

        </form>
    </div>
</div>