import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { PopNotificationService } from '@broadstone/pop-notification-service';
import { AlertService } from '@broadstone/alert';
import { EventsTracker } from 'src/services/public_api';


@Component({
	selector: 'app-manage-site',
	templateUrl: './manage-site.component.html',
	styleUrls: ['./manage-site.component.scss']
})
export class ManageSiteComponent implements OnInit {
	pageSetup = {
		breadcrumbs: [],
		pageTitle: '',
		extraInfo: ''
	};
	siteUuid: any;
	site_name: string;
	private sub: any;

	constructor(
		private event_tracker: EventsTracker,
		private _location: Location,
		private route: ActivatedRoute,
		private popNotif: PopNotificationService,
		private router: Router,
		private alert: AlertService
	) { }

	ngOnInit() {
		this.sub = this.route.params.subscribe(params => {
			this.siteUuid = params['site_uuid']; // (+) converts string 'id' to a number
		});
	}

	ngOnDestroy() {
		this.sub.unsubscribe();
	}


	receiveSiteName($event) {
		this.site_name = $event;
	}

	redirectBack() {
		this.popNotif.createNotification('error', 'There has been a problem.', 'Site not found.');
		this._location.back();
	}

	redirect(res) {
		if (res && res.uuid) {
			this.event_tracker.trackEvent('create_site', res);
			if (res.edit) {
				this._location.back();
			}
			else {
				this.router.navigate(['jobs/role/' + res.uuid + '/create'])
			}
		}
	}

	goBack() {
		this.alert.showConfirm("Unsaved changes", "Are you sure you want to leave this page? The changes won't be applied until you save the site.", 'Continue', 'Cancel').then(() => {
			this.router.navigate(['/jobs']);
		}).catch(() => {
			//cancel alert
		});
	}



}
