import { AuthSessionService } from '@broadstone/auth-session-service';
import { ApiService } from '@broadstone/api-service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApplicationsAdapter } from './applications.model';
import { DatesHelper } from '@broadstone/helpers';
import { FiltersServices } from '../filters/filters.service';
import { LoggingService } from '../logging/logging.service';
import { CachingService } from '../caching-service';




@Injectable({
	providedIn: 'root'
})
export class ApplicationsService {

	allApplications = [];

	constructor(
		public http: HttpClient,
		public api: ApiService,
		private session: AuthSessionService,
		private adapter: ApplicationsAdapter,
		public date_helper: DatesHelper,
		private filters_service: FiltersServices,
		public logger: LoggingService,
		private cachingService: CachingService
	) {

	}

	public getApplicants(job, page, limit, filters?, shift?, cache: boolean = false): Promise<any> {
		const now = this.date_helper.utc_date(new Date().setSeconds(0, 0));
		filters = this.filters_service.adapt_filters(filters, this.date_helper);
		return new Promise((resolve, reject) => {
			let filter = `companies/applications/applicants?`;
			if (job) {
				filter += `job=${job}&active=${filters.active_toggle ? 1 : 0}`;
			}
			if (shift) {
				filter += `shift=${shift}`;
			}
			if (filters && filters.applicant_name) {
				filter += `&name=${filters.applicant_name}`;
			}
			if (filters.start_at && filters.end_at) {
				const start = this.date_helper.adapt_dates('start_at', filters.start_at, this.date_helper);
				const end = this.date_helper.adapt_dates('end_at', filters.end_at, this.date_helper);
				filter += `&shift.start_at=${start}<>${end}`;
			}
			else if (filters.active_toggle) {
				filter += `&shift.end_at=>=${now}`;
			}
			else if (!filters.active_toggle) {
				filter += `&shift.end_at=<=${now}`;
			}

			if (filters && filters.status) {
				filter += `&status=${filters.status}`;
			}
			filter += `&page=${page}&limit=${limit}`;

			const url = this.api.getFullUrl(filter);

			const headers = this.api.getDefaultHeaders(this.session.getToken());
			if (cache) {
				this.cachingService.addCache(url, 30, true);
			}else{
				this.cachingService.removeCache(url);
			}
			return this.http.get(url, { headers })
				.subscribe(
					(data) => {

						const adapted_data = data['data'].map(item => {
							return this.adapter.adaptApplicant(item, this.logger);
						});
						const result = this.api.adapt_pagination(adapted_data, data);
						resolve(result);
					},
					(error) => reject(this.api.handleErrors(error))
				)
		});
	}

	recordMissingApplicants(data) {
		return new Promise((resolve, reject) => {
			const url = this.api.getFullUrl(`company/applicant-log`);
			const headers = this.api.getDefaultHeaders(this.session.getToken());
			return this.http.post(url, data, { headers })
				.subscribe(
					(data) => {
						resolve(data);
					},
					(error) => reject(this.api.handleErrors(error))
				)
		});
	}

	public getApplicantsByShift(shiftUuid, status?, page = 1, limit = 9999) {
		return new Promise((resolve, reject) => {
			let filter = `companies/shifts/${shiftUuid}/applicants?`;
			if (status) {
				filter += `&status=${status}`;
			}
			filter += `&page=${page}&limit=${limit}`;

			const url = this.api.getFullUrl(filter);

			const headers = this.api.getDefaultHeaders(this.session.getToken());
			return this.http.get(url, { headers })
				.subscribe(
					(data) => {
						const adapted_data = data['data'].map(item => {
							return this.adapter.adaptApplicantApplication(item);
						});
						const result = this.api.adapt_pagination(adapted_data, data);
						resolve(result);
					},
					(error) => reject(this.api.handleErrors(error))
				)
		});
	}

	public getApplicantProfile(profileUuid): Promise<any> {
		return new Promise((resolve, reject) => {
			const url = this.api.getFullUrl('companies/applications/applicants/:profileUuid', { profileUuid });
			const headers = this.api.getDefaultHeaders(this.session.getToken());
			this.cachingService.addCache(url, 30, true);
			return this.http.get(url, { headers })
				.subscribe(
					(data) => {
						const result = this.adapter.adaptApplicantProfile(data, this.logger);
						resolve(result);
					},
					(error) => reject(this.api.handleErrors(error))
				)
		});
	}


	public assignWorkerTrainedOnSite(siteUuid, jobProfileUuid): Promise<any> {
		return new Promise((resolve, reject) => {
			const url = this.api.getFullUrl(`companies/sites/${siteUuid}/favourite/${jobProfileUuid}`);
			const headers = this.api.getDefaultHeaders(this.session.getToken());
			return this.http.put(url, null, { headers })
				.subscribe(
					(data) => {
						resolve(data);
					},
					(error) => reject(this.api.handleErrors(error))
				)
		});
	}
	public unassignWorkerTrainedOnSite(siteUuid, jobProfileUuid): Promise<any> {
		return new Promise((resolve, reject) => {
			const url = this.api.getFullUrl(`companies/sites/${siteUuid}/un-favourite/${jobProfileUuid}`);
			const headers = this.api.getDefaultHeaders(this.session.getToken());
			return this.http.delete(url, { headers })
				.subscribe(
					(data) => {
						resolve(data);
					},
					(error) => reject(this.api.handleErrors(error))
				)
		});
	}

	public blacklistFromSite(obj, reason): Promise<any> {
		return new Promise((resolve, reject) => {
			const url = this.api.getFullUrl(`company/sites/${obj.site}/blacklist/${obj.uuid}`);
			const headers = this.api.getDefaultHeaders(this.session.getToken());
			return this.http.post(url, reason, { headers })
				.subscribe(
					(data) => {
						resolve(data);
					},
					(error) => reject(this.api.handleErrors(error))
				)
		});
	}

	public removeBlacklistFromSite(worker, site): Promise<any> {
		return new Promise((resolve, reject) => {
			const url = this.api.getFullUrl(`company/sites/${site}/blacklist/${worker}`);
			const headers = this.api.getDefaultHeaders(this.session.getToken());
			return this.http.delete(url, { headers })
				.subscribe(
					(data) => {
						resolve(data);
					},
					(error) => reject(this.api.handleErrors(error))
				)
		});
	}

	public getBlacklistStatus(obj): Promise<any> {
		return new Promise((resolve, reject) => {
			const url = this.api.getFullUrl(`company/sites/${obj.site}/blacklist/${obj.applicant}`);
			const headers = this.api.getDefaultHeaders(this.session.getToken());
			return this.http.get(url, { headers })
				.subscribe(
					(data) => {
						resolve(data);
					},
					(error) => reject(this.api.handleErrors(error))
				)
		});
	}

	public getSiteBlacklisted(siteUuid): Promise<any> {
		return new Promise((resolve, reject) => {
			const url = this.api.getFullUrl(`company/sites/${siteUuid}/blacklist`);
			const headers = this.api.getDefaultHeaders(this.session.getToken());
			this.cachingService.addCache(url, 120, true);
			return this.http.get(url, { headers })
				.subscribe(
					(data) => {
						const adapted_data = data['data'].map(item => {
							return this.adapter.adaptBlackListedWorker(item);
						});
						resolve({ data: adapted_data });
					},
					(error) => reject(this.api.handleErrors(error))
				)
		});
	}

	public getApplications(shifts, applicants, filters?, page = 1, cache:boolean = false): Promise<any> {
		return new Promise((resolve, reject) => {
			let filter = `companies/applications?shifts=${shifts}`;
			if (applicants) {
				filter += `&applicants=${applicants}`;
			}
			if (filters && filters.status) {
				filter += `&status=${filters.status}`;
			}
			if (filters.active_toggle !== null) {
				filter += `&active=${filters.active_toggle ? 1 : 0}`;
			}
			filter += `&limit=1000&page=${page}`;
			const url = this.api.getFullUrl(filter);
			const headers = this.api.getDefaultHeaders(this.session.getToken());
			if (cache) {
				this.cachingService.addCache(url, 30, true);
			}else{
				this.cachingService.removeCache(url);
			}
			return this.http.get(url, { headers })
				.subscribe(
					(data) => {
						data['data'].forEach(element => {
							const found = this.allApplications.filter(existsElement => {
								return element.uuid === existsElement.uuid;
							});
							if (found && found.length === 0) {
								this.allApplications.push(element);
							}

						});
						const adapted_data = this.allApplications.map(item => {
							return this.adapter.adaptApplications(item, this.logger);
						});
						if (data['links'] && data['links'].next) {
							this.getApplications(shifts, applicants, filters, page + 1, true);
						}
						resolve({ data: adapted_data });
					},
					(error) => reject(this.api.handleErrors(error))
				)
		});
	}
	public approveApplications(applications) {
		return new Promise((resolve, reject) => {
			const url = this.api.getFullUrl('companies/applications/accept');
			const headers = this.api.getDefaultHeaders(this.session.getToken());
			return this.http.put(url, applications, { headers })
				.subscribe(
					(data: any) => {
						const adapted_data = data['data'].map(item => {
							return this.adapter.adaptApplications(item, this.logger);
						});
						let rejected_applications = [];
						if (data && data['rejected_applications'] && data['rejected_applications'].length > 0) {
							rejected_applications = data['rejected_applications'].map(item => {
								return this.adapter.adaptRejectApplications(item);
							});
						}

						let errorsObject: any;
						if (data && data['errors'] && Object.keys(data.errors).length > 0) {
							for (let key in data.errors) {
								errorsObject = {
									...data.errors,
									[key]: data.errors[key]
								}
							}
							errorsObject = this.adapter.adaptErrorApplications(errorsObject)
						}
						const new_data = { data: adapted_data, rejected: rejected_applications, errors: errorsObject };
						resolve(new_data);
					},
					(error) => reject(this.api.handleErrors(error))
				)
		});
	}
	public declineApplications(applications) {
		return new Promise((resolve, reject) => {
			const url = this.api.getFullUrl('companies/applications/decline');
			const headers = this.api.getDefaultHeaders(this.session.getToken());
			return this.http.put(url, applications, { headers })
				.subscribe(
					(data) => {
						const adapted_data = data['data'].map(item => {
							return this.adapter.adaptApplications(item, this.logger);
						});
						resolve(adapted_data);
					},
					(error) => reject(this.api.handleErrors(error))
				)
		});
	}

	public dashboardApplications(shifts, page, limit, applicant_name, status?) {
		return new Promise((resolve, reject) => {
			let filter = `companies/dashboard/applications?shifts=${shifts}`;

			if (status) {
				filter += `&status=${status}`;
			}
			if (applicant_name) {
				filter += `&applicant_name=${applicant_name}`;
			}
			filter += `&page=${page}&limit=${limit}`;
			const url = this.api.getFullUrl(filter);
			const headers = this.api.getDefaultHeaders(this.session.getToken());
			return this.http.get(url, { headers })
				.subscribe(
					(data) => {
						const adapted_data = data['data'].map(item => {
							return this.adapter.adaptDashboardApplication(item, this.logger);
						});
						const result = this.api.adapt_pagination(adapted_data, data);
						resolve(result);
					},
					(error) => reject(this.api.handleErrors(error))
				)
		});
	}

	public workersWorked(applicant_name, page, limit) {
		return new Promise((resolve, reject) => {
			let filter = `company/pools/workers?&name=`;
			if (applicant_name) {
				filter += `${applicant_name}`;
			}
			filter += `&limit=${limit}&page=${page}`;
			const url = this.api.getFullUrl(filter);
			const headers = this.api.getDefaultHeaders(this.session.getToken());
			return this.http.get(url, { headers })
				.subscribe(
					(data) => {
						const adapted_data = data['data'].map(item => {
							return this.adapter.adaptWorkersWorked(item);
						});
						const metaData = { ...data['meta'] };

						const result = {
							data: adapted_data,
							metaData
						};
						resolve(result);
					},
					(error) => reject(this.api.handleErrors(error))
				);
		});
	}

	public canWorkCheck(obj) {

		let filter = `company/pools/is-worker-available?startAt=${obj.startAtList}&endAt=${obj.endAtList}&siteUuid=${obj.siteUuid}&workerUuids=${obj.workerUuids}`;

		return new Promise((resolve, reject) => {
			const url = this.api.getFullUrl(filter);
			const headers = this.api.getDefaultHeaders(this.session.getToken());
			return this.http.get(url, { headers })
				.subscribe(
					(data) => {
						resolve(data);
					},
					(error) => reject(this.api.handleErrors(error))
				)
		});
	}

	public setNoShow(uuid) {
		return new Promise((resolve, reject) => {
			const url = this.api.getFullUrl('companies/dashboard/:uuid/no-show', { uuid });
			const headers = this.api.getDefaultHeaders(this.session.getToken());
			return this.http.post(url, null, { headers })
				.subscribe(
					(data) => {
						resolve(data);
					},
					(error) => reject(this.api.handleErrors(error))
				)
		});
	}

	public revertNoShow(uuid) {
		return new Promise((resolve, reject) => {
			const url = this.api.getFullUrl('companies/dashboard/:uuid/no-show/revert', { uuid });
			const headers = this.api.getDefaultHeaders(this.session.getToken());
			return this.http.post(url, null, { headers })
				.subscribe(
					(data) => {
						resolve(data);
					},
					(error) => reject(this.api.handleErrors(error))
				);
		});
	}

	public getAllWorkersCount(uuid) {
		return new Promise((resolve, reject) => {
			let filter = `company/pools/${uuid}/workers/radius/count`;

			const url = this.api.getFullUrl(filter);
			const headers = this.api.getDefaultHeaders(this.session.getToken());
			return this.http.get(url, { headers })
				.subscribe(
					(data) => {
						resolve(data['data']);
					},
					(error) => reject(this.api.handleErrors(error))
				);
		});
	}
	public getAllWorkersWorkedCount(uuid) {
		return new Promise((resolve, reject) => {
			let filter = `company/pools/${uuid}/workers/worked/count`;

			const url = this.api.getFullUrl(filter);
			const headers = this.api.getDefaultHeaders(this.session.getToken());
			return this.http.get(url, { headers })
				.subscribe(
					(data) => {
						resolve(data['data']);
					},
					(error) => reject(this.api.handleErrors(error))
				);
		});
	}

	public getIncidentReports(page): Promise<any> {
		return new Promise((resolve, reject) => {
			const url = this.api.getFullUrl(`company/incident-reports?page=${page}`);
			const headers = this.api.getDefaultHeaders(this.session.getToken());
			this.cachingService.addCache(url, 60);
			return this.http.get(url, { headers })
				.subscribe(
					(data) => {
						const adapted_data = data['data'].map(item => {
							return this.adapter.adaptIncidentReport(item);
						});
						const metaData = { ...data['meta'] };
						const response = { data: adapted_data, metaData }
						resolve(response);
					},
					(error) => reject(this.api.handleErrors(error))
				)
		});
	}

}
