import { Injectable } from '@angular/core';
import { Site } from '../companies/companies.model';
import { Service, Industry, ServiceRole } from './sectors-services.model';
import { Requirements, clientRequirements } from './requirements.model';
import { GlobalAdapter } from '../adapter';


export interface JobSetup {
	site: string,
	title: string,
	sector_title: string,
	description: string,
	invoice_desc: string,
	vacancy_service_id: string,
	vacancy_service_title: string,
	vacancy_service_role_uuid: string,
	vacancy_industry_id: string,
	requirements: Array<Requirements>,
	client_requirements: Array<clientRequirements>,
	payment_method: string,
	worker_hourly_rate: number,
	bank_holiday_rate: number,
	smart_staff: boolean,
	uniform_required: boolean,
	uniform_description: string,
	uniform_image: string,
	reference: string,
	require_worker_within: number,
	location_type: string,
	uuid: string
}
export interface Job {
	applications_pending_count?: number,
	auto_fill_enabled: number,
	applications_total_count?: number,
	new_cancellation_count?: number,
	requirements: Array<Requirements>,
	client_requirements: Array<clientRequirements>,
	shifts_count?: number,
	shifts_filled_count?: number,
	bank_holiday_rate?: number,
	smart_staff: boolean,
	created_at: string,
	description: string,
	invoice_desc?: string,
	last_shift_created_at: string,
	vacancy_service: Service,
	vacancy_service_role: ServiceRole,
	vacancy_industry: Industry,
	sector_title?: string,
	payment_method: string,
	reference: string,
	require_worker_within: number,
	location_type: string,
	site: Site,
	title: string,
	uniform_description: string,
	uniform_image: string,
	uniform_required: boolean,
	updated_at: string,
	uuid: string,
	worker_hourly_rate: string,
}

export interface JobPay {
	amounts: any,
	fee: any,
	additional_fee: any,
	holiday: any,
	minimum: any,
	payment_method: any,
}

export interface JobTypeRate {
	minimum_worker_hourly_rate: number,
	minimum_worker_worker_hourly_rate_effective_date: string,
	post_effective_date_min_worker_hourly_rate: number
	instant_pay_rate: number,
	fee_rate: number,
}

export interface PayRates {
	contractor: JobTypeRate,
	paye: JobTypeRate
}




@Injectable({
	providedIn: 'root'
})
export class JobAdapter {

	constructor(
		public adapter: GlobalAdapter
	) {

	}

	jobModel = [
		'applications_pending_count',
		'applications_total_count',
		'auto_fill_enabled',
		'new_cancellation_count',
		'requirements',
		'client_requirements',
		'shifts_count',
		'shifts_filled_count',
		'bank_holiday_rate',
		'smart_staff',
		'created_at',
		'description',
		'vacancy_service',
		'vacancy_service_role',
		'vacancy_industry',
		'payment_method',
		'reference',
		'require_worker_within',
		'location_type',
		'site',
		'title',
		'uniform_description',
		'uniform_image',
		'uniform_required',
		'updated_at',
		'uuid',
		'worker_hourly_rate',
		'job_id'
	]
	jobModelCreation = [
		'requirements',
		'client_requirements',
		'bank_holiday_rate',
		'smart_staff',
		'created_at',
		'description',
		'vacancy_service',
		'vacancy_service_role',
		'vacancy_industry',
		'payment_method',
		'reference',
		'require_worker_within',
		'location_type',
		'site',
		'title',
		'uniform_description',
		'uniform_image',
		'uniform_required',
		'updated_at',
		'uuid',
		'worker_hourly_rate',
		'job_id'
	]

	adaptRole(job, logger): Job {
		logger.logMissingObjProps('Job', job, this.jobModel);
		return this.adapter.globalAdapting(this.jobModel, job) as Job;
	}

	adaptRoleCreation(job, logger): Job {
		logger.logMissingObjProps('Job', job, this.jobModelCreation);
		return this.adapter.globalAdapting(this.jobModelCreation, job) as Job;
	}

	adapt_pay(data): JobPay {
		return {
			'amounts': data.amounts,
			'fee': data.fee,
			'additional_fee': data.additional_fee,
			'holiday': data.holiday,
			'minimum': data.minimum,
			'payment_method': data.payment_method,
		}
	}
	adapt_pay_rate(data): PayRates {
		return {
			"contractor": data.contractor,
			"paye": data.paye
		}
	}

}
