import { Component, OnInit } from '@angular/core';
import { CompaniesService } from 'src/services/public_api';
import { PopNotificationService } from '@broadstone/pop-notification-service';

@Component({
	selector: 'app-company-edit',
	templateUrl: './company-edit.component.html',
	styleUrls: ['./company-edit.component.scss']
})
export class CompanyEditComponent implements OnInit {
	currentUser: any;
	current_company: any;
	constructor(
		private companies: CompaniesService,
		private popNotif: PopNotificationService,
	) { }

	ngOnInit() {
		this.getCompanyInfo();
	}

	getCompanyInfo() {
		this.companies.getCompanyInfo().then((result) => {
			this.current_company = result;
		}).catch(err => {
			this.popNotif.createNotification('error', 'There has been a problem.', 'Please try again later.');
			// this.logger.error(err);
		});
	}

}
