import { Injectable } from '@angular/core';

export interface TimesheetData {
  amended_clocked_in_at: string
  amended_clocked_out_at: string
  clocked_in_at: string
  clocked_in_latitude: string
  clocked_in_longitude: string
  clocked_out_at: string
  clocked_out_latitude: string
  clocked_out_longitude: string
  hours_worked: number
  timesheet_status: string
  uuid: string
}

export interface WorkerData {
  firstName: string;
  surname: string;
  avatarPath: string;
}

export interface ShiftData {
  hourlyRate: string;
  startAt: string;
  endAt: string;
}

export interface SiteData {
  address1: string;
  address2: string;
  city: string;
  county: string;
  region: string;
  postcode: string;
  country: string;
  siteLat: string;
  siteLng: string;
}


export interface Timesheet {
  shift: ShiftData;
  site: SiteData;
  timesheet: TimesheetData;
  billable: any;
  user: WorkerData;
  client: string;
  cost: number;
  uuid: string;
  job_profile: any;
  deleted_at: string;
}


@Injectable({
  providedIn: 'root'
})
export class TimesheetAdapter {
  adaptTimesheet(data): Timesheet {
    return {
      shift: data.shift,
      site: data.site,
      timesheet: data.timesheet,
      billable: data.billable,
      user: data.user,
      client: data.client,
      cost: data.cost,
      uuid: data.uuid,
      job_profile: data.job_profile,
      deleted_at: data.deleted_at
    }
  }
}
