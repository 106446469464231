import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { EditShiftsComponent } from './edit-shifts.component';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { LoadingSpinnerModule } from '@broadstone/loading-spinner';
import { PolicyModule } from '../policy/policy.module';
import { ClibButtonModule } from '@broadstone/clib-button';
import { ModalModule } from '@broadstone/modal';
import { DirectivesModule } from 'src/directives/directives.module';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';

@NgModule({
  declarations: [EditShiftsComponent],
  exports: [EditShiftsComponent],
  imports: [
    CommonModule,
	NzDatePickerModule,
	NzAvatarModule,
	NzToolTipModule,
	NzPopoverModule,
	NzSkeletonModule,
    BrowserModule,
    FormsModule,
    DirectivesModule,
    LoadingSpinnerModule,
    ClibButtonModule,
    PolicyModule,
    ModalModule
  ]
})
export class EditShiftsModule { }
